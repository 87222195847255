import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    requestAdminAddEmail,
    requestAdminAllEmail,
    requestAdminEmail, requestAdminAllUser,
    requestAdminDeleteEmail,
    requestAdminEditEmail,
} from "../Redux/actions";
import Swal from "sweetalert2";

function Email(props) {

    const [pagination, setpagination] = useState({});
    const navigate = useNavigate();
    const [change, setchange] = useState(false);
    const [pages, setpages] = useState([]);
    const [page_size, setpage_size] = useState(0);
    const [error, seterror] = useState("");
    const [id, setid] = useState(0);
    const [email, setemail] = useState([]);
    const [username, setusername] = useState([]);
    const [adddata, setadddata] = useState({
        name: "", email: "", user_id: 0, password: "", port: "", encryption: "ssl", host: "", admin_id: 0
    });
    const [editdata, seteditdata] = useState({
        name: "", email: "", user_id: 0, password: "", port: "", encryption: "", host: "", admin_id: 0
    });
    const params = useParams();
    const [addmodal, setaddModal] = useState(false);
    const [editmodal, seteditModal] = useState(false);
    const [erroremail, seterroremail] = useState("");
    const [errorpassword, seterrorpassword] = useState("");
    const [errorport, seterrorport] = useState("");
    const [errorencryption, seterrorencryption] = useState("");
    const [errorhost, seterrorhost] = useState("");
    const [uid, setUID] = useState('');

    useEffect(() => {
        let formfieldData = props.data.loginData.data.data;
        setUID(formfieldData.id);

    }, [props.data.loginData.data.data]);

    useEffect(() => {
        props.requestAdminAllUser({
            page: 1,
            page_size: 100,
            admin_id: uid
        });
    }, [uid]);

    useEffect(() => {
        let allUserData = props.data.allUserData;
        if (allUserData !== undefined) {
            if (allUserData.data) {
                setusername(allUserData.data.data);
            }
        }
    }, [props.data.allUserData]);

    function validateadd() {
        let formIsValid = false;
        if (!adddata["name"]) {
            formIsValid = false;
            seterror("*Enter Email Remark.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateemail() {
        let formIsValid = false;
        if (!adddata["email"]) {
            formIsValid = false;
            seterroremail("*Enter Email Address.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validatepassword() {
        let formIsValid = false;
        if (!adddata["password"]) {
            formIsValid = false;
            seterrorpassword("*Enter password.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateport() {
        let formIsValid = false;
        if (!adddata["port"]) {
            formIsValid = false;
            seterrorport("*Enter port number.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validatehost() {
        let formIsValid = false;
        if (!adddata["host"]) {
            formIsValid = false;
            seterrorhost("*Enter host.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateAddForm() {
        let validate = validateadd(); let email = validateemail(); let password = validatepassword();
        let port = validateport(); let host = validatehost();
        let valid = validate && email && password && port && host;
        return valid;
    }

    function validateedit() {
        let formIsValid = false;
        if (!editdata["name"]) {
            formIsValid = false;
            seterror("*Enter Email Name.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateeditemail() {
        let formIsValid = false;
        if (!editdata["email"]) {
            formIsValid = false;
            seterroremail("*Enter Email Address.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateeditpassword() {
        let formIsValid = false;
        if (!editdata["password"]) {
            formIsValid = false;
            seterrorpassword("*Enter password.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateeditport() {
        let formIsValid = false;
        if (!editdata["port"]) {
            formIsValid = false;
            seterrorport("*Enter port number.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateedithost() {
        let formIsValid = false;
        if (!editdata["host"]) {
            formIsValid = false;
            seterrorhost("*Enter host.");
        }
        else {
            formIsValid = true;
        }
        return formIsValid;
    }

    function validateEditForm() {
        let validate = validateedit(); let email = validateeditemail(); let password = validateeditpassword();
        let port = validateeditport(); let host = validateedithost();
        let valid = validate && email && password && port && host;
        return valid;
    }

    function onchangeadddata(e) {
        setadddata((adddata) => ({
            ...adddata,
            [e.target.name]: e.target.value,
        }));
    }

    function onchangeeditdata(e) {
        seteditdata((editdata) => ({
            ...editdata,
            [e.target.name]: e.target.value,
        }));
    }

    const addtoggle = () => setaddModal(!addmodal);
    const saveaddtoggle = (e) => {        
        e.preventDefault();
        if (validateAddForm()) {
            props.requestAdminAddEmail({
                data: {
                    name: adddata.name,
                    email: adddata.email, user_id: adddata.user_id, password: adddata.password,
                    port: adddata.port, encryption: adddata.encryption, host: adddata.host, admin_id: uid
                },
            });
            setadddata({
                name: "", email: "", user_id: 0, password: "", port: 0, encryption: "", host: "",
            });
            addtoggle();
        }
    };

    useEffect(() => {
        let addEmailData = props.data.addEmailData;
        if (addEmailData !== undefined) {
            if (addEmailData.data) {
                Swal.fire({
                    title: 'Good job!',
                    text: 'Email Settings added successfully..',
                    icon: 'success'
                });
                setchange(!change)
            }
        }
        props.data.addEmailData = undefined;
    }, [props.data.addEmailData]);

    const edittoggle = (id) => {

        if (editmodal === true) {
            seteditModal(!editmodal);
        } else {
            seteditModal(!editmodal);
            setid(id);
            seteditdata({
                name: "", email: "", user_id: 0, password: "", port: 0, encryption: "", host: "",
            });
            props.requestAdminEmail({
                id: id,
            });
        }
    };

    useEffect(() => {
        let email = props.data.emailData;
        if (email !== undefined) {
            if (email.data) {
                seteditdata(email.data.data.email_master[0]);
            }
        }
    }, [props.data.emailData]);

    const saveedittoggle = (e) => {
        e.preventDefault();

        if (validateEditForm()) {
            props.requestAdminEditEmail({
                id: id,
                data: {
                    name: editdata.name,
                    email: editdata.email, user_id: editdata.user_id, password: editdata.password,
                    port: editdata.port, encryption: editdata.encryption, host: editdata.host, admin_id: uid
                },
            });
            seteditdata({
                name: "", email: "", user_id: 0, password: "", port: 0, encryption: "", host: "",
            });
            setid(0);
            edittoggle();
        }
    };

    useEffect(() => {
        let editEmailData = props.data.editEmailData;
        if (editEmailData !== undefined) {
            if (editEmailData.data) {
                Swal.fire({
                    title: 'Good job!',
                    text: 'Email settings updated successfully..',
                    icon: 'success'
                });
                setchange(!change)
            }
        }
        props.data.editEmailData = undefined;
    }, [props.data.editEmailData]);

    useEffect(() => {
        props.requestAdminAllEmail({
            page: params.page,
            page_size: params.page_size,
            user_id: uid
        });
        setpage_size(params.page_size);
    }, [params.page, params.page_size, change]);

    function onChangePageSize(e) {
        setpage_size(e.target.value);
        if (e.target.value > 0) {
            navigate(`/admin/email/1/${e.target.value}`);
        }
    }

    useEffect(() => {
        let allEmailData = props.data.allEmailData;
        if (allEmailData !== undefined) {
            if (allEmailData.data) {
                setemail(allEmailData.data.data);
                setpagination(allEmailData.data.meta);
                if (allEmailData.data.meta.last_page < 5) {
                    let array = [];
                    Array.from(Array(allEmailData.data.meta.last_page), (e, i) => {
                        array.push(i + 1);
                    });
                    setpages(array);
                } else {
                    let array = [];
                    if (allEmailData.data.meta.current_page <= 3) {
                        array.push(1, 2, 3, 4, 5);
                        setpages(array);
                    } else if (
                        allEmailData.data.meta.last_page - allEmailData.data.meta.current_page <=
                        2
                    ) {
                        array.push(
                            allEmailData.data.meta.last_page - 4,
                            allEmailData.data.meta.last_page - 3,
                            allEmailData.data.meta.last_page - 2,
                            allEmailData.data.meta.last_page - 1,
                            allEmailData.data.meta.last_page
                        );
                        setpages(array);
                    } else {
                        array.push(
                            Number(allEmailData.data.meta.current_page) - 2,
                            Number(allEmailData.data.meta.current_page) - 1,
                            allEmailData.data.meta.current_page,
                            Number(allEmailData.data.meta.current_page) + 1,
                            Number(allEmailData.data.meta.current_page) + 2
                        );
                        setpages(array);
                    }
                }
            }
        }
    }, [props.data.allEmailData]);

    const CustomUI = ({ onSubmit, onCancel }) => (
        <div
            className="custom-ui"
            style={{
                backgroundColor: "white",
                width: "350px",
                padding: "25px",
                border: "1px solid black",
                margin: "auto",
            }}
        >
            <h3>Are you sure?</h3>
            <p>
                Do you really want to delete this records? This process cannot be
                undone.
            </p>
            <div>
                <button
                    onClick={onSubmit}
                    style={{ border: "0px", backgroundColor: "green", color: "white", padding: "2px 10px" }}
                >
                    Yes
                </button>
                <button
                    onClick={onCancel}
                    style={{
                        border: "0px",
                        backgroundColor: "red",
                        color: "white",
                        marginLeft: "10px",
                        padding: "2px 10px"
                    }}
                >
                    No
                </button>
            </div>
        </div>
    );
    function deletedata(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            console.log(id);
            console.log(uid);
            if (result.isConfirmed) {
                props.requestAdminDeleteEmail({
                    id: id, user_id: uid
                });
            }
        })
    }

    useEffect(() => {
        let deleteEmailData = props.data.deleteEmailData;
        if (deleteEmailData !== undefined) {
            if (deleteEmailData.data) {
                Swal.fire({
                    title: 'Good job!',
                    text: 'Email settings deleted successfully..',
                    icon: 'success'
                });
                setchange(!change)
            }
        }
        props.data.deleteEmailData = undefined;
    }, [props.data.deleteEmailData]);

    function printPage() {
        var printContents =
            "<br/><center><h3>Emails</h3></center>" +
            document.getElementById("printme").innerHTML;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    return (
        <>
            <div class="container-scroller">
                <Header name="Email Settings" />
                <div class="container-fluid page-body-wrapper">
                    <Sidebar name="Options" />

                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="row flex-grow">
                                            <div class="col-12 grid-margin stretch-card">
                                                <div class="card card-rounded">
                                                    <div class="card-body">
                                                        <div class="d-sm-flex justify-content-between align-items-start">
                                                            <div class="btn-wrapper">
                                                                <RWebShare
                                                                    data={{
                                                                        text: "Job Portal",
                                                                        url: `http://localhost:3000/admin/email/${params.page}/${params.page_size}`,
                                                                        title: "Job Portal",
                                                                    }}
                                                                >
                                                                    <p class="btn btn-otline-dark align-items-center">
                                                                        <i class="icon-share"></i> Share
                                                                    </p>
                                                                </RWebShare>
                                                                <button
                                                                    onClick={printPage}
                                                                    class="btn btn-otline-dark"
                                                                >
                                                                    <i class="icon-printer"></i> Print
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    class="btn btn-primary btn-md text-white mb-0 me-0"
                                                                    type="button"
                                                                    onClick={addtoggle}
                                                                >
                                                                    Add new email
                                                                </button>
                                                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                                                    <ModalHeader toggle={addtoggle}>
                                                                        Add Email Settings
                                                                    </ModalHeader>
                                                                    <ModalBody>
                                                                        <form class="forms-sample">
                                                                            <div class="form-group">
                                                                                <label>Username or Type</label>
                                                                                <select
                                                                                    class="form-select"
                                                                                    name="user_id"
                                                                                    value={adddata.user_id}
                                                                                    onChange={onchangeadddata}
                                                                                >
                                                                                    <option value="0">General</option>
                                                                                    <option value="-1">Reset Password</option>
                                                                                    {username.map((option) => (
                                                                                        <option value={option.id}>
                                                                                            {option.name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <label>Email Name</label>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    placeholder="name"
                                                                                    name="name"
                                                                                    value={adddata.name}
                                                                                    onBlur={validateadd}
                                                                                    onChange={onchangeadddata}
                                                                                />
                                                                                {error && <p>{error}</p>}
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <label>Email ID</label>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    placeholder="Email Address"
                                                                                    name="email"
                                                                                    value={adddata.email}
                                                                                    onBlur={validateemail}
                                                                                    onChange={onchangeadddata}
                                                                                />
                                                                                {erroremail && <p>{erroremail}</p>}
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Password</label>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    placeholder="Password"
                                                                                    name="password"
                                                                                    value={adddata.password}
                                                                                    onBlur={validatepassword}
                                                                                    onChange={onchangeadddata}
                                                                                />
                                                                                {errorpassword && <p>{errorpassword}</p>}
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Port</label>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    placeholder="Port"
                                                                                    name="port"
                                                                                    value={adddata.port}
                                                                                    onBlur={validateport}
                                                                                    onChange={onchangeadddata}
                                                                                />
                                                                                {errorport && <p>{errorport}</p>}
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Encryption</label>
                                                                                <select
                                                                                    class="form-select"
                                                                                    name="encryption"
                                                                                    value={adddata.encryption}
                                                                                    onChange={onchangeadddata}
                                                                                >
                                                                                    <option value="ssl">SSL</option>
                                                                                    <option value="tls">TLS</option>
                                                                                    <option value="">Nothing</option>
                                                                                </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Host</label>
                                                                                <input
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    placeholder="Host e.g. smtp.wensiteaddress"
                                                                                    name="host"
                                                                                    value={adddata.host}
                                                                                    onBlur={validatehost}
                                                                                    onChange={onchangeadddata}
                                                                                />
                                                                                {errorhost && <p>{errorhost}</p>}
                                                                            </div>
                                                                            <button
                                                                                type="submit"
                                                                                class="btn btn-primary me-2"
                                                                                onClick={saveaddtoggle}
                                                                            >
                                                                                Submit
                                                                            </button>
                                                                            <button
                                                                                class="btn btn-light"
                                                                                onClick={addtoggle}
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </form>
                                                                    </ModalBody>
                                                                </Modal>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <div class="table-responsive  mt-1" id="printme">
                                                            <table class="table select-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Name</th>
                                                                        <th>Email</th>
                                                                        <th>Port</th>
                                                                        <th>Encryption</th>
                                                                        <th>Host</th>
                                                                        <th>Added By</th>
                                                                        <th>Created At</th>
                                                                        <th>Updated At</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {email.map((item, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.name}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.email}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.port}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.encryption}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.host}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div class="d-flex ">
                                                                                        <div>
                                                                                            <h6>{item.added_by}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{item.created_at !== null ? item.created_at.substring(0, 10) : ''}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p>{item.updated_at !== null ? item.updated_at.substring(0, 10) : ''}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        class="badge badge-opacity-success"
                                                                                        style={{
                                                                                            border: "0px",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            edittoggle(item.id);
                                                                                        }}
                                                                                    >
                                                                                        <i class="fa fa-pencil-square-o"></i>
                                                                                    </button>
                                                                                    {editmodal === true && (
                                                                                        <Modal
                                                                                            isOpen={editmodal}
                                                                                            toggle={edittoggle}
                                                                                        >
                                                                                            <ModalHeader toggle={edittoggle}>
                                                                                                Edit Email names
                                                                                            </ModalHeader>
                                                                                            <ModalBody>
                                                                                                <form class="forms-sample">
                                                                                                    <div class="form-group">
                                                                                                        <label>Username or Type</label>
                                                                                                        <select
                                                                                                            class="form-select"
                                                                                                            name="user_id"
                                                                                                            value={editdata.user_id}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        >
                                                                                                            <option value="0">General</option>
                                                                                                            <option value="-1">Reset Password</option>
                                                                                                            {username.map((option) => (
                                                                                                                <option value={option.id}>
                                                                                                                    {option.name}
                                                                                                                </option>
                                                                                                            ))}
                                                                                                        </select>
                                                                                                    </div>

                                                                                                    <div class="form-group">
                                                                                                        <label>Email Name</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder="name"
                                                                                                            name="name"
                                                                                                            value={editdata.name}
                                                                                                            onBlur={validateedit}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        />
                                                                                                        {error && <p>{error}</p>}
                                                                                                    </div>

                                                                                                    <div class="form-group">
                                                                                                        <label>Email ID</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder="Email editress"
                                                                                                            name="email"
                                                                                                            value={editdata.email}
                                                                                                            onBlur={validateeditemail}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        />
                                                                                                        {erroremail && <p>{erroremail}</p>}
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <label>Password</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder="Password"
                                                                                                            name="password"
                                                                                                            value={editdata.password}
                                                                                                            onBlur={validateeditpassword}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        />
                                                                                                        {errorpassword && <p>{errorpassword}</p>}
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <label>Port</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder="Port"
                                                                                                            name="port"
                                                                                                            value={editdata.port}
                                                                                                            onBlur={validateeditport}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        />
                                                                                                        {errorport && <p>{errorport}</p>}
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <label>Encryption</label>
                                                                                                        <select
                                                                                                            class="form-select"
                                                                                                            name="encryption"
                                                                                                            value={editdata.encryption}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        >
                                                                                                            <option value="ssl">SSL</option>
                                                                                                            <option value="tls">TLS</option>
                                                                                                            <option value="">Nothing</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div class="form-group">
                                                                                                        <label>Host</label>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder="Host e.g. smtp.wensiteeditress"
                                                                                                            name="host"
                                                                                                            value={editdata.host}
                                                                                                            onBlur={validateedithost}
                                                                                                            onChange={onchangeeditdata}
                                                                                                        />
                                                                                                        {errorhost && <p>{errorhost}</p>}
                                                                                                    </div>
                                                                                                    <button
                                                                                                        type="submit"
                                                                                                        class="btn btn-primary me-2"
                                                                                                        onClick={saveedittoggle}
                                                                                                    >
                                                                                                        Submit
                                                                                                    </button>
                                                                                                    <button
                                                                                                        class="btn btn-light"
                                                                                                        onClick={edittoggle}
                                                                                                    >
                                                                                                        Cancel
                                                                                                    </button>
                                                                                                </form>
                                                                                            </ModalBody>
                                                                                        </Modal>
                                                                                    )}
                                                                                    <button
                                                                                        class="badge badge-opacity-danger"
                                                                                        style={{
                                                                                            border: "0px",
                                                                                            background: "#fea8a9",
                                                                                            marginLeft: "5px",
                                                                                        }}
                                                                                        onClick={() => {

                                                                                            deletedata(item.id);

                                                                                        }}
                                                                                    >
                                                                                        <i class="fa fa-trash"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-10">
                                                                <div class="pagination center">
                                                                    <ul class="pagination-list">
                                                                        {pagination.current_page !== 1 && (
                                                                            <li>
                                                                                <a
                                                                                    href={`/admin/email/${params.page - 1}/${params.page_size
                                                                                        }`}
                                                                                >
                                                                                    <i class="fa fa-long-arrow-left"></i>
                                                                                </a>
                                                                            </li>
                                                                        )}
                                                                        {pages.map((i) => {
                                                                            return pagination.current_page === i ? (
                                                                                <li class="active">
                                                                                    <a
                                                                                        href={`/admin/email/${i}/${params.page_size}`}
                                                                                    >
                                                                                        {i}
                                                                                    </a>
                                                                                </li>
                                                                            ) : (
                                                                                <li>
                                                                                    <a
                                                                                        href={`/admin/email/${i}/${params.page_size}`}
                                                                                    >
                                                                                        {i}
                                                                                    </a>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                        {pagination.current_page !==
                                                                            pagination.last_page && (
                                                                                <li>
                                                                                    <a
                                                                                        href={`/admin/email/${Number(params.page) + 1
                                                                                            }/${params.page_size}`}
                                                                                    >
                                                                                        <i class="fa fa-long-arrow-right"></i>
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="col-2">
                                                                <div class="pagination center">
                                                                    <form onSubmit={onChangePageSize}>
                                                                        <div class="form-group">
                                                                            <input
                                                                                type="number"
                                                                                class="form-control"
                                                                                placeholder={page_size}
                                                                                name="page_size"
                                                                                value={page_size}
                                                                                onChange={onChangePageSize}
                                                                                style={{ width: "100px" }}
                                                                            />
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestAdminAddEmail,
            requestAdminAllEmail,
            requestAdminEmail, requestAdminAllUser,
            requestAdminDeleteEmail,
            requestAdminEditEmail,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Email);
