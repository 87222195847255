import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { requestAddJob, requestAdminJobs, requestDeleteJob, requestEditJob, requestJobDetails, requestStatusJob } from "../Redux/actions";
import Footer from './footer';
import PageHeader from './header';
import Sidebar from './sidebar';
import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import { DataTable, Header, HeaderRow, HeaderCell, Body, Row, Cell, } from '@table-library/react-table-library/table';
import DataTable from 'react-data-table-component';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon } from 'react-share';

function Jobs(props) {
  const params = useParams();
  const [jobs, setjobs] = useState([]);
  // const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(10);
  const [change, setchange] = useState(false);
  const [totalRows, setTotalRows] = useState(0);

  const [companies, setcompanies] = useState([]);
  const [statusmodal, setstatusModal] = useState(false);
  const [id, setid] = useState(0);
  const [statusdata, setstatusdata] = useState({ status: 0 });

  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState([]);
  const [logindetails, setLoginDetails] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const handleSearch = (event) => {
    console.log(event.target.value);
    setSearch(event.target.value);
  };

  const columns = [
    {
      name: 'Company',
      cell: item => <div><a href={'/admin/viewCompany/' + item.company_id}></a>
        <div>
          <a href={'/admin/viewCompany/' + item.company_id}>   <h6>{item.company_name}</h6></a>
          <p>
            <i class="fa fa-map-marker"></i>{" "}
            {item.locations}
          </p>
        </div>
      </div>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Job Title',
      cell: item => <a href={'/admin/viewJob/' + item.id}>  <h6>{item.title}
        <p>Industry: {item.industry_name}</p>
        <p>Function Area: {item.functional_area_name}</p></h6></a>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Salary',
      cell: item => <div><h6>{item.currency} {item.salary_from}-{item.salary_to}</h6>
        <p>{item.min_exp > 0 ? 'Exp.: ' + item.min_exp + ' to ' + item.max_exp + " years" : "Fresher"}</p></div>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Dates',
      cell: item => <div><h6>Created Date: {item.created_at.substring(0, 10)}</h6>
        <p>End Date: {item.expiry_date}</p></div>,
      selector: item => item.created_at,
      width: '22%'
    },
    {
      name: 'Applications',
      cell: item => <div>Total:
        <a href={'/admin/candidates/1/10/' + item.id + "/Sourcing"}>
          <button
            class="badge badge-opacity-warning"
            style={{ border: "0px" }}
          >
            <i class="fa fa-chart"></i>
            {item.cand_count}
          </button>
        </a>
        <br />
        New: {item.new_cand_count}</div>,
      selector: item => item.id,
      width: '14%'
    },
    {
      name: 'Action',
      cell: item => <div>
        {logindetails["job_status"] != 0 ?
          <a onClick={() => { statustoggle(item.id); }} title="Change Status">
            <button
              class={'badge ' + (item.status === 2 ? 'badge-opacity-success' : item.status === 1 ? 'badge-opacity-dark' : item.status === 3 ? 'badge-opacity-warning' : item.status === 4 ? 'badge-opacity-danger' : 'badge-opacity-light')}
              style={{ border: "0px" }}
            >
              <i class="fa fa-toggle-on"></i>
            </button>
          </a>

          : ''}
        {statusmodal === true && (
          <Modal
            isOpen={statusmodal}
            toggle={statustoggle}
          >
            <ModalHeader toggle={statustoggle}>
              Change Status of the Job
            </ModalHeader>
            <ModalBody>
              <form class="forms-sample">
                <div class="item">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Status</label>
                      <select
                        class="form-select"
                        name="status"
                        value={statusdata.status}
                        onChange={event => onchangestatusdata(event, item.status)}
                      >
                        <option value="1">Open</option>
                        <option value="3">Hold</option>
                        <option value="2">Closed</option>
                        <option value="4">Cancelled</option>
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="submit" class="btn btn-primary me-2" onClick={savestatustoggle} >
                  Submit
                </button>
                <button
                  class="btn btn-light"
                  onClick={statustoggle}
                >
                  Cancel
                </button>
              </form>
            </ModalBody>
          </Modal>
        )}

        {logindetails["job_edit"] != 0 ?
          <a href={`/admin/jobedit/${item.id}`} title="Edit Job">
            <button
              class="badge badge-opacity-success"
              style={{ border: "0px" }}>
              <i class="fa fa-pencil-square-o"></i>
            </button>
          </a> : ''
        }
        {logindetails["job_delete"] != 0 ?
          <button class="badge badge-opacity-danger" style={{ border: "0px", background: "#fea8a9", marginLeft: "5px", }}
            onClick={() => { deletedata(item.id); }}><i class="fa fa-trash"></i></button> : ''
        }
        {logindetails["job_question"] != 0 ?
          <Link class="badge badge-opacity-warning" to={`/admin/question/${item.id}`}
            state={{ job_title: item.title +' for '+ item.company_name }}
          ><i class="fa fa-question"></i></Link> : ''
        }
      </div>,
      selector: item => item.id,
      width: '100px'
    },
  ];

  //form field
  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    setLoginDetails(formfieldData.admin_detail);
    console.log(logindetails.admin_id);

    setcompanies(formfieldData.companies);
  }, [props.data.loginData.data.data]);

  const onchangestatusdata = (event, param1) => {
    // console.log(event.target.value);
    // console.log(param1);
    setstatusdata((statusdata) => ({
      ...statusdata,
      [event.target.name]: event.target.value,
    }));
  };

  //request job list
  useEffect(() => {
    props.requestAdminJobs({
      page: params.page,
      page_size: params.page_size,
      search: ''
    });
    setpage_size(params.page_size);

  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/jobs/1/${e.target.value}`);
    }
  }

  //set job list
  useEffect(() => {
    let jobsData = props.data.jobsData;

    if (jobsData !== undefined) {
      if (jobsData.data) {
        console.log(jobsData.data);
        setjobs(jobsData.data.data);
        setFilter(jobsData.data.data);
        setTotalRows(jobsData.data.meta.total);
      }
    }
  }, [props.data.jobsData]);

  const handlePageChange = page => {
    console.log(page);
    props.requestAdminJobs({
      page: page,
      page_size: page_size,
      search: ''
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setpage_size(newPerPage);
    props.requestAdminJobs({
      page: page,
      page_size: newPerPage,
      search: ''
    });
  }

  function printPage() {
    var printContents = "<br/><center><h3>Jobs</h3></center>" + document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }


  const statustoggle = (id) => {
    if (statusmodal === true) {
      setstatusModal(!statusmodal);
    } else {
      setstatusModal(!statusmodal);
      setid(id);
      //        setstatusdata({ status: 0 });
      props.requestJobDetails({
        id: id,
      });
    }
  };

  const savestatustoggle = (e) => {
    e.preventDefault();
    props.requestStatusJob({
      id: id,
      data: { status: statusdata.status },
    });
    console.log(statusdata.status);
    setid(0);
    statustoggle();
  }

  useEffect(() => {
    let statusJobData = props.data.empStatusJobData;
    if (statusJobData !== undefined) {
      if (statusJobData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Job Updated Successfully.",
          "success"
        );
        props.data.empStatusJobData = undefined;
        setstatusdata({ status: 0 });
        setchange(!change);
      } else {
        Swal.fire("Error!", `${statusJobData.data.message}`, "error");
        props.data.empStatusJobData = undefined;
      }
    }
  }, [props.data.empStatusJobData]);


  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestDeleteJob({
          id: id,
        });
      }
    })
  }

  //delete
  useEffect(() => {
    let deleteJobData = props.data.deleteJobData;
    if (deleteJobData !== undefined) {
      if (deleteJobData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Job deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteJobData = undefined;
  }, [props.data.deleteJobData]);

  useEffect(() => {
    setTypingTimeout(setTimeout(() => {
      props.requestAdminJobs({
        page: 1,
        page_size: page_size,
        search: search
      });
    }, 3000));
  }, [search]);

  const tableHeaderStyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px"
      },
    },
  }
  return (
    <>
      <div class="container-scroller">
        <PageHeader name="Jobs" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Jobs" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "HR Ace",
                                    url: `http://localhost:3000/admin/jobs/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center"><i class="icon-share"></i> Share</p>
                                </RWebShare>
                                <button onClick={printPage} class="btn btn-otline-dark"><i class="icon-printer"></i> Print</button>
                              </div>
                            </div>
                            <div class="row">
                              {logindetails["job_add"] != 0 ?
                                <div class="col-md-3">
                                  <a href={`/admin/jobadd`} title="Add Job">
                                    <button class="btn btn-primary btn-md text-white mb-0 me-0" type="button" > Add new Job </button>
                                  </a>
                                </div> : ''
                              }
                              <div class="row">
                                <div class="col-md-3"><label htmlFor="search">Job Status Color codes : </label></div>
                                <div class="col-md-2"><button class='badge badge-opacity-success' style={{ border: "0px" }}>Fullfilled</button></div>
                                <div class="col-md-2"><button class='badge badge-opacity-dark' style={{ border: "0px" }}>Pending</button></div>
                                <div class="col-md-2"><button class='badge badge-opacity-warning' style={{ border: "0px" }}>Hold</button></div>
                                <div class="col-md-2"><button class='badge badge-opacity-danger' style={{ border: "0px" }}>Cancelled</button></div>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <DataTable
                                columns={columns}
                                data={filter}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                actions={<button className="btn btn-success">Export to PDF</button>}
                                subHeader
                                subHeaderComponent={
                                  <input id="search" type="text" className="w-25 form-control" placeholder="Search..." value={search} onChange={handleSearch} />
                                }
                                customStyles={tableHeaderStyle}
                              />
                              {/* <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Company</th>
                                    <th>Job Title</th>
                                    <th>Salary</th>
                                    <th>Dates</th>
                                    <th>Applications</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {jobs.map((item, index) => {
                                    // const img = item.employee_logo
                                    //     ? process.env.REACT_APP_API_HOST + item.employee_logo
                                    //     : image;
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <a href={`/admin/viewCompany/${item.company_id}`}></a>
                                            <div>
                                              <a href={`/admin/viewCompany/${item.company_id}`}>   <h6>{item.company_name}</h6></a>
                                              <p>
                                                <i class="fa fa-map-marker"></i>{" "}
                                                {item.city_name},{" "}
                                                {item.state_name},{" "}
                                                {item.country_name}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <a href={`/admin/viewJob/${item.id}`}>  <h6>{item.title}</h6>
                                            <p>Industry: {item.industry_name}</p>
                                            <p>Function Area: {item.functional_area_name}</p></a>
                                        </td>
                                        <td>
                                          <h6>{item.currency} {item.salary_from}-{item.salary_to}</h6>
                                          <p>{item.experience > 0 ? item.experience + "year experienced" : "Fresher"}</p>
                                        </td>
                                        <td>
                                          <h6>Created Date: {item.created_at.substring(0, 10)}</h6>
                                          <p>End Date: {item.expiry_date}</p>
                                        </td>
                                        <td>
                                          Total:
                                          <a href={`/admin/candidates/1/10/${item.id}`}>
                                            <button
                                              class="badge badge-opacity-warning"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-chart"></i>
                                              {item.cand_count}
                                            </button>
                                          </a>
                                          <br />
                                          New: {item.new_cand_count}
                                        </td>
                                        <td>
                                          <a onClick={() => { statustoggle(item.id); }} title="Change Status">
                                            <button
                                              class={'badge ' + (item.status === 1 ? 'badge-opacity-success' : item.status === 2 ? 'badge-opacity-warning' : 'badge-opacity-danger')}
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-toggle-on"></i>
                                            </button>
                                          </a>
                                          {statusmodal === true && (
                                            <Modal
                                              isOpen={statusmodal}
                                              toggle={statustoggle}
                                            >
                                              <ModalHeader toggle={statustoggle}>
                                                Change Status of the Job
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                      <div class="form-group">
                                                        <label>Status</label>
                                                        <select
                                                          class="form-select"
                                                          name="status"
                                                          value={statusdata.status}
                                                          onChange={event => onchangestatusdata(event, item.status)}
                                                        >
                                                          <option value="1">Open</option>
                                                          <option value="2">Hold</option>
                                                          <option value="3">Closed</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    type="submit" class="btn btn-primary me-2" onClick={savestatustoggle} >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={statustoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <a href={`/admin/viewJob/${item.id}`}>
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-eye"></i>
                                            </button>
                                          </a>
                                          <a href={`/admin/jobedit/${item.id}`} title="Edit Job">
                                            <button
                                              class="badge badge-opacity-success"
                                              style={{ border: "0px" }}>
                                              <i class="fa fa-pencil-square-o"></i>
                                            </button>
                                          </a>
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {

                                              deletedata(item.id);

                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table> */}
                            </div>
                            {/* <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/jobs/${params.page - 1
                                            }/${params.page_size}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/jobs/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/jobs/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/jobs/${Number(params.page) + 1
                                              }/${params.page_size}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminJobs, requestAddJob, requestJobDetails, requestEditJob, requestDeleteJob, requestStatusJob }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Jobs);