import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminCandidateDetails, requestCandSearch, requestStatusCandidate, requestStatusCandidateRemark } from "../Redux/actions";
import image from "../images/profile.png";
import { format } from 'date-fns';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";

function ViewCandidate(props) {

  const params = useParams();
  const [data, setdata] = useState({});
  const [experience, setexperience] = useState([{
    title: "",
    company: "",
    start_month: "",
    start_year: "",
    leave_month: "",
    leave_year: "",
    achieve: "",
  },
  ]);
  const [education, seteducation] = useState([{
    degree: "",
    course: "",
    specialization: "",
    university: "",
    start: "",
    leave: "",
    achieve: "",
  },
  ]);

  const [img, setimg] = useState("");
  const [resume, setresume] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [logindetails, setLoginDetails] = useState([]);
  const [degree_levels, setdegree_levels] = useState([]);
  const [status, setStatus] = useState("");
  const [jobassignmodal, setjobassignModal] = useState(false);
  const [remarkmodal, setremarkmodal] = useState(false);
  const [assign_status, setAssignStatus] = useState('');
  const [status_label, setstatus_label] = useState('');
  const [uid, setUID] = useState('0');
  const [errorColor, seterrorColor] = useState('black');
  const [statusdata, setstatusdata] = useState({ status: "", remark: "", cand_remark: "" });
  const [remarkdata, setremarkdata] = useState({ remark: "", fu_date: "", fu_time: "" });
  const [selectedCand, setSelectedCand] = useState([{ id: "" }]);
  const [jobs, setjobs] = useState([]);
  const [job_id, setJobId] = useState('0');
  const [follow_ups, setFollowUps] = useState([]);

  useEffect(() => {
    props.requestAdminCandidateDetails({
      id: params.id,
    });
  }, [params.id]);

  useEffect(() => {
    let candidateDeatilsData = props.data.candidateDeatilsData.data.data;
    console.log(candidateDeatilsData);
    if (candidateDeatilsData.candidate !== undefined) {
      setdata(candidateDeatilsData?.candidate[0]);
      setjobs(candidateDeatilsData?.jobs);
      if(candidateDeatilsData?.jobs.length > 0){
        setJobId(candidateDeatilsData?.jobs[0].id);
      }
      
      setFollowUps(candidateDeatilsData?.follow_ups);
      console.log(candidateDeatilsData?.follow_ups);
      if (candidateDeatilsData.candidate[0].phone !== null) {
        var mob = candidateDeatilsData.candidate[0].phone.split(",").length > 0 ?
          candidateDeatilsData.candidate[0].phone.split(",")[0] : '';
        setMobile(mob);
      }
      if (candidateDeatilsData.candidate[0].email !== null) {
        var ema = candidateDeatilsData.candidate[0].email.split(",").length > 0 ?
          candidateDeatilsData.candidate[0].email.split(",")[0] : '';
        setEmail(ema);
      }
      setStatus(candidateDeatilsData.candidate[0].status);
      // setexperience(candidateDeatilsData?.data?.data[0].experience1);
      var text = candidateDeatilsData.candidate[0].experience1;
      if (text !== null) {
        if (text.split("start_month").length - 1 === 1) {
          setexperience(JSON.parse(text));
        }
        else {
          var match = /\r|\n/.exec(text);
          var ntext = text;
          if (match) {
            var reg = new RegExp(/(\r\n?|\n|\t)/g);
            ntext = text.replace('],[', ',').replace(reg, "-");
          }
          var all_exp = [];
          if (ntext !== null) {
            console.log(ntext);
            if(ntext != "[]"){
              JSON.parse("[" + ntext + "]").forEach(e => {
                var element = e[0];
                var exp = [];
                exp.title = element.title; exp.company = element.company; exp.start_month = element.start_month;
                exp.start_year = element.start_year; exp.leave_month = element.leave_month; exp.leave_year = element.leave_year; exp.achieve = element.achieve;
                all_exp.push(exp);
                setexperience(all_exp);
              });  
            }
          }
        }
      }

      var text1 = candidateDeatilsData.candidate[0].education;
      if (text1 !== null) {
        if (text1.split("start").length - 1 > 0) {
          var match = /\r|\n/.exec(text1);
          var ntext = text1;
          if (match) {
            var reg = new RegExp(/(\r\n?|\n|\t)/g);
            ntext = text1.replace('],[', ',').replace(reg, "-");
          }
          var all_edu = [];

          console.log(ntext);
          if (ntext !== undefined) {
            JSON.parse("[" + ntext + "]").forEach(e => {
              var element = e[0];
              var edu = [];
              if(element.degree !== 0){edu.degree = candidateDeatilsData.degree_levels.find(bird => bird.id === parseInt(element.degree)).level} else{element.degree = 0;}
              if(element.course !== 0){edu.course = candidateDeatilsData.courses.find(bird => bird.id === parseInt(element.course)).name;} else {element.course = 0;}
              if(element.specialization !== ""){edu.specialization = candidateDeatilsData.specializations.find(bird => bird.id === parseInt(element.specialization)).name;} else {element.specialization = 0;}
              edu.start = element.start; edu.leave = element.leave; edu.achieve = element.achieve; edu.university = element.university;
              all_edu.push(edu);
              seteducation(all_edu);
            });
          }
        }
      }

      if (candidateDeatilsData.candidate[0].profile) {
        setimg(
          process.env.REACT_APP_API_HOST + '/public' + candidateDeatilsData.candidate[0].profile
        );
      } else {
        setimg(image);
      }
      var path = process.env.REACT_APP_API_HOST;
      if (candidateDeatilsData.candidate[0].resume.includes("uploads/")) {
        path = process.env.REACT_APP_API_HOST + '/public';
      }
      else {
        path = process.env.REACT_APP_API_HOST + '/public/uploads/resumes/';
      }
      if (candidateDeatilsData.candidate[0].resume !== null && candidateDeatilsData.candidate[0].resume.includes("pdf")) {
        setresume(
          path + candidateDeatilsData.candidate[0].resume
        );
      }
      else {
        setresume(
          // <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=http://remote.url.tld/path/to/document.doc' width='1366px' height='623px' frameborder='0'>This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by <a target='_blank' href='http://office.com/webapps'>Office Online</a>.</iframe>
          'https://view.officeapps.live.com/op/embed.aspx?src=' + path + candidateDeatilsData.candidate[0].resume
        );
      }
      // let object1 = JSON.parse('[{ "title":"Manmager HR IR Admin","company":"Apcotex Industries Ltd","start":"Feb-20","leave":"NA","achieve":"?	Responsible for HR; IR; Compliance & Admin; Liasoning activities in manufacturing Recruitment & Induction:- All Level Position PMS Performance Management System) based on KRA’s effectively & releasing timely the increment & recognitions HRbudget; Manpower; Recruitment; Training; Welfare; & Safety. Arranging Training workshops based on TNI as well as organizational needs HR MIS (Monthly Information System)."}],[{ "title":"HR Manager","company":"Chemspec Chemicals Pvt. Ltd.Taloja Navi Mumbai ","start":"Mar-19","leave":"Feb-20","achieve":"I Have  handled their Govt authorities ;  licensing  work ;T& D; Union Matters  ;IR Issues and Admin work. Time Office; HR Manual; Policies and others work."}]');
      // let object = JSON.parse(JSON.stringify('[{ "title":"Manmager HR IR Admin","company":"Apcotex Industries Ltd","start":"Feb-20","leave":"NA","achieve":"?	Responsible for HR; IR; Compliance & Admin; Liasoning activities in manufacturing Recruitment & Induction:- All Level Position PMS Performance Management System) based on KRA’s effectively & releasing timely the increment & recognitions HRbudget; Manpower; Recruitment; Training; Welfare; & Safety. Arranging Training workshops based on TNI as well as organizational needs HR MIS (Monthly Information System)."}]'));
    }
  }, [props.data.candidateDeatilsData]);

  //form field
  useEffect(() => {
    let loginData = props.data.loginData.data.data;
    props.requestCandSearch({
      token: loginData.token,
    });
    setLoginDetails(loginData.admin_detail);
    setUID(loginData.id);
  }, [props.data.loginData.data.data]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Candidate Details</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  const savestatustoggle = (e) => {
    e.preventDefault();
    if (status_label === "") {
      seterrorColor("red");
    }
    else {
      props.requestStatusCandidate({
        data: { cand_id: params.id, job_id: job_id, status: status_label, remark: statusdata.remark, uid: uid },
      });
      console.log(props);
      jobassigntoggle();
      window.location.reload();
    }
  }

  const jobassigntoggle = (id) => {
    if (jobassignmodal === true) {
      setjobassignModal(!jobassignmodal);
    } else {
      setjobassignModal(!jobassignmodal);
      setJobId(id);
    }
  };

  function assignstatuslabel(status) {
    setstatus_label(status);
  }

  const onchangestatusdata = (event, param1) => {
    // console.log(event.target.value);
    // console.log(param1);
    setstatusdata((statusdata) => ({
      ...statusdata,
      [event.target.name]: event.target.value,
    }));
  };

  const remarktoggle = (id) => {
    if (remarkmodal === true) {
      setremarkmodal(!remarkmodal);
    } else {
      setremarkmodal(!remarkmodal);
    }
  };

  const onchangeremarkdata = (event, param1) => {
    setremarkdata((remarkdata) => ({
      ...remarkdata,
      [event.target.name]: event.target.value,
    }));
  };

  const saveremarktoggle = (e) => {
    e.preventDefault();
    props.requestStatusCandidateRemark({
      data: { cand_id: params.id, job_id: job_id, status: remarkdata.remark, fu_date: remarkdata.fu_date, fu_time: remarkdata.fu_time, uid: uid },
    });
    remarktoggle();
         window.location.reload();
  }

  useEffect(() => {
    let statusCandidateRemarkData = props.data.statusCandidateRemark;
    console.log(statusCandidateRemarkData);
    if (statusCandidateRemarkData !== undefined) {
      if (statusCandidateRemarkData.data) {
        Swal.fire({
          title: "Good job!",
          text: "Candidate Remark added successfully..",
          icon: "success",
        });
        setremarkdata({ fu_date: "", remark: "", fu_time: "" });
      }
    }
    props.data.statusCandidateRemark = undefined;
  }, [props.data.statusCandidateRemark]);

  return (
    <>
      <div class="container-scroller">
        <Header name="Candidate Details" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Candidates" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Candidate Details",
                                    url: `https://recruiterserp.com/admin/viewCandidate/${params.id}`,
                                    title: "Candidate Details",
                                  }}
                                >

                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="fa fa-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="fa fa-print"></i> Print
                                </button>
                                {logindetails["cand_edit"] != 0 ?
                                  <a href={`/admin/candedit/${params.id}`} title="Edit Candidate">
                                    <button
                                      class="btn btn-otline-dark">
                                      <i class="fa fa-pencil-square-o"></i>Edit
                                    </button>
                                  </a> : ''}
                                {/* Current Status:
                                <h6 class='badge' style={{
                                  color: "white", backgroundColor: (status == "Sourcing" ? "#9FE2BF" : status == "Offered" ? "#6E2C00" : status == "Screening" ? "#78281F" : status == "Move to the Assessment" ? "#4A235A" :
                                    status == "Move to the Client" ? "#1B4F72" : status == "Sent Profile" ? "#154360" : status == "Shortlisted" ? "#186A3B" : status == "Interviewed" ? "#7E5109" : status == "Joined" ? "#424949" : status == "Invoice" ? "#17202A" : "white")
                                }}>{status}</h6>
                                <button class="btn btn-otline-dark btn-primary" style={{ margin: '8px' }} onClick={() => { jobassigntoggle(); }}>Change Status</button>
                                {jobassignmodal === true && (
                                  <Modal
                                    isOpen={jobassignmodal}
                                    toggle={jobassigntoggle}
                                  >
                                    <ModalHeader toggle={jobassigntoggle}>
                                      Change Status of the Candidate
                                    </ModalHeader>
                                    <ModalBody>
                                      <form class="forms-sample">
                                        <div class="row">
                                          <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                              <label style={{ color: errorColor }}>Click on the Status below to assign it for Candidates to this Job</label>
                                              <br />
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#9FE2BF" }} onClick={() => { assignstatuslabel("Sourcing"); }}>Sourcing</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#78281F" }} onClick={() => { assignstatuslabel("Screening"); }}>Screening</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#4A235A" }} onClick={() => { assignstatuslabel("Move to the Assessment"); }}>Move to the Assessment</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#1B4F72" }} onClick={() => { assignstatuslabel("Move to the Client"); }}>Move to the Client</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#154360" }} onClick={() => { assignstatuslabel("Sent Profile"); }}>Sent Profile</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#186A3B", color: "white" }} onClick={() => { assignstatuslabel("Shortlisted"); }}>Shortlisted</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#7E5109", color: "white" }} onClick={() => { assignstatuslabel("Interviewed"); }}>Interviewed</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#6E2C00", color: "white" }} onClick={() => { assignstatuslabel("Offered"); }}>Offered</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#424949", color: "white" }} onClick={() => { assignstatuslabel("Joined"); }}>Joined</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#17202A", color: "white" }} onClick={() => { assignstatuslabel("Invoice"); }}>Invoice</label>
                                              <br />
                                              Status Selected: <b>{status_label}</b>
                                              <br />
                                              <label>Internal Remark</label>
                                              <input
                                                class="form-control" type="text" name="remark" onChange={onchangestatusdata}
                                                value={statusdata.remark} placeholder="Remark for internal use." />
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          type="submit" class="btn btn-primary me-2" onClick={savestatustoggle} >
                                          Submit
                                        </button>
                                        <button
                                          class="btn btn-light"
                                          onClick={jobassigntoggle}
                                        >
                                          Cancel
                                        </button>
                                      </form>
                                    </ModalBody>
                                  </Modal>
                                )} */}
                              </div>
                              <div>
                                Profile Edit Request
                                <a 
                                // href={'https://web.whatsapp.com/send?phone=91' + mobile +
                                //   '&text=Hi, ' + data.first_name + " " + data.last_name + ", %0D%0A%0D%0A Greetings from HR Ace! %0D%0A%0D%0A Don't miss out on the chance to advance your career, explore new horizons, and connect with your dream job.%0D%0A Seize the opportunity and create/edit/update your profile to unlock a world of career possibilities and maximize your professional prospects! %0D%0A%0D%0A https://hrace.in/editprofile/" + params.id + '%0D%0A%0D%0A Thanks! %0D%0A%0D%0A HR Ace %0D%0Ahttps://hrace.in/%0D%0A Office no 62, Shreeji heights Plot no 1ABC, %0D%0ASector 46A, Seawoods West,%0D%0ANew Mumbai 400706,%0D%0AMaharashtra'} 
                                href={'https://web.whatsapp.com/send?phone=91' + mobile +
                                  '&text=Hi, ' + data.first_name + " " + data.last_name + ", %0D%0A%0D%0A Greetings from HR Ace! Thanks for reaching out to us and applying for the jobs. Kindly update your profile on the link below%0D%0A%0D%0A https://hrace.in/editprofile/" + params.id + '%0D%0A%0D%0A Also, help us with the following details: %0D%0A 1. Candidate Name: %0D%0A 2. Candidate current company: %0D%0A 3. Candidate current location: %0D%0A 4. Date of birth: %0D%0A 5. Location applying for: %0D%0A 6. Educational Qualification: %0D%0A 7. Certification if any: %0D%0A 8. Current CTC: %0D%0A 9. Expected CTC: %0D%0A 10. Notice period: %0D%0A 11. Total Experience: %0D%0A 12. Relevant Experience: %0D%0A 13. Candidate summary: %0D%0A 14. Reason for change: %0D%0A%0D%0A HR Ace %0D%0Ahttps://hrace.in/'}
title="Update your profile to get more opportunity-HRAce" target="_blank">
                                  <button
                                    class="btn btn-primary btn-md text-white mb-0 me-0"
                                    style={{ padding: "6px 8px", marginLeft: "12px" }}>
                                    <i class="fa fa-whatsapp"></i>
                                  </button>
                                </a>
                                &nbsp;&nbsp;
                                <a href={"mailto:" + email + "?subject=Update your profile to get more opportunity-HRAce&body=Hi " + data.first_name + " " + data.last_name + ",%0D%0A Greetings from HR Ace! %0D%0A Don't miss out on the chance to advance your career, explore new horizons, and connect with your dream job.%0D%0A Seize the opportunity and create/edit/update your profile to unlock a world of career possibilities and maximize your professional prospects! %0D%0A%0D%0A https://hrace.in/editprofile/" + params.id + "%0D%0A%0D%0A Thanks! %0D%0A HR Ace %0D%0A%0D%0Ahttps://hrace.in/%0D%0A Office no 62, Shreeji heights Plot no 1ABC, %0D%0ASector 46A, Seawoods West,%0D%0ANew Mumbai 400706,%0D%0AMaharashtra"}>
                                  <button
                                    class="btn btn-primary btn-md text-white mb-0 me-0"
                                    style={{ padding: "6px 8px" }}>
                                    <i class="fa fa-envelope"></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <br />
                            <div id="printme">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="d-flex ">
                                    <img src={img} alt="" height="60" width="60" />
                                    <div style={{ paddingLeft: "15px" }}>
                                      <h4>
                                        {data.first_name} {data.last_name}
                                      </h4>
                                      <p>
                                        <i class="fa fa-map-marker"></i>{" "}
                                        {data.address}, {data.city_name},{" "}
                                        {data.state_name}, {data.country_name}
                                      </p>
                                      <p>Last updated at:  {data.updated_at !== null ? formatDate(new Date(data.updated_at)) : ''}</p>
                                    </div>
                                  </div>
                                  <p><b>Source: </b>  {data.source == 1 ? 'website' : data.source == 2 ? 'Naukri' : data.source == 3 ? 'Imported' : ''}</p>
                                  <p><b>Uploaded at: </b> {data.created_at !== null ? formatDate(new Date(data.created_at)) : ''}</p>
                                  <p><b>Current Designation: </b>{data.designation === null ? data.role_name : data.designation}</p>
                                  <p><b>Employment Type: </b>{data.emptype}</p>
                                </div>
                                <div class="col-md-6">
                                  <div class="row">
                                    <table class="select-table" style={{ fontSize: "70%" }}>
                                      <thead>
                                        <tr>
                                          <th width="5%">Company</th>
                                          <th width="5%">Title</th>
                                          <th width="5%">Designation</th>
                                          <th width="1%">Status</th>
                                          <th width="5%">Follow Date</th>
                                          <th width="5%">Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {jobs.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{item.company_name}</td>
                                              <td>{item.title}</td>
                                              <td>{item.designation}</td>
                                              <td><h6 class='badge' style={{
                                                color: "white", backgroundColor: (item.cand_status == "Sourcing" ? "#9FE2BF" : item.cand_status == "Offered" ? "#6E2C00" : item.cand_status == "Screening" ? "#78281F" : item.cand_status == "Move to the Assessment" ? "#4A235A" : item.cand_status == "Rejected" ? "#B00020" :
                                                  item.cand_status == "Move to the Client" ? "#1B4F72" : item.cand_status == "Sent Profile" ? "#154360" : item.cand_status == "Shortlisted" ? "#186A3B" : item.cand_status == "Interviewed" ? "#7E5109" : item.cand_status == "Joined" ? "#424949" : item.cand_status == "Invoice" ? "#17202A" : "white")
                                              }}>{item.cand_status}</h6></td>
                                              <td>{item.follow_date}</td>
                                              <td>
                                                <button class="btn badge-opacity-success" style={{ margin: '0px' }} onClick={() => { jobassigntoggle(item.id); }}><i class="fa fa-toggle-on"></i></button>
                                                {jobassignmodal === true && (
                                                  <Modal
                                                    isOpen={jobassignmodal}
                                                    toggle={jobassigntoggle}
                                                  >
                                                    <ModalHeader toggle={jobassigntoggle}>
                                                      Change Status of the Candidate
                                                    </ModalHeader>
                                                    <ModalBody>
                                                      <form class="forms-sample">
                                                        <div class="row">
                                                          <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                              <label style={{ color: errorColor }}>Click on the Status below to assign it for Candidates to this Job</label>
                                                              <br />
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#9FE2BF" }} onClick={() => { assignstatuslabel("Sourcing"); }}>Sourcing</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#78281F" }} onClick={() => { assignstatuslabel("Screening"); }}>Screening</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#4A235A" }} onClick={() => { assignstatuslabel("Move to the Assessment"); }}>Move to the Assessment</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#B00020" }} onClick={() => { assignstatuslabel("Rejected"); }}>Rejected</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#154360" }} onClick={() => { assignstatuslabel("Sent Profile"); }}>Sent Profile</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#186A3B", color: "white" }} onClick={() => { assignstatuslabel("Shortlisted"); }}>Shortlisted</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#7E5109", color: "white" }} onClick={() => { assignstatuslabel("Interviewed"); }}>Interviewed</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#6E2C00", color: "white" }} onClick={() => { assignstatuslabel("Offered"); }}>Offered</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#424949", color: "white" }} onClick={() => { assignstatuslabel("Joined"); }}>Joined</label>
                                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#17202A", color: "white" }} onClick={() => { assignstatuslabel("Invoice"); }}>Invoice</label>
                                                              <br />
                                                              Status Selected: <b>{status_label}</b>
                                                              <br />
                                                              <label>Internal Remark</label>
                                                              <input
                                                                class="form-control" type="text" name="remark" onChange={onchangestatusdata}
                                                                value={statusdata.remark} placeholder="Remark for internal use." />
                                                              <br />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <button
                                                          type="submit" class="btn btn-primary me-2" onClick={savestatustoggle} >
                                                          Submit
                                                        </button>
                                                        <button
                                                          class="btn btn-light"
                                                          onClick={jobassigntoggle}
                                                        >
                                                          Cancel
                                                        </button>
                                                      </form>
                                                    </ModalBody>
                                                  </Modal>
                                                )}
                                              </td>
                                            </tr>)
                                        })}
                                      </tbody>
                                    </table>
                                    <hr />
                                    <table class="select-table" style={{ fontSize: "70%" }}>
                                      <thead>
                                        <tr>
                                          <th width="5%">Follow Date</th>
                                          <th width="5%">Follow Time</th>
                                          <th width="10%">Remark</th>
                                          <th width="10%">Added By</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {follow_ups.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>{item.fu_date}</td>
                                              <td>{item.fu_time}</td>
                                              <td>{item.remark}</td>
                                              <td>{item.username}</td>
                                            </tr>)
                                        })}
                                      </tbody>
                                    </table>
                                    <button class="btn btn-otline-dark col-md-4" onClick={() => { remarktoggle(); }}><i class="fa fa-plus"></i>Add Follow Ups</button>
                                    {remarkmodal === true && (
                                      <Modal
                                        isOpen={remarkmodal}
                                        toggle={remarktoggle}
                                      >
                                        <ModalHeader toggle={remarktoggle}>
                                          Add Remark for the Candidate
                                        </ModalHeader>
                                        <ModalBody>
                                          <form class="forms-sample">
                                            <div class="row">
                                              <div class="col-lg-12 col-md-12">
                                                <div class="form-group">
                                                  <label>Candidate Remark</label>
                                                  <input
                                                    class="form-control" type="text" name="remark" onChange={onchangeremarkdata}
                                                    value={remarkdata.remark} placeholder="Remark for internal use." />
                                                  <label>Next Follow up Date</label>
                                                  <div class="row">
                                                    <div class="col-md-6">
                                                      <input
                                                        class="form-control" type="date" name="fu_date" onChange={onchangeremarkdata}
                                                        value={remarkdata.fu_date} />
                                                    </div>
                                                    <div class="col-md-6">
                                                      <input
                                                        class="form-control" type="time" name="fu_time" onChange={onchangeremarkdata}
                                                        value={remarkdata.fu_time} />
                                                    </div>
                                                  </div>
                                                  <br />
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              type="submit" class="btn btn-primary me-2" onClick={saveremarktoggle} >
                                              Submit
                                            </button>
                                            <button
                                              class="btn btn-light"
                                              onClick={remarktoggle}
                                            >
                                              Cancel
                                            </button>
                                          </form>
                                        </ModalBody>
                                      </Modal>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-md-6">
                                  <p>
                                    <b>Email: </b>
                                    <a href={`mailto:${data.email}`}>
                                      {data.email == "null"? "" : data.email}
                                    </a>
                                  </p>
                                </div>
                                <div class="col-md-6">
                                  <p>
                                    <b>Phone: </b>
                                    <a href={`callto:${data.phone}`}>
                                      {data.phone == "null"? "" : data.phone}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Birth Date: </b>
                                    {data.birth_date !== null ? formatDate(new Date(data.birth_date)) : ''}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Gender: </b>
                                    {data.gender == 1 ? 'Male' : data.gender == 0 ?'Female' : data.gender == 2 ?'Transgender' : ""}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Marital Status: </b>
                                    {data.marital_name == "null"? "" : data.marital_name}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8">
                                  <p>
                                    <b>Skill Name: </b>
                                    {data.skills !== null ? data.skills : data.skill}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Current Industry: </b>
                                    {data.industry_name == "null"? "" : data.industry_name}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Career Level: </b>
                                    {data.career_level1 == "null"? "" : data.career_level1}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Current Fun. Area: </b>
                                    {data.functional_area1 == "null"? "" : data.functional_area1}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Experience: </b>
                                    {data.exp_year + ' year/s. & ' + data.exp_month + ' months'}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Current Salary per Year: </b>
                                    {data.current_salary == 0 ? '' : data.currency + ' ' + data.current_salary == "null"? "" : data.current_salary}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Expected Salary per Year: </b>
                                    {data.expected_salary == 0 ? '' : data.currency + ' ' + data.expected_salary == "null" ? "":data.expected_salary}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Notice Period: </b>
                                    {data.notice_period + ' '} {data.immediate_available == 1 ? '(Immediate Available)' : ''}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Current Location: </b>
                                    {data.city_name == "null"? "" : data.city_name}
                                  </p>
                                </div>
                                <div class="col-md-8">
                                  <p>
                                    <b>Preffered Location: </b>
                                    {/* {data.pref_cities} */}
                                    {data.pref_location == "null"? "" : data.pref_location}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Job Type: </b>
                                    {data.job_type_name == "null"? "" : data.job_type_name}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Work Status: </b>
                                    {data.work_status == "null"? "" : data.work_status}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Pref. Countries: </b>
                                    {data.countries == "null"? "" : data.countries}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                {/* <div class="col-md-4">
                                  <p>
                                    <b>Caste: </b>
                                    {data.caste}
                                  </p>
                                </div> */}
                                <div class="col-md-8">
                                  <p>
                                    <b>Physical Handicape: </b>
                                    {data.physical == "null"? "" : data.physical == 0 ? "No" : 
                                    data.physical == null ? "" : "Yes "}  {data.physical_desc == "null" ? "" : data.physical_desc}
                                    {/* {data.physical == "null"? "" : data.physical + '(' + (data.physical_desc == "null"? "" : data.physical_desc + ')')} */}
                                  </p>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <p>
                                    <b>Languages: </b>
                                    {data.languages == "null"? "" : data.languages}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>Nationality: </b>
                                    {data.nationality == "null"? "" : data.nationality}
                                  </p>
                                </div>
                                <div class="col-md-4">
                                  <p>
                                    <b>National ID Card No: </b>
                                    {data.national_id_card == "null"? "" : data.national_id_card}
                                  </p>
                                </div>
                              </div>
                              <p><b>Linkedin Profile: </b>{data.linkedin_url == "null"? "" : data.linkedin_url}</p>
                              <p>
                                <b>About: </b>
                                {data.about == "null"? "" : data.about}
                              </p>
                              <p>
                                <b>Experience: </b>
                                <table class="table select-table">
                                  <thead>
                                    <tr>
                                      <th>Title</th>
                                      <th>Company</th>
                                      <th>Start Month</th>
                                      <th>Start Year</th>
                                      <th>Leave Month</th>
                                      <th>Leave Year</th>
                                      <th>Achievement</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {experience.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{item.title}</td>
                                          <td>{item.company}</td>
                                          <td>{item.start_month}</td>
                                          <td>{item.start_year}</td>
                                          <td>{item.leave_month == '' ? 'Till Present' : item.leave_month}</td>
                                          <td>{item.leave_year == '' ? 'Till Present' : item.leave_year}</td>
                                          <td>{item.achieve}</td>
                                        </tr>)
                                    })}
                                  </tbody>
                                </table>
                              </p>
                              <b>Education: </b>
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Degree Level</th>
                                    <th>Course</th>
                                    <th>Specialization</th>
                                    <th>University</th>
                                    <th>Year</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {education.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item.degree}</td>
                                        <td>{item.course}</td>
                                        <td>{item.specialization}</td>
                                        <td>{item.university}</td>
                                        <td>{item.start} to {item.leave}</td>
                                      </tr>)
                                  })}

                                  {/* <tr>
                                    <td>Graduation</td>
                                    <td>{data.ugcourse_name}</td>
                                    <td>{data.ugspecialization}</td>
                                    <td>{data.UGUniversity}</td>
                                    <td>{data.UGYear}</td>
                                  </tr>
                                  <tr>
                                    <td>Post Graduation</td>
                                    <td>{data.pgcourse_name}</td>
                                    <td>{data.pgspecialization}</td>
                                    <td>{data.PGUniversity}</td>
                                    <td>{data.PGYear}</td>
                                  </tr>
                                  <tr>
                                    <td>Doctorate</td>
                                    <td>{data.doccourse_name}</td>
                                    <td>{data.docspecialization}</td>
                                    <td>{data.DoctorateUniversity}</td>
                                    <td>{data.DoctorateYear}</td>
                                  </tr> */}
                                </tbody>
                              </table>
                              {data.resume ? <iframe src={resume} width="1000" height="1100" /> : <p>Resume is not Uploaded.</p>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminCandidateDetails, requestCandSearch, requestStatusCandidate, requestStatusCandidateRemark }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewCandidate);
