import { RECEIVE_ADD_CANDIDATE_PROFILE, RECEIVE_EDIT_CANDIDATE_PROFILE, RECEIVE_ADMIN_ALL_COUNT, RECEIVE_ADMIN_COMPANY_JOB, RECEIVE_ADMIN_FUNCTIONAL_CANDIDATE, USER_LOGOUT, RECEIVE_FILTERED_CAND_FIRST, RECEIVE_ADMIN_GET_CANDIDATES_STATUS, RECEIVE_GET_CANDIDATE_FU, RECEIVE_CANDIDATE_STATUS_ALL, RECEIVE_CANDIDATE_FOLLOW_UP_ALL, RECEIVE_ADMIN_GET_CANDIDATES_REMARK, RECEIVE_CANDIDATE_FOLLOW_UP_ADD, RECEIVE_CANDIDATE_FOLLOW_UP_DELETE, RECEIVE_CANDIDATE_FOLLOW_UP_EDIT, RECEIVE_ADMIN_GET_CANDIDATES_ANSWERS, RECEIVE_COPY_JOB } from "./actions";
import {
  RECEIVE_ADMIN_LOGIN,
  RECEIVE_ADMIN_FORGOT1,
  RECEIVE_ADMIN_FORGOT2,
  RECEIVE_ADMIN_FORGOT3,
  RECEIVE_ADMIN_COMPANIES,
  RECEIVE_ADMIN_COMPANY_DETAILS,
  RECEIVE_ADMIN_JOBS,
  RECEIVE_ADMIN_JOB_DETAILS,
  RECEIVE_ADMIN_CANDIDATES,
  RECEIVE_ADMIN_CANDIDATE_DETAILS,
  RECEIVE_ADMIN_ADD_CAREER,
  RECEIVE_ADMIN_ALL_CAREER,
  RECEIVE_ADMIN_CAREER,
  RECEIVE_ADMIN_DELETE_CAREER,
  RECEIVE_ADMIN_EDIT_CAREER,
  RECEIVE_ADMIN_ADD_SIZE,
  RECEIVE_ADMIN_ALL_SIZE,
  RECEIVE_ADMIN_SIZE,
  RECEIVE_ADMIN_DELETE_SIZE,
  RECEIVE_ADMIN_EDIT_SIZE,
  RECEIVE_ADMIN_ADD_DEGREE,
  RECEIVE_ADMIN_ALL_DEGREE,
  RECEIVE_ADMIN_DEGREE,
  RECEIVE_ADMIN_DELETE_DEGREE,
  RECEIVE_ADMIN_EDIT_DEGREE,
  RECEIVE_ADMIN_ADD_FUNCTIONAL,
  RECEIVE_ADMIN_ALL_FUNCTIONAL,
  RECEIVE_ADMIN_DELETE_FUNCTIONAL,
  RECEIVE_ADMIN_EDIT_FUNCTIONAL,
  RECEIVE_ADMIN_FUNCTIONAL,
  RECEIVE_ADMIN_ADD_INDUSTRY,
  RECEIVE_ADMIN_ALL_INDUSTRY,
  RECEIVE_ADMIN_DELETE_INDUSTRY,
  RECEIVE_ADMIN_EDIT_INDUSTRY,
  RECEIVE_ADMIN_INDUSTRY,
  RECEIVE_ADMIN_ADD_CATEGORY,
  RECEIVE_ADMIN_ALL_CATEGORY,
  RECEIVE_ADMIN_CATEGORY,
  RECEIVE_ADMIN_DELETE_CATEGORY,
  RECEIVE_ADMIN_EDIT_CATEGORY,
  RECEIVE_ADMIN_ADD_TAG,
  RECEIVE_ADMIN_ALL_TAG,
  RECEIVE_ADMIN_DELETE_TAG,
  RECEIVE_ADMIN_EDIT_TAG,
  RECEIVE_ADMIN_TAG,
  RECEIVE_ADMIN_ADD_SHIFT,
  RECEIVE_ADMIN_ALL_SHIFT,
  RECEIVE_ADMIN_DELETE_SHIFT,
  RECEIVE_ADMIN_EDIT_SHIFT,
  RECEIVE_ADMIN_SHIFT,
  RECEIVE_ADMIN_ADD_OWNER,
  RECEIVE_ADMIN_ALL_OWNER,
  RECEIVE_ADMIN_DELETE_OWNER,
  RECEIVE_ADMIN_EDIT_OWNER,
  RECEIVE_ADMIN_OWNER,
  RECEIVE_ADMIN_ADD_TYPE,
  RECEIVE_ADMIN_ALL_TYPE,
  RECEIVE_ADMIN_DELETE_TYPE,
  RECEIVE_ADMIN_EDIT_TYPE,
  RECEIVE_ADMIN_TYPE,
  RECEIVE_ADMIN_ADD_CURRENCY,
  RECEIVE_ADMIN_ALL_CURRENCY,
  RECEIVE_ADMIN_CURRENCY,
  RECEIVE_ADMIN_DELETE_CURRENCY,
  RECEIVE_ADMIN_EDIT_CURRENCY,
  RECEIVE_ADMIN_ADD_PERIOD,
  RECEIVE_ADMIN_ALL_PERIOD,
  RECEIVE_ADMIN_DELETE_PERIOD,
  RECEIVE_ADMIN_EDIT_PERIOD,
  RECEIVE_ADMIN_PERIOD,
  RECEIVE_ADMIN_ADD_SKILL,
  RECEIVE_ADMIN_ALL_SKILL,
  RECEIVE_ADMIN_DELETE_SKILL,
  RECEIVE_ADMIN_EDIT_SKILL,
  RECEIVE_ADMIN_SKILL,
  RECEIVE_ADMIN_SUBSCRIBE,
  RECEIVE_ADMIN_GET_PROFILE,
  RECEIVE_ADMIN_UPDATE_PROFILE,
  RECEIVE_ADMIN_CHANGE_PASSWOD,
  RECEIVE_ADMIN_SIX_COMPANIES,
  RECEIVE_ADMIN_SIX_JOBS,
  RECEIVE_ADMIN_CATEGORY_JOB,
  RECEIVE_ADMIN_MONTH_APPLIED_JOB,
  RECEIVE_ADMIN_MONTH_JOB,
  RECEIVE_ADMIN_ADD_POSITION,
  RECEIVE_ADMIN_ALL_POSITION,
  RECEIVE_ADMIN_DELETE_POSITION,
  RECEIVE_ADMIN_EDIT_POSITION,
  RECEIVE_ADMIN_POSITION,
  RECEIVE_ADMIN_ALL_CONTACT, RECEIVE_ADMIN_CONTACT, RECEIVE_ADMIN_EDIT_CONTACT,
  RECEIVE_COUNTRY, RECEIVE_STATE, RECEIVE_CITY, RECEIVE_ADMIN_ADD_COMPANY, RECEIVE_ADMIN_GET_COMPANY, RECEIVE_ADMIN_EDIT_COMPANY, RECEIVE_ADMIN_DELETE_COMPANY,
  RECEIVE_ADMIN_ALL_COMPADDRESS, RECEIVE_ADMIN_ADD_COMPADDRESS, RECEIVE_ADMIN_GET_COMPADDRESS, RECEIVE_ADMIN_EDIT_COMPADDRESS, RECEIVE_ADMIN_DELETE_COMPADDRESS,
  RECEIVE_ADMIN_ALL_COMPCONTACT, RECEIVE_ADMIN_ADD_COMPCONTACT, RECEIVE_ADMIN_GET_COMPCONTACT, RECEIVE_ADMIN_EDIT_COMPCONTACT, RECEIVE_ADMIN_DELETE_COMPCONTACT,
  RECEIVE_ADMIN_GET_ADDRESSES,
  RECEIVE_ADMIN_ALL_QUESTION, RECEIVE_ADMIN_ADD_QUESTION, RECEIVE_ADMIN_GET_QUESTION, RECEIVE_ADMIN_EDIT_QUESTION, RECEIVE_ADMIN_DELETE_QUESTION, RECEIVE_ADMIN_ALL_QUESTIONOPTIONS,
  RECEIVE_ADMIN_ALL_QUESTIONTEMPLATE, RECEIVE_ADMIN_ADD_QUESTIONTEMPLATE, RECEIVE_ADMIN_GET_QUESTIONTEMPLATE, RECEIVE_ADMIN_EDIT_QUESTIONTEMPLATE, RECEIVE_ADMIN_DELETE_QUESTIONTEMPLATE,
  RECEIVE_ADMIN_ADD_COURSE, RECEIVE_ADMIN_ALL_COURSE, RECEIVE_ADMIN_COURSE, RECEIVE_ADMIN_DELETE_COURSE, RECEIVE_ADMIN_EDIT_COURSE,
  RECEIVE_ADMIN_ADD_SPECIALIZATION, RECEIVE_ADMIN_ALL_SPECIALIZATION, RECEIVE_ADMIN_SPECIALIZATION, RECEIVE_ADMIN_DELETE_SPECIALIZATION, RECEIVE_ADMIN_EDIT_SPECIALIZATION, RECEIVE_ADMIN_GET_ALL_COURSES,
  RECEIVE_ADD_JOB, RECEIVE_JOB_DETAILS, RECEIVE_EDIT_JOB, RECEIVE_DELETE_JOB, RECEIVE_STATUS_JOB, RECEIVE_CAND_SEARCH, RECEIVE_FILTERED_CAND,
  RECEIVE_CAND_JOBASSIGN, RECEIVE_PENDING_JOBSBYEMPLOYER, RECEIVE_STATUS_CANDIDATE, RECEIVE_STATUS_CANDIDATE_REMARK,
  RECEIVE_ADMIN_ALL_USER, RECEIVE_ADMIN_ADD_USER, RECEIVE_ADMIN_GET_USER, RECEIVE_ADMIN_EDIT_USER, RECEIVE_ADMIN_DELETE_USER,
  RECEIVE_FORM_FIELD_CAND,
  RECEIVE_ADMIN_ADD_SOURCE, RECEIVE_ADMIN_ALL_SOURCE, RECEIVE_ADMIN_DELETE_SOURCE, RECEIVE_ADMIN_EDIT_SOURCE, RECEIVE_ADMIN_SOURCE, RECEIVE_EDIT_CANDIDATE_STATUS,
  RECEIVE_GET_CANDIDATE_STATUS, RECEIVE_REPOST_JOB,
  RECEIVE_ADMIN_ADD_EMAIL, RECEIVE_ADMIN_ALL_EMAIL, RECEIVE_ADMIN_EMAIL, RECEIVE_ADMIN_DELETE_EMAIL, RECEIVE_ADMIN_EDIT_EMAIL, RECEIVE_IMPORT_FROM_RESUME
} from "./actions";
export default (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_ADMIN_LOGIN:
      return {
        ...state,
        loginData: data,
      };
    case RECEIVE_ADMIN_ALL_COUNT:
      return {
        ...state,
        allCountData: data
      }
    case RECEIVE_ADMIN_CATEGORY_JOB:
      return {
        ...state,
        categoryJobData: data,
      };
    case RECEIVE_ADMIN_COMPANY_JOB:
      return {
        ...state,
        companyJobData: data,
      };
    case RECEIVE_ADMIN_FUNCTIONAL_CANDIDATE:
      return {
        ...state,
        functionalCanditateData: data,
      };
    case RECEIVE_ADMIN_MONTH_APPLIED_JOB:
      return {
        ...state,
        monthWiseAppliedjobData: data,
      };
    case RECEIVE_ADMIN_MONTH_JOB:
      return {
        ...state,
        monthWiseJobData: data,
      };

    case RECEIVE_ADMIN_SIX_COMPANIES:
      return {
        ...state,
        lastSixEmpData: data,
      };
    case RECEIVE_ADMIN_SIX_JOBS:
      return {
        ...state,
        lastSixJobData: data,
      };

    case RECEIVE_ADMIN_FORGOT1:
      return {
        ...state,
        forgotPassword1Data: data,
      };

    case RECEIVE_ADMIN_FORGOT2:
      return {
        ...state,
        forgotPassword2Data: data,
      };

    case RECEIVE_ADMIN_FORGOT3:
      return {
        ...state,
        forgotPassword3Data: data,
      };

    case RECEIVE_ADMIN_CHANGE_PASSWOD:
      return {
        ...state,
        changePasswordData: data,
      };

    case RECEIVE_ADMIN_GET_PROFILE:
      return {
        ...state,
        getProfileData: data,
      };
    case RECEIVE_ADMIN_UPDATE_PROFILE:
      return {
        ...state,
        updateProfileData: data,
      };

    case RECEIVE_ADMIN_SUBSCRIBE:
      return {
        ...state,
        subscribersData: data,
      };

    case RECEIVE_ADMIN_COMPANIES:
      return {
        ...state,
        companiesData: data,
      };

    case RECEIVE_ADMIN_COMPANY_DETAILS:
      return {
        ...state,
        companyDeatilsData: data,
      };

    case RECEIVE_ADMIN_CANDIDATES:
      return {
        ...state,
        candidatesData: data,
      };

    case RECEIVE_ADMIN_CANDIDATE_DETAILS:
      return {
        ...state,
        candidateDeatilsData: data,
      };

    case RECEIVE_ADMIN_JOBS:
      return {
        ...state,
        jobsData: data,
      };

    case RECEIVE_ADMIN_JOB_DETAILS:
      return {
        ...state,
        jobDeatilsData: data,
      };

    case RECEIVE_ADMIN_ADD_CAREER:
      return {
        ...state,
        addCareerData: data,
      };
    case RECEIVE_ADMIN_ALL_CAREER:
      return {
        ...state,
        allCareerData: data,
      };
    case RECEIVE_ADMIN_CAREER:
      return {
        ...state,
        careerData: data,
      };
    case RECEIVE_ADMIN_DELETE_CAREER:
      return {
        ...state,
        deleteCareerData: data,
      };
    case RECEIVE_ADMIN_EDIT_CAREER:
      return {
        ...state,
        editCareerData: data,
      };

    case RECEIVE_ADMIN_ADD_SIZE:
      return {
        ...state,
        addSizeData: data,
      };
    case RECEIVE_ADMIN_ALL_SIZE:
      return {
        ...state,
        allSizeData: data,
      };
    case RECEIVE_ADMIN_SIZE:
      return {
        ...state,
        sizeData: data,
      };
    case RECEIVE_ADMIN_DELETE_SIZE:
      return {
        ...state,
        deleteSizeData: data,
      };
    case RECEIVE_ADMIN_EDIT_SIZE:
      return {
        ...state,
        editSizeData: data,
      };

    case RECEIVE_ADMIN_ADD_DEGREE:
      return {
        ...state,
        addDegreeData: data,
      };
    case RECEIVE_ADMIN_ALL_DEGREE:
      return {
        ...state,
        allDegreeData: data,
      };
    case RECEIVE_ADMIN_DEGREE:
      return {
        ...state,
        degreeData: data,
      };
    case RECEIVE_ADMIN_DELETE_DEGREE:
      return {
        ...state,
        deleteDegreeData: data,
      };
    case RECEIVE_ADMIN_EDIT_DEGREE:
      return {
        ...state,
        editDegreeData: data,
      };

    case RECEIVE_ADMIN_ADD_FUNCTIONAL:
      return {
        ...state,
        addFunctionalData: data,
      };
    case RECEIVE_ADMIN_ALL_FUNCTIONAL:
      return {
        ...state,
        allFunctionalData: data,
      };
    case RECEIVE_ADMIN_FUNCTIONAL:
      return {
        ...state,
        functionalData: data,
      };
    case RECEIVE_ADMIN_DELETE_FUNCTIONAL:
      return {
        ...state,
        deleteFunctionalData: data,
      };
    case RECEIVE_ADMIN_EDIT_FUNCTIONAL:
      return {
        ...state,
        editFunctionalData: data,
      };

    case RECEIVE_ADMIN_ADD_INDUSTRY:
      return {
        ...state,
        addIndustryData: data,
      };
    case RECEIVE_ADMIN_ALL_INDUSTRY:
      return {
        ...state,
        allIndustryData: data,
      };
    case RECEIVE_ADMIN_INDUSTRY:
      return {
        ...state,
        industryData: data,
      };
    case RECEIVE_ADMIN_DELETE_INDUSTRY:
      return {
        ...state,
        deleteIndustryData: data,
      };
    case RECEIVE_ADMIN_EDIT_INDUSTRY:
      return {
        ...state,
        editIndustryData: data,
      };

    case RECEIVE_ADMIN_ADD_CATEGORY:
      return {
        ...state,
        addCategoryData: data,
      };
    case RECEIVE_ADMIN_ALL_CATEGORY:
      return {
        ...state,
        allCategoryData: data,
      };
    case RECEIVE_ADMIN_CATEGORY:
      return {
        ...state,
        categoryData: data,
      };
    case RECEIVE_ADMIN_DELETE_CATEGORY:
      return {
        ...state,
        deleteCategoryData: data,
      };
    case RECEIVE_ADMIN_EDIT_CATEGORY:
      return {
        ...state,
        editCategoryData: data,
      };

    case RECEIVE_ADMIN_ADD_TAG:
      return {
        ...state,
        addTagData: data,
      };
    case RECEIVE_ADMIN_ALL_TAG:
      return {
        ...state,
        allTagData: data,
      };
    case RECEIVE_ADMIN_TAG:
      return {
        ...state,
        tagData: data,
      };
    case RECEIVE_ADMIN_DELETE_TAG:
      return {
        ...state,
        deleteTagData: data,
      };
    case RECEIVE_ADMIN_EDIT_TAG:
      return {
        ...state,
        editTagData: data,
      };

    case RECEIVE_ADMIN_ADD_SHIFT:
      return {
        ...state,
        addShiftData: data,
      };
    case RECEIVE_ADMIN_ALL_SHIFT:
      return {
        ...state,
        allShiftData: data,
      };
    case RECEIVE_ADMIN_SHIFT:
      return {
        ...state,
        shiftData: data,
      };
    case RECEIVE_ADMIN_DELETE_SHIFT:
      return {
        ...state,
        deleteShiftData: data,
      };
    case RECEIVE_ADMIN_EDIT_SHIFT:
      return {
        ...state,
        editShiftData: data,
      };

    case RECEIVE_ADMIN_ADD_OWNER:
      return {
        ...state,
        addOwnerData: data,
      };
    case RECEIVE_ADMIN_ALL_OWNER:
      return {
        ...state,
        allOwnerData: data,
      };
    case RECEIVE_ADMIN_OWNER:
      return {
        ...state,
        ownerData: data,
      };
    case RECEIVE_ADMIN_DELETE_OWNER:
      return {
        ...state,
        deleteOwnerData: data,
      };
    case RECEIVE_ADMIN_EDIT_OWNER:
      return {
        ...state,
        editOwnerData: data,
      };

    case RECEIVE_ADMIN_ADD_TYPE:
      return {
        ...state,
        addTypeData: data,
      };
    case RECEIVE_ADMIN_ALL_TYPE:
      return {
        ...state,
        allTypeData: data,
      };
    case RECEIVE_ADMIN_TYPE:
      return {
        ...state,
        typeData: data,
      };
    case RECEIVE_ADMIN_DELETE_TYPE:
      return {
        ...state,
        deleteTypeData: data,
      };
    case RECEIVE_ADMIN_EDIT_TYPE:
      return {
        ...state,
        editTypeData: data,
      };

    case RECEIVE_ADMIN_ADD_CURRENCY:
      return {
        ...state,
        addCurrencyData: data,
      };
    case RECEIVE_ADMIN_ALL_CURRENCY:
      return {
        ...state,
        allCurrencyData: data,
      };
    case RECEIVE_ADMIN_CURRENCY:
      return {
        ...state,
        currencyData: data,
      };
    case RECEIVE_ADMIN_DELETE_CURRENCY:
      return {
        ...state,
        deleteCurrencyData: data,
      };
    case RECEIVE_ADMIN_EDIT_CURRENCY:
      return {
        ...state,
        editCurrencyData: data,
      };

    case RECEIVE_ADMIN_ADD_PERIOD:
      return {
        ...state,
        addPeriodData: data,
      };
    case RECEIVE_ADMIN_ALL_PERIOD:
      return {
        ...state,
        allPeriodData: data,
      };
    case RECEIVE_ADMIN_PERIOD:
      return {
        ...state,
        periodData: data,
      };
    case RECEIVE_ADMIN_DELETE_PERIOD:
      return {
        ...state,
        deletePeriodData: data,
      };
    case RECEIVE_ADMIN_EDIT_PERIOD:
      return {
        ...state,
        editPeriodData: data,
      };

    case RECEIVE_ADMIN_ADD_SKILL:
      return {
        ...state,
        addSkillData: data,
      };
    case RECEIVE_ADMIN_ALL_SKILL:
      return {
        ...state,
        allSkillData: data,
      };
    case RECEIVE_ADMIN_SKILL:
      return {
        ...state,
        skillData: data,
      };
    case RECEIVE_ADMIN_DELETE_SKILL:
      return {
        ...state,
        deleteSkillData: data,
      };
    case RECEIVE_ADMIN_EDIT_SKILL:
      return {
        ...state,
        editSkillData: data,
      };

    case RECEIVE_ADMIN_ADD_POSITION:
      return {
        ...state,
        addPositionData: data,
      };
    case RECEIVE_ADMIN_ALL_POSITION:
      return {
        ...state,
        allPositionData: data,
      };
    case RECEIVE_ADMIN_POSITION:
      return {
        ...state,
        positionData: data,
      };
    case RECEIVE_ADMIN_DELETE_POSITION:
      return {
        ...state,
        deletePositionData: data,
      };
    case RECEIVE_ADMIN_EDIT_POSITION:
      return {
        ...state,
        editPositionData: data,
      };

    case RECEIVE_ADMIN_ALL_CONTACT:
      return {
        ...state,
        allContactData: data,
      };
    case RECEIVE_ADMIN_CONTACT:
      return {
        ...state,
        contactData: data,
      };
    case RECEIVE_ADMIN_EDIT_CONTACT:
      return {
        ...state,
        editContactData: data,
      };
    case RECEIVE_COUNTRY:
      return {
        ...state,
        addCountryData: data,
      };
    case RECEIVE_STATE:
      return {
        ...state,
        addStateData: data,
      };
    case RECEIVE_CITY:
      return {
        ...state,
        addCityData: data,
      };
    case RECEIVE_ADMIN_ADD_COMPANY:
      return {
        ...state,
        addCompanyData: data,
      }
    case RECEIVE_ADMIN_GET_COMPANY:
      return {
        ...state,
        getCompanyData: data,
      }
    case RECEIVE_ADMIN_EDIT_COMPANY:
      return {
        ...state,
        editCompanyData: data,
      }
    case RECEIVE_ADMIN_DELETE_COMPANY:
      return {
        ...state,
        deleteCompanyData: data,
      }

    //address edit
    case RECEIVE_ADMIN_ALL_COMPADDRESS:
      return {
        ...state,
        allCompAddressData: data,
      }
    case RECEIVE_ADMIN_ADD_COMPADDRESS:
      return {
        ...state,
        addCompAddressData: data,
      }
    case RECEIVE_ADMIN_GET_COMPADDRESS:
      return {
        ...state,
        getCompAddressData: data,
      }
    case RECEIVE_ADMIN_EDIT_COMPADDRESS:
      return {
        ...state,
        editCompAddressData: data,
      }
    case RECEIVE_ADMIN_DELETE_COMPADDRESS:
      return {
        ...state,
        deleteCompAddressData: data,
      }
    case RECEIVE_ADMIN_GET_ADDRESSES:
      return {
        ...state,
        addressesData: data,
      };

    //address contact
    case RECEIVE_ADMIN_ALL_COMPCONTACT:
      return {
        ...state,
        allCompContactData: data,
      }
    case RECEIVE_ADMIN_ADD_COMPCONTACT:
      return {
        ...state,
        addCompContactData: data,
      }
    case RECEIVE_ADMIN_GET_COMPCONTACT:
      return {
        ...state,
        getCompContactData: data,
      }
    case RECEIVE_ADMIN_EDIT_COMPCONTACT:
      return {
        ...state,
        editCompContactData: data,
      }
    case RECEIVE_ADMIN_DELETE_COMPCONTACT:
      return {
        ...state,
        deleteCompContactData: data,
      }

    //question
    case RECEIVE_ADMIN_ALL_QUESTION:
      return {
        ...state,
        allQuestionData: data,
      }
    case RECEIVE_ADMIN_ADD_QUESTION:
      return {
        ...state,
        addQuestionData: data,
      }
    case RECEIVE_ADMIN_GET_QUESTION:
      return {
        ...state,
        getQuestionData: data,
      }
    case RECEIVE_ADMIN_EDIT_QUESTION:
      return {
        ...state,
        editQuestionData: data,
      }
    case RECEIVE_ADMIN_DELETE_QUESTION:
      return {
        ...state,
        deleteQuestionData: data,
      }
    case RECEIVE_ADMIN_ALL_QUESTIONOPTIONS:
      return {
        ...state,
        allQuestionOptionsData: data,
      }

    //question template
    case RECEIVE_ADMIN_ALL_QUESTIONTEMPLATE:
      return {
        ...state,
        allQuestionTemplateData: data,
      }
    case RECEIVE_ADMIN_ADD_QUESTIONTEMPLATE:
      return {
        ...state,
        addQuestionTemplateData: data,
      }
    case RECEIVE_ADMIN_GET_QUESTIONTEMPLATE:
      return {
        ...state,
        getQuestionTemplateData: data,
      }
    case RECEIVE_ADMIN_EDIT_QUESTIONTEMPLATE:
      return {
        ...state,
        editQuestionTemplateData: data,
      }
    case RECEIVE_ADMIN_DELETE_QUESTIONTEMPLATE:
      return {
        ...state,
        deleteQuestionTemplateData: data,
      }

    //course
    case RECEIVE_ADMIN_ADD_COURSE:
      return {
        ...state,
        addCourseData: data,
      };
    case RECEIVE_ADMIN_ALL_COURSE:
      return {
        ...state,
        allCourseData: data,
      };
    case RECEIVE_ADMIN_COURSE:
      return {
        ...state,
        courseData: data,
      };
    case RECEIVE_ADMIN_DELETE_COURSE:
      return {
        ...state,
        deleteCourseData: data,
      };
    case RECEIVE_ADMIN_EDIT_COURSE:
      return {
        ...state,
        editCourseData: data,
      };

    //specialization
    case RECEIVE_ADMIN_ADD_SPECIALIZATION:
      return {
        ...state,
        addSpecializationData: data,
      };
    case RECEIVE_ADMIN_ALL_SPECIALIZATION:
      return {
        ...state,
        allSpecializationData: data,
      };
    case RECEIVE_ADMIN_SPECIALIZATION:
      return {
        ...state,
        specializationData: data,
      };
    case RECEIVE_ADMIN_DELETE_SPECIALIZATION:
      return {
        ...state,
        deleteSpecializationData: data,
      };
    case RECEIVE_ADMIN_EDIT_SPECIALIZATION:
      return {
        ...state,
        editSpecializationData: data,
      };

    case RECEIVE_ADMIN_GET_ALL_COURSES:
      return {
        ...state,
        loadCourseData: data,
      };

    case RECEIVE_ADD_JOB:
      return {
        ...state,
        addJobData: data,
      };
    case RECEIVE_JOB_DETAILS:
      return {
        ...state,
        jobDetailsData: data,
      };
    case RECEIVE_EDIT_JOB:
      return {
        ...state,
        empEditJobData: data,
      };
    case RECEIVE_DELETE_JOB:
      return {
        ...state,
        deleteJobData: data,
      };
    case RECEIVE_STATUS_JOB:
      return {
        ...state,
        empStatusJobData: data,
      };
    case RECEIVE_CAND_SEARCH:
      return {
        ...state,
        candSearch: data,
      };
    case RECEIVE_FILTERED_CAND:
      return {
        ...state,
        candFiltered: data,
      };
    case RECEIVE_FILTERED_CAND_FIRST:
      return {
        ...state,
        candFilteredFirst: data,
      };
    case RECEIVE_CAND_JOBASSIGN:
      return {
        ...state,
        candJobAssign: data,
      };
    case RECEIVE_PENDING_JOBSBYEMPLOYER:
      return {
        ...state,
        pendingJobsByEmployer: data,
      };
    case RECEIVE_STATUS_CANDIDATE:
      return {
        ...state,
        statusCandidate: data,
      };
    case RECEIVE_STATUS_CANDIDATE_REMARK:
      return {
        ...state,
        statusCandidateRemark: data,
      };
    case RECEIVE_ADD_CANDIDATE_PROFILE:
      return {
        ...state,
        addCandidate: data,
      };
    case RECEIVE_EDIT_CANDIDATE_PROFILE:
      return {
        ...state,
        editCandidate: data,
      };

    //user crud
    case RECEIVE_ADMIN_ALL_USER:
      return {
        ...state,
        allUserData: data,
      }
    case RECEIVE_ADMIN_ADD_USER:
      return {
        ...state,
        addUserData: data,
      }
    case RECEIVE_ADMIN_GET_USER:
      return {
        ...state,
        getUserData: data,
      }
    case RECEIVE_ADMIN_EDIT_USER:
      return {
        ...state,
        editUserData: data,
      }
    case RECEIVE_ADMIN_DELETE_USER:
      return {
        ...state,
        deleteUserData: data,
      }
    case RECEIVE_FORM_FIELD_CAND:
      return {
        ...state,
        formfieldCandData: data,
      };

    case RECEIVE_ADMIN_ADD_SOURCE:
      return {
        ...state,
        addSourceData: data,
      };
    case RECEIVE_ADMIN_ALL_SOURCE:
      return {
        ...state,
        allSourceData: data,
      };
    case RECEIVE_ADMIN_SOURCE:
      return {
        ...state,
        sourceData: data,
      };
    case RECEIVE_ADMIN_DELETE_SOURCE:
      return {
        ...state,
        deleteSourceData: data,
      };
    case RECEIVE_ADMIN_EDIT_SOURCE:
      return {
        ...state,
        editSourceData: data,
      };

    case RECEIVE_EDIT_CANDIDATE_STATUS:
      return {
        ...state,
        editCandStatusData: data,
      };

    case RECEIVE_GET_CANDIDATE_STATUS:
      return {
        ...state,
        getCandStatusData: data,
      };

    case RECEIVE_ADMIN_GET_CANDIDATES_STATUS:
      return {
        ...state,
        candidatesStatusData: data,
      }
    case RECEIVE_ADMIN_GET_CANDIDATES_REMARK:
      return {
        ...state,
        candidatesRemarkData: data,
      }
    case RECEIVE_GET_CANDIDATE_FU:
      return {
        ...state,
        candidateFUData: data,
      }

    case RECEIVE_CANDIDATE_STATUS_ALL:
      return {
        ...state,
        CandidateStatusAllData: data,
      }

    case RECEIVE_CANDIDATE_FOLLOW_UP_ALL:
      return {
        ...state,
        CandidateFollowUpAllData: data,
      }

    case RECEIVE_CANDIDATE_FOLLOW_UP_ADD:
      return {
        ...state,
        addCandidateFUData: data,
      };
    case RECEIVE_CANDIDATE_FOLLOW_UP_DELETE:
      return {
        ...state,
        deleteCandidateFUData: data,
      };
    case RECEIVE_CANDIDATE_FOLLOW_UP_EDIT:
      return {
        ...state,
        editCandidateFUData: data,
      };
    case RECEIVE_ADMIN_GET_CANDIDATES_ANSWERS:
      return {
        ...state,
        candidatesAnswersData: data,
      }
    case RECEIVE_COPY_JOB:
      return {
        ...state,
        copyJobData: data,
      }
    case RECEIVE_REPOST_JOB:
      return {
        ...state,
        repostJobData: data,
      }

    //email
    case RECEIVE_ADMIN_ADD_EMAIL:
      return {
        ...state,
        addEmailData: data,
      };
    case RECEIVE_ADMIN_ALL_EMAIL:
      return {
        ...state,
        allEmailData: data,
      };
    case RECEIVE_ADMIN_EMAIL:
      return {
        ...state,
        emailData: data,
      };
    case RECEIVE_ADMIN_DELETE_EMAIL:
      return {
        ...state,
        deleteEmailData: data,
      };
    case RECEIVE_ADMIN_EDIT_EMAIL:
      return {
        ...state,
        editEmailData: data,
      };
    case RECEIVE_IMPORT_FROM_RESUME:
      return {
        ...state,
        importResumeData: data,
      };

    default:
      return state;
  }
};