import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminJobDetails, requestCopyJob, requestRepostJob } from "../Redux/actions";
import image from "../images/extraLogo.png";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';
import ShareLink from 'react-linkedin-share-link'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function ViewJob(props) {

  const params = useParams();
  const [data, setdata] = useState({});
  const [img, setimg] = useState({});
  const navigate = useNavigate();
  const [sourcing, setSourcing] = useState('0');
  const [screening, setScreening] = useState('0');
  const [assessment, setAssessment] = useState('0');
  const [rejected, setRejected] = useState('0');
  const [toClient, setToClient] = useState('0');
  const [sentProfile, setSentProfile] = useState('0');
  const [shortlisted, setShortlisted] = useState('0');
  const [interviewed, setInterviewed] = useState('0');
  const [offered, setOffered] = useState('0');
  const [joined, setJoined] = useState('0');
  const [invoice, setInvoice] = useState('0');
  const [followups, setFollowUps] = useState({});
  const [fu_counts, setFUCounts] = useState('0');
  const [fumodal, setfuModal] = useState(false);

  useEffect(() => {
    props.requestAdminJobDetails({
      id: params.id,
    });
  }, [params.id]);

  useEffect(() => {
    let jobDeatilsData = props.data.jobDeatilsData;
    if (jobDeatilsData !== undefined) {
      setdata(jobDeatilsData.data.data.job[0]);
      if (jobDeatilsData.data.data.job[0].job_status !== undefined && jobDeatilsData.data.data.job[0].job_status !== null) {
        const job_array = jobDeatilsData.data.data.job[0].job_status.split('|');
        setFUCounts(jobDeatilsData.data.data.job[0].fu_counts);
        setFollowUps(jobDeatilsData.data.data.follow_ups);
        job_array.forEach((key) => {
          const status_array = key.split('^');
          if (status_array[0] === 'Sourcing') {
            setSourcing(status_array[1]);
          }
          else if (status_array[0] === 'Screening') {
            setScreening(status_array[1]);
          }
          else if (status_array[0] === 'Move to the Assessment') {
            setAssessment(status_array[1]);
          }
          else if (status_array[0] === 'Rejected') {
            setRejected(status_array[1]);
          }
          else if (status_array[0] === 'Move to the Client') {
            setToClient(status_array[1]);
          }
          else if (status_array[0] === 'Sent Profile') {
            setSentProfile(status_array[1]);
          }
          else if (status_array[0] === 'Shortlisted') {
            setShortlisted(status_array[1]);
          }
          else if (status_array[0] === 'Interviewed') {
            setInterviewed(status_array[1]);
          }
          else if (status_array[0] === 'Offered') {
            setOffered(status_array[1]);
          }
          else if (status_array[0] === 'Joined') {
            setJoined(status_array[1]);
          }
          else if (status_array[0] === 'Invoice') {
            setInvoice(status_array[1]);
          }
        })
      }
      if (jobDeatilsData.data.data.job[0].employee_logo) {
        setimg(
          process.env.REACT_APP_API_HOST +
          jobDeatilsData.data.data.job[0].employee_logo
        );
      } else {
        setimg(image);
      }
    }
  }, [props.data.jobDeatilsData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Job Details</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  function copyJob() {
    Swal.fire({
      title: 'Are you sure want to create duplicate job?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, create it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestCopyJob({
          data: {
            id: params.id,
          },
        });
      }
    })
  }

  function repostJob() {
    Swal.fire({
      title: 'Are you sure want to repost the job?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, repost it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestRepostJob({
          data: {
            id: params.id,
          },
        });
      }
    })
  }

  useEffect(() => {
    let repostJobData = props.data.repostJobData;
    if (repostJobData !== undefined) {
      if (repostJobData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Job Reposted Successfully.",
          "success"
        );
        props.data.repostJobData = undefined;
        // window.location = "/admin/jobs/1/10";
      } else {
        Swal.fire("Error!", `${repostJobData.data.message}`, "error");
        props.data.repostJobData = undefined;
      }
    }
  }, [props.data.repostJobData]);

  useEffect(() => {
    let copyJobData = props.data.copyJobData;
    if (copyJobData !== undefined) {
      if (copyJobData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Duplicate Job Created Successfully.",
          "success"
        );
        props.data.copyJobData = undefined;
        window.location = "/admin/jobs/1/10";

      } else {
        Swal.fire("Error!", `${copyJobData.data.message}`, "error");
        props.data.copyJobData = undefined;
      }
    }
  }, [props.data.copyJobData]);

  function rountechange(status) {
    navigate(`/admin/candidates/1/10/${params.job_id}/` + status);
  }

  const futoggle = () => {
    if (fumodal === true) {
      setfuModal(!fumodal);
    } else {
      setfuModal(!fumodal);
    }
  };

  return (
    <>
      <div class="container-scroller">
        <Header name="Job Details" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Jobs" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="home-tab">
                  <div class="row flex-grow">
                    <div class="col-12 grid-margin stretch-card">
                      <div class="card card-rounded">
                        <div class="card-body">
                          <div class="d-sm-flex justify-content-between align-items-start">
                            <div class="btn-wrapper">
                              <RWebShare
                                data={{
                                  text: "Job Vacancy",
                                  url: process.env.REACT_APP_WEBSITE + '/jobdetails?psd=' + params.id,
                                  title: "Job Vacancy",
                                }}
                              >
                                <p class="btn btn-otline-dark align-items-center">
                                  <i class="fa fa-share"></i> Share
                                </p>
                              </RWebShare>
                              <button
                                onClick={printPage}
                                class="btn btn-otline-dark"
                              >
                                <i class="fa fa-print"></i> Print
                              </button>
                              <button onClick={copyJob} class="btn btn-otline-dark">
                                <i class="fa fa-copy"></i> Copy
                              </button>
                              <button onClick={repostJob} class="btn btn-otline-dark">
                                <i class="fa fa-calendar"></i> Repost
                              </button>
                              <FacebookShareButton
                                url={process.env.REACT_APP_WEBSITE + '/jobdetails?psd=' + params.id}
                                quote={data.title}
                                hashtag="#hrace #job"
                              >
                                <FacebookIcon size={32} round />
                              </FacebookShareButton>
                              <TwitterShareButton
                                url={process.env.REACT_APP_WEBSITE + '/jobdetails?psd=' + params.id}
                                quote={data.title}
                                hashtag="#hrace #job"
                              >
                                <TwitterIcon size={32} round />
                              </TwitterShareButton>
                              <WhatsappShareButton
                                url={process.env.REACT_APP_WEBSITE + '/jobdetails?psd=' + params.id}
                                title={data.title}
                                separator=' Apply Now: '
                              >
                                <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                              &nbsp;&nbsp;
                              <ShareLink link={process.env.REACT_APP_WEBSITE + '/jobdetails?psd=' + params.id}>
                                {link => (
                                  <a href={link} target='_blank'><i class="fa fa-linkedin"></i> </a>
                                )}
                              </ShareLink>
                            </div>
                            <div>
                            </div>
                          </div>
                          {/* <div class="row">
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Screening</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Move for Assessment</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Move to the Client</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Sent Profile</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Shortlisted</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Interviewed</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                            <div class="col-md-2">
                              <Card style={{ width: '10rem', height: "10rem" }}>
                                <Card.Body>
                                  <Card.Title>Offered</Card.Title><Button variant="primary">0</Button>
                                </Card.Body>
                              </Card>
                            </div>
                          </div> */}
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#9FE2BF" }} href={`/admin/candidates/1/10/${params.id}/Sourcing`}>  Sourcing ({sourcing})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#78281F" }} href={`/admin/candidates/1/10/${params.id}/Screening`}>  Screening ({screening})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#4A235A" }} href={`/admin/candidates/1/10/${params.id}/Move to the Assessment`}>  Move to the Assessment ({assessment})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#B00020" }} href={`/admin/candidates/1/10/${params.id}/Rejected`}>  Rejected ({rejected})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#1B4F72" }} href={`/admin/candidates/1/10/${params.id}/Move to the Client`}>  Move to the Client ({toClient})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#154360" }} href={`/admin/candidates/1/10/${params.id}/Sent Profile`}>  Sent Profile ({sentProfile})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#186A3B" }} href={`/admin/candidates/1/10/${params.id}/Shortlisted`}>  Shortlisted ({shortlisted})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#7E5109" }} href={`/admin/candidates/1/10/${params.id}/Interviewed`}>  Interviewed ({interviewed})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#6E2C00" }} href={`/admin/candidates/1/10/${params.id}/Offered`}>Offered ({offered})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#424949" }} href={`/admin/candidates/1/10/${params.id}/Joined`}>Joined ({joined})</a>
                          <a class='badge' style={{ border: "0px", color: "white", backgroundColor: "#17202A" }} href={`/admin/candidates/1/10/${params.id}/Invoice`}>Invoice ({invoice})</a>
                          <button class="badge" style={{ border: "0px", color: "white", backgroundColor: "#FFDE03" }} onClick={() => { futoggle(); }}>Follow Ups ({fu_counts})</button>
                          {fumodal === true && (
                            <Modal
                              isOpen={fumodal}
                              toggle={futoggle}
                            >
                              <ModalHeader toggle={futoggle}>
                                Follow Ups
                              </ModalHeader>
                              <ModalBody>
                                <form class="forms-sample">
                                  <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                      <div class="form-group">
                                        <table class="select-table" style={{ fontSize: "70%" }}>
                                          <thead>
                                            <tr>
                                              <th width="5%">Cand. Name</th>
                                              <th width="5%">FU. Date</th>
                                              <th width="5%">FU. Time</th>
                                              <th width="1%">Remark</th>
                                              <th width="5%">Added By</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {followups.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>{item.first_name} {item.last_name}</td>
                                                  <td>{item.fu_date}</td>
                                                  <td>{item.fu_time}</td>
                                                  <td>{item.remark}</td>
                                                  <td>{item.username}</td>
                                                </tr>)
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <button
                                    class="btn btn-light"
                                    onClick={futoggle}
                                  >
                                    Cancel
                                  </button>
                                </form>
                              </ModalBody>
                            </Modal>
                          )}
                          <br />
                          <br />
                          <div id="printme">
                            <div class="d-flex ">
                              <a href={`/admin/viewCompany/${data.company_id}`}>  <img src={img} alt="" height="60" width="60" /></a>
                              <div style={{ paddingLeft: "15px" }}>
                                <h4>{data.title}</h4>
                                <p>
                                  <i class="fa fa-map-marker"></i>{" "}
                                  <a href={`/admin/viewCompany/${data.company_id}`}>  {data.company_name}</a>
                                </p>
                              </div>
                            </div>

                            <hr />
                            <div class="row">
                              <div class="col-md-4">
                                {<p dangerouslySetInnerHTML={{ __html: '<b>Salary: </b>' + data.currency_name + ' ' + data.salary_from + '-' + data.salary_to }}></p>}
                              </div>
                              <div class="col-md-4">
                                <p>
                                  <b>Shift : </b>
                                  {data.shift_name}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p>
                                  <b>Functional Area: </b>
                                  {data.functional_area_name}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-4">
                                <p>
                                  <b>Experience: </b>
                                  {data.max_exp > 0
                                    ? data.min_exp + " year/s to " + data.max_exp + "year/s."
                                    : data.experience > 0
                                      ? data.experience + " year experienced in "
                                      : "Fresher in "}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p>
                                  <b>Category: </b>{data.level} {data.category_name}
                                </p>
                              </div>
                              <div class="col-md-4"></div>
                            </div>
                            <div class="row">
                              <div class="col-md-4">
                                <p>
                                  <b>Location: </b>
                                  <text dangerouslySetInnerHTML={{ __html: data.city_name }}></text>
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p>
                                  <b>Industry: </b>
                                  {data.industry_name}
                                </p>
                              </div>
                              <div class="col-md-4">
                                <p>
                                  <b>Education: </b>
                                  {data.specialization_name}
                                </p>
                              </div>
                            </div>
                            <p>
                              <b>Skills: </b>
                              {data.skill_name}
                            </p>
                            <p>
                              <b>Description: </b>
                              <pre style={{
                                whiteSpace: "pre-wrap",
                                fontFamily: "Inter",
                                fontWeight: "normal",
                                fontStyle: "normal",
                                color: "#7E8890",
                                fontSize: "14px",
                              }} dangerouslySetInnerHTML={{ __html: data.description }}></pre>
                            </p>
                            {data.desc_role != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Role: </b> <text>' + data.desc_role + '</text>' }}></p> : ''}
                            {data.desc_profile != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Profile: </b> <text>' + data.desc_profile + '</text>' }}></p> : ''}
                            {data.desc_perks != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Perks: </b> <text>' + data.desc_perks + '</text>' }}></p> : ''}
                            {data.Recruiter_visible == 1 ? <p dangerouslySetInnerHTML={{ __html: '<b>Recruiters Details: </b></br> <text> <b>Name: </b>' + data.Recruiter_name + '</br> <b>Email: </b>' + data.Recruiter_email + '</br> <b>Company: </b>' + data.Recruiter_comp + '</br> <b>Phone No.: </b>' + data.Recruiter_phoneno + '</br> <b>Website: </b>' + data.Recruiter_website + '</br> <b>Details: </b>' + data.Recruiter_details + '</text>' }}></p> : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <div class="container-fluid page-body-wrapper">
          dfh
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminJobDetails, requestCopyJob, requestRepostJob }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewJob);
