import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { userLogout } from "../Redux/actions";

function Sidebar(props) {

    const base_url=process.env.PUBLIC_URL

    const navigate = useNavigate()
    const [show, setShow] = useState("none");
    const [setting, setSetting] = useState("none");
    const [user_display, setUserDisplay] = useState(0);

    useEffect(() => {
        let formfieldData = props.data.loginData.data.data;
        console.log(formfieldData.admin_detail.user_list);
        if (formfieldData !== undefined) {
            setUserDisplay(formfieldData.admin_detail.user_list);
        }
    }, [props.data.loginData.data.data]);

    function displayOption() {
        if (show === "none") {
            setShow("block")
        } else {
            setShow("none")
        }
    }
    function displaySetting() {
        if (setting === "none") {
            setSetting("block")
        } else {
            setSetting("none")
        }
    }

    function logout() {
        props.userLogout();
        navigate("/admin/login")
    }
    return (
        <>
            {/* <div class="theme-setting-wrapper">
                <button style={{ border: "0px", backgroundColor: "transparent" }} onClick={displaySetting}>
                    <div id="settings-trigger"><i class="fa fa-cog"></i></div>
                </button>

                <div id="theme-settings" class="settings-panel"  style={{ display: `${setting}` }}>
                    <i class="settings-close ti-close"></i>
                    <p class="settings-heading">SIDEBAR SKINS</p>
                    <div class="sidebar-bg-options selected" id="sidebar-light-theme"><div class="img-ss rounded-circle bg-light border me-3"></div>Light</div>
                    <div class="sidebar-bg-options" id="sidebar-dark-theme"><div class="img-ss rounded-circle bg-dark border me-3"></div>Dark</div>
                    <p class="settings-heading mt-2">HEADER SKINS</p>
                    <div class="color-tiles mx-0 px-4">
                        <div class="tiles success"></div>
                        <div class="tiles warning"></div>
                        <div class="tiles danger"></div>
                        <div class="tiles info"></div>
                        <div class="tiles dark"></div>
                        <div class="tiles default"></div>
                    </div>
                </div>
            </div> */}

            <nav class="sidebar sidebar-offcanvas" id="sidebar" style={{ marginLeft: "-12px" }}>
                <ul class="nav">
                    <li class={`nav-item ${props.name === "Home" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/home"}>
                            <i class="fa fa-home menu-icon"></i>
                            <span class="menu-title">Home</span>
                        </a>
                    </li>

                    <li class="nav-item nav-category">Data</li>
                    {/* <li class={`nav-item ${props.name === "Candidates" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/candidates/1/10/0" >
                            <i class="menu-icon fa fa-users"></i>
                            <span class="menu-title">Candidates</span>
                        </a>
                    </li> */}
                    <li class={`nav-item ${props.name === "Jobs" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/jobs/1/10"} >
                            <i class="menu-icon fa fa-user-secret"></i>
                            <span class="menu-title">Jobs</span>
                        </a>
                    </li>

                    <li class={`nav-item ${props.name === "CandidateStatusAll" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/candidateallstatus/1/10"} >
                            <i class="menu-icon fa fa-user"></i>
                            <span class="menu-title">Candidate Statuses</span>
                        </a>
                    </li>

                    <li class={`nav-item ${props.name === "CandidateFollowUpAll" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/candidateallfu/1/10"} >
                            <i class="menu-icon fa fa-calendar"></i>
                            <span class="menu-title">Candidate FollowUps</span>
                        </a>
                    </li>

                    <li class="nav-item nav-category">Search</li>
                    <li class={`nav-item ${props.name === "SearchCandidates" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/search"} >
                            <i class="menu-icon fa fa-search"></i>
                            <span class="menu-title">Candidates</span>
                        </a>
                    </li>

                    <li class="nav-item nav-category">Masters</li>
                    <li class={`nav-item ${props.name === "Options" && "active"} `}>
                        <button class="nav-link" style={{ border: "0px", backgroundColor: "transparent" }} onClick={displayOption}>
                            <i class="menu-icon fa fa-th"></i>
                            <span class="menu-title">Masters</span>
                            <i class="menu-arrow"></i>
                        </button>
                        <div class="collapse" id="form-elements" style={{ display: `${show}` }}>
                            <ul class="nav flex-column sub-menu">
                                <li class="nav-item" >
                                    <a class="nav-link" href={base_url+"/admin/companies/1/10"} >
                                        <i class="fa fa-building">Companies</i>
                                    </a>
                                </li>
                                <li class="nav-item" >
                                    <a class="nav-link" href={base_url+"/admin/questionTemplate/1/10"}>
                                        <i class="fa fa-question"> Questionare </i>
                                    </a>
                                </li>
                                <li class="nav-item" >
                                    <a class="nav-link" href={base_url+"/admin/career/1/10"}>
                                        <i class="fa fa-area-chart"> Career Levels </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/size/1/10"}>
                                        <i class="fa fa-signal"> Company Size</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/degree/1/10"}>
                                        <i class="fa fa-graduation-cap" > Degree Levels </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/course/1/10"}>
                                        <i class="fa fa-graduation-cap" > Courses </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/specialization/1/10"}>
                                        <i class="fa fa-graduation-cap" > Specialization </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/functional/1/10"}>
                                        <i class="fa fa-cogs"> Functional Area </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/industries/1/10"}>
                                        <i class="fa fa-industry"> Industries </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/categories/1/10"}>
                                        <i class="fa fa-th-large"> Job Categories </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/shifts/1/10"}>
                                        <i class="fa fa-clock-o">  Job Shifts</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/tags/1/10"}>
                                        <i class="fa fa-hashtag"> Tags </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/types/1/10"}>
                                        <i class="fa fa-bars">  Job Types</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/ownership/1/10"}>
                                        <i class="fa fa-university">  Ownership Types</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/position/1/10"}>
                                        <i class="fa fa-sitemap">  Positions</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/currency/1/10"}>
                                        <i class="fa fa-inr">  Salary Currency </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/period/1/10"}>
                                        <i class="fa fa-circle-o-notch">  Salary Period </i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/skill/1/10"}>
                                        <i class="fa fa-thumbs-up"> Skills</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href={base_url+"/admin/source/1/10"}>
                                        <i class="fa fa-th-large"> Sources</i>
                                    </a>
                                </li>
                                {user_display !== 0 ?
                                    <div>
                                        <li class="nav-item">
                                            <a class="nav-link" href={base_url+"/admin/user/1/10"}>
                                                <i class="fa fa-user"> Users</i>
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href={base_url+"/admin/email/1/10"}>
                                                <i class="fa fa-envelope"> Email Master</i>
                                            </a>
                                        </li>
                                    </div>
                                    : ''
                                }
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item nav-category">Subscribe</li>
                    <li class={`nav-item ${props.name === "Subscribe" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/subscribe/1/10"}>
                            <i class="menu-icon fa fa-file"></i>
                            <span class="menu-title">Subscribers</span>
                        </a>
                    </li>
                    <li class={`nav-item ${props.name === "Contact" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/contact/1/10"}>
                            <i class="menu-icon fa fa-question-circle-o"></i>
                            <span class="menu-title">Contact</span>
                        </a>
                    </li>
                    <li class="nav-item nav-category">Profile</li>
                    <li class={`nav-item ${props.name === "Profile" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/profile"}>
                            <i class="menu-icon fa fa-user-circle-o"></i>
                            <span class="menu-title">Profile</span>
                        </a>
                    </li>
                    <li class={`nav-item ${props.name === "Password" && "active"} `}>
                        <a class="nav-link" href={base_url+"/admin/changepass"}>
                            <i class="menu-icon fa fa-unlock-alt"></i>
                            <span class="menu-title">Change Password</span>
                        </a>
                    </li>
                    <li class="nav-item" style={{ cursor: "pointer" }}>
                        <a class="nav-link" onClick={logout}>
                            <i class="menu-icon fa fa-sign-out"></i>
                            <span class="menu-title">Logout</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </>
    )
}
const mapStateToProps = (state) => {
    return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        { userLogout },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);