import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import "./style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminGetCandidatesAnswers } from "../Redux/actions";
import Swal from "sweetalert2";

function CandidateAnswers(props) {
  const location = useLocation();
  const params = useParams();
  const [candidates, setcandidates] = useState([]);
  const [candidateAnswers, setCandidateAnswers] = useState([]);
  const [job, setjob] = useState(location.state.job);
  const navigate = useNavigate();
  const [page_size, setpage_size] = useState(0);
  const [id, setid] = useState(0);
  const [logindetails, setLoginDetails] = useState([]);

  const [uid, setUID] = useState('0');
  const [candidate_id, setcandidate_id] = useState(0);

  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    setLoginDetails(formfieldData.admin_detail);
    setUID(formfieldData.id);

  }, [props.data.loginData.data.data]);

  useEffect(() => {
    props.requestAdminGetCandidatesAnswers({
      job_id: params.job_id,
      cand_id: params.cand_id,
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size]);

  useEffect(() => {
    let candidatesData = props.data.candidatesAnswersData.data;
    if (candidatesData !== undefined) {
      if (candidatesData) {
//        setjob(candidatesData.data);
        setCandidateAnswers(candidatesData.answers);
      }
    }
  }, [props.data.candidatesAnswersData]);

  return (
    <>
      <div class="container-scroller">
        <Header name="Candidate Answers" sub={
          <div class="row">
            <div class="row">
              <div class="col-md-4">
                <p dangerouslySetInnerHTML={{ __html: '<b>Salary: </b>' + job.currency_name + ' ' + job.salary_from + '-' + job.salary_to }}></p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Skills : </b>
                  {job.skill_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Functional Area: </b>
                  {job.functional_area_name}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Experience: </b>
                  {job.max_exp > 0
                    ? job.min_exp + " year/s to " + job.max_exp + "year/s."
                    : job.experience > 0
                      ? job.experience + " year experienced in "
                      : "Fresher in "}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Category: </b>{job.level} {job.category_name}
                </p>
              </div>
              {/* <div class="col-md-4">{job.desc_role != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Role: </b> <text>' + job.desc_role + '</text>' }}></p> : ''}</div> */}
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Location: </b>
                  <text dangerouslySetInnerHTML={{ __html: job.city_name }}></text>
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Industry: </b>
                  {job.industry_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Education: </b>
                  {job.specialization_name}
                </p>
              </div>
            </div>
            {/* <p>
              <b>Skills: </b>
              {job.skill_name}
            </p>
             */}
          </div>
        } />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="CandidateAnswers" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <div class="row">
                                  <h6><b>{job.first_name} {job.last_name}</b></h6>
                                  <div class="col-sm-4"><p><b>Phone No.:</b> {job.phone}</p></div>
                                  <div class="col-sm-4"><p><b>Email: </b>{job.email}</p></div>
                                  <div class="col-sm-4"><p><b>Experience: </b>{job.exp_year} Years & {job.exp_month} Months</p></div>
                                  {/* <div class="col-sm-4"><p><b>Industry: </b>{job.industries}</p></div> */}
                                  <div class="col-sm-4"><p><b>Current Salary: </b>{job.currency} {job.current_salary}</p></div>
                                  <div class="col-sm-4"><p><b>Notice Period: </b>{job.notice_period}</p></div>
                                  {/* <div class="col-sm-4"><p><b>Skill Name: </b>{job.skill_name}</p></div> */}
                                  {/* <div class="col-sm-4"><p><b>Functional Area: </b>{job.functional_area1}</p></div> */}
                                </div>
                              </div>
                            </div>
                            <br />
                                  {candidateAnswers.map((item) => {                                    
                                    return (
                                          <div class="col-md-12">
                                            <div class="row">
                                            <b>{item.name}</b>
                                              {item.type == 1 ?
                                                <div class="col-md-12">
                                                  <label>{item.answer}</label>
                                                </div> : ''}
                                              {item.type == 2 ?
                                                <div class="col-md-12">
                                                  <label>{item.answer}</label>
                                                </div> : ''}
                                              {item.type == 3 ?
                                                <div class="col-md-12">
                                                  <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                      value="1" checked ={item.radio == 1}  />
                                                    <label class="form-check-label">Yes</label>
                                                  </div>
                                                  <div class="form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                      value="0" checked ={item.radio == 0} />
                                                    <label class="form-check-label">No</label>
                                                  </div>
                                                </div>
                                                : ''}
                                              {item.type == 4 ?
                                                <div class="col-md-12">
                                                  {item.q_option1?<div>
                                                    <input type="checkbox"
                                                     style={{width: '20px'}} id="option1" checked={item.option1 == 1} />
                                                    {item.q_option1}<br />
                                                    </div> :''}
                                                    {item.q_option2?<div>
                                                    <input type="checkbox"
                                                      style={{width: '20px'}} id="option2" checked={item.option2 == 1} />
                                                    {item.q_option2}<br />
                                                    </div> :''}
                                                    {item.q_option3?<div>
                                                    <input type="checkbox"
                                                      style={{width: '20px'}} id="option3" checked={item.option3 == 1} />
                                                    {item.q_option3}<br />
                                                    </div> :''}
                                                    {item.q_option4?<div>
                                                    <input type="checkbox"
                                                      style={{width: '20px'}} id="option4" checked={item.option4} />
                                                    {item.q_option4}<br />
                                                    </div> :''}
                                                    {item.q_option5?<div>
                                                    <input type="checkbox"
                                                      style={{width: '20px'}} id="option5" checked={item.option5} />
                                                    {item.q_option5}<br />
                                                    </div> :''}
                                                    {item.q_option6?<div>
                                                      <input type="checkbox"
                                                      style={{width: '20px'}} id="option6" checked={item.option6} />
                                                    {item.q_option6}
                                                    </div> :''}
                                                </div>
                                                : ''}
                                            </div>
                                            <br />
                                          </div>
                                    );
                                  })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminGetCandidatesAnswers }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAnswers);
