import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import "./style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminGetCandidatesRemark, requestStatusCandidateRemark, requestCandidateFollowUpEdit, requestGetCandidateStatus, requestCandidateFollowUpDelete } from "../Redux/actions";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";

function CandidatesFollowUp(props) {
  const location = useLocation();
  const params = useParams();
  const [candidates, setcandidates] = useState(location.state.candidates);
  const [candidateStatus, setcandidateStatus] = useState([]);
  const [job, setjob] = useState(location.state.job);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [selectedFile, setselectedFile] = useState([]);
  const [statusmodal, setstatusModal] = useState(false);
  const [statusdata, setstatusdata] = useState({ status: "", remark: "", cand_remark: "" });
  const [statuseditdata, setstatuseditdata] = useState({ eid: '0', estatus: '', eremark: '' });
  const [id, setid] = useState(0);
  const inputFile = useRef();
  const [logindetails, setLoginDetails] = useState([]);
  const [sourcing, setSourcing] = useState('0');
  const [screening, setScreening] = useState('0');
  const [assessment, setAssessment] = useState('0');
  const [toClient, setToClient] = useState('0');
  const [sentProfile, setSentProfile] = useState('0');
  const [shortlisted, setShortlisted] = useState('0');
  const [interviewed, setInterviewed] = useState('0');
  const [offered, setOffered] = useState('0');
  const [joined, setJoined] = useState('0');
  const [invoice, setInvoice] = useState('0');
  const [job_details, setjob_details] = useState(location.state.job_details);
  const [selectedCand, setSelectedCand] = useState([{ id: "" }]);

  const [jobassignmodal, setjobassignModal] = useState(false);
  const [assign_status, setAssignStatus] = useState('');
  const [status_label, setstatus_label] = useState('');
  const [errorColor, seterrorColor] = useState('black');
  const [uid, setUID] = useState('0');
  const [editmodal, seteditModal] = useState(false);
  const [remarkModal, setRemarkModal] = useState(false);
  const [followUpList, setFollowUpList] = useState([]);
  const [candidate_id, setcandidate_id] = useState(0);

  const [fueditmodal, setfueditModal] = useState(false);
  const [fueditdata, setfueditdata] = useState({
    reamrk: ""
  });

  const [editdata, seteditdata] = useState({ remark: "", fu_date: "", fu_time: "" });
  const [adddata, setadddata] = useState({ remark: "", fu_date: "", fu_time: "" });

  const [addmodal, setaddModal] = useState(false);

  const [change, setchange] = useState(false);

  const [errorremark, seterrorremark] = useState("");
  const [errorfudate, seterrorfudate] = useState("");
  const [errorfutime, seterrorfutime] = useState("");

  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    setLoginDetails(formfieldData.admin_detail);
    setUID(formfieldData.id);

  }, [props.data.loginData.data.data]);


  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/candidates/1/${e.target.value}/${params.job_id}/${params.status}`);
    }
  }

  useEffect(() => {
    console.log(candidates);
    props.requestAdminGetCandidatesRemark({
      page: params.page,
      page_size: params.page_size,
      cand_status_id: params.cand_status_id,
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  useEffect(() => {
    let candidatesData = props.data.candidatesRemarkData.data.data;
    if (candidatesData !== undefined) {
      if (candidatesData) {
        setcandidateStatus(candidatesData.data);
        // setcandidates(props.data.candidatesRemarkData.data.candidate);
        // if (candidatesData.data.job[0].job_status !== undefined && candidatesData.data.job[0].job_status !== null) {
        //   if (candidatesData.data.job[0].job_status !== undefined && candidatesData.data.job[0].job_status !== null) {
        //     const job_array = candidatesData.data.job[0].job_status.split('|');
        //     job_array.forEach((key) => {
        //       const status_array = key.split('^');
        //       if (status_array[0] === 'Sourcing') {
        //         setSourcing(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Screening') {
        //         setScreening(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Move to the Assessment') {
        //         setAssessment(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Move to the Client') {
        //         setToClient(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Sent Profile') {
        //         setSentProfile(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Shortlisted') {
        //         setShortlisted(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Interviewed') {
        //         setInterviewed(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Offered') {
        //         setOffered(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Joined') {
        //         setJoined(status_array[1]);
        //       }
        //       else if (status_array[0] === 'Invoice') {
        //         setInvoice(status_array[1]);
        //       }
        //     })
        //   }
        // }
        // setpagination(candidatesData.data.meta);
        // if (candidatesData.data.meta.last_page < 5) {
        //   let array = [];
        //   Array.from(Array(candidatesData.data.meta.last_page), (e, i) => {
        //     array.push(i + 1);
        //   });
        //   setpages(array);
        // } else {
        //   let array = [];
        //   if (candidatesData.data.meta.current_page <= 3) {
        //     array.push(1, 2, 3, 4, 5);
        //     setpages(array);
        //   } else if (
        //     candidatesData.data.meta.last_page -
        //     candidatesData.data.meta.current_page <=
        //     2
        //   ) {
        //     array.push(
        //       candidatesData.data.meta.last_page - 4,
        //       candidatesData.data.meta.last_page - 3,
        //       candidatesData.data.meta.last_page - 2,
        //       candidatesData.data.meta.last_page - 1,
        //       candidatesData.data.meta.last_page
        //     );
        //     setpages(array);
        //   } else {
        //     array.push(
        //       Number(candidatesData.data.meta.current_page) - 2,
        //       Number(candidatesData.data.meta.current_page) - 1,
        //       candidatesData.data.meta.current_page,
        //       Number(candidatesData.data.meta.current_page) + 1,
        //       Number(candidatesData.data.meta.current_page) + 2
        //     );
        //     setpages(array);
        //   }
        // }
      }
    }
  }, [props.data.candidatesData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Candidates</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  const jobassigntoggle = () => {
    if (jobassignmodal === true) {
      setjobassignModal(!jobassignmodal);
    } else {
      setjobassignModal(!jobassignmodal);
    }
  };

  const onchangestatusdata = (event, param1) => {
    // console.log(event.target.value);
    // console.log(param1);
    seteditdata((editdata) => ({
      ...editdata,
      [event.target.name]: event.target.value,
    }));
  };

  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }

  function validateremark() {
    let formIsValid = false;
    if (!adddata["remark"]) {
      formIsValid = false;
      seterrorremark("*Enter Remark.");
    }
    else {
      formIsValid = true;
    }
    return formIsValid;
  }

  function validatefudate() {
    let formIsValid = false;
    if (!adddata["fu_date"]) {
      formIsValid = false;
      seterrorfudate("*Enter FollowUp Date.");
    }
    else {
      formIsValid = true;
    }
    return formIsValid;
  }

  function validatefutime() {
    let formIsValid = false;
    if (!adddata["fu_time"]) {
      formIsValid = false;
      seterrorfutime("*Enter FollowUp Time.");
    }
    else {
      formIsValid = true;
    }
    return formIsValid;
  }

  function validateAddForm() {
    let remark = validateremark(); let fudate = validatefudate(); let futime = validatefutime();
    let valid = remark && fudate && futime;
    return valid;
  }

  const addtoggle = () => setaddModal(!addmodal);

  const saveaddtoggle = (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      props.requestStatusCandidateRemark({
        data: {
          status: adddata.remark, fu_date: adddata.fu_date, fu_time: adddata.fu_time, 
          cand_id: candidates.id, cand_status_id: params.cand_status_id, uid: uid
        },
      });
      setadddata({
        remark: "", fu_date: "", fu_time: ""
      });
      addtoggle();
    }
  };

  useEffect(() => {
    let statusCandidateRemarkData = props.data.statusCandidateRemark;
    if (statusCandidateRemarkData !== undefined) {
      if (statusCandidateRemarkData.data) {
        Swal.fire({
          title: "Good job!",
          text: "Candidate Remark added successfully..",
          icon: "success",
        });
        setchange(!change);
        setstatusdata({ status: "", remark: "", cand_remark: "" });
      }
    }
    props.data.statusCandidateRemark = undefined;
  }, [props.data.statusCandidateRemark]);

  const edittoggle = (item) => {
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(item.id);
      seteditdata({
        remark: item.remark, fu_date: item.fu_date, fu_time: item.fu_time
      });
    }
  };

  // useEffect(() => {
  //   let editCandStatusData = props.data.getCandStatusData;
  //   if (editCandStatusData !== undefined) {
  //     if (editCandStatusData.data) {
  //       seteditdata(editCandStatusData.data.data.Candidate[0]);
  //       status_label = editCandStatusData.data.data.Candidate[0].status;
  //     }
  //   }
  // }, [props.data.editCandStatusData]);

  const saveedittoggle = (e) => {
    e.preventDefault();
      console.log(id);
      props.requestCandidateFollowUpEdit({
        id: id,
        data: { remark: editdata.remark, fu_date: editdata.fu_date, fu_time: editdata.fu_time, uid: uid },
      });
      seteditdata({
        remark: "",
      });
      setid(0);
      edittoggle();
  };

  // function assignstatuslabel(status) {
  //   setstatus_label(status);
  // }

  useEffect(() => {
    let editCareerData = props.data.editCandidateFUData;
    if (editCareerData !== undefined) {
      if (editCareerData.data) {
        Swal.fire({
          title: "Good job!",
          text: "Candidate Status updated successfully..",
          icon: "success",
        });
        // setchange(!change);
        props.requestAdminGetCandidatesRemark({
          page: params.page,
          page_size: params.page_size,
          cand_status_id: params.cand_status_id,
        });
      }
    }
    props.data.editCandidateFUData = undefined;
  }, [props.data.editCandidateFUData]);


  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // props.requestCandidateFollowUpDelete({
        //   id: id,
        //   data: { remark: editdata.remark, fu_date: editdata.fu_date, fu_time: editdata.fu_time, uid: uid },
        // });
  
        props.requestCandidateFollowUpDelete({
          id: id,
          data: { user_id: uid }
        });
      }
    })
  }

  useEffect(() => {
    let deleteCandidateStatusData = props.data.deleteCandidateFUData;
    if (deleteCandidateStatusData !== undefined) {
      if (deleteCandidateStatusData.data) {
        Swal.fire({
          title: "Good job!",
          text: "Candidate Status deleted successfully..",
          icon: "success",
        });
        setchange(!change);
        // props.requestAdminGetCandidatesRemark({
        //   page: params.page,
        //   page_size: params.page_size,
        //   cand_status_id: params.cand_status_id,
        // });
        window.location.reload();
      }
    }
    props.data.deleteCandidateFUData = undefined;
  }, [props.data.deleteCandidateFUData]);

  const remarktoggle = (id) => {
    if (remarkModal === true) {
      setRemarkModal(!remarkModal);
    } else {
      setRemarkModal(!remarkModal);
      setcandidate_id(id);
    }
  };

  useEffect(() => {
    if (remarkModal === true) {
      const getCandidateFollowUp = async () => {
        const resst = await fetch(process.env.REACT_APP_API_HOST + '/api/admin/getcandidatefollowups/' + candidate_id);
        const getst = await resst.json();
        setFollowUpList(getst);
      }
      getCandidateFollowUp();
    }
  }, [remarkModal]);

  const onchangefudata = (event, param1) => {
    // console.log(event.target.value);
    // console.log(param1);
    seteditdata((fueditdata) => ({
      ...fueditdata,
      [event.target.name]: event.target.value,
    }));
  };

  const fuedittoggle = (id) => {
    if (fueditmodal === true) {
      setfueditModal(!fueditmodal);
    } else {
      setfueditModal(!fueditmodal);
      setid(id);
      setfueditdata({
        remark: " ",
      });
    }
  };

  useEffect(() => {
    let editCandFUData = props.data.candidateFUData;
    if (editCandFUData !== undefined) {
      if (editCandFUData.data) {
        setfueditdata(editCandFUData.data.data.Candidate[0]);
        //        FU_label = editCandFUData.data.data.Candidate[0].FU;
      }
    }
  }, [props.data.candidateFUData]);

  function deletefudata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestDeleteCandidateFU({
          id: id,
        });
      }
    })
  }

  useEffect(() => {
    let deleteCandidateFUData = props.data.candidatesFUDeleteData;
    if (deleteCandidateFUData !== undefined) {
      if (deleteCandidateFUData.data) {
        Swal.fire({
          title: "Good job!",
          text: "Candidate Follow up deleted successfully..",
          icon: "success",
        });
        setchange(!change);
        props.requestAdminGetCandidatesRemark({
          page: params.page,
          page_size: params.page_size,
          job_id: params.job_id,
          cand_id: params.cand_id,
          status: params.status,
        });
      }
    }
    props.data.candidatesFUDeleteData = undefined;
  }, [props.data.candidatesFUDeleteData]);

  return (
    <>
      <div class="container-scroller">
        <Header name={job_details.replace('Candidates for ', '')} sub={
          <div class="row">
            <div class="row">
              <div class="col-md-4">
                <p dangerouslySetInnerHTML={{ __html: '<b>Salary: </b>' + job.currency_name + ' ' + job.salary_from + '-' + job.salary_to }}></p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Skills : </b>
                  {job.skill_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Functional Area: </b>
                  {job.functional_area_name}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Experience: </b>
                  {job.max_exp > 0
                    ? job.min_exp + " year/s to " + job.max_exp + "year/s."
                    : job.experience > 0
                      ? job.experience + " year experienced in "
                      : "Fresher in "}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Category: </b>{job.level} {job.category_name}
                </p>
              </div>
              {/* <div class="col-md-4">{job.desc_role != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Role: </b> <text>' + job.desc_role + '</text>' }}></p> : ''}</div> */}
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Location: </b>
                  <text dangerouslySetInnerHTML={{ __html: job.city_name }}></text>
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Industry: </b>
                  {job.industry_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Education: </b>
                  {job.specialization_name}
                </p>
              </div>
            </div>
            {/* <p>
              <b>Skills: </b>
              {job.skill_name}
            </p>
             */}
          </div>
        } />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Candidates" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <div class="row">
                                  <h6><b>{candidates.first_name} {candidates.last_name}</b></h6>
                                  <div class="col-sm-4"><p><b>Phone No.:</b> {candidates.phone}</p></div>
                                  <div class="col-sm-4"><p><b>Email: </b>{candidates.email}</p></div>
                                  <div class="col-sm-4"><p><b>Experience: </b>{candidates.exp_year} Years & {candidates.exp_month} Months</p></div>
                                  <div class="col-sm-4"><p><b>Industry: </b>{candidates.industries}</p></div>
                                  <div class="col-sm-4"><p><b>Current Salary: </b>{candidates.currency} {candidates.current_salary}</p></div>
                                  <div class="col-sm-4"><p><b>Notice Period: </b>{candidates.notice_period}</p></div>
                                  <div class="col-sm-4"><p><b>Skill Name: </b>{candidates.skill_name}</p></div>
                                  <div class="col-sm-4"><p><b>Functional Area: </b>{candidates.functional_area1}</p></div>
                                </div>
                                <button class="btn btn-otline-dark btn-primary" style={{ margin: '8px', color: "white" }} onClick={() => { addtoggle(); }}>Set Candidate Remark & Follow Up</button>
                                {addmodal === true && (
                                  <Modal
                                    isOpen={addmodal}
                                    toggle={addtoggle}
                                  >
                                    <ModalHeader toggle={addtoggle}>
                                      Add Remark for the Candidate
                                    </ModalHeader>
                                    <ModalBody>
                                      <form class="forms-sample">
                                        <div class="row">
                                          <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                              <label>Candidate Remark</label>
                                              <input
                                                class="form-control" type="text" name="remark" onChange={onchangeadddata}
                                                value={adddata.remark} placeholder="Remark for internal use." onBlur={validateremark}/>
                                                {errorremark && <p>{errorremark}</p>}
                                              <label>Next Follow up Date</label>
                                              <div class="row">
                                                <div class="col-md-6">
                                                  <input
                                                    class="form-control" type="date" name="fu_date" onChange={onchangeadddata}
                                                    value={adddata.fu_date} onBlur={validatefudate}/>
                                                    {errorfudate && <p>{errorfudate}</p>}
                                                </div>
                                                <div class="col-md-6">
                                                  <input
                                                    class="form-control" type="time" name="fu_time" onChange={onchangeadddata}
                                                    value={adddata.fu_time} onBlur={validatefutime}/>
                                                    {errorfutime && <p>{errorfutime}</p>}
                                                </div>
                                              </div>
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          type="submit" class="btn btn-primary me-2" onClick={saveaddtoggle} >
                                          Submit
                                        </button>
                                        <button
                                          class="btn btn-light"
                                          onClick={addtoggle}
                                        >
                                          Cancel
                                        </button>
                                      </form>
                                    </ModalBody>
                                  </Modal>
                                )}
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Date</th>
                                    <th>Update Date</th>
                                    <th>User</th>
                                    <th>Status</th>
                                    <th>Remark</th>
                                    <th>Follow Up</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {candidateStatus.map((item, index) => {
                                    return (
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Sourcing" ? "#9FE2BF" : "white"), backgroundColor: (params.status == "Sourcing" ? "white" : "#9FE2BF") }} href={`/admin/candidates/1/10/${params.job_id}/Sourcing`}>  Sourcing </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Screening" ? "#78281F" : "white"), backgroundColor: (params.status == "Screening" ? "white" : "#78281F") }} href={`/admin/candidates/1/10/${params.job_id}/Screening`}>  Screening </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Move to the Assessment" ? "#4A235A" : "white"), backgroundColor: (params.status == "Move to the Assessment" ? "white" : "#4A235A") }} href={`/admin/candidates/1/10/${params.job_id}/Move to the Assessment`}>  Move to the Assessment </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Move to the Client" ? "#1B4F72" : "white"), backgroundColor: (params.status == "Move to the Client" ? "white" : "#1B4F72") }} href={`/admin/candidates/1/10/${params.job_id}/Move to the Client`}>  Move to the Client </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Sent Profile" ? "#154360" : "white"), backgroundColor: (params.status == "Sent Profile" ? "white" : "#154360") }} href={`/admin/candidates/1/10/${params.job_id}/Sent Profile`}>  Sent Profile </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Shortlisted" ? "#186A3B" : "white"), backgroundColor: (params.status == "Shortlisted" ? "white" : "#186A3B") }} href={`/admin/candidates/1/10/${params.job_id}/Shortlisted`}>  Shortlisted </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Interviewed" ? "#7E5109" : "white"), backgroundColor: (params.status == "Interviewed" ? "white" : "#7E5109") }} href={`/admin/candidates/1/10/${params.job_id}/Interviewed`}>  Interviewed </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Offered" ? "#6E2C00" : "white"), backgroundColor: (params.status == "Offered" ? "white" : "#6E2C00") }} href={`/admin/candidates/1/10/${params.job_id}/Offered`}>Offered </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Joined" ? "#424949" : "white"), backgroundColor: (params.status == "Joined" ? "white" : "#424949") }} href={`/admin/candidates/1/10/${params.job_id}/Joined`}>Joined </a>
                                      // <a class='badge' style={{ border: "0px", color: (params.status == "Invoice" ? "#17202A" : "white"), backgroundColor: (params.status == "Invoice" ? "white" : "#17202A") }} href={`/admin/candidates/1/10/${params.job_id}/Invoice`}>Invoice </a>

                                      <tr key={index}>
                                        <td><h6>{item.created_at.substring(0, 10)}</h6></td>
                                        <td><h6>{item.updated_at.substring(0, 10)}</h6></td>
                                        <td><h6>{item.username}</h6></td>
                                        <td style={{ width: '10px' }}><h6 class='badge' style={{
                                          color: "white", backgroundColor: (item.status == "Sourcing" ? "#9FE2BF" : item.status == "Offered" ? "#6E2C00" : item.status == "Screening" ? "#78281F" : item.status == "Move to the Assessment" ? "#4A235A" : item.status == "Rejected" ? "#B00020" :
                                            item.status == "Move to the Client" ? "#1B4F72" : item.status == "Sent Profile" ? "#154360" : item.status == "Shortlisted" ? "#186A3B" : item.status == "Interviewed" ? "#7E5109" : item.status == "Joined" ? "#424949" : item.status == "Invoice" ? "#17202A" : "white")
                                        }}>{item.status}</h6></td>
                                        <td><h6>{item.remark}</h6></td>
                                        <td width='5%'><h6>{item.cand_remark}</h6></td>
                                        <td><h6>{item.fu_date} {item.fu_time}</h6></td>
                                        <td>
                                          <br />
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{ border: "0px" }} onClick={() => { edittoggle(item); }}>
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit Candidate Remark
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="form-group">
                                                    <label>Candidate Remark</label>
                                                    <input
                                                      class="form-control" type="text" name="remark" onChange={onchangestatusdata}
                                                      value={editdata.remark} placeholder="Remark for internal use." />
                                                    <label>Next Follow up Date</label>
                                                    <div class="row">
                                                      <div class="col-md-6">
                                                        <input
                                                          class="form-control" type="date" name="fu_date" onChange={onchangestatusdata}
                                                          value={editdata.fu_date} />
                                                      </div>
                                                      <div class="col-md-6">
                                                        <input
                                                          class="form-control" type="time" name="fu_time" onChange={onchangestatusdata}
                                                          value={editdata.fu_time} />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              deletedata(item.id);
                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                          <Link class="badge badge-opacity-warning" to={`/admin/candfu/1/20/${item.id}`}
                                            state={{ job_details: job_details, job: job }}
                                          ><i class="fa fa-chart"></i>{item.remark_count}</Link>

                                          {/* <button
                                            class="badge badge-opacity-warning"
                                            style={{ border: "0px" }} onClick={() => { remarktoggle(item.id); }}
                                          >
                                            <i class="fa fa-chart"></i>
                                            {item.remark_count}
                                          </button>
                                          {remarkModal === true && (
                                            <Modal
                                              isOpen={remarkModal}
                                              toggle={remarktoggle}>
                                              <ModalHeader toggle={remarktoggle}>
                                                Statuses
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="row">
                                                    <div class="col-lg-12 col-md-12">
                                                      <div class="form-group">
                                                        <table>
                                                          <thead>
                                                            <tr>
                                                              <th>Date</th>
                                                              <th>Remark</th>
                                                              <th>Follow Up Date</th>
                                                              <th>Follow Up Time</th>
                                                              <th>Added By</th>
                                                              <th>Updated At</th>
                                                              <th>Action</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {followUpList.map((item, index) => {
                                                              return (
                                                                <tr key={index}>
                                                                  <td><p>{item.created_at.substring(0, 10)}</p></td>
                                                                  <td>{item.remark}</td>
                                                                  <td><p>{item.fu_date}</p></td>
                                                                  <td><p>{item.fu_time}</p></td>
                                                                  <td><p>{item.username}</p></td>
                                                                  <td><p>{item.updated_at.substring(0, 10)}</p></td>
                                                                  <td><button class="badge badge-opacity-success"
                                                                    style={{ border: "0px" }} onClick={() => { fuedittoggle(item.id); }}>
                                                                    <i class="fa fa-pencil-square-o"></i>
                                                                  </button>
                                                                    {fueditmodal === true && (
                                                                      <Modal
                                                                        isOpen={fueditmodal}
                                                                        toggle={fuedittoggle}
                                                                      >
                                                                        <ModalHeader toggle={fuedittoggle}>
                                                                          Edit Candidate Follow Up
                                                                        </ModalHeader>
                                                                        <ModalBody>
                                                                          <form class="forms-sample">
                                                                            <div class="form-group">
                                                                              <label>Internal Remark</label>
                                                                              <input
                                                                                class="form-control" type="text" name="furemark" onChange={onchangefudata}
                                                                                value={fueditdata.remark} placeholder="Remark for internal use." />
                                                                              <label>Next Follow up Date</label>
                                                                              <div class="row">
                                                                                <div class="col-md-6">
                                                                                  <input
                                                                                    class="form-control" type="date" name="fu_date" onChange={onchangefudata}
                                                                                    value={fueditdata.fu_date} />
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                  <input
                                                                                    class="form-control" type="time" name="fu_time" onChange={onchangefudata}
                                                                                    value={fueditdata.fu_time} />
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                            <button
                                                                              type="submit"
                                                                              class="btn btn-primary me-2"
                                                                              onClick={savefuedittoggle}
                                                                            >
                                                                              Submit
                                                                            </button>
                                                                            <button
                                                                              class="btn btn-light"
                                                                              onClick={fuedittoggle}
                                                                            >
                                                                              Cancel
                                                                            </button>
                                                                          </form>
                                                                        </ModalBody>
                                                                      </Modal>
                                                                    )}
                                                                    <button
                                                                      class="badge badge-opacity-danger"
                                                                      style={{
                                                                        border: "0px",
                                                                        background: "#fea8a9",
                                                                        marginLeft: "5px",
                                                                      }}
                                                                      onClick={() => {
                                                                        deletefudata(item.id);
                                                                      }}
                                                                    >
                                                                      <i class="fa fa-trash"></i>
                                                                    </button>
                                                                  </td>
                                                                </tr>
                                                              );
                                                            })}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={remarktoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )} */}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/candidates/${params.page - 1
                                            }/${params.page_size}/${params.job_id}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/candidates/${Number(params.page) + 1
                                              }/${params.page_size}/${params.job_id}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminGetCandidatesRemark, requestStatusCandidateRemark, requestCandidateFollowUpEdit, requestGetCandidateStatus, requestCandidateFollowUpDelete }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidatesFollowUp);
