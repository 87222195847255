import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestAdminAddCompAddress, requestAdminAllCompAddress, requestAdminGetCompAddress, requestAdminDeleteCompAddress,
  requestAdminEditCompAddress, requestAdminCompanyDetails
} from "../Redux/actions";
import Swal from "sweetalert2";
import axios from 'axios';

function CompanyAddress(props) {
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [change, setchange] = useState(false)
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [error, seterror] = useState("");
  const [erroraddress, seterroraddress] = useState("");
  const [errorcountry, seterrorcountry] = useState("");
  const [errorstate, seterrorstate] = useState("");
  const [errorcity, seterrorcity] = useState("");
  const [id, setid] = useState(0);  
  const [companyName, setcomapnyname] = useState("");
  const [compAddress, setcompAddress] = useState([]);
  const [adddata, setadddata] = useState({
    name: "", address: "", remark: "", countryId: 0, stateId: 0, cityId: 0
  });
  const [editdata, seteditdata] = useState({
    name: "", address: "", remark: "", countryId: 0, stateId: 0, cityId: 0
  });
  const [countryId, setcountryId] = useState(0);
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);

  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);
  const [countries, setcountries] = useState([]);

  const params = useParams();
  const [addmodal, setaddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);

  useEffect(() => {
    props.requestAdminCompanyDetails({
      id: params.id,
    });
    setcomapnyname(props.data.companyDeatilsData.data.data[0].name);
    console.log(props.data.companyDeatilsData.data.data[0].name);
  }, []);

  useEffect(() => {
    let countryData = props.data.loginData.data.data.countries;
    if (countryData !== undefined) {
        setcountries(countryData);
    }
  }, [props.data.loginData.data.data]);

  function onChangeCountry(e) {
    setcountryId(e.target.value);
    axios.get('https://yourdomain/api/state/' + e.target.value)
    .then(res=>{
        setstates(res.data.data.states);
    })
    .catch(err=>{
        console.log(err);
    })
  }    

  function onChangeState(e) {
    setstateId(e.target.value);
    axios.get('https://yourdomain/api/city/' + e.target.value)
    .then(res=>{
        setcities(res.data.data.cities);
    })
    .catch(err=>{
        console.log(err);
    })
  }    

  function onChangeCity(e) {
    setcityId(e.target.value);
  }

  function validateadd() {
    let formIsValid = false;
    if (!adddata["name"]) {
      formIsValid = false;
      seterror("*Enter Company Address.");
    } else if (typeof adddata["name"] !== "undefined") {
      if (!adddata["name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
        formIsValid = false;
        seterror("*Please enter Alphabet characters only.");
      } else {
        formIsValid = true;
        seterror("");
      }
    }
    return formIsValid;
  }
  function addressadd() {
    let formIsValid = false;
    if (!adddata["address"]) {
      formIsValid = false;
      seterroraddress("*Enter Address address.");
    } else {
      formIsValid = true;
      seterroraddress("");
    }
    return formIsValid;
  }

  function validatecountry() {
    let formIsValid = false;
    if (countryId === "0") {
      formIsValid = false;
      seterrorcountry("*Select your country.");
    } else if (typeof countryId === "undefined") {
      formIsValid = false;
      seterrorcountry("*Select your country.");
    } else {
      formIsValid = true;
      seterrorcountry("");
    }
    return formIsValid;
  }
  function validatestate() {
    let formIsValid = false;
    if (stateId === "0") {
      formIsValid = false;
      seterrorstate("*Select your state.");
    } else if (typeof stateId === "undefined") {
      formIsValid = false;
      seterrorstate("*Select your state.");
    } else {
      formIsValid = true;
      seterrorstate("");
    }
    return formIsValid;
  }
  function validatecity() {
    let formIsValid = false;
    if (cityId === "0") {
      formIsValid = false;
      seterrorcity("*Select your city.");
    } else if (typeof cityId === "undefined") {
      formIsValid = false;
      seterrorcity("*Select your city.");
    } else {
      formIsValid = true;
      seterrorcity("");
    }
    return formIsValid;
  }

  function validateAddForm() {
    let validate = validateadd(); let address = addressadd(); let country = validatecountry(); let state = validatestate(); let city = validatecity();
    let valid = validate && country && state && city && address;
    return valid;
  }

  function validateedit() {
    let formIsValid = false;
    if (!editdata["name"]) {
      formIsValid = false;
      seterror("*Enter Address name.");
    } else if (typeof editdata["name"] !== "undefined") {
      if (!editdata["name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
        formIsValid = false;
        seterror("*Please enter Alphabet characters only.");
      } else {
        formIsValid = true;
        seterror("");
      }
    }
    return formIsValid;
  }
  function addressedit() {
    let formIsValid = false;
    if (!editdata["address"]) {
      formIsValid = false;
      seterroraddress("*Enter address.");
    } else {
      formIsValid = true;
      seterroraddress("");
    }
    return formIsValid;
  }
  function validateEditForm() {
    let validate = validateedit();
    let address = addressedit();
    let valid = validate && address;
    return valid;
  }
  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }
  function onchangeeditdata(e) {
    seteditdata((editdata) => ({
      ...editdata,
      [e.target.name]: e.target.value,
    }));
  }

  const addtoggle = () => setaddModal(!addmodal);
  const saveaddtoggle = (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      console.log(id);
      props.requestAdminAddCompAddress({
        data: {
          company_id: params.id,
          name: adddata.name,
          address: adddata.address,
          country_id: countryId,
          state_id: stateId,
          city_id: cityId,
          user_id: 1,          
          remark: adddata.remark
        },
      });
      setadddata({
        name: "",
        address: "",
      });
      addtoggle();
    }
  };

  useEffect(() => {
    let addCompAddressData = props.data.addCompAddressData;
    if (addCompAddressData !== undefined) {
      if (addCompAddressData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Address added successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.addCompAddressData = undefined;
  }, [props.data.addCompAddressData]);

  const edittoggle = (id) => {
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(id);
      seteditdata({
        name: " ",
        address: " ",
      });
      props.requestAdminGetCompAddress({
        id: id,
      });
    }
  };

  useEffect(() => {
    console.log(props.data);
    let compAddress = props.data.getCompAddressData;
    if (compAddress !== undefined) {
      if (compAddress.data) {
        seteditdata(compAddress.data.data.CompAddress[0]);
      }
    }
  }, [props.data.getCompAddressData]);

  const saveedittoggle = (e) => {
    e.preventDefault();
    console.log('here');
    if (validateEditForm()) {
      console.log(id);
      props.requestAdminEditCompAddress({
        id: id,
        data: {
          name: editdata.name,
          address: editdata.address,
          country_id: countryId,
          state_id: stateId,
          city_id: cityId,
          user_id: 1,    
          remark: editdata.remark
        },
      });
      seteditdata({
        name: "",
        address: "",
      });
      setid(0);
      edittoggle();
    }
  };

  useEffect(() => {
    let editCompAddressData = props.data.editCompAddressData;
    if (editCompAddressData !== undefined) {
      if (editCompAddressData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Address updated successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.editCompAddressData = undefined;
  }, [props.data.editCompAddressData]);

  useEffect(() => {
    props.requestAdminAllCompAddress({
      id: params.id
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/compaddress/1/${e.target.value}`);
    }
  }

  useEffect(() => {
    let allCompAddressData = props.data.allCompAddressData;
    if (allCompAddressData !== undefined) {
      if (allCompAddressData.data) {
        setcompAddress(allCompAddressData.data.data);
        setpagination(allCompAddressData.data.meta);
        // if (allCompAddressData.data.meta.last_page < 5) {
        //   let array = [];
        //   Array.from(Array(allCompAddressData.data.meta.last_page), (e, i) => {
        //     array.push(i + 1);
        //   });
        //   setpages(array);
        // } else {
        //   let array = [];
        //   if (allCompAddressData.data.meta.current_page <= 3) {
        //     array.push(1, 2, 3, 4, 5);
        //     setpages(array);
        //   } else if (
        //     allCompAddressData.data.meta.last_page -
        //     allCompAddressData.data.meta.current_page <=
        //     2
        //   ) {
        //     array.push(
        //       allCompAddressData.data.meta.last_page - 4,
        //       allCompAddressData.data.meta.last_page - 3,
        //       allCompAddressData.data.meta.last_page - 2,
        //       allCompAddressData.data.meta.last_page - 1,
        //       allCompAddressData.data.meta.last_page
        //     );
        //     setpages(array);
        //   } else {
        //     array.push(
        //       Number(allCompAddressData.data.meta.current_page) - 2,
        //       Number(allCompAddressData.data.meta.current_page) - 1,
        //       allCompAddressData.data.meta.current_page,
        //       Number(allCompAddressData.data.meta.current_page) + 1,
        //       Number(allCompAddressData.data.meta.current_page) + 2
        //     );
        //     setpages(array);
        //   }
        // }
      }
    }
  }, [props.data.allCompAddressData]);
  const CustomUI = ({ onSubmit, onCancel }) => (
    <div
      className="custom-ui"
      style={{
        backgroundColor: "white",
        width: "350px",
        padding: "25px",
        border: "1px solid black",
        margin: "auto",
      }}
    >
      <h3>Are you sure?</h3>
      <p>
        Do you really want to delete this records? This process cannot be
        undone.
      </p>
      <div>
        <button
          onClick={onSubmit}
          style={{ border: "0px", backgroundColor: "green", color: "white", padding: "2px 10px" }}
        >
          Yes
        </button>
        <button
          onClick={onCancel}
          style={{
            border: "0px",
            backgroundColor: "red",
            color: "white",
            marginLeft: "10px",
            padding: "2px 10px"
          }}
        >
          No
        </button>
      </div>
    </div>
  );
  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteCompAddress({
          id: id,
        });
      }
    })
  }

  useEffect(() => {
    let deleteCompAddressData = props.data.deleteCompAddressData;
    if (deleteCompAddressData !== undefined) {
      if (deleteCompAddressData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Address deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteCompAddressData = undefined;
  }, [props.data.deleteCompAddressData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Addresses of " + props.data.companyDeatilsData.data.data[0].name + "</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <>
      <div class="container-scroller">
        <Header name="Addresses of "{...companyName} />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Options" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/compaddress/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                              <div>
                                <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={addtoggle}
                                >
                                  Add new Address
                                </button>
                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                  <ModalHeader toggle={addtoggle}>
                                    Add Address
                                  </ModalHeader>
                                  <ModalBody>
                                    <form class="forms-sample">
                                      <div class="form-group">
                                        <label>Title</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Name"
                                          name="name"
                                          value={adddata.name}
                                          onBlur={validateadd}
                                          onChange={onchangeadddata}
                                        />
                                        {error && <p>{error}</p>}
                                        <label>
                                          <br />
                                          Address
                                        </label>
                                        <textarea
                                          name="address"
                                          class="form-control"
                                          placeholder="Address"
                                          style={{ height: "100px" }}
                                          value={adddata.address}
                                          onBlur={addressadd}
                                          onChange={onchangeadddata}
                                        >
                                          Address
                                        </textarea>
                                        {erroraddress && <p>{erroraddress}</p>}
                                        <div class="row">
                                          <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                              <label>Country</label>
                                              <select
                                                class="form-select"
                                                name="country"
                                                value={countryId}
                                                onBlur={validatecountry}
                                                onChange={onChangeCountry}
                                              >
                                                <option value="0">Select Country</option>
                                                {countries.map((option) => (
                                                  <option value={option.id}>
                                                    {option.name}
                                                  </option>
                                                ))}
                                              </select>
                                              {errorcountry && <p>{errorcountry}</p>}
                                            </div>
                                          </div>
                                          <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                              <label>State</label>
                                              <select
                                                class="form-select"
                                                name="state"
                                                value={stateId}
                                                onBlur={validatestate}
                                                onChange={onChangeState}
                                              >
                                                <option value="0">Select State</option>
                                                {states.map((option) => (
                                                  <option value={option.id}>
                                                    {option.name}
                                                  </option>
                                                ))}
                                              </select>
                                              {errorstate && <p>{errorstate}</p>}
                                            </div>
                                          </div>
                                          <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                              <label>City</label>
                                              <select
                                                class="form-select"
                                                name="city"
                                                value={cityId}
                                                onBlur={validatecity}
                                                onChange={onChangeCity}
                                              >
                                                <option value="0">Select City</option>
                                                {cities.map((option) => (
                                                  <option value={option.id}>
                                                    {option.name}
                                                  </option>
                                                ))}
                                              </select>
                                              {errorcity && <p>{errorcity}</p>}
                                            </div>
                                          </div>                                        
                                        </div>                                        
                                        <label>
                                          <br />
                                          Remark
                                        </label>
                                        <textarea
                                          name="remark"
                                          class="form-control"
                                          placeholder="Remark"
                                          style={{ height: "100px" }}
                                          value={adddata.remark}
                                          onChange={onchangeadddata}
                                        >
                                          Remark
                                        </textarea>
                                      </div>
                                      <button
                                        type="submit"
                                        class="btn btn-primary me-2"
                                        onClick={saveaddtoggle}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        class="btn btn-light"
                                        onClick={addtoggle}
                                      >
                                        Cancel
                                      </button>
                                    </form>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Title</th>
                                    <th>Address</th>
                                    <th>Remark</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {compAddress.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <div>
                                              <h6>{item.name}</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <p>{item.address}</p>
                                        </td>
                                        <td>
                                          <p>{item.remark}</p>
                                        </td>
                                        <td>
                                          <p>{item.created_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <p>{item.updated_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              edittoggle(item.id);
                                            }}
                                          >
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit Company's Address
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="form-group">
                                                    <label>Title</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Name"
                                                      name="name"
                                                      value={editdata.name}
                                                      onBlur={validateedit}
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    />
                                                    {error && <p>{error}</p>}
                                                    <label>
                                                      <br />
                                                      Address
                                                    </label>
                                                    <textarea
                                                      name="address"
                                                      class="form-control"
                                                      placeholder="Address"
                                                      style={{
                                                        height: "100px",
                                                      }}
                                                      value={
                                                        editdata.address
                                                      }
                                                      onBlur={addressedit}
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    >
                                                      Address
                                                    </textarea>
                                                    {erroraddress && (
                                                      <p>{erroraddress}</p>
                                                    )}
                                                    <div class="row">
                                                      <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                          <label>Country</label>
                                                          <select
                                                            class="form-select"
                                                            name="country"
                                                            value={editdata.countryId}
                                                            onBlur={validatecountry}
                                                            onChange={onChangeCountry}
                                                          >
                                                            <option value="0">Select Country</option>
                                                            {countries.map((option) => (
                                                              <option value={option.id}>
                                                                {option.name}
                                                              </option>
                                                            ))}
                                                          </select>
                                                          {errorcountry && <p>{errorcountry}</p>}
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                          <label>State</label>
                                                          <select
                                                            class="form-select"
                                                            name="state"
                                                            value={editdata.stateId}
                                                            onBlur={validatestate}
                                                            onChange={onChangeState}
                                                          >
                                                            <option value="0">Select State</option>
                                                            {states.map((option) => (
                                                              <option value={option.id}>
                                                                {option.name}
                                                              </option>
                                                            ))}
                                                          </select>
                                                          {errorstate && <p>{errorstate}</p>}
                                                        </div>
                                                      </div>
                                                      <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                          <label>City</label>
                                                          <select
                                                            class="form-select"
                                                            name="city"
                                                            value={editdata.cityId}
                                                            onBlur={validatecity}
                                                            onChange={onChangeCity}
                                                          >
                                                            <option value="0">Select City</option>
                                                            {cities.map((option) => (
                                                              <option value={option.id}>
                                                                {option.name}
                                                              </option>
                                                            ))}
                                                          </select>
                                                          {errorcity && <p>{errorcity}</p>}
                                                        </div>
                                                      </div>                                        
                                                    </div>                                        

                                                    <label>
                                                      <br />
                                                      Remark
                                                    </label>
                                                    <textarea
                                                      name="remark"
                                                      class="form-control"
                                                      placeholder="Remark"
                                                      style={{
                                                        height: "100px",
                                                      }}
                                                      value={
                                                        editdata.remark
                                                      }
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    >
                                                      Remark
                                                    </textarea>
                                                  </div>

                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {

                                              deletedata(item.id);

                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/compaddress/${params.page - 1
                                            }/${params.page_size}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/compaddress/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/compaddress/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/compaddress/${Number(params.page) + 1
                                              }/${params.page_size}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAdminAddCompAddress,
      requestAdminAllCompAddress,
      requestAdminGetCompAddress,
      requestAdminDeleteCompAddress,
      requestAdminEditCompAddress, requestAdminCompanyDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAddress);
