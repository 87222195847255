import Sidebar from './sidebar';
import Header from './header';
import Footer from './footer';
import { RWebShare } from "react-web-share";
import Select from 'react-select';
import './style.css'
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminAddUser } from "../Redux/actions";
import Swal from "sweetalert2";
import camera from "../images/camera.png";

import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';
import WOW from "wowjs";
import Breadcrumbs from "../Section/breadcrumbsSection";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { Hint } from "react-autocomplete-hint";

function UserAdd(props) {
    useEffect(() => {
        new WOW.WOW().init();
        window.scrollTo(0, 0);
        localStorage.removeItem("link");
    }, []);
    const mystyle = { color: "#D10000", backgroundColor: "#FFD2D2", padding: "3px 10px", border: "1px solid red", borderRadius: "5px", marginTop: "5px" };
    const navigate = useNavigate();
    const [token, settoken] = useState("");
    const [admin_id, setadmin_id] = useState(0);
    const [cand_add, setcand_add] = useState(0);
    const [cand_edit, setcand_edit] = useState(0);
    const [cand_delete, setcand_delete] = useState(0);
    const [cand_assign_job, setcand_assign_job] = useState(0);
    const [job_add, setjob_add] = useState(0);
    const [job_edit, setjob_edit] = useState(0);
    const [job_delete, setjob_delete] = useState(0);
    const [job_status, setjob_status] = useState(0);
    const [selectedFilters, setSelectedFilters] = useState([{ name: "", value: "" }]);

    const [img, setimg] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [errorName, seterrorName] = useState("");
    const [errorEmail, seterrorEmail] = useState("");
    const [errorPassword, seterrorPassword] = useState("");
    const [errorPhoneNo, seterrorPhoneNo] = useState("");

    const [adddata, setadddata] = useState({
        name: "", email: "", password: "", cand_add: 0, cand_edit: 0, cand_delete: 0, cand_assign_job: 0,
        job_add: 0, job_edit: 0, job_delete: 0, job_status: 0, admin_id: 0, phone_no: ""
    });

    useEffect(() => {
        let formfieldData = props.data.loginData.data.data;
        console.log(formfieldData);

        if (formfieldData !== undefined) {
            settoken(formfieldData.token);
            setadmin_id(formfieldData.id);
        }
    }, [props.data.loginData.data.data]);

    function onchangeadddata(e) {
        setadddata((adddata) => ({
            ...adddata,
            [e.target.name]: e.target.value,
        }));
    }

    function validate_name() {
        let formIsValid = false;
        if (!adddata["name"]) {
            formIsValid = false;
            seterrorName("*Enter the name of user.");
        } else if (typeof adddata["name"] === "undefined") {
            formIsValid = false;
            seterrorName("*Enter the name of user.");
        } else if (!adddata["name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
            formIsValid = false;
            seterrorName("*Please enter Alphabet characters only.");
        } else {
            formIsValid = true;
            seterrorName("");
        }
        return formIsValid;
    }

    function validate_email() {
        let formIsValid = false;
        if (!adddata["email"]) {
            formIsValid = false;
            seterrorEmail("*Enter the email of user.");
        } else if (typeof adddata["email"] === "undefined") {
            formIsValid = false;
            seterrorEmail("*Enter the email of user.");
        } else {
            formIsValid = true;
            seterrorEmail("");
        }
        return formIsValid;
    }

    function validate_password() {
        let formIsValid = false;
        if (!adddata["password"]) {
            formIsValid = false;
            seterrorPassword("*Enter the password of user.");
        } else if (typeof adddata["password"] === "undefined") {
            formIsValid = false;
            seterrorPassword("*Enter the password of user.");
        } else if (adddata["password"].length < 6) {
            formIsValid = false;
            seterrorPassword("*Password should be minimum 6 character or number.");
        } else {
            formIsValid = true;
            seterrorPassword("");
        }
        return formIsValid;
    }

    function validate_phone_no() {
        let formIsValid = false;
        if (!adddata["phone_no"]) {
            formIsValid = false;
            seterrorPhoneNo("*Enter the phone_no of user.");
        } else if (typeof adddata["phone_no"] === "undefined") {
            formIsValid = false;
            seterrorPhoneNo("*Enter the phone_no of user.");
        } else {
            formIsValid = true;
            seterrorPhoneNo("");
        }
        return formIsValid;
    }

    function validateForm() {
        let name = validate_name();
        let phone_no = validate_phone_no();
        let email = validate_email();
        let password = validate_password();
        let valid = name && phone_no && email && password;
        return valid;
    }

    function submitForm(e) {
        e.preventDefault();
        console.log(selectedFilters);
        if (validateForm()) {
            let formData
            formData = new FormData();
            formData.append("profile", selectedFile);
            formData.append("name", adddata.name === undefined ? '' : adddata.name);
            formData.append("email", adddata.email === undefined ? '' : adddata.email);
            formData.append("password", adddata.password === undefined ? '' : adddata.password);
            formData.append("phone_no", adddata.phone_no === undefined ? '' : adddata.phone_no);

            formData.append("cand_add", cand_add);
            formData.append("cand_edit", cand_edit);
            formData.append("cand_delete", cand_delete);
            formData.append("cand_assign_job", cand_assign_job);

            formData.append("job_add", job_add);
            formData.append("job_edit", job_edit);
            formData.append("job_delete", job_delete);
            formData.append("job_status", job_status);

            formData.append("admin_id", admin_id);
            props.requestAdminAddUser({
                data: formData
            });
        }
    }

    useEffect(() => {
        let userprofile = props.data.addUserData;
        console.log(props.data.addUserData);
        if (userprofile !== undefined) {
            if (userprofile?.data?.status == "success") {
                Swal.fire("Good job!", "Profile updated Successfully.", "success");
                props.data.addUserData = undefined;
                navigate(`/admin/user/1/10`);
            } else {
                Swal.fire("Error!", `${userprofile.data.message}`, "error");
                props.data.addUserData = undefined;
                // props.requestGetUser({
                //     id: emp.id,
                //     token: emp.token,
                // });
            }
        }
    }, [props.data.addUserData]);

    const onSelectFile = (e) => {
        setimg(URL.createObjectURL(e.target.files[0]));
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
        } else {
            setSelectedFile(e.target.files[0]);
        }
    };

    function onchangeCand_Add(e) {
        e.target.checked ? setcand_add(1) : setcand_add(0);
    }
    function onchangeCand_Edit(e) {
        e.target.checked ? setcand_edit(1) : setcand_edit(0);
    }
    function onchangeCand_Delete(e) {
        e.target.checked ? setcand_delete(1) : setcand_delete(0);
    }
    function onchangeCand_AssignJob(e) {
        e.target.checked ? setcand_assign_job(1) : setcand_assign_job(0);
    }
    function onchangeJob_Add(e) {
        e.target.checked ? setjob_add(1) : setjob_add(0);
    }
    function onchangeJob_Edit(e) {
        e.target.checked ? setjob_edit(1) : setjob_edit(0);
    }
    function onchangeJob_Delete(e) {
        e.target.checked ? setjob_delete(1) : setjob_delete(0);
    }
    function onchangeJob_Status(e) {
        e.target.checked ? setjob_status(1) : setjob_status(0);
    }

    function onchangeCheckbox(e) {
        let name1 = e.target.name;
        const checked = e.target.checked;
        const value = e.target.value;
        console.log(value);
        setSelectedFilters(
            checked
                ? [...selectedFilters, {
                    name: name1,
                    value: value
                },]
                : selectedFilters.filter((item) => item.name !== name1 && item.value !== value)
        );

    }

    return (
        <>
            <div class="container-scroller">
                <Header name="User" />
                <div class="container-fluid page-body-wrapper">
                    <Sidebar name="User" />
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="row flex-grow">
                                            <div class="col-12 grid-margin stretch-card">
                                                <div class="card card-rounded">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="form-group">
                                                                <label>Profile Picture &nbsp;&nbsp;</label>
                                                                <br />
                                                                <br />
                                                                <div
                                                                    style={{
                                                                        position: "relative",
                                                                        left: "0px",
                                                                        top: "0px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={img}
                                                                        width="250"
                                                                        style={{
                                                                            position: "relative",
                                                                            top: "0px",
                                                                            border: "2px solid black",
                                                                            borderRadius: "25px",
                                                                        }}
                                                                    />

                                                                    <label for="file-input">
                                                                        <img
                                                                            src={camera}
                                                                            height="35"
                                                                            width="auto"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "-12px",
                                                                                left: "230px",
                                                                                backgroundColor: "white",
                                                                                borderRadius: "25px",
                                                                                border: "1px solid black",
                                                                                padding: "3px",
                                                                            }}
                                                                        />
                                                                    </label>

                                                                    <input
                                                                        id="file-input"
                                                                        type="file"
                                                                        style={{ display: "none" }}
                                                                        name="profile"
                                                                        onChange={onSelectFile}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <h3 class="title">Basic Information</h3>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Name*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="name"
                                                                        value={adddata.name}
                                                                        onBlur={validate_name}
                                                                        onChange={onchangeadddata}
                                                                        placeholder=""
                                                                    />
                                                                    {errorName && (
                                                                        <div style={mystyle}>{errorName}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Email*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="email"
                                                                        name="email"
                                                                        value={adddata.email}
                                                                        onBlur={validate_email}
                                                                        onChange={onchangeadddata}
                                                                        placeholder=""
                                                                    />
                                                                    {errorEmail && (
                                                                        <div style={mystyle}>{errorEmail}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Phone No*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="tel"
                                                                        name="phone_no"
                                                                        value={adddata.phone_no}
                                                                        onBlur={validate_phone_no}
                                                                        onChange={onchangeadddata}
                                                                        placeholder=""
                                                                    />
                                                                    {errorPhoneNo && (
                                                                        <div style={mystyle}>{errorPhoneNo}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Password*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="password"
                                                                        name="password"
                                                                        value={adddata.password}
                                                                        onBlur={validate_password}
                                                                        onChange={onchangeadddata}
                                                                        placeholder=""
                                                                    />
                                                                    {errorPassword && (
                                                                        <div style={mystyle}>{errorPassword}</div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <h3 class="title">Assign Rights For Candidate</h3>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Add Candidate</label>
                                                                    <input type="checkbox" name="cand_add" value={adddata.cand_add} onChange={onchangeCand_Add}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Edit Candidate</label>
                                                                    <input type="checkbox" name="cand_edit" value={adddata.cand_edit} onChange={onchangeCand_Edit}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Delete Candidate</label>
                                                                    <input type="checkbox" name="cand_delete" value={adddata.cand_delete} onChange={onchangeCand_Delete}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Assign Job to Candidate</label>
                                                                    <input type="checkbox" name="cand_assign_job" value={adddata.cand_assign_job} onChange={onchangeCand_AssignJob}></input>
                                                                </div>
                                                            </div>

                                                            <h3 class="title">Assign Rights For Job</h3>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Add Job</label>
                                                                    <input type="checkbox" name="job_add" value={adddata.job_add} onChange={onchangeJob_Add}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Edit Job</label>
                                                                    <input type="checkbox" name="job_edit" value={adddata.job_edit} onChange={onchangeJob_Edit}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Delete Job</label>
                                                                    <input type="checkbox" name="job_delete" value={adddata.job_delete} onChange={onchangeJob_Delete}></input>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Change Job Status</label>
                                                                    <input type="checkbox" name="job_status" value={adddata.job_status} onChange={onchangeJob_Status}></input>
                                                                </div>
                                                            </div>

                                                            <h3 class="title">Masters</h3>
                                                            <table class="table select-table">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Name</td>
                                                                        <td>Add</td>
                                                                        <td>Edit</td>
                                                                        <td>Delete</td>
                                                                        <td>Display</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Company</td>
                                                                        <td><input type="checkbox" name="comp_add" value="comp_add" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="comp_edit" value="comp_edit" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="comp_delete" value="comp_delete" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="comp_list" value="comp_list" onChange={onchangeCheckbox}></input></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Questionaire</td>
                                                                        <td><input type="checkbox" name="q_add" value="q_add" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="q_edit" value="q_edit" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="q_delete" value="q_delete" onChange={onchangeCheckbox}></input></td>
                                                                        <td><input type="checkbox" name="q_list" value="q_list" onChange={onchangeCheckbox}></input></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary me-2"
                                                            style={{
                                                                color: "white",
                                                                width: "150px",
                                                                height: "50px",
                                                            }}
                                                            onClick={submitForm}
                                                        >
                                                            Save
                                                        </button>
                                                        <a href={`/admin/user/1/10`} title="Cancel Adding User">
                                                            <button
                                                                class="btn btn-light"
                                                                style={{ border: "0px" }}>
                                                                {/* <i class="fa fa-pencil-square-o"></i> */}
                                                                Cancel
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return { data: state.data, user: state.user };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestAdminAddUser
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdd);