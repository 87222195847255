import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminCandidates, requestStatusCandidate, requestEditStatusCandidate } from "../Redux/actions";
import image from "../images/profile.png";
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

function Candidates(props) {

  const params = useParams();
  const [candidates, setcandidates] = useState([]);
  const [job, setjob] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [selectedFile, setselectedFile] = useState([]);
  const [statusmodal, setstatusModal] = useState(false);
  const [statusdata, setstatusdata] = useState({ status: "", remark: "", cand_remark: "" });
  const [statuseditdata, setstatuseditdata] = useState({ eid: '0', estatus: '', eremark: '' });
  const [id, setid] = useState(0);
  const inputFile = useRef();
  const [logindetails, setLoginDetails] = useState([]);
  const [sourcing, setSourcing] = useState('0');
  const [screening, setScreening] = useState('0');
  const [assessment, setAssessment] = useState('0');
  const [rejected, setRejected] = useState('0');
  const [toClient, setToClient] = useState('0');
  const [sentProfile, setSentProfile] = useState('0');
  const [shortlisted, setShortlisted] = useState('0');
  const [interviewed, setInterviewed] = useState('0');
  const [offered, setOffered] = useState('0');
  const [joined, setJoined] = useState('0');
  const [invoice, setInvoice] = useState('0');
  const [job_details, setjob_details] = useState('');
  const [selectedCand, setSelectedCand] = useState([{ id: "" }]);

  const [jobassignmodal, setjobassignModal] = useState(false);
  const [assign_status, setAssignStatus] = useState('');
  const [status_label, setstatus_label] = useState('');
  const [errorColor, seterrorColor] = useState('black');
  const [uid, setUID] = useState('0');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const Checkbox = ({ id, isChecked, onChange, name, value }) => (
    <input type="checkbox" name={name} value={value} id={id} checked={isChecked} onChange={() => onChange(id)} />
  );

  // Function to handle individual checkbox change
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = selectedCheckboxes.includes(id)
      ? selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      : [...selectedCheckboxes, id];
    setSelectedCheckboxes(updatedCheckboxes);
  };

  // Function to handle "Select All" checkbox change
  const handleSelectAllChange = () => {
    const allCheckboxIds = candidates.map((item) => item.id);
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.length === allCheckboxIds.length ? [] : allCheckboxIds
    );
  };

  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    setLoginDetails(formfieldData.admin_detail);
    setUID(formfieldData.id);
    console.log(params);
  }, [props.data.loginData.data.data]);

  const statustoggle = (id) => {
    if (statusmodal === true) {
      setstatusModal(!statusmodal);
    } else {
      if (params.job_id !== 0) {
        setstatusModal(!statusmodal);
      }
      setstatusModal(!statusmodal);
      setid(id);
      //        setstatusdata({ status: 0 });
      props.requestJobDetails({
        id: id,
      });
    }
  };

  const onchangeaddcandidate = (event) => {
    console.log(event.target.value);
    if (event.target.value == 1) {
      navigate(`/admin/candidateadd`);
    }
    else if (event.target.value == 2) {
      inputFile.current.click();
    }
  };

  const onchangestatusdata = (event, param1) => {
    // console.log(event.target.value);
    // console.log(param1);
    setstatusdata((statusdata) => ({
      ...statusdata,
      [event.target.name]: event.target.value,
    }));
  };

  const onchangeeditstatusdata = (event, param1) => {
    console.log(event.target.value);
    // console.log(param1);
    setstatuseditdata((statuseditdata) => ({
      ...statuseditdata,
      [event.target.name]: event.target.value,
    }));
  };

  const savestatustoggle = (e) => {
    e.preventDefault();
    console.log(status_label);
    if (status_label === "") {
      seterrorColor("red");
    }
    else {
      const myObjStr = JSON.stringify(selectedCheckboxes);
      props.requestStatusCandidate({
        data: { cand_id: myObjStr, job_id: params.job_id, status: status_label, remark: statusdata.remark, uid: uid },
      });
      console.log(selectedCheckboxes);
      setid(0);
      jobassigntoggle();
      // window.location.reload();
    }
  }

  const saveeditstatustoggle = (e) => {
    e.preventDefault();
    console.log(status_label);
    if (status_label === "") {
      seterrorColor("red");
    }
    else {
      const myObjStr = JSON.stringify(selectedCand);
      props.requestStatusEditCandidate({
        data: { cand_id: statuseditdata.eid, job_id: params.job_id, status: status_label, remark: statuseditdata.eremark, uid: uid },
      });
      console.log(props);
      setid(0);
      jobassigntoggle();
      // window.location.reload();
    }
  }

  useEffect(() => {
    props.requestAdminCandidates({
      page: params.page,
      page_size: params.page_size,
      job_id: params.job_id,
      status: params.status,
    });
    console.log(params.job_id);
    setpage_size(params.page_size);
  }, [params.page, params.page_size]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/candidates/1/${e.target.value}/${params.job_id}/${params.status}`);
    }
  }

  useEffect(() => {
    let candidatesData = props.data.candidatesData;
    if (candidatesData !== undefined) {
      if (candidatesData.data.cand.data) {
        setjob_details(candidatesData.data.cand.data[0].job_details);
        setjob(candidatesData.data.job[0]);
        if (candidatesData.data.job[0].job_status !== undefined && candidatesData.data.job[0].job_status !== null) {
          if (candidatesData.data.job[0].job_status !== undefined && candidatesData.data.job[0].job_status !== null) {
            const job_array = candidatesData.data.job[0].job_status.split('|');
            job_array.forEach((key) => {
              const status_array = key.split('^');
              if (status_array[0] === 'Sourcing') {
                setSourcing(status_array[1]);
              }
              else if (status_array[0] === 'Screening') {
                setScreening(status_array[1]);
              }
              else if (status_array[0] === 'Move to the Assessment') {
                setAssessment(status_array[1]);
              }
              else if (status_array[0] === 'Rejected') {
                setAssessment(status_array[1]);
              }
              else if (status_array[0] === 'Move to the Client') {
                setToClient(status_array[1]);
              }
              else if (status_array[0] === 'Sent Profile') {
                setSentProfile(status_array[1]);
              }
              else if (status_array[0] === 'Shortlisted') {
                setShortlisted(status_array[1]);
              }
              else if (status_array[0] === 'Interviewed') {
                setInterviewed(status_array[1]);
              }
              else if (status_array[0] === 'Offered') {
                setOffered(status_array[1]);
              }
              else if (status_array[0] === 'Joined') {
                setJoined(status_array[1]);
              }
              else if (status_array[0] === 'Invoice') {
                setInvoice(status_array[1]);
              }
            })
          }
        }
        setcandidates(candidatesData.data.cand.data);
        // setpagination(candidatesData.data.meta);
        // if (candidatesData.data.meta.last_page < 5) {
        //   let array = [];
        //   Array.from(Array(candidatesData.data.meta.last_page), (e, i) => {
        //     array.push(i + 1);
        //   });
        //   setpages(array);
        // } else {
        //   let array = [];
        //   if (candidatesData.data.meta.current_page <= 3) {
        //     array.push(1, 2, 3, 4, 5);
        //     setpages(array);
        //   } else if (
        //     candidatesData.data.meta.last_page -
        //     candidatesData.data.meta.current_page <=
        //     2
        //   ) {
        //     array.push(
        //       candidatesData.data.meta.last_page - 4,
        //       candidatesData.data.meta.last_page - 3,
        //       candidatesData.data.meta.last_page - 2,
        //       candidatesData.data.meta.last_page - 1,
        //       candidatesData.data.meta.last_page
        //     );
        //     setpages(array);
        //   } else {
        //     array.push(
        //       Number(candidatesData.data.meta.current_page) - 2,
        //       Number(candidatesData.data.meta.current_page) - 1,
        //       candidatesData.data.meta.current_page,
        //       Number(candidatesData.data.meta.current_page) + 1,
        //       Number(candidatesData.data.meta.current_page) + 2
        //     );
        //     setpages(array);
        //   }
        // }
      }
    }
  }, [props.data.candidatesData, params]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Candidates</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  function onFileChange(e) {
    setselectedFile(e.target.files[0]);
  }

  function onFileUpload() {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "profile",
      selectedFile
    );

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object
    axios.post("https://yourdomain/api/candidate/uploadresume", formData)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });

  }
  function handleselect(e) {
    const checked = e.target.checked;
    const value = e.target.value;

    setSelectedCand(
      checked
        ? [...selectedCand, {
          id: value
        },]
        : selectedCand.filter((item) => item.id !== value)
    );
    console.log(selectedCand);
  }

  const jobassigntoggle = () => {
    if (jobassignmodal === true) {
      setjobassignModal(!jobassignmodal);
    } else {
      setjobassignModal(!jobassignmodal);
    }
  };

  const savejobassigntoggle = (e) => {
    e.preventDefault();
    const myObjStr = JSON.stringify(selectedCand);
    console.log(myObjStr);
    // props.requestCandAssignJob({
    //   data: { ids: myObjStr, job_id: job },
    // });
    // jobassigntoggle();
  }

  function onchangestatus(e) {
    setAssignStatus(e.target.value);
    // props.requestPendingJobsByEmployer({
    //   id: e.target.value,
    // });
  }

  function assignstatuslabel(status) {
    setstatus_label(status);
  }

  return (
    <>
      <div class="container-scroller">
        <Header name={job_details} sub={
          <div class="row">
            <div class="row">
              <div class="col-md-4">
                <p dangerouslySetInnerHTML={{ __html: '<b>Salary: </b>' + job.currency_name + ' ' + job.salary_from + '-' + job.salary_to }}></p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Skills : </b>
                  {job.skill_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Functional Area: </b>
                  {job.functional_area_name}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Experience: </b>
                  {job.max_exp > 0
                    ? job.min_exp + " year/s to " + job.max_exp + "year/s."
                    : job.experience > 0
                      ? job.experience + " year experienced in "
                      : "Fresher in "}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Category: </b>{job.level} {job.category_name}
                </p>
              </div>
              {/* <div class="col-md-4">{job.desc_role != '' ? <p dangerouslySetInnerHTML={{ __html: '<b>Role: </b> <text>' + job.desc_role.length < 20 ? job.desc_role : job.desc_role.substring(0,20) + '</text>' }}></p> : ''}</div> */}
            </div>
            <div class="row">
              <div class="col-md-4">
                <p>
                  <b>Location: </b>
                  <text dangerouslySetInnerHTML={{ __html: job.city_name }}></text>
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Industry: </b>
                  {job.industry_name}
                </p>
              </div>
              <div class="col-md-4">
                <p>
                  <b>Education: </b>
                  {job.specialization_name}
                </p>
              </div>
            </div>
            {/* <p>
              <b>Skills: </b>
              {job.skill_name}
            </p>
             */}
          </div>
        } />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Candidates" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                Selected Status : {params.status}
                                <br />
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/candidates/${params.page}/${params.page_size}/${params.job_id}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <br />
                                {/* {'badge' + (params.status === "Screening" ? "": 'badge-opacity-success')} */}
                                <a class='badge' style={{ border: "0px", color: (params.status == "Sourcing" ? "#9FE2BF" : "white"), backgroundColor: (params.status == "Sourcing" ? "white" : "#9FE2BF") }} href={`/admin/candidates/1/10/${params.job_id}/Sourcing`}>  Sourcing ({sourcing})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Screening" ? "#78281F" : "white"), backgroundColor: (params.status == "Screening" ? "white" : "#78281F") }} href={`/admin/candidates/1/10/${params.job_id}/Screening`}>  Screening ({screening})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Move to the Assessment" ? "#4A235A" : "white"), backgroundColor: (params.status == "Move to the Assessment" ? "white" : "#4A235A") }} href={`/admin/candidates/1/10/${params.job_id}/Move to the Assessment`}>  Move to the Assessment ({assessment})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Rejected" ? "#B00020" : "white"), backgroundColor: (params.status == "Rejected" ? "white" : "#B00020") }} href={`/admin/candidates/1/10/${params.job_id}/Rejected`}>  Rejected ({rejected})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Move to the Client" ? "#1B4F72" : "white"), backgroundColor: (params.status == "Move to the Client" ? "white" : "#1B4F72") }} href={`/admin/candidates/1/10/${params.job_id}/Move to the Client`}>  Move to the Client ({toClient})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Sent Profile" ? "#154360" : "white"), backgroundColor: (params.status == "Sent Profile" ? "white" : "#154360") }} href={`/admin/candidates/1/10/${params.job_id}/Sent Profile`}>  Sent Profile ({sentProfile})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Shortlisted" ? "#186A3B" : "white"), backgroundColor: (params.status == "Shortlisted" ? "white" : "#186A3B") }} href={`/admin/candidates/1/10/${params.job_id}/Shortlisted`}>  Shortlisted ({shortlisted})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Interviewed" ? "#7E5109" : "white"), backgroundColor: (params.status == "Interviewed" ? "white" : "#7E5109") }} href={`/admin/candidates/1/10/${params.job_id}/Interviewed`}>  Interviewed ({interviewed})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Offered" ? "#6E2C00" : "white"), backgroundColor: (params.status == "Offered" ? "white" : "#6E2C00") }} href={`/admin/candidates/1/10/${params.job_id}/Offered`}>Offered ({offered})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Joined" ? "#424949" : "white"), backgroundColor: (params.status == "Joined" ? "white" : "#424949") }} href={`/admin/candidates/1/10/${params.job_id}/Joined`}>Joined ({joined})</a>
                                <a class='badge' style={{ border: "0px", color: (params.status == "Invoice" ? "#17202A" : "white"), backgroundColor: (params.status == "Invoice" ? "white" : "#17202A") }} href={`/admin/candidates/1/10/${params.job_id}/Invoice`}>Invoice ({invoice})</a>
                                <br />
                                <br />

                                {/* <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                                <input type="file" style={{display: 'none'}} ref={inputFile} onChange={onFileChange} title=" " />
                                <select
                                  class="form-select col-md-3"
                                  name="addcandidate"
                                  onChange={onchangeaddcandidate}>
                                  <option value="0">Add new candidate</option>
                                  <option value="1">Add Single Candididate</option>
                                  <option value="2">Import Candidates</option>
                                  <option value="3">Muliple Candidates - Resume Upload</option>
                                </select> */}
                                <br />
                                <button class="btn btn-otline-dark btn-primary" style={{ margin: '8px' }} onClick={() => { jobassigntoggle(); }}>Change Status</button>
                                {jobassignmodal === true && (
                                  <Modal
                                    isOpen={jobassignmodal}
                                    toggle={jobassigntoggle}
                                  >
                                    <ModalHeader toggle={jobassigntoggle}>
                                      Change Status of the Candidate
                                    </ModalHeader>
                                    <ModalBody>
                                      <form class="forms-sample">
                                        <div class="row">
                                          <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                              <label style={{ color: errorColor }}>Click on the Status below to assign it for Candidates to this Job</label>
                                              <br />
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#9FE2BF" }} onClick={() => { assignstatuslabel("Sourcing"); }}>Sourcing ({sourcing})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#78281F" }} onClick={() => { assignstatuslabel("Screening"); }}>Screening ({screening})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#4A235A" }} onClick={() => { assignstatuslabel("Move to the Assessment"); }}>Move to the Assessment ({assessment})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#B00020", color: "white" }} onClick={() => { assignstatuslabel("Rejected"); }}>Rejected ({rejected})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#1B4F72" }} onClick={() => { assignstatuslabel("Move to the Client"); }}>Move to the Client ({toClient})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#154360" }} onClick={() => { assignstatuslabel("Sent Profile"); }}>Sent Profile ({sentProfile})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#186A3B", color: "white" }} onClick={() => { assignstatuslabel("Shortlisted"); }}>Shortlisted ({shortlisted})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#7E5109", color: "white" }} onClick={() => { assignstatuslabel("Interviewed"); }}>Interviewed ({interviewed})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#6E2C00", color: "white" }} onClick={() => { assignstatuslabel("Offered"); }}>Offered ({offered})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#424949", color: "white" }} onClick={() => { assignstatuslabel("Joined"); }}>Joined ({joined})</label>
                                              <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#17202A", color: "white" }} onClick={() => { assignstatuslabel("Invoice"); }}>Invoice ({invoice})</label>
                                              <br />
                                              Status Selected: <b>{status_label}</b>
                                              <br />
                                              <label>Internal Remark</label>
                                              <input
                                                class="form-control" type="text" name="remark" onChange={onchangestatusdata}
                                                value={statusdata.remark} placeholder="Remark for internal use." />

                                              {/* <select class="form-select" name="jobassign" value={assign_status} onChange={onchangestatus} >
                                                <option value="0">Select Status</option>
                                                <option value="Screening">Screening</option>
                                                <option value="Move to the Assessment">Move to the Assessment</option>
                                                <option value="Move to the Client">Move to the Client</option>
                                                <option value="Sent Profile">Sent Profile</option>
                                                <option value="Shortlisted">Shortlisted</option>
                                                <option value="Interviewed">Interviewed</option>
                                                <option value="Offered">Offered</option>
                                                <option value="Joined">Joined</option>
                                                <option value="Invoice">Invoice</option>
                                              </select> */}
                                              <br />

                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          type="submit" class="btn btn-primary me-2" onClick={savestatustoggle} >
                                          Submit
                                        </button>
                                        <button
                                          class="btn btn-light"
                                          onClick={jobassigntoggle}
                                        >
                                          Cancel
                                        </button>
                                      </form>
                                    </ModalBody>
                                  </Modal>
                                )}
                              </div>
                            </div>
                            <br />

                            <div class="table-responsive  mt-1" id="printme">
                            <Checkbox id="selectAll" isChecked={selectedCheckboxes.length === page_size} onChange={handleSelectAllChange} />
                              <table class="table select-table">
                                {/* <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Functional Area</th>
                                    {params.job_id != 0 ? <th>Current Status</th> : ''}
                                    <th>Action</th>
                                  </tr>
                                </thead> */}
                                <tbody>
                                  {candidates.map((item, index) => {
                                    const img = item.profile
                                      ? process.env.REACT_APP_API_HOST +
                                      item.profile
                                      : image;
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {/* <input type="checkbox" name="select" value={item.id} onClick={handleselect} /> */}
                                          <Checkbox id={item.id} name="select" value={item.id} onChange={handleCheckboxChange} isChecked={selectedCheckboxes.includes(item.id)}></Checkbox>
                                          </td>
                                        <td>
                                          <div class="d-flex ">
                                            <a
                                              href={`/admin/viewCandidate/${item.id}`}
                                            >
                                              {" "}
                                              <img src={img} alt="" />
                                            </a>
                                            <div>
                                              <a
                                                href={`/admin/viewCandidate/${item.id}`}
                                              >
                                                {" "}
                                                <h6>
                                                  {item.first_name}{" "}
                                                  {item.last_name}
                                                </h6>
                                              </a>
                                              <p>
                                                <i class="fa fa-map-marker"></i>{" "}
                                                {item.city_name},{" "}
                                                {item.state_name},{" "}
                                                {item.country_name}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <h6>
                                            <a href={`mailto:${item.email}`}>
                                              {item.email}
                                            </a>
                                          </h6>
                                          {item.phone && (
                                            <p>
                                              <a href={`callto:${item.phone}`}>
                                                {item.phone}
                                              </a>
                                            </p>
                                          )}
                                          <p><b>Industry:</b>{item.industries}</p>
                                          <p><b>Fun. Area:</b>{item.functional_area1} </p>
                                        </td>
                                        <td>
                                          <p><b>Notice Period:</b> {item.notice_period}</p>
                                          <p><b>Current Salary:</b> {item.currency_icon} {item.current_salary}</p>
                                          <p><b>Expected Salary:</b> {item.currency_icon} {item.expected_salary}</p>
                                          <p><b>Experience:</b> {item.experience}</p>
                                          <p><b>Immediate Available:</b> {item.immediate_available == 1 ? "Yes" : "No"}</p>
                                        </td>
                                        {params.job_id != 0 ?
                                          <td>{item.status} ({item.status_remark})</td> : ''}
                                        <td>
                                          <a href={`/admin/viewCandidate/${item.id}`}>
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-eye"></i>
                                            </button>
                                          </a>
                                          <br />
                                          <a href={`/admin/candedit/${item.id}`} title="Edit Candidate">
                                            <button
                                              class="badge badge-opacity-success"
                                              style={{ border: "0px" }}>
                                              <i class="fa fa-pencil-square-o"></i>
                                            </button>
                                          </a>
                                          <br />
                                          <Link to={`/admin/candstatus/1/20/${params.job_id}/${item.id}`}
                                            state= {{ job_details: job_details, job: job }}
                                          ><i class="fa fa-list"></i></Link>
                                          {/* <a href={`/admin/candstatus/1/20/${params.job_id}/${item.id}`} title="Change Status">
                                            <button
                                              class={'badge ' + (item.status === 1 ? 'badge-opacity-success' : item.status === 2 ? 'badge-opacity-warning' : 'badge-opacity-danger')}
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-list"></i>
                                            </button>
                                          </a> */}

                                          {/* {statusmodal === true && (
                                            <Modal
                                              isOpen={statusmodal}
                                              toggle={statustoggle}
                                            >
                                              <ModalHeader toggle={statustoggle}>
                                                Change Status of the Candidate
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="row">
                                                    <input type="text" class="form-control" value={item.id} name="eid" onChange={onchangeeditstatusdata} />
                                                    <div class="col-lg-12 col-md-12">
                                                      <div class="form-group">
                                                        <label style={{ color: errorColor }}>Click on the Status below to assign it for Candidates to this Job</label>
                                                        <br />
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#9FE2BF" }} onClick={() => { assignstatuslabel("Sourcing"); }}>Sourcing ({sourcing})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#78281F" }} onClick={() => { assignstatuslabel("Screening"); }}>Screening ({screening})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#4A235A" }} onClick={() => { assignstatuslabel("Move to the Assessment"); }}>Move to the Assessment ({assessment})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#1B4F72" }} onClick={() => { assignstatuslabel("Move to the Client"); }}>Move to the Client ({toClient})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", color: "white", backgroundColor: "#154360" }} onClick={() => { assignstatuslabel("Sent Profile"); }}>Sent Profile ({sentProfile})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#186A3B", color: "white" }} onClick={() => { assignstatuslabel("Shortlisted"); }}>Shortlisted ({shortlisted})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#7E5109", color: "white" }} onClick={() => { assignstatuslabel("Interviewed"); }}>Interviewed ({interviewed})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#6E2C00", color: "white" }} onClick={() => { assignstatuslabel("Offered"); }}>Offered ({offered})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#424949", color: "white" }} onClick={() => { assignstatuslabel("Joined"); }}>Joined ({joined})</label>
                                                        <label class='badge' style={{ cursor: "pointer", border: "0px", backgroundColor: "#17202A", color: "white" }} onClick={() => { assignstatuslabel("Invoice"); }}>Invoice ({invoice})</label>
                                                        <br />
                                                        Status Selected: <b>{status_label}</b>
                                                        <br />
                                                        <label>Internal Remark</label>
                                                        <input type="text" class="form-control" value={item.status_remark} name="eremark" onChange={onchangeeditstatusdata} />
                                                        <br />

                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    type="submit" class="btn btn-primary me-2" onClick={saveeditstatustoggle} >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={statustoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )} */}

                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/candidates/${params.page - 1
                                            }/${params.page_size}/${params.job_id}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/candidates/${Number(params.page) + 1
                                              }/${params.page_size}/${params.job_id}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminCandidates, requestStatusCandidate, requestEditStatusCandidate }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Candidates);
