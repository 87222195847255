import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminAllQuestionTemplate, requestAdminAddQuestionTemplate, requestAdminGetQuestionTemplate, requestAdminEditQuestionTemplate, requestAdminDeleteQuestionTemplate } from "../Redux/actions";
import image from "../images/extraLogo.png";
import Swal from "sweetalert2";
import axios from 'axios';

function QuestionTemplate(props) {
  
  const params = useParams();
  const [questionTemplate, setquestionTemplate] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [change, setchange] = useState(false);
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const addtoggle = () => setaddModal(!addmodal);
  const [addmodal, setaddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const [optionmodal, setoptionModal] = useState(false);

  const [error, seterror] = useState("");
  const [errorTags, seterrorTags] = useState("");
  const [errorRole, seterrorRole] = useState("");
  const [errorExperience, seterrorExperience] = useState("");

  const [id, setid] = useState(0);
  const [adddata, setadddata] = useState({
    name: "",
    tags: "",
    fun_area: "",
    experience: "",

  });
  const [editdata, seteditdata] = useState({
    name: "",
    tags: "",
    fun_area: "",
    experience: "",
  });
  const [fun_areas, setfun_areas] = useState([]);

  useEffect(() => {
    let funAreaData = props.data.loginData.data.data.fun_area;
    if (funAreaData !== undefined) {
      setfun_areas(funAreaData);
    }
  }, [props.data.loginData.data.data]);

  function validateadd() {
    let formIsValid = false;
    if (!adddata["name"]) {
      formIsValid = false;
      seterror("*Enter questionTemplate.");
    } else if (typeof adddata["name"] !== "undefined") {
        formIsValid = true;
        seterror("");
    }    
    return formIsValid;    
  }

  function tagsadd() {
    let formIsValid = false;
    if (!adddata["tags"]) {
      formIsValid = false;
      seterrorTags("*Enter tags by comma.");
    } else {
      formIsValid = true;
      seterrorTags("");
    }
    return formIsValid;
  }

  function experienceadd() {
    let formIsValid = false;
    if (!adddata["experience"]) {
      formIsValid = false;
      seterrorExperience("*Enter experience.");
    } else {
      formIsValid = true;
      seterrorExperience("");
    }
    return formIsValid;
  }

  function validateAddForm() {      
    let validate = validateadd(); let tags = tagsadd(); let experience = experienceadd();
    let valid = validate && tags && experience;
    return valid;
  }

  //edit form
  function validateedit() {
    let formIsValid = false;
    if (!editdata["name"]) {
      formIsValid = false;
      seterror("*Enter Question Template name.");
    } else if (typeof editdata["name"] !== "undefined") {      
        formIsValid = true;
        seterror("");
    }
    return formIsValid;
  }

  function tagsedit() {
    let formIsValid = false;
    if (!editdata["tags"]) {
      formIsValid = false;
      seterrorTags("*Enter tags.");
    } else {
      formIsValid = true;
      seterrorTags("");
    }
    return formIsValid;
  }

  function roleedit() {
    let formIsValid = false;
    if (!editdata["role"]) {
      formIsValid = false;
      seterrorRole("*Enter role.");
    } else {
      formIsValid = true;
      seterrorRole("");
    }
    return formIsValid;
  }

  function experienceedit() {
    let formIsValid = false;
    if (!editdata["experience"]) {
      formIsValid = false;
      seterrorExperience("*Enter experience.");
    } else {
      formIsValid = true;
      seterrorExperience("");
    }
    return formIsValid;
  }

  function validateEditForm() {
    let validate = validateedit(); let tags = tagsedit(); let experience = experienceedit();
    let valid = validate && tags && experience;
    return valid;
  }

  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }
  
  function onchangeeditdata(e) {
    seteditdata((editdata) => ({
      ...editdata,
      [e.target.name]: e.target.value,
    }));
  }

  //save questionTemplate
  const saveaddtoggle = (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      props.requestAdminAddQuestionTemplate({
        data: {
          user_id: 1,
          name: adddata.name,
          tags: adddata.tags,
          fun_area: adddata.fun_area,
          experience: adddata.experience,
        },
      });
      setadddata({
        name: "",
        tags: "",
        fun_area: "",
        experience: "",
      });
      addtoggle();
    }
  };

  //save Question Template response
  useEffect(() => {
    let addQuestionTemplateData = props.data.addQuestionTemplateData;
    if (addQuestionTemplateData !== undefined) {
      if (addQuestionTemplateData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Question Template Added Successfully.",
          "success"
        );
        props.data.addQuestionTemplateData = undefined;
        setadddata({
          name: "",
          description: "",
        });        
        addtoggle();
        setchange(!change);
      } else {
        Swal.fire("Error!", `${addQuestionTemplateData.data.message}`, "error");
        props.data.addQuestionTemplateData = undefined;
      }
    }
  }, [props.data.addQuestionTemplateData]);

  // set edit
  const edittoggle = (id) => {
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(id);
      seteditdata({
        name: " ",
        tags: " ",
        fun_area: " ",
        experience: " ",
      });
      props.requestAdminGetQuestionTemplate({
        id: id,
      });
    }
  };

  useEffect(() => {
    let questionTemplate = props.data.getQuestionTemplateData;
    if (questionTemplate !== undefined) {
      if (questionTemplate.data) {
        console.log('questionTemplate.data.data.QT[0]');
        console.log(questionTemplate.data.data.QuestionTemplate[0]);
        seteditdata(questionTemplate.data.data.QuestionTemplate[0]);
      }
    }
  }, [props.data.getQuestionTemplateData]);

  const saveedittoggle = (e) => {
    e.preventDefault();
    if (validateEditForm()) {
      props.requestAdminEditQuestionTemplate({
        id: id,
        data: {
          name: editdata.name,
          user_id: 1,
          tags: editdata.tags,
          fun_area: editdata.fun_area,
          experience: editdata.experience,
        },
      });
      seteditdata({
        name: "",
        tags: "",
        fun_area: 0,
        experience: "",
      });
      setid(0);
      edittoggle();
    }
  };

  //update response
  useEffect(() => {
    let editQuestionTemplateData = props.data.editQuestionTemplateData;
    if (editQuestionTemplateData !== undefined) {
      if (editQuestionTemplateData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Question Template Updated Successfully.",
          "success"
        );
        props.data.editQuestionTemplateData = undefined;
        seteditdata({
          name: "",
          tags: "",
          fun_area: 0,
          experience: "",
        });
        edittoggle();
        setchange(!change);
      } else {
        Swal.fire("Error!", `${editQuestionTemplateData.data.message}`, "error");
        props.data.editQuestionTemplateData = undefined;
      }
    }
  }, [props.data.editQuestionTemplateData]);

  //list
  useEffect(() => {
    props.requestAdminAllQuestionTemplate({
      page: params.page,
      page_size: params.page_size,
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/questionTemplate/1/${e.target.value}`);
    }
  }

  //list without pagination
  useEffect(() => {
    let questionTemplateData = props.data.allQuestionTemplateData;
    if (questionTemplateData !== undefined) {
      if (questionTemplateData) {    
        setquestionTemplate(questionTemplateData);
      }
    }
  }, [props.data.questionTemplateData]);

  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteQuestionTemplate({
          id: id,
        });
      }
    })
  }

  //delete
  useEffect(() => {
    let deleteQuestionTemplateData = props.data.deleteQuestionTemplateData;
    if (deleteQuestionTemplateData !== undefined) {
      if (deleteQuestionTemplateData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Comapny name deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteQuestionTemplateData = undefined;
  }, [props.data.deleteQuestionTemplateData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Question Template</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <>
      <div class="container-scroller">
        <Header name="QuestionTemplate" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="QuestionTemplate" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/questionTemplate/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                              <div>
                                <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={addtoggle}
                                >
                                  Add new QuestionTemplate
                                </button>
                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                  <ModalHeader toggle={addtoggle}>
                                    Add Question Template
                                  </ModalHeader>
                                  <ModalBody>
                                    <form class="forms-sample">
                                    <div class="row">
                                    <label>Name</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Name"
                                          name="name"
                                          value={adddata.name}
                                          onBlur={validateadd}
                                          onChange={onchangeadddata}
                                        />
                                        {error && <p>{error}</p>}
                                        <label>
                                          <br />
                                          Tags
                                        </label>
                                        <input
                                          type="text"
                                          name="tags"
                                          class="form-control"
                                          placeholder="Tags (seperated by comma)"
                                          value={adddata.tags}
                                          onBlur={tagsadd}
                                          onChange={onchangeadddata}
                                        />
                                        {errorTags && <p>{errorTags}</p>}
                                        <label>
                                          <br />
                                          Functional Area
                                        </label>
                                        <select
                                          class="form-select"
                                          name="fun_area"
                                          value={adddata.fun_area}
                                          // onBlur={roleadd}
                                          onChange={onchangeadddata}
                                        >
                                          <option value="0">Select Functional Area</option>
                                          {fun_areas.map((option) => (
                                            <option value={option.id}>{option.name}</option>
                                          ))}
                                        </select>
                                        {errorRole && <p>{errorRole}</p>}
                                        <label>
                                          <br />
                                          Experience
                                        </label>
                                        <input
                                          type="text"
                                          name="experience"
                                          class="form-control"
                                          placeholder="Experience"
                                          value={adddata.experience}
                                          onBlur={experienceadd}
                                          onChange={onchangeadddata}
                                        />
                                        {errorExperience && <p>{errorExperience}</p>}
                                    </div>
                                      <button
                                        type="submit"
                                        class="btn btn-primary me-2"
                                        onClick={saveaddtoggle}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        class="btn btn-light"
                                        onClick={addtoggle}
                                      >
                                        Cancel
                                      </button>
                                    </form>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr >
                                    <th>QuestionTemplate</th>
                                    <th>Functional Area</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {questionTemplate.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <div>
                                              <h6>{item.name}</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <p>{item.fun_area}</p>
                                        </td>
{/*                                        <td>
                                         <button
                                            class="badge badge-opacity-warning"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              optiontoggle(item.id);
                                            }}
                                          >
                                            {item.count}
                                          </button>
                                          {optionmodal === true && (
                                            <Modal
                                              isOpen={optionmodal}
                                              toggle={optiontoggle}
                                            >
                                              <ModalHeader toggle={optiontoggle}>
                                                QuestionTemplate Options
                                              </ModalHeader>
                                              <ModalBody>
                                              <div class="table-responsive  mt-1" id="printme">
                                                <table class="table select-table">
                                                  <thead>
                                                    <tr >
                                                    <th>Options</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                  {questionTemplateoptions.map((item, index) => {
                                                      return (
                                                        <tr key={index}>
                                                          <td>
                                                            <div class="d-flex ">
                                                              <div>
                                                                <h6>{item.q_option}</h6>
                                                              </div>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                              </ModalBody>
                                            </Modal>
                                          )} 
                                        </td> */}
                                        <td>
                                          <p>{item.created_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <p>{item.updated_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <a href={`/admin/question/${item.id}`}>
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-question"></i>
                                            </button>
                                          </a>
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              edittoggle(item.id);
                                            }}
                                          >
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit QuestionTemplate
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="form-group">
                                                    <label>Name</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Name"
                                                      name="name"
                                                      value={editdata.name}
                                                      onBlur={validateedit}
                                                      onChange={onchangeeditdata}
                                                    />
                                                    {error && <p>{error}</p>}
                                                    <label>
                                                      <br />
                                                      Tags
                                                    </label>
                                                    <input
                                                      type="text"
                                                      name="tags"
                                                      class="form-control"
                                                      placeholder="Tags (seperated by comma)"
                                                      value={editdata.tags}
                                                      onChange={onchangeeditdata}
                                                    />
                                                    {errorTags && <p>{errorTags}</p>}
                                                    <label>
                                                      <br />
                                                      Functional Area
                                                    </label>
                                                    <select
                                                      class="form-select"
                                                      name="fun_area"
                                                      value={editdata.fun_area}
                                                      // onBlur={roleadd}
                                                      onChange={onchangeeditdata}
                                                    >
                                                      <option value="0">Select Functional Area</option>
                                                      {fun_areas.map((option) => (
                                                        <option value={option.id}>{option.name}</option>
                                                      ))}
                                                    </select>
                                                    {errorRole && <p>{errorRole}</p>}
                                                    <label>
                                                      <br />
                                                      Experience
                                                    </label>
                                                    <input
                                                      type="text" name="experience" class="form-control" placeholder="Experience"
                                                      value={editdata.experience} onBlur={experienceadd} onChange={onchangeeditdata}/>
                                                      {errorExperience && <p>{errorExperience}</p>}
                                                  </div>

                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              deletedata(item.id);
                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/questionTemplate/${params.page - 1
                                            }/${params.page_size}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/questionTemplate/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/questionTemplate/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/questionTemplate/${Number(params.page) + 1
                                              }/${params.page_size}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
    requestAdminAllQuestionTemplate, requestAdminAddQuestionTemplate, requestAdminGetQuestionTemplate, requestAdminEditQuestionTemplate, requestAdminDeleteQuestionTemplate}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTemplate);
