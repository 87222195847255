import Header from './header';
import Footer from './footer';
import Select from 'react-select';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestFormFieldCand, requestGetCandidate, requestEditCandidateProfile, requestCountry, requestState, requestCity, requestAdminCandidateDetails } from "../Redux/actions";
import Swal from "sweetalert2";
import camera from "../images/camera.png";
import image from "../images/profile.png";

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import axios from 'axios';
import WOW from "wowjs";

function CandidateEdit(props) {
    useEffect(() => {
        new WOW.WOW().init();
        window.scrollTo(0, 0);
        localStorage.removeItem("link");
    }, []);

    const mystyle = {
        color: "#D10000",
        backgroundColor: "#FFD2D2",
        padding: "3px 10px",
        border: "1px solid red",
        borderRadius: "5px",
        marginTop: "5px",
    };

    const params = useParams();
    const [data, setData] = useState({});
    const [countryId, setcountryId] = useState(0);
    const [stateId, setstateId] = useState(0);
    const [cityId, setcityId] = useState(0);

    const [cities, setcities] = useState([]);
    const [states, setstates] = useState([]);
    const [countries, setcountries] = useState([]);

    const [degree_levels, setdegree_levels] = useState([]);
    const [degree, setdegree] = useState(0);
    const [courses, setcourses] = useState([]);
    const [course, setcourse] = useState(0);
    const [specializations, setspecializations] = useState([]);
    const [specialization, setspecialization] = useState(0);
    const [selectedspecialization, setselectedspecialization] = useState([]);
    const [selectedspecializationstr, setselectedspecializationstr] = useState("");
    const [prevspecialization, setprevspecialization] = useState([]);

    const [img, setimg] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [selectedResume, setSelectedResume] = useState();

    const [skill, setskill] = useState([]);
    const [career_level, setcareer_level] = useState([]);
    const [industry, setindustry] = useState([]);
    const [functional_area, setfunctional_area] = useState([]);
    const [currency, setcurrency] = useState([]);

    const [skills, setskills] = useState([]);
    const [selectedskill, setselectedskill] = useState([]);
    const [selectedskillstr, setselectedskillstr] = useState("");
    const [prevskill, setprevskill] = useState([]);
    const [experience, setExperience] = useState([
        {
            title: "",
            company: "",
            start_month: "",
            start_year: "",
            leave_month: "",
            leave_year: "",
            achieve: "",
        },
    ]);
    const [education, setEducation] = useState([
        {
            degree: 0,
            course: 0,
            specialization: "",
            university: "",
            start: "",
            leave: "",
            achieve: "",
        },
    ]);

    const [errorfirst_name, seterrorfirst_name] = useState("");
    const [errorlast_name, seterrorlast_name] = useState("");
    const [errorphone_no, seterrorphone_no] = useState("");
    const [erroremail, seterroremail] = useState("");
    const [errordesignation, seterrordesignation] = useState("");
    const [errorpincode, seterrorpincode] = useState("");
    const [errorgender, seterrorgender] = useState("");
    const [errorcountry, seterrorcountry] = useState("");
    const [errorstate, seterrorstate] = useState("");
    const [errorcity, seterrorcity] = useState("");
    const [errorbirth_date, seterrorbirth_date] = useState("");
    const [errormarital_status, seterrormarital_status] = useState("");
    const [errorlanguages, seterrorlanguages] = useState("");
    const [errornationality, seterrornationality] = useState("");
    const [errorcareer_level, seterrorcareer_level] = useState("");
    const [errornotice_period, seterrornotice_period] = useState("");
    const [errorindustry, seterrorindustry] = useState("");
    const [errorfunctional_area, seterrorfunctional_area] = useState("");
    const [errorcurrent_salary, seterrorcurrent_salary] = useState("");
    const [errorexpected_salary, seterrorexpected_salary] = useState("");
    const [errorsalary_currency, seterrorsalary_currency] = useState("");
    const [errorimmediate_available, seterrorimmediate_available] = useState("");
    const [erroraddress, seterroraddress] = useState("");
    const [errorphysical, seterrorphysical] = useState("");
    const [errorexperience, setErrorexperience] = useState([
        {
            title: "",
            company: "",
            start_month: "",
            start_year: "",
            leave_month: "",
            leave_year: "",
            achieve: "",
        },
    ]);
    const [erroreducation, setErroreducation] = useState([
        {
            degree: "",
            university: "",
            start_month: "",
            start_year: "",
            leave_month: "",
            leave_year: "",
            achieve: "",
        },
    ]);
    const navigate = useNavigate();
    const [emp, setEmp] = useState({});
    const [token, settoken] = useState("");
    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(50), (val, index) => year - index);
    const resumeFile = useRef();
    const [resume_path, setresume_path] = useState("Selected filename with be displayed here.");

    const openResume = () => {
        resumeFile.current.click();
    }

    const onClearResume = () => {
        resumeFile.value = null;
        setresume_path(setresume_path(resumeFile.current.files[0].name));
    }

    useEffect(() => {
        props.requestFormFieldCand();
    }, []);

    useEffect(() => {
        let formfieldData = props.data.formfieldCandData.data.data;
        if (formfieldData !== undefined) {
            settoken(formfieldData.token);
            setindustry(formfieldData.industries);
            setskill(formfieldData.skills);
            setcareer_level(formfieldData.career_levels);
            setfunctional_area(formfieldData.fun_area);
            setcurrency(formfieldData.currencies);
            setdegree_levels(formfieldData.degree_levels);
        }
    }, [props.data.formfieldCandData]);

    function onChangeData(e) {
        if (e.target.type === 'radio') {
            setData((data) => ({
                ...data,
                [e.target.name]: parseInt(e.target.value),
            }));
        } else {
            setData((data) => ({
                ...data,
                [e.target.name]: e.target.value,
            }));
        }
    }

    useEffect(() => {
        // console.log("profile", data);
    }, [data])

    function onChangeSkill(e) {
        selectedskill.push(e.target.value);
    }

    useEffect(() => {
        props.requestCountry();
    }, []);

    useEffect(() => {
        let countryData = props.candidate.countryData;
        if (countryData !== undefined) {
            if (countryData?.data?.status == "success") {
                setcountries(countryData.data.data.countries);
            }
        }
    }, [props.candidate.countryData]);

    function onChangeCountry(e) {
        setcountryId(e.target.value);
        props.requestState({
            id: e.target.value,
        });
    }

    useEffect(() => {
        let stateData = props.candidate.stateData;
        if (stateData !== undefined) {
            if (stateData?.data?.status == "success") {
                setstates(stateData.data.data.states);
            }
        }
    }, [props.candidate.stateData]);

    function onChangeState(e) {
        setstateId(e.target.value);
        props.requestCity({
            id: e.target.value,
        });
    }

    useEffect(() => {
        let cityData = props.candidate.cityData;
        if (cityData !== undefined) {
            if (cityData?.data?.status == "success") {
                setcities(cityData.data.data.cities);
            }
        }
    }, [props.candidate.cityData]);

    function onChangeCity(e) {
        setcityId(e.target.value);
    }

    useEffect(() => {
        props.requestAdminCandidateDetails({
            id: params.id,
        });
    }, []);

    useEffect(() => {
        let candidateDeatilsData = props.data.candidateDeatilsData;
        if (candidateDeatilsData !== undefined) {
            setData(candidateDeatilsData.data.data[0]);
            setcountryId(candidateDeatilsData.data.data[0].country);
            props.requestState({
                id: countryId,
            });

            setstateId(candidateDeatilsData.data.data[0].state);
            props.requestCity({
                id: stateId,
            });

            var text = candidateDeatilsData.data.data[0].experience1;
            if (text.split("start_month").length - 1 === 1) {
                setExperience(JSON.parse(text));
            }
            else {
                var match = /\r|\n/.exec(text);
                var ntext = text;
                if (match) {
                    var reg = new RegExp(/(\r\n?|\n|\t)/g);
                    ntext = text.replace('],[', ',').replace(reg, "-");
                }
                setcityId(candidateDeatilsData.data.data[0].city);
                var all_exp = [];
                JSON.parse(ntext).forEach(element => {
                    var exp = [];
                    exp.title = element.title; exp.company = element.company; exp.start_month = element.start_month;
                    exp.start_year = element.start_year; exp.leave_month = element.leave_month; exp.leave_year = element.leave_year; exp.achieve = element.achieve;
                    all_exp.push(exp);
                    setExperience(exp);
                });
            }

            // experience.map((x, i) => {console.log(x.title)});
            // setExperience(JSON.parse(ntext));
            console.log(experience);
            // console.log(JSON.parse(candidateDeatilsData.data.data[0].education).degree);
            if (candidateDeatilsData.data.data[0].education !== null) {
                axios.get('https://yourdomain/api/courses/' + JSON.parse(candidateDeatilsData.data.data[0].education)[0].degree)
                    .then(res => {
                        setcourses(res.data.data.courses);
                    })
                    .catch(err => {
                        console.log(err);
                    });

                setEducation(JSON.parse(candidateDeatilsData.data.data[0].education));
                axios.get('https://yourdomain/api/specializations/' + JSON.parse(candidateDeatilsData.data.data[0].education)[0].course)
                    .then(res => {
                        setspecializations(res.data.data.specializations);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }

            if (candidateDeatilsData.data.data[0].profile) {
                setimg(
                    process.env.REACT_APP_API_HOST + '/public' + candidateDeatilsData.data.data[0].profile
                );
            } else {
                setimg(image);
            }
        }
    }, [props.data.candidateDeatilsData]);

    useEffect(() => {
        let job = props.data.candidateDeatilsData;
        let skill_rec = job.data.data[0].skill;

        const loadLocation = async () => {
            setskill(props.data.formfieldCandData.data.data.skills);
            // const skillData =  skill.split(',').map(Number);

            let filterData = skill.filter((el) => skill_rec.includes(el.id.toString()));
            console.log(filterData.length);
            setselectedskill([]);
            setselectedskillstr("");
            filterData.map((option) => (
                selectedskill.push(option.id)
            ));
            const dflt = filterData.map((option) => { return { label: option.name, value: option.name, key: option.id } });
            console.log(dflt);
            setprevskill(dflt);
            setselectedskillstr(selectedskill.toString());
        }
        loadLocation();

    }, [skill]);




    //   useEffect(() => {
    //     let job = props.data.candidateDeatilsData;
    //     console.log(job.data.data[0].skill);

    //     if (job !== undefined) {
    //         if (job.data) {
    //             const loadLocation = async () => {
    //                 const skillData =  job.data.data[0].skill.split(',').map(Number);
    //                 console.log(skillData);

    //                 let filterData  = skills.filter((el) => skillData.includes(el.id));
    //                 console.log(filterData.length);
    //                 setselectedskill([]);
    //                 setselectedskillstr("");
    //                 filterData.map((option) => (
    //                   selectedskill.push(option.id)
    //                 ));
    //                 const dflt= filterData.map((option) =>{return {label: option.name, value: option.name, key: option.id}});
    //                 console.log(dflt);
    //                 setprevskill(dflt);
    //                 setselectedskillstr(selectedskill.toString());    
    //             }
    //             loadLocation();
    //         }            
    //       }
    //   }, [props.data.candidateDeatilsData]);

    function validateExperienceTitle(i) {
        let formIsValid = false;
        if (!experience[i].title) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].title = "*Enter title of your experience.";
            setErrorexperience(list);
        } else if (typeof experience[i].title === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].title = "*Enter title of your experience.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].title = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }
    function validateExperienceCompany(i) {
        let formIsValid = false;
        if (!experience[i].company) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].company = "*Enter company of your experience.";
            setErrorexperience(list);
        } else if (typeof experience[i].company === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].company = "*Enter company of your experience.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].company = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceStartMonth(i) {
        let formIsValid = false;
        if (experience[i].start_month === "0") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start_month = "*Select Experience Start Month.";
        } else if (typeof experience[i].start_month === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start_month = "*Select Experience Start Month.";
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].start_month = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceStartYear(i) {
        let formIsValid = false;
        if (experience[i].start_year === "0") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start_year = "*Select Experience Start Year.";
        } else if (typeof experience[i].start_year === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start_year = "*Select Experience Start Year.";
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].start_year = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    // function validateExperienceLeaveMonth(i) {
    //     let formIsValid = false;
    //     if (experience[i].leave_month === "0") {
    //         formIsValid = false;
    //         const list = [...errorexperience];
    //         list[i].leave_month = "*Select Experience Start Month.";
    //     } else if (typeof experience[i].leave_month === "undefined") {
    //         formIsValid = false;
    //         const list = [...errorexperience];
    //         list[i].leave_month = "*Select Experience Start Month.";
    //     } else {
    //         formIsValid = true;
    //         const list = [...errorexperience];
    //         list[i].leave_month = "";
    //         setErrorexperience(list);
    //     }
    //     return formIsValid;
    // }

    function validateExperienceLeaveYear(i) {
        let formIsValid = false;
        if (experience[i].leave_year === "0") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave_year = "*Select Experience Leave Year.";
        } else if (typeof experience[i].leave_year === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave_year = "*Select Experience Leave Year.";
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].leave_year = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validateExperienceStart(i) {
        let formIsValid = false;
        var Today = new Date();
        if (!experience[i].start) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start = "*Enter start month of your experience.";
            setErrorexperience(list);
        } else if (typeof experience[i].start === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start = "*Enter start month of your experience.";
            setErrorexperience(list);
        } else if (new Date(experience[i].start).getTime() >= Today.getTime()) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].start = "*Joining time should be in past.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].start = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }
    function validateExperienceLeave(i) {
        let formIsValid = false;
        var Today = new Date();
        if (!experience[i].leave) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave = "*Enter leave month of your experience.";
            setErrorexperience(list);
        } else if (typeof experience[i].leave === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave = "*Enter leave month of your experience.";
            setErrorexperience(list);
        } else if (new Date(experience[i].leave).getTime() >= Today.getTime()) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave = "*Leaving time should be in past or current month.";
            setErrorexperience(list);
        } else if (
            new Date(experience[i].leave).getTime() <=
            new Date(experience[i].start).getTime()
        ) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].leave = "*Leaving time should be longer than joining time.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].leave = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }
    function validateExperienceAchieve(i) {
        let formIsValid = false;
        if (!experience[i].achieve) {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].achieve = "*Enter achievement of your experience.";
            setErrorexperience(list);
        } else if (typeof experience[i].achieve === "undefined") {
            formIsValid = false;
            const list = [...errorexperience];
            list[i].achieve = "*Enter achievement of your experience.";
            setErrorexperience(list);
        } else {
            formIsValid = true;
            const list = [...errorexperience];
            list[i].achieve = "";
            setErrorexperience(list);
        }
        return formIsValid;
    }

    function validatefirst_name() {
        let formIsValid = false;
        if (!data["first_name"]) {
            formIsValid = false;
            seterrorfirst_name("*Enter your first name.");
        } else if (typeof data["first_name"] === "undefined") {
            formIsValid = false;
            seterrorfirst_name("*Enter your first name.");
        } else if (!data["first_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
            formIsValid = false;
            seterrorfirst_name("*Please enter Alphabet characters only.");
        } else {
            formIsValid = true;
            seterrorfirst_name("");
        }
        return formIsValid;
    }
    // function validatelast_name() {
    //     let formIsValid = false;
    //     if (!data["last_name"]) {
    //         formIsValid = false;
    //         seterrorlast_name("*Enter your last name.");
    //     } else if (typeof data["last_name"] === "undefined") {
    //         formIsValid = false;
    //         seterrorlast_name("*Enter the your last name.");
    //     } else if (!data["last_name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
    //         formIsValid = false;
    //         seterrorlast_name("*Please enter Alphabet characters only.");
    //     } else {
    //         formIsValid = true;
    //         seterrorlast_name("");
    //     }
    //     return formIsValid;
    // }
    function validatephone_no() {
        let formIsValid = false;
        if (!data["phone"]) {
            formIsValid = false;
            seterrorphone_no("*Enter your phone no.");
        } else if (typeof data["phone"] === "undefined") {
            formIsValid = false;
            seterrorphone_no("*Enter your phone no.");
        } else {
            formIsValid = true;
            seterrorphone_no("");
        }
        return formIsValid;
    }

    function validateemail() {
        let formIsValid = false;
        if (!data["email"]) {
            formIsValid = false;
            seterroremail("*Enter your email.");
        } else if (typeof data["email"] === "undefined") {
            formIsValid = false;
            seterroremail("*Enter your email.");
        } else {
            formIsValid = true;
            seterroremail("");
        }
        return formIsValid;
    }

    function validatedesignation() {
        let formIsValid = false;
        if (!data["designation"]) {
            formIsValid = false;
            seterrordesignation("*Enter your last name.");
        } else if (typeof data["designation"] === "undefined") {
            formIsValid = false;
            seterrordesignation("*Enter your last name.");
        } else if (!data["designation"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
            formIsValid = false;
            seterrordesignation("*Please enter Alphabet characters only.");
        } else {
            formIsValid = true;
            seterrordesignation("");
        }
        return formIsValid;
    }

    function validatepincode() {
        let formIsValid = false;
        if (!data["pincode"]) {
            formIsValid = false;
            seterrorpincode("*Enter your zip code.");
        } else if (typeof data["pincode"] === "undefined") {
            formIsValid = false;
            seterrorpincode("*Enter your zip code.");
        } else {
            formIsValid = true;
            seterrorpincode("");
        }
        return formIsValid;
    }

    function validategender() {
        let formIsValid = false;
        if (typeof data["gender"] === "undefined") {
            formIsValid = false;
            seterrorgender("*Select your gender.");
        } else {
            formIsValid = true;
            seterrorgender("");
        }
        return formIsValid;
    }
    function validatecountry() {
        let formIsValid = false;
        if (countryId === "0") {
            formIsValid = false;
            seterrorcountry("*Select your country.");
        } else if (typeof countryId === "undefined") {
            formIsValid = false;
            seterrorcountry("*Select your country.");
        } else {
            formIsValid = true;
            seterrorcountry("");
        }
        return formIsValid;
    }
    function validatestate() {
        let formIsValid = false;
        if (stateId === "0") {
            formIsValid = false;
            seterrorstate("*Select your state.");
        } else if (typeof stateId === "undefined") {
            formIsValid = false;
            seterrorstate("*Select your state.");
        } else {
            formIsValid = true;
            seterrorstate("");
        }
        return formIsValid;
    }
    function validatecity() {
        let formIsValid = false;
        if (cityId === "0") {
            formIsValid = false;
            seterrorcity("*Select your city.");
        } else if (typeof cityId === "undefined") {
            formIsValid = false;
            seterrorcity("*Select your city.");
        } else {
            formIsValid = true;
            seterrorcity("");
        }
        return formIsValid;
    }
    function validatebirth_date() {
        let formIsValid = false;
        var Today = new Date();
        if (typeof data["birth_date"] !== "undefined") {
            if (new Date(data["birth_date"]).getTime() >= Today.getTime()) {
                formIsValid = false;
                seterrorbirth_date("*Please select proper date.");
            } else {
                formIsValid = true;
                seterrorbirth_date("");
            }
        } else {
            formIsValid = false;
            seterrorbirth_date("*Please select birth date.");
        }
        return formIsValid;
    }
    function validatemarital_status() {
        let formIsValid = false;
        if (typeof data["marital_status"] === "undefined") {
            formIsValid = false;
            seterrormarital_status("*Select your marital status.");
        } else {
            formIsValid = true;
            seterrormarital_status("");
        }
        return formIsValid;
    }
    function validatenotice_period() {
        let formIsValid = false;
        if (data["notice_period"] === "0") {
            formIsValid = false;
            seterrornotice_period("*Select notice period.");
        } else if (typeof data["notice_period"] === "undefined") {
            formIsValid = false;
            seterrornotice_period("*Select your notice period.");
        } else {
            formIsValid = true;
            seterrornotice_period("");
        }
        return formIsValid;
    }

    function validatelanguages() {
        let formIsValid = false;
        if (!data["languages"]) {
            console.log('here');
            formIsValid = false;
            seterrorlanguages("*Enter your known languages.");
        } else if (typeof data["languages"] === "undefined") {
            console.log('there');
            formIsValid = false;
            seterrorlanguages("*Enter your known languages.");
        } else {
            formIsValid = true;
            seterrorlanguages("");
        }
        return formIsValid;
    }

    function validatenationality() {
        let formIsValid = false;
        if (!data["nationality"]) {
            formIsValid = false;
            seterrornationality("*Enter your nationality.");
        } else if (typeof data["nationality"] === "undefined") {
            formIsValid = false;
            seterrornationality("*Enter your nationality.");
        } else if (!data["nationality"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
            formIsValid = false;
            seterrornationality("*Please enter Alphabet characters only.");
        } else {
            formIsValid = true;
            seterrornationality("");
        }
        return formIsValid;
    }

    function validatecareer_level() {
        let formIsValid = false;
        if (data["career_level"] === "0") {
            formIsValid = false;
            seterrorcareer_level("*Select your career level.");
        } else if (typeof data["career_level"] === "undefined") {
            formIsValid = false;
            seterrorcareer_level("*Select your career level.");
        } else {
            formIsValid = true;
            seterrorcareer_level("");
        }
        return formIsValid;
    }

    function validateindustry() {
        let formIsValid = false;
        if (data["industry"] === "0") {
            formIsValid = false;
            seterrorindustry("*Select your industry.");
        } else if (typeof data["industry"] === "undefined") {
            formIsValid = false;
            seterrorindustry("*Select your industry.");
        } else {
            formIsValid = true;
            seterrorindustry("");
        }
        return formIsValid;
    }
    function validatefunctional_area() {
        let formIsValid = false;
        if (data["functional_area"] === "0") {
            formIsValid = false;
            seterrorfunctional_area("*Select your functional area.");
        } else if (typeof data["functional_area"] === "undefined") {
            formIsValid = false;
            seterrorfunctional_area("*Select your functional area.");
        } else {
            formIsValid = true;
            seterrorfunctional_area("");
        }
        return formIsValid;
    }
    function validatecurrent_salary() {
        let formIsValid = false;
        if (typeof data["current_salary"] !== "undefined") {
            if (data["current_salary"] < 0) {
                formIsValid = false;
                seterrorcurrent_salary("*Please enter positive current salary.");
            } else {
                formIsValid = true;
                seterrorcurrent_salary("");
            }
        } else {
            formIsValid = false;
            seterrorcurrent_salary("*Please enter current salary.");
        }
        return formIsValid;
    }
    function validateexpected_salary() {
        let formIsValid = false;
        if (typeof data["expected_salary"] !== "undefined") {
            if (data["expected_salary"] < 0) {
                formIsValid = false;
                seterrorexpected_salary("*Please enter positive expected salary.");
            } else {
                formIsValid = true;
                seterrorexpected_salary("");
            }
        } else {
            formIsValid = false;
            seterrorexpected_salary("*Please enter expected salary.");
        }
        return formIsValid;
    }
    function validatesalary_currency() {
        let formIsValid = false;
        if (data["salary_currency"] === "0") {
            formIsValid = false;
            seterrorsalary_currency("*Select your salary currency.");
        } else if (typeof data["salary_currency"] === "undefined") {
            formIsValid = false;
            seterrorsalary_currency("*Select your salary currency.");
        } else {
            formIsValid = true;
            seterrorsalary_currency("");
        }
        return formIsValid;
    }
    function validateimmediate_available() {
        let formIsValid = false;
        if (typeof data["immediate_available"] === "undefined") {
            formIsValid = false;
            seterrorimmediate_available("*Select your availablity.");
        } else {
            formIsValid = true;
            seterrorimmediate_available("");
        }
        return formIsValid;
    }
    function validateaddress() {
        let formIsValid = false;
        if (!data["address"]) {
            formIsValid = false;
            seterroraddress("*Enter your address.");
        } else if (typeof data["address"] === "undefined") {
            formIsValid = false;
            seterroraddress("*Enter your address.");
        } else {
            formIsValid = true;
            seterroraddress("");
        }
        return formIsValid;
    }

    function validateEducationUniversity(i) {
        let formIsValid = false;
        if (!education[i].university) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].university = "*Enter university of your education.";
            setErroreducation(list);
        } else if (typeof education[i].university === "undefined") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].university = "*Enter university of your education.";
            setErroreducation(list);
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].university = "";
            setErroreducation(list);
        }
        return formIsValid;
    }
    function validateEducationStart(i) {
        let formIsValid = false;
        if (education[i].start === "0") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].start = "*Select Education Start Year.";
        } else if (typeof education[i].start === "undefined") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].start = "*Select Education Start Year.";
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].start = "";
            setErroreducation(list);
        }
        return formIsValid;
    }
    function validateEducationLeave(i) {
        let formIsValid = false;
        if (education[i].leave === "0") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].leave = "*Select Education Leave Year.";
        } else if (typeof education[i].leave === "undefined") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].leave = "*Select Education Leave Year.";
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].leave = "";
            setErroreducation(list);
        }
        return formIsValid;
    }

    function validateEducationAchieve(i) {
        let formIsValid = false;
        if (!education[i].achieve) {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].achieve = "*Enter achievement of your education.";
            setErroreducation(list);
        } else if (typeof education[i].achieve === "undefined") {
            formIsValid = false;
            const list = [...erroreducation];
            list[i].achieve = "*Enter achievement of your education.";
            setErroreducation(list);
        } else {
            formIsValid = true;
            const list = [...erroreducation];
            list[i].achieve = "";
            setErroreducation(list);
        }
        return formIsValid;
    }

    function validatephysical() {
        let formIsValid = false;
        if (typeof data["physical"] === "undefined") {
            formIsValid = false;
            seterrorphysical("*Select physical handicaped status.");
        } else {
            formIsValid = true;
            seterrorphysical("");
        }
        return formIsValid;
    }

    function validateForm() {
        let first_name = validatefirst_name();
        let phone_no = validatephone_no();
        let email = validateemail();
        //        let last_name = validatelast_name();
        let gender = validategender();
        let country = validatecountry();
        let state = validatestate();
        let city = validatecity();
        let birth_date = validatebirth_date();
        let marital_status = validatemarital_status();
        let languages = validatelanguages();
        let nationality = validatenationality();
        let career_level = validatecareer_level();
        let industry = validateindustry();
        let functional_area = validatefunctional_area();
        let current_salary = validatecurrent_salary();
        let expected_salary = validateexpected_salary();
        let salary_currency = validatesalary_currency();
        let immediate_available = validateimmediate_available();
        let address = validateaddress();
        let designation = validatedesignation();
        let pincode = validatepincode();
        let physical = validatephysical();

        let validexperiencetitle = true;
        for (var i = 0; i < experience.length; i++) {
            validexperiencetitle = validexperiencetitle && validateExperienceTitle(i);
        }
        let validexperiencecompany = true;
        for (var i = 0; i < experience.length; i++) {
            validexperiencecompany =
                validexperiencecompany && validateExperienceCompany(i);
        }
        let validexperiencestart_month = true;
        for (var i = 0; i < experience.length; i++) {
            validexperiencestart_month = validexperiencestart_month && validateExperienceStartMonth(i);
        }
        let validexperiencestart_year = true;
        for (var i = 0; i < experience.length; i++) {
            validexperiencestart_year = validexperiencestart_year && validateExperienceStartYear(i);
        }

        // let validexperienceleave_month = true;
        // for (var i = 0; i < experience.length; i++) {
        //     validexperienceleave_month = validexperienceleave_month && validateExperienceLeaveMonth(i);
        // }
        // let validexperienceleave_year = true;
        // for (var i = 0; i < experience.length; i++) {
        //     validexperienceleave_year = validexperienceleave_year && validateExperienceLeaveYear(i);
        // }

        let valideducationuniversity = true;
        for (var i = 0; i < education.length; i++) {
            valideducationuniversity =
                valideducationuniversity && validateEducationUniversity(i);
        }
        let valideducationstart = true;
        for (var i = 0; i < education.length; i++) {
            valideducationstart = valideducationstart && validateEducationStart(i);
        }
        let valideducationleave = true;
        for (var i = 0; i < education.length; i++) {
            valideducationleave = valideducationleave && validateEducationLeave(i);
        }

        let valid =
            first_name && phone_no && email &&
            // last_name &&
            gender &&
            country &&
            state &&
            city &&
            birth_date &&
            marital_status &&
            languages &&
            nationality &&
            career_level &&
            industry &&
            functional_area &&
            current_salary &&
            expected_salary &&
            salary_currency &&
            address &&
            validexperiencetitle &&
            validexperiencecompany &&
            validexperiencestart_month && validexperiencestart_year &&
            // validexperienceleave_month && validexperienceleave_year &&
            // validexperienceachieve &&
            valideducationuniversity &&
            valideducationstart &&
            valideducationleave && pincode && physical
            // valideducationachieve &&
            // designation
            ;
        return valid;
    }

    function submitForm(e) {
        e.preventDefault();
        if (validateForm()) {
            let formData
            formData = new FormData();
            formData.append("profile", selectedFile);
            formData.append("resume", selectedResume);
            formData.append("first_name", data.first_name === undefined ? '' : data.first_name);
            formData.append("last_name", data.last_name === undefined ? '' : data.last_name);
            formData.append("phone", data.phone === undefined ? '' : data.phone);
            formData.append("email", data.email === undefined ? '' : data.email);
            formData.append("gender", data.gender);
            formData.append("country", countryId);
            formData.append("state", stateId);
            formData.append("city", cityId);
            formData.append("birth_date", data.birth_date);
            formData.append("marital_status", data.marital_status);
            formData.append("physical", data.physical);
            formData.append("physical_desc", data.physical_desc);
            formData.append("skill", selectedskillstr);
            formData.append("languages", data.languages === undefined ? '' : data.languages);
            formData.append("nationality", data.nationality === undefined ? '' : data.nationality);
            formData.append("national_id_card", data.national_id_card === undefined ? '' : data.national_id_card);
            formData.append("career_level", data.career_level === undefined ? '' : data.career_level);
            formData.append("industry", data.industry);
            formData.append("functional_area", data.functional_area);
            formData.append("current_salary", data.current_salary);
            formData.append("expected_salary", data.expected_salary);
            formData.append("salary_currency", data.salary_currency);
            formData.append("notice_period", data.notice_period === undefined ? '' : data.notice_period);
            formData.append("address", data.address === undefined ? '' : data.address);
            formData.append("linkedin_url", data.linkedin_url === undefined ? '' : data.linkedin_url);
            formData.append("experience1", JSON.stringify(experience));
            formData.append("education", JSON.stringify(education));
            formData.append("designation", data.designation === undefined ? '' : data.designation);
            formData.append("pincode", data.pincode === undefined ? '' : data.pincode);
            formData.append("certificate", data.certificate);
            formData.append("editedby", "1");
            formData.append("work_status", data.work_status);
            formData.append("countries", data.countries);
            formData.append("exp_month", data.exp_month);
            formData.append("exp_year", data.exp_year);

            props.requestEditCandidateProfile({
                id: params.id,
                data: formData
            });
        }
    }

    useEffect(() => {
        let candidateprofile = props.data.editCandidate;
        console.log(props.data.editCandidate);
        if (candidateprofile !== undefined) {
            if (candidateprofile?.data?.status == "success") {
                Swal.fire("Good job!", "Profile updated Successfully.", "success");
                props.data.editCandidate = undefined;
                navigate(`/admin/search`);
            } else {
                Swal.fire("Error!", `${candidateprofile.data.message}`, "error");
                props.data.editCandidate = undefined;
                props.requestGetCandidate({
                    id: emp.id,
                    token: emp.token,
                });
            }
        }
    }, [props.data.editCandidate]);

    function onChangeSkill(e) {
        setselectedskill([]);
        setselectedskillstr("");
        e.map((option) => (
            selectedskill.push(option.key)
        ));
        setselectedskillstr(selectedskill.toString());
        setprevskill(e);
    }

    const handleInputExperience = (e, index) => {
        console.log('sdsds');
        const { name, value } = e.target;
        const list = [...experience];
        list[index][name] = value;
        setExperience(list);
    };
    const handleRemoveExperience = (index) => {
        const list = [...experience];
        list.splice(index, 1);
        setExperience(list);
        const errorlist = [...errorexperience];
        errorlist.splice(index, 1);
        setErrorexperience(errorlist);
    };

    const handleAddExperience = () => {
        let length = experience.length;
        if (length === 0) {
            setExperience([
                ...experience,
                {
                    title: "",
                    company: "",
                    start_month: "",
                    start_year: "",
                    leave_month: "",
                    leave_year: "",
                    achieve: "",
                },
            ]);
            setErrorexperience([
                ...errorexperience,
                {
                    title: "",
                    company: "",
                    start_month: "",
                    start_year: "",
                    leave_month: "",
                    leave_year: "",
                    achieve: "",
                },
            ]);
        } else if (
            experience[length - 1].title === "" ||
            experience[length - 1].company === "" ||
            experience[length - 1].start_month === "" ||
            experience[length - 1].start_year === "" ||
            experience[length - 1].leave_month === "" ||
            experience[length - 1].leave_year === "" ||
            experience[length - 1].achieve === ""
        ) {
            Swal.fire(
                "Error!",
                "After completing previous one, You can add new one.",
                "error"
            );
        } else {
            setExperience([
                ...experience,
                {
                    title: "",
                    company: "",
                    start_month: "",
                    start_year: "",
                    leave_month: "",
                    leave_year: "",
                    achieve: "",
                },
            ]);
            setErrorexperience([
                ...errorexperience,
                {
                    title: "",
                    company: "",
                    start_month: "",
                    start_year: "",
                    leave_month: "",
                    leave_year: "",
                    achieve: "",
                },
            ]);
        }
    };

    const handleInputEducation = (e, index) => {
        const { name, value } = e.target;
        const list = [...education];
        list[index][name] = value;
        setEducation(list);
    };
    const handleRemoveEducation = (index) => {
        const list = [...education];
        list.splice(index, 1);
        setEducation(list);
        const errorlist = [...erroreducation];
        errorlist.splice(index, 1);
        setErroreducation(errorlist);
    };
    const handleAddEducation = () => {
        let length = education.length;
        console.log(education);
        if (length === 0) {
            setEducation([
                ...education,
                {
                    degree: 0,
                    course: 0,
                    specialization: "",
                    university: "",
                    start: "",
                    leave: "",
                    achieve: "",
                },
            ]);
            setErroreducation([
                ...erroreducation,
                {
                    degree: 0,
                    course: 0,
                    specialization: "",
                    university: "",
                    start: "",
                    leave: "",
                    achieve: "",
                },
            ]);
        } else if (
            education[length - 1].degree === 0 || education[length - 1].course === 0 || education[length - 1].specialization === "" ||
            education[length - 1].university === "" ||
            education[length - 1].start === "" ||
            education[length - 1].leave === "" ||
            education[length - 1].achieve === ""
        ) {
            Swal.fire(
                "Error!",
                "After completing previous one, You can add new one.",
                "error"
            );
        } else {
            setEducation([
                ...education,
                {
                    degree: 0,
                    course: 0,
                    specialization: "",
                    university: "",
                    start: "",
                    leave: "",
                    achieve: "",
                },
            ]);
            setErroreducation([
                ...erroreducation,
                {
                    degree: 0,
                    course: 0,
                    specialization: "",
                    university: "",
                    start: "",
                    leave: "",
                    achieve: "",
                },
            ]);
        }
    };

    function onChangeDegree(e) {
        console.log(e.target.value);
        setdegree(e.target.value);
        let length = education.length;
        education[length - 1].degree = e.target.value;

        axios.get('https://yourdomain/api/courses/' + e.target.value)
            .then(res => {
                setcourses(res.data.data.courses);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function onChangeCourse(e) {
        console.log(e.target.value);
        setcourse(e.target.value);
        let length = education.length;
        education[length - 1].course = e.target.value;

        axios.get('https://yourdomain/api/specializations/' + e.target.value)
            .then(res => {
                setspecializations(res.data.data.specializations);
            })
            .catch(err => {
                console.log(err);
            })
    }

    function onChangeSpecialization(e) {
        setspecialization(e.target.value);
        let length = education.length;
        education[length - 1].specialization = e.target.value;
    }

    const onSelectFile = (e) => {
        console.log(e.target.files[0]);
        setimg(URL.createObjectURL(e.target.files[0]));
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined);
        } else {
            setSelectedFile(e.target.files[0]);
        }
    };

    const onClearImage = (e) => {
        setimg();
    }

    const onSelectResume = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedResume(undefined);
        } else {
            setSelectedResume(e.target.files[0]);
            setresume_path(e.target.files[0].name);
        }
    };

    return (
        <>
            <div class="container-scroller">
                <Header name="Candidate" />
                <div class="container-fluid page-body-wrapper">
                    <div class="main-panel">
                        <div class="content-wrapper">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="home-tab">
                                        <div class="row flex-grow">
                                            <div class="col-12 grid-margin stretch-card">
                                                <div class="card card-rounded">
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-6 form-group">
                                                                <label>Profile Picture &nbsp;&nbsp;</label>
                                                                <br />
                                                                <br />
                                                                <div
                                                                    style={{
                                                                        position: "relative",
                                                                        left: "0px",
                                                                        top: "0px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={img}
                                                                        width="250"
                                                                        style={{
                                                                            position: "relative",
                                                                            top: "0px",
                                                                            border: "2px solid black",
                                                                            borderRadius: "25px",
                                                                        }}
                                                                    />

                                                                    <label for="file-input">
                                                                        <img
                                                                            src={camera}
                                                                            height="35"
                                                                            width="auto"
                                                                            style={{
                                                                                position: "absolute",
                                                                                top: "-12px",
                                                                                left: "230px",
                                                                                backgroundColor: "white",
                                                                                borderRadius: "25px",
                                                                                border: "1px solid black",
                                                                                padding: "3px",
                                                                            }}
                                                                        />
                                                                    </label>
                                                                    <button id="profile_close" onClick={onClearImage}
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "25px", left: "230px",
                                                                            backgroundColor: "white",
                                                                            borderRadius: "25px", border: "1px solid black",
                                                                            padding: "3px", height: "35px", width: "35px",
                                                                        }}
                                                                    ><i class="fa fa-times"></i></button>
                                                                    <input
                                                                        id="file-input"
                                                                        type="file"
                                                                        style={{ display: "none" }}
                                                                        name="profile"
                                                                        onChange={onSelectFile}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>Select Resume &nbsp;&nbsp;</label>
                                                                <br />
                                                                <div
                                                                    style={{ position: "relative", left: "0px", top: "0px", }}>
                                                                    <button class="btn btn-primary me-2" onClick={openResume}
                                                                        style={{
                                                                            color: "white",
                                                                            width: "150px",
                                                                            height: "50px",
                                                                        }}>Upload Resume</button>
                                                                    <input
                                                                        id="file-resume"
                                                                        type="file" name="resume" style={{ display: "none" }}
                                                                        onChange={onSelectResume} ref={resumeFile}
                                                                    />
                                                                </div>
                                                                <label>{resume_path} &nbsp;&nbsp;</label>
                                                                <button id="resume_close" onClick={onClearResume}
                                                                    style={{
                                                                        position: "absolute",
                                                                        backgroundColor: "white",
                                                                        borderRadius: "25px", border: "1px solid black",
                                                                        padding: "3px", height: "35px", width: "35px",
                                                                    }}
                                                                ><i class="fa fa-times"></i></button>
                                                            </div>
                                                            <h3 class="title">Basic Information</h3>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>First Name*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="first_name"
                                                                        value={data.first_name}
                                                                        onBlur={validatefirst_name}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errorfirst_name && (
                                                                        <div style={mystyle}>{errorfirst_name}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Last Name*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="last_name"
                                                                        value={data.last_name}
                                                                        // onBlur={validatelast_name}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {/* {errorlast_name && (
                                                                        <div style={mystyle}>{errorlast_name}</div>
                                                                    )} */}
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3 col-md-3">
                                                                <div style={{ color: "black" }}>
                                                                    <label for="gender" class="label">
                                                                        Gender
                                                                    </label>

                                                                    <br />
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            style={{ margin: "0px" }}
                                                                            id="male"
                                                                            name="gender"
                                                                            value="1"
                                                                            onBlur={validategender}
                                                                            checked={data.gender === 1}
                                                                            onChange={onChangeData}
                                                                        />
                                                                        <label class="form-check-label">Male</label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            style={{ margin: "0px" }}
                                                                            id="female"
                                                                            name="gender"
                                                                            value="0"
                                                                            onBlur={validategender}
                                                                            checked={data.gender === 0}
                                                                            onChange={onChangeData}
                                                                        />
                                                                        <label class="form-check-label">Female</label>
                                                                    </div>
                                                                    {errorgender && (
                                                                        <div style={mystyle}>{errorgender}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Phone No*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="phone"
                                                                        value={data.phone}
                                                                        onBlur={validatephone_no}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errorphone_no && (
                                                                        <div style={mystyle}>{errorphone_no}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Email*</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="email"
                                                                        value={data.email}
                                                                        onBlur={validateemail}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {erroremail && (
                                                                        <div style={mystyle}>{erroremail}</div>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-3 col-md-3">
                                                                <div style={{ color: "black" }}>
                                                                    <label for="marital_status" class="label">
                                                                        Marital Status
                                                                    </label>

                                                                    <br />
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            style={{ margin: "0px" }}
                                                                            id="married"
                                                                            name="marital_status"
                                                                            value="2"
                                                                            onBlur={validatemarital_status}
                                                                            checked={data.marital_status === 2}
                                                                            onChange={onChangeData}
                                                                        />
                                                                        <label class="form-check-label">
                                                                            Married
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            style={{ margin: "0px" }}
                                                                            id="unmarried"
                                                                            name="marital_status"
                                                                            value="4"
                                                                            onBlur={validatemarital_status}
                                                                            checked={data.marital_status === 4}
                                                                            onChange={onChangeData}
                                                                        />
                                                                        <label class="form-check-label">
                                                                            Unmarried
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            style={{ margin: "0px" }}
                                                                            id="divorced"
                                                                            name="marital_status"
                                                                            value="1"
                                                                            onBlur={validatemarital_status}
                                                                            checked={data.marital_status === 1}
                                                                            onChange={onChangeData}
                                                                        />
                                                                        <label class="form-check-label">
                                                                            Divorced
                                                                        </label>
                                                                    </div>
                                                                    {errormarital_status && (
                                                                        <div style={mystyle}>
                                                                            {errormarital_status}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Notice Period *</label>
                                                                    <select class="form-select" name="notice_period"
                                                                        value={data.notice_period}
                                                                        onBlur={validatenotice_period}
                                                                        onChange={onChangeData}>
                                                                        <option>Select Notice Period</option>
                                                                        <option value="Immediate">Immediate</option>
                                                                        <option value="Serving Notice Period">Serving Notice Period</option>
                                                                        <option value="8 Days">8 Days</option>
                                                                        <option value="15 Days">15 Days</option>
                                                                        <option value="30 Days">30 Days</option>
                                                                        <option value="45 Days">45 Days</option>
                                                                        <option value="60 Days">60 Days</option>
                                                                        <option value="90 Days">90 Days</option>
                                                                    </select>
                                                                </div>
                                                                {errorimmediate_available && (
                                                                    <div style={mystyle}>
                                                                        {errorimmediate_available}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>
                                                                        Date of Birth
                                                                    </label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="date"
                                                                        name="birth_date"
                                                                        value={data.birth_date}
                                                                        onBlur={validatebirth_date}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errorbirth_date && (
                                                                        <div style={mystyle}>{errorbirth_date}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div style={{ color: "black" }}>
                                                                        <label for="physical" class="label">
                                                                            Physically Handicaped?
                                                                        </label>

                                                                        <br />
                                                                        <div class="form-check form-check-inline">
                                                                            <input
                                                                                class="form-check-input"
                                                                                type="radio"
                                                                                style={{ margin: "0px" }}
                                                                                id="physical"
                                                                                name="physical"
                                                                                value="1"
                                                                                onBlur={validatephysical}
                                                                                checked={data.physical === 1}
                                                                                onChange={onChangeData}
                                                                            />
                                                                            <label class="form-check-label">
                                                                                Yes
                                                                            </label>
                                                                        </div>
                                                                        <div class="form-check form-check-inline">
                                                                            <input
                                                                                class="form-check-input"
                                                                                type="radio"
                                                                                style={{ margin: "0px" }}
                                                                                id="non_physical"
                                                                                name="physical"
                                                                                value="0"
                                                                                onBlur={validatephysical}
                                                                                checked={data.physical === 0}
                                                                                onChange={onChangeData}
                                                                            />
                                                                            <label class="form-check-label">
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                        {errorphysical && (
                                                                            <div style={mystyle}>
                                                                                {errorphysical}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 col-md-6">
                                                                    <div class="form-group">
                                                                        <label>Physical Handicaped Description</label>
                                                                        <input
                                                                            class="form-control"
                                                                            type="text"
                                                                            name="physical_desc"
                                                                            value={data.physical_desc}
                                                                            onChange={onChangeData}
                                                                            placeholder=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <h3 class="title">Detail Information</h3>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Skill*</label>
                                                                    <Select
                                                                        className="basic-multi-select" classNamePrefix="select" name="skill"
                                                                        options={skill.map((option) => { return { label: option.name, value: option.name, key: option.id } })}
                                                                        value={prevskill}
                                                                        onChange={onChangeSkill} isMulti></Select>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Languages</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="languages"
                                                                        value={data.languages}
                                                                        onBlur={validatelanguages}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errorlanguages && (
                                                                        <div style={mystyle}>{errorlanguages}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Naionality</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="nationality"
                                                                        value={data.nationality}
                                                                        onBlur={validatenationality}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errornationality && (
                                                                        <div style={mystyle}>{errornationality}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>National ID Card</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="national_id_card"
                                                                        value={data.national_id_card}
                                                                        // onBlur={validatenational_id_card}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Work Status</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="work_status"
                                                                        value={data.work_status}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Prefered countries</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="countries"
                                                                        value={data.countries}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Total Experience:</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Years</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="number"
                                                                        name="exp_year"
                                                                        value={data.exp_year}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-3">
                                                                <div class="form-group">
                                                                    <label>Months</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="exp_month"
                                                                        value={data.exp_month}
                                                                        onChange={onChangeData}>
                                                                        <option value="0">0</option>
                                                                        <option value="1">1</option>
                                                                        <option value="2">2</option>
                                                                        <option value="3">3</option>
                                                                        <option value="4">4</option>
                                                                        <option value="5">5</option>
                                                                        <option value="6">6</option>
                                                                        <option value="7">7</option>
                                                                        <option value="8">8</option>
                                                                        <option value="9">9</option>
                                                                        <option value="10">10</option>
                                                                        <option value="11">11</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <h3 class="title">Industry Information</h3>

                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Career Level</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="career_level"
                                                                        value={data.career_level}
                                                                        onBlur={validatecareer_level}
                                                                        onChange={onChangeData}>
                                                                        <option value="0">Select Career Level</option>
                                                                        {career_level.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.level}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorcareer_level && (
                                                                        <div style={mystyle}>{errorcareer_level}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Industry</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="industry"
                                                                        value={data.industry}
                                                                        onBlur={validateindustry}
                                                                        onChange={onChangeData}
                                                                    >
                                                                        <option value="0">Select Industry</option>
                                                                        {industry.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorindustry && (
                                                                        <div style={mystyle}>{errorindustry}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Functional Area</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="functional_area"
                                                                        value={data.functional_area}
                                                                        onBlur={validatefunctional_area}
                                                                        onChange={onChangeData}
                                                                    >
                                                                        <option value="0">
                                                                            Select Functional Area
                                                                        </option>
                                                                        {functional_area.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorfunctional_area && (
                                                                        <div style={mystyle}>
                                                                            {errorfunctional_area}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Designation</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="designation"
                                                                        value={data.designation}
                                                                        // onBlur={validatedesignation}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {/* {errordesignation && (
                                                                        <div style={mystyle}>{errordesignation}</div>
                                                                    )} */}
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Current Salary Per Year</label>
                                                                        <input
                                                                            class="form-control"
                                                                            type="number"
                                                                            name="current_salary"
                                                                            value={data.current_salary}
                                                                            onBlur={validatecurrent_salary}
                                                                            onChange={onChangeData}
                                                                            placeholder=""
                                                                        />
                                                                        {errorcurrent_salary && (
                                                                            <div style={mystyle}>
                                                                                {errorcurrent_salary}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Expected Salary Per Year</label>
                                                                        <input
                                                                            class="form-control"
                                                                            type="number"
                                                                            name="expected_salary"
                                                                            value={data.expected_salary}
                                                                            onBlur={validateexpected_salary}
                                                                            onChange={onChangeData}
                                                                            placeholder=""
                                                                        />
                                                                        {errorexpected_salary && (
                                                                            <div style={mystyle}>
                                                                                {errorexpected_salary}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-4 col-md-4">
                                                                    <div class="form-group">
                                                                        <label>Salary Currency</label>
                                                                        <select
                                                                            class="form-select"
                                                                            name="salary_currency"
                                                                            value={data.salary_currency}
                                                                            onBlur={validatesalary_currency}
                                                                            onChange={onChangeData}
                                                                        >
                                                                            <option value="0">Select Currency</option>
                                                                            {currency.map((option) => (
                                                                                <option value={option.id}>
                                                                                    {option.currency_icon}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {errorsalary_currency && (
                                                                            <div style={mystyle}>
                                                                                {errorsalary_currency}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="single-section exprerience">
                                                                <h4>
                                                                    Work Exprerience
                                                                    <button
                                                                        onClick={handleAddExperience}
                                                                        style={{
                                                                            backgroundColor: "transparent",
                                                                            border: "1px solid green",
                                                                            padding: "4px 8px",
                                                                            float: "right",
                                                                        }}
                                                                    >
                                                                        <i class="fa fa-plus" style={{ color: "green" }}></i>
                                                                    </button>
                                                                </h4>

                                                                {experience.map((x, i) => {
                                                                    return (
                                                                        <div
                                                                            class="row "
                                                                            style={{
                                                                                // boxShadow: "2px 4px 8px 4px rgba(0,0,0,0.2)",
                                                                                backgroundColor: "#FAF9F6",
                                                                                transition: "0.3s",
                                                                                padding: "20px",
                                                                                borderRadius: "15px",
                                                                                marginTop: "20px",
                                                                            }}
                                                                        >
                                                                            <div
                                                                                class="col-lg-12 col-md-12"
                                                                                style={{ marginBottom: "5px", color: "black" }}
                                                                            >
                                                                                <b>Work Experience : {i + 1}</b>
                                                                                <button
                                                                                    onClick={() => handleRemoveExperience(i)}
                                                                                    style={{
                                                                                        backgroundColor: "transparent",
                                                                                        border: "1px solid red",
                                                                                        padding: "4px 8px",
                                                                                        float: "right",
                                                                                    }}
                                                                                >
                                                                                    <i
                                                                                        class="fa fa-trash"
                                                                                        style={{ color: "red" }}
                                                                                    ></i>
                                                                                </button>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-6 col-md-6"
                                                                                style={{ padding: "5px" }}
                                                                            >
                                                                                <div class="form-group">
                                                                                    <label>Job Title</label>
                                                                                    <input
                                                                                        class="form-control"
                                                                                        type="text"
                                                                                        name="title"
                                                                                        value={x.title}
                                                                                        onBlur={() => validateExperienceTitle(i)}
                                                                                        onChange={(e) => handleInputExperience(e, i)}
                                                                                        placeholder=""
                                                                                    />
                                                                                    {errorexperience[i].title && (
                                                                                        <div style={mystyle}>
                                                                                            {errorexperience[i].title}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-6 col-md-6"
                                                                                style={{ padding: "5px" }}
                                                                            >
                                                                                <div class="form-group">
                                                                                    <label>Company Name</label>
                                                                                    <input
                                                                                        class="form-control"
                                                                                        type="text"
                                                                                        name="company"
                                                                                        value={x.company}
                                                                                        onBlur={() => validateExperienceCompany(i)}
                                                                                        onChange={(e) => handleInputExperience(e, i)}
                                                                                        placeholder=""
                                                                                    />
                                                                                    {errorexperience[i].company && (
                                                                                        <div style={mystyle}>
                                                                                            {errorexperience[i].company}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                class="col-lg-6 col-md-6"
                                                                                style={{ padding: "5px" }}
                                                                            >
                                                                                <div class="form-group">
                                                                                    <label>Starting Time</label>
                                                                                    <div class="row">
                                                                                        <div class="col-lg-6 col-md-6">
                                                                                            <select
                                                                                                class="form-select"
                                                                                                name="start_month"
                                                                                                value={x.start_month}
                                                                                                onBlur={() => validateExperienceStartMonth(i)}
                                                                                                onChange={(e) => handleInputExperience(e, i)}>
                                                                                                <option value="0"></option>
                                                                                                <option value="Jan">Jan</option>
                                                                                                <option value="Feb">Feb</option>
                                                                                                <option value="Mar">Mar</option>
                                                                                                <option value="Apr">Apr</option>
                                                                                                <option value="May">May</option>
                                                                                                <option value="Jun">Jun</option>
                                                                                                <option value="Jul">Jul</option>
                                                                                                <option value="Aug">Aug</option>
                                                                                                <option value="Sep">Sep</option>
                                                                                                <option value="Oct">Oct</option>
                                                                                                <option value="Nov">Nov</option>
                                                                                                <option value="Dec">Dec</option>
                                                                                            </select>
                                                                                            {errorexperience[i].start_month && (
                                                                                                <div style={mystyle}>
                                                                                                    {errorexperience[i].start_month}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <div class="col-lg-6 col-md-6">
                                                                                            <select
                                                                                                class="form-select"
                                                                                                name="start_year"
                                                                                                value={x.start_year}
                                                                                                onBlur={() => validateExperienceStartYear(i)}
                                                                                                onChange={(e) => handleInputExperience(e, i)}
                                                                                            >
                                                                                                <option value="0"></option>
                                                                                                {
                                                                                                    years.map((year, index) => {
                                                                                                        return <option key={`year${index}`} value={year}>{year}</option>
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                            {errorexperience[i].start_year && (
                                                                                                <div style={mystyle}>
                                                                                                    {errorexperience[i].start_year}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="col-lg-6 col-md-6"
                                                                                style={{ padding: "5px" }}
                                                                            >
                                                                                <div class="form-group">
                                                                                    <label>Leaving Time</label>
                                                                                    <div class="row">
                                                                                        <div class="col-lg-6 col-md-6">
                                                                                            <select
                                                                                                class="form-select"
                                                                                                name="leave_month"
                                                                                                value={x.leave_month}
                                                                                                // onBlur={() => validateExperienceLeaveMonth(i)}
                                                                                                onChange={(e) => handleInputExperience(e, i)}>
                                                                                                <option value="0">Select Leave Month</option>
                                                                                                <option value="Jan">Jan</option>
                                                                                                <option value="Feb">Feb</option>
                                                                                                <option value="Mar">Mar</option>
                                                                                                <option value="Apr">Apr</option>
                                                                                                <option value="May">May</option>
                                                                                                <option value="Jun">Jun</option>
                                                                                                <option value="Jul">Jul</option>
                                                                                                <option value="Aug">Aug</option>
                                                                                                <option value="Sep">Sep</option>
                                                                                                <option value="Oct">Oct</option>
                                                                                                <option value="Nov">Nov</option>
                                                                                                <option value="Dec">Dec</option>
                                                                                            </select>
                                                                                            {/* {errorexperience[i].leave_month && (
                                                                                                <div style={mystyle}>
                                                                                                    {errorexperience[i].leave_month}
                                                                                                </div>
                                                                                            )} */}
                                                                                        </div>
                                                                                        <div class="col-lg-6 col-md-6">
                                                                                            <select
                                                                                                class="form-select"
                                                                                                name="leave_year"
                                                                                                value={x.leave_year}
                                                                                                onBlur={() => validateExperienceLeaveYear(i)}
                                                                                                onChange={(e) => handleInputExperience(e, i)}
                                                                                            >
                                                                                                <option value="0">Select Leave Year</option>
                                                                                                {
                                                                                                    years.map((year, index) => {
                                                                                                        return <option key={`year${index}`} value={year}>{year}</option>
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                            {errorexperience[i].leave_year && (
                                                                                                <div style={mystyle}>
                                                                                                    {errorexperience[i].leave_year}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            <div
                                                                                class="col-lg-12 col-md-12"
                                                                                style={{ padding: "5px" }}
                                                                            >
                                                                                <div class="form-group">
                                                                                    <label>Achievement in this Role</label>
                                                                                    <textarea
                                                                                        class="form-control"
                                                                                        rows="5"
                                                                                        name="achieve"
                                                                                        style={{
                                                                                            whiteSpace: "pre-wrap",
                                                                                            fontFamily: "Inter",
                                                                                            fontWeight: "normal",
                                                                                            fontStyle: "normal",
                                                                                            color: "#7E8890",
                                                                                            fontSize: "14px",
                                                                                            lineHeight: "18px",
                                                                                            height: "100px"
                                                                                        }}
                                                                                        value={x.achieve}
                                                                                        onChange={(e) => handleInputExperience(e, i)}
                                                                                        // onBlur={() => validateExperienceAchieve(i)}
                                                                                        placeholder=""
                                                                                    ></textarea>
                                                                                    {errorexperience[i].achieve && (
                                                                                        <div style={mystyle}>
                                                                                            {errorexperience[i].achieve}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <div class="row">
                                                                <div class="single-section education">
                                                                    <h4 style={{ marginTop: "24px" }}>
                                                                        Education
                                                                        <button
                                                                            onClick={handleAddEducation}
                                                                            style={{
                                                                                backgroundColor: "transparent",
                                                                                border: "1px solid green",
                                                                                padding: "4px 8px",
                                                                                float: "right",
                                                                            }}
                                                                        >
                                                                            <i class="fa fa-plus" style={{ color: "green" }}></i>
                                                                        </button>
                                                                    </h4>

                                                                    {education.map((x, i) => {
                                                                        return (
                                                                            <div
                                                                                class="row"
                                                                                style={{
                                                                                    // boxShadow: "1px 2px 4px 2px rgba(0,0,0,0.2)",
                                                                                    backgroundColor: "#FAF9F6",
                                                                                    transition: "0.3s",
                                                                                    padding: "20px",
                                                                                    borderRadius: "15px",
                                                                                    marginTop: "20px",
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    class="col-lg-12 col-md-12"
                                                                                    style={{ marginBottom: "5px", color: "black" }}
                                                                                >
                                                                                    <b>Education : {i + 1}</b>
                                                                                    <button
                                                                                        onClick={() => handleRemoveEducation(i)}
                                                                                        style={{
                                                                                            backgroundColor: "transparent",
                                                                                            border: "1px solid red",
                                                                                            padding: "4px 8px",
                                                                                            float: "right",
                                                                                        }}
                                                                                    >
                                                                                        <i
                                                                                            class="fa fa-trash"
                                                                                            style={{ color: "red" }}
                                                                                        ></i>
                                                                                    </button>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-lg-4 col-md-4">
                                                                                        <div class="form-group">
                                                                                            <label>Degree Level</label>
                                                                                            <select
                                                                                                class="form-select"
                                                                                                name="degree"
                                                                                                value={x.degree}
                                                                                                onChange={onChangeDegree}
                                                                                            >
                                                                                                <option value="0">Select Degree</option>
                                                                                                {degree_levels.map((option) => (
                                                                                                    <option value={option.id}>
                                                                                                        {option.level}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-4 col-md-4">
                                                                                        <div class="form-group">
                                                                                            <label>Course</label>
                                                                                            <select
                                                                                                class="form-select" name="course"
                                                                                                value={x.course}
                                                                                                onChange={onChangeCourse}>
                                                                                                <option value="0">Select Course</option>
                                                                                                {courses.map((option) => (
                                                                                                    <option value={option.id}>
                                                                                                        {option.name}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-4 col-md-4">
                                                                                        <div class="form-group">
                                                                                            <label>Specialization or Option</label>
                                                                                            <select
                                                                                                class="form-select" name="course"
                                                                                                value={x.specialization}
                                                                                                onChange={onChangeSpecialization}>
                                                                                                <option value="0">Select Specialization</option>
                                                                                                {specializations.map((option) => (
                                                                                                    <option value={option.id}>
                                                                                                        {option.name}
                                                                                                    </option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div
                                                                                    class="col-lg-6 col-md-6"
                                                                                    style={{ padding: "5px" }}
                                                                                >
                                                                                    <div class="form-group">
                                                                                        <label>University Name</label>
                                                                                        <input
                                                                                            class="form-control"
                                                                                            type="text"
                                                                                            name="university"
                                                                                            value={x.university}
                                                                                            onBlur={() => validateEducationUniversity(i)}
                                                                                            onChange={(e) => handleInputEducation(e, i)}
                                                                                            placeholder=""
                                                                                        />
                                                                                        {erroreducation[i].university && (
                                                                                            <div style={mystyle}>
                                                                                                {erroreducation[i].university}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-lg-3 col-md-3"
                                                                                    style={{ padding: "5px" }}
                                                                                >
                                                                                    <div class="form-group">
                                                                                        <label>Starting Year</label>
                                                                                        <select
                                                                                            class="form-select"
                                                                                            name="start"
                                                                                            value={x.start}
                                                                                            onBlur={() => validateEducationStart(i)}
                                                                                            onChange={(e) => handleInputEducation(e, i)}
                                                                                        >
                                                                                            <option value="0">Select Start Year</option>
                                                                                            {
                                                                                                years.map((year, index) => {
                                                                                                    return <option key={`year${index}`} value={year}>{year}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        {erroreducation[i].start && (
                                                                                            <div style={mystyle}>
                                                                                                {erroreducation[i].start}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-lg-3 col-md-3"
                                                                                    style={{ padding: "5px" }}
                                                                                >
                                                                                    <div class="form-group">
                                                                                        <label>Leaving Year</label>
                                                                                        <select
                                                                                            class="form-select"
                                                                                            name="leave"
                                                                                            value={x.leave}
                                                                                            onBlur={() => validateEducationLeave(i)}
                                                                                            onChange={(e) => handleInputEducation(e, i)}
                                                                                        >
                                                                                            <option value="0">Select Leave Year</option>
                                                                                            {
                                                                                                years.map((year, index) => {
                                                                                                    return <option key={`year${index}`} value={year}>{year}</option>
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                        {erroreducation[i].leave && (
                                                                                            <div style={mystyle}>
                                                                                                {erroreducation[i].leave}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    class="col-lg-12 col-md-12"
                                                                                    style={{ padding: "5px" }}
                                                                                >
                                                                                    <div class="form-group">
                                                                                        <label>Achievement in this Degree</label>
                                                                                        <textarea
                                                                                            class="form-control"
                                                                                            rows="5"
                                                                                            name="achieve"
                                                                                            style={{
                                                                                                whiteSpace: "pre-wrap",
                                                                                                fontFamily: "Inter",
                                                                                                fontWeight: "normal",
                                                                                                fontStyle: "normal",
                                                                                                color: "#7E8890",
                                                                                                fontSize: "14px",
                                                                                                lineHeight: "18px",
                                                                                                height: "100px"
                                                                                            }}
                                                                                            value={x.achieve}
                                                                                            onChange={(e) => handleInputEducation(e, i)}
                                                                                            // onBlur={() => validateEducationAchieve(i)}
                                                                                            placeholder=""
                                                                                        ></textarea>
                                                                                        {/* {erroreducation[i].achieve && (
                                                                                            <div style={mystyle}>
                                                                                                {erroreducation[i].achieve}
                                                                                            </div>
                                                                                        )} */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <h3 class="title">Certification if any</h3>
                                                            <div class="row">
                                                                <textarea
                                                                    class="form-control"
                                                                    rows="5"
                                                                    name="certificate"
                                                                    style={{
                                                                        whiteSpace: "pre-wrap",
                                                                        fontFamily: "Inter",
                                                                        fontWeight: "normal",
                                                                        fontStyle: "normal",
                                                                        color: "#7E8890",
                                                                        fontSize: "14px",
                                                                        lineHeight: "18px",
                                                                        height: "100px"
                                                                    }}
                                                                    value={data.certificate}
                                                                    onChange={onChangeData}
                                                                    placeholder=""
                                                                ></textarea>
                                                            </div>

                                                            <br />
                                                            <h3 class="title">Address Information</h3>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>Country</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="country"
                                                                        value={countryId}
                                                                        onBlur={validatecountry}
                                                                        onChange={onChangeCountry}
                                                                    >
                                                                        <option value="0">Select Country</option>
                                                                        {countries.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorcountry && (
                                                                        <div style={mystyle}>{errorcountry}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>State</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="state"
                                                                        value={stateId}
                                                                        onBlur={validatestate}
                                                                        onChange={onChangeState}
                                                                    >
                                                                        <option value="0">Select State</option>
                                                                        {states.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorstate && (
                                                                        <div style={mystyle}>{errorstate}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-4 col-md-4">
                                                                <div class="form-group">
                                                                    <label>City</label>
                                                                    <select
                                                                        class="form-select"
                                                                        name="city"
                                                                        value={cityId}
                                                                        onBlur={validatecity}
                                                                        onChange={onChangeCity}
                                                                    >
                                                                        <option value="0">Select City</option>
                                                                        {cities.map((option) => (
                                                                            <option value={option.id}>
                                                                                {option.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    {errorcity && (
                                                                        <div style={mystyle}>{errorcity}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Address</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="address"
                                                                        value={data.address}
                                                                        onBlur={validateaddress}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {erroraddress && (
                                                                        <div style={mystyle}>{erroraddress}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>pincode</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="pincode"
                                                                        value={data.pincode}
                                                                        onBlur={validatepincode}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                    {errorpincode && (
                                                                        <div style={mystyle}>{errorpincode}</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <h3 class="title">Social Links</h3>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label>Linkedin URL</label>
                                                                    <input
                                                                        class="form-control"
                                                                        type="text"
                                                                        name="linkedin_url"
                                                                        value={data.linkedin_url}
                                                                        // onBlur={validatelinkedin_url}
                                                                        onChange={onChangeData}
                                                                        placeholder=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            class="btn btn-primary me-2"
                                                            style={{
                                                                color: "white",
                                                                width: "150px",
                                                                height: "50px",
                                                            }}
                                                            onClick={submitForm}
                                                        >
                                                            Save
                                                        </button>
                                                        <a href={`/admin/search`} title="Cancel Adding Candidate">
                                                            <button
                                                                class="btn btn-light"
                                                                style={{ border: "0px" }}>
                                                                {/* <i class="fa fa-pencil-square-o"></i> */}
                                                                Cancel
                                                            </button>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return { data: state.data, candidate: state.candidate };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            requestFormFieldCand,
            requestEditCandidateProfile,
            requestCountry,
            requestState,
            requestCity,
            requestGetCandidate,
            requestAdminCandidateDetails
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CandidateEdit);