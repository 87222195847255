import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestCandSearch, requestFilteredCandidates, requestFilteredCandidatesFirst, requestCandAssignJob, 
  requestPendingJobsByEmployer, requestImportFromResume } from "../Redux/actions";
import image from "../images/profile.png";
import axios from 'axios';
import Form from "react-bootstrap/Form";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import DataTable from 'react-data-table-component';
import * as ReactBootStrap from "react-bootstrap";
// import { Checkbox } from "@mui/material";
const STATUS_IDLE = 0
const STATUS_UPLOADING = 1

function Search(props) {
  const params = useParams();
  const [candidates, setcandidates] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState(1);
  const [change, setchange] = useState(false);
  const [page_size, setpage_size] = useState(10);
  const [pending, setPending] = useState(true);
  const [selectedFile, setselectedFile] = useState([]);

  const [prospect_show, setprospect_show] = useState(false);
  const [applied_show, setapplied_show] = useState(false);
  const [lead_show, setlead_show] = useState(false);
  const [shortlist_show, setshortlist_show] = useState(false);
  const [interview_show, setinterview_show] = useState(false);
  const [offer_show, setoffer_show] = useState(false);
  const [rejected_show, setrejected_show] = useState(false);
  const [feedback_show, setfeedback_show] = useState(false);
  const [starrating_show, setstarrating_show] = useState(false);
  const [activity_show, setactivity_show] = useState(false);
  const [processme_show, setprocessme_show] = useState(false);
  const [processanyone_show, setprocessanyone_show] = useState(false);
  const [applicationdate_show, setapplicationdate_show] = useState(false);
  const [interviewdate_show, setinterviewdate_show] = useState(false);
  const [joiningdate_show, setjoiningdate_show] = useState(false);
  const [lastmodified_show, setlastmodified_show] = useState(false);
  const [location_show, setlocation_show] = useState(false);
  const [company_show, setcompany_show] = useState(false);
  const [funcarea_show, setfuncarea_show] = useState(false);
  const [role_show, setrole_show] = useState(false);
  const [industry_show, setindustry_show] = useState(false);
  const [ctc_show, setctc_show] = useState(false);
  const [experience_show, setexperience_show] = useState(false);
  const [keyskills_show, setkeyskills_show] = useState(false);
  const [institutes_show, setinstitutes_show] = useState(false);
  const [courses_show, setcourses_show] = useState(false);
  const [reqdoc_show, setreqdoc_show] = useState(false);
  const [comments_show, setcomments_show] = useState(false);
  const [employer_show, setemployer_show] = useState(false);
  const [empcontact_show, setempcontact_show] = useState(false);
  const [source_show, setsource_show] = useState(false);
  const [addedby_show, setaddedby_show] = useState(false);
  const [notice_show, setnotice_show] = useState(false);
  const [filter_show, setfilter_show] = useState(false);

  const [locations, setlocations] = useState([]);
  const [companies, setcompanies] = useState([]);
  const [functional_areas, setfunctional_areas] = useState([]);
  const [roles, setroles] = useState([]);
  const [industries, setindustries] = useState([]);
  const [keyskills, setkeyskills] = useState([]);
  const [institutes, setinstitutes] = useState([]);
  const [courses, setcourses] = useState([]);
  const [employers, setemployers] = useState([]);
  const [employer, setemployer] = useState(0);
  const [jobs, setjobs] = useState([]);
  const [job, setjob] = useState(0);
  const [sources, setsources] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState([{ name: "", value: "" }]);
  const [jobassignmodal, setjobassignModal] = useState(false);
  const [jobassigndata, setjobassigndata] = useState({ jobassign: 0 });
  const [selectedCand, setSelectedCand] = useState([{ id: "" }]);

  const [jobAppliedModal, setJobAppliedModal] = useState(false);
  const [joblist, setjoblist] = useState([]);
  const [candidate_id, setcandidate_id] = useState(0);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const inputFile = useRef();
  const import_sample = useRef();
  const upload_file = useRef();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [logindetails, setLoginDetails] = useState([]);
  const [profile_updates, setProfileUpdates] = useState(0);
  const [profile_new, setProfileNew] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uid, setUID] = useState('0');
  const [checked_all, setCheckedAll] = useState(false);
  const [checked_one, setCheckedOne] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState(STATUS_IDLE);
  const dv_import_multiple = useRef();

  const renderFileList = () => (<ol>
    {[...files].map((f, i) => (
      <li key={i}>{f.name} - {f.type}</li>
    ))}
  </ol>)

  const getButtonStatusText = () => (
    (status === STATUS_IDLE) ?
      'Import' :
      'Please Wait'
  )

  const uploadFiles = (data) => {
    setStatus(STATUS_UPLOADING);
    // const formData = new FormData();

    // // Update the formData object
    // formData.append("profile",files);

    // // Details of the uploaded file
    // console.log(files);
      }

//  useEffect(() => { let formfieldData = props.data.importResumeData; }, [props.data.importResumeData]);

  const packFiles = (files) => {
    const data = new FormData();
    [...files].forEach((file, i) => {
      data.append(`file-${i}`, file, file.name)
    })
    console.log(files.length);
    data.append('count', files.length);
    return data
  }

  const handleUploadClick = () => {
    if (files.length) {
      const data = packFiles(files)
      uploadFiles(data);
    }
  }
  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const Checkbox = ({ id, isChecked, onChange, name, value }) => (
    <input type="checkbox" name={name} value={value} id={id} checked={isChecked} onChange={() => onChange(id)} />
  );

  // Function to handle individual checkbox change
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = selectedCheckboxes.includes(id)
      ? selectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      : [...selectedCheckboxes, id];
    setSelectedCheckboxes(updatedCheckboxes);
  };

  // Function to handle "Select All" checkbox change
  const handleSelectAllChange = () => {
    const allCheckboxIds = candidates.map((item) => item.id);
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.length === allCheckboxIds.length ? [] : allCheckboxIds
    );
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('/');
  }

  const columns = [
    {
      name: 'Select',
      cell: item => <div>
        {/* <input id={item.id} type="checkbox" name="select" value={item.id} onChange={handleselect} isChecked={selectedCand.includes(item.id)}/> */}
        <Checkbox id={item.id} name="select" value={item.id} onChange={handleCheckboxChange} isChecked={selectedCheckboxes.includes(item.id)}></Checkbox>
      </div>,
      selector: item => item.id,
      width: '1%'
    },
    {
      name: 'Name',
      cell: item => <div class="d-flex ">
        <a
          href={`/admin/viewCandidate/${item.id}`}
        >
          <img style={{ width: '50px', height: '50px', marginRight: '5px' }} src={item.profile ? process.env.REACT_APP_API_HOST + '/public' + item.profile : image} alt="" />
        </a>
        <div>
          <a
            href={`/admin/viewCandidate/${item.id}`}
          >
            {" "}
            <h6>
              {item.first_name}{" "}
              {item.last_name}
            </h6>
          </a>
          <p>{item.role_name}</p>
          <p>
            <i class="fa fa-map-marker"></i>{" "}
            {item.city_name},{" "}
            {item.state_name},{" "}
            {item.country_name}
          </p>
          <p>Last updated at:  {item.updated_at !== null ? formatDate(new Date(item.updated_at)) : ''}</p>
        </div>
      </div>,
      selector: item => item.id,
      width: '25%'
    },
    {
      name: 'Contact',
      cell: item => <div>
        <p>
          <a href={`mailto:${item.email}`}>
            {item.email}
          </a>
        </p>
        {item.phone && (
          <p>
            <a href={`callto:${item.phone}`}>
              {item.phone}
            </a>
          </p>
        )}
      </div>,
      selector: item => item.id,
      width: '15%'
    },
    {
      name: 'Current Status',
      cell: item => <div>
        <p><b>Current Designation: </b>{item.designation}</p>
        <p><b>Current Fun.Area: </b>{item.functional_area1}</p>
        <p><b>Current Industry: </b>{item.industry_name}</p>
      </div>,
      selector: item => item.id,
      width: '21%'
    },
    {
      name: 'Current Salary / NP',
      cell: item => <div>
        <p><b>Current Salary: </b>{item.currency_icon != null ? item.currency_icon : '₹'} {' ' + Math.round(item.current_salary)}</p>
        <p><b>Expected Salary: </b>{item.currency_icon != null ? item.currency_icon : '₹'}{' ' + item.expected_salary != null ? item.expected_salary : ''}</p>
        <p><b>Notice Period: </b>{item.notice_period != null || item.notice_period != 'undefined' ? item.notice_period : ''}</p>
      </div>,
      selector: item => item.id,
      width: '21%'
    },
    {
      name: 'Other Details',
      cell: item => <div>
        <p><b>Skills: </b>{item.skills !== null ? item.skills : item.skill_name}</p>
        <p><b>Experience: </b>{item.exp_year + ' year ' + item.exp_month + ' months'}</p>
        <p><b>Birth Date: </b>{item.birth_date !== null ? formatDate(new Date(item.birth_date)) : ''}</p>
      </div>,
      selector: item => item.id,
      width: '21%'
    },
    {
      name: 'Applied Jobs',
      cell: item => <div>
        <button
          class="badge badge-opacity-warning"
          style={{ border: "0px" }} onClick={() => { jobappliedtoggle(item.id); }}
        >
          <i class="fa fa-chart"></i>
          {item.job_count}
        </button>
        {jobAppliedModal === true && (
          <Modal
            isOpen={jobAppliedModal}
            toggle={jobappliedtoggle}>
            <ModalHeader toggle={jobappliedtoggle}>
              Jobs
            </ModalHeader>
            <ModalBody>
              <form class="forms-sample">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <table>
                        <thead>
                          <tr>
                            <th>Company</th>
                            <th>Title</th>
                            <th>Salary</th>
                            <th>Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {joblist.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <div class="d-flex ">
                                    <a href={`/admin/viewCompany/${item.company_id}`}></a>
                                    <div>
                                      <a href={`/admin/viewCompany/${item.company_id}`}>   <h6>{item.company_name}</h6></a>
                                      <p>
                                        <i class="fa fa-map-marker"></i>{" "}
                                        {item.city_name},{" "}
                                        {item.state_name},{" "}
                                        {item.country_name}
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <a href={`/admin/viewJob/${item.id}`}>  <h6>{item.title}</h6></a>
                                  <p>Category: {item.jobs_name}</p>
                                  <p>Function Area: {item.functional_area_name}</p>
                                </td>
                                <td>
                                  <h6>{item.currency_name} {item.salary_from}-{item.salary_to}</h6>
                                  <p>{item.experience > 0 ? item.experience + "year experienced" : "Fresher"}</p>
                                </td>
                                <td>
                                  <p>Applied Date: {formatDate(new Date(item.created_at.substring(0, 10)))}</p>
                                  <p>End Date: {formatDate(new Date(item.expiry_date))}</p>
                                  <p>Status: {item.app_status}</p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-light"
                  onClick={jobappliedtoggle}
                >
                  Cancel
                </button>
              </form>
            </ModalBody>
          </Modal>
        )}
      </div>,
      selector: item => item.id,
      width: '10%'
    },

    // {
    //   name: 'Action',
    //   cell: item => <div>
    //     <a href={`/admin/viewCandidate/${item.id}`}>
    //       <button
    //         class="badge badge-opacity-primary"
    //         style={{ border: "0px" }}
    //       >
    //         <i class="fa fa-eye"></i>
    //       </button>
    //     </a>
    //     {logindetails["cand_edit"] != 0 ?
    //     <a href={`/admin/candedit/${item.id}`} title="Edit Candidate">
    //       <button
    //         class="badge badge-opacity-success"
    //         style={{ border: "0px" }}>
    //         <i class="fa fa-pencil-square-o"></i>
    //       </button>
    //     </a> : ''}
    //   </div>,
    //   selector: item => item.id,
    //   width: '18%'
    // },
  ]

  function onchangeemployer(e) {
    setemployer(e.target.value);
    props.requestPendingJobsByEmployer({
      id: e.target.value,
    });
  }

  function onchangejob(e) {
    setjob(e.target.value);
  }

  // function onLocationMore(){
  //   const getLocations = async () => {
  //     const resst = await fetch(process.env.REACT_APP_API_HOST + '/api/locations/');
  //     const getst = await resst.json();
  //     console.log(getst.data);
  //     setlocations(getst.data);
  //   }
  //   getLocations();

  // }

  const onchangeaddcandidate = (event) => {
    if (event.target.value == 1) {
      navigate(`/admin/candidateadd`);
      dv_import_multiple.current.style.display = "block";
      import_sample.current.style.display = "none";
      upload_file.current.style.display = "none";
    }
    else if (event.target.value == 2) {
      dv_import_multiple.current.style.display = "none";
      import_sample.current.style.display = "block";
      upload_file.current.style.display = "block";
    }
    else if (event.target.value == 3) {
      dv_import_multiple.current.style.display = "block";
      import_sample.current.style.display = "none";
      upload_file.current.style.display = "none";
    }
    else {
      import_sample.current.style.display = "none";
      upload_file.current.style.display = "none";
      dv_import_multiple.current.style.display = "none";
    }
  };

  const onClickUploadFile = () => {
    inputFile.current.click();

  }

  useEffect(() => {
    const getJobs = async () => {
      const resst = await fetch(process.env.REACT_APP_API_HOST + '/api/admin/filterjobbyemployeravail/' + employer);
      const getst = await resst.json();
      setjobs(getst.data);
    }
    getJobs();
  }, [employer]);

  const onchangejobassigndata = (event, param1) => {
    setjobassigndata((jobassigndata) => ({
      ...jobassigndata,
      [event.target.name]: event.target.value,
    }));
  };

  const jobassigntoggle = () => {
    if (jobassignmodal === true) {
      setjobassignModal(!jobassignmodal);
    } else {
      setjobassignModal(!jobassignmodal);
    }
  };

  const jobappliedtoggle = (id) => {
    if (jobAppliedModal === true) {
      setJobAppliedModal(!jobAppliedModal);
    } else {
      setJobAppliedModal(!jobAppliedModal);
      setcandidate_id(id);
    }
  };

  function onFileChange(e) {
    setselectedFile(e.target.files[0]);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "profile",
      e.target.files[0]
    );

    // Details of the uploaded file
    console.log(e.target.files[0]);

    // Request made to the backend api
    // Send formData object
    axios.post("https://yourdomain/api/candidate/uploadresume", formData)
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (jobAppliedModal === true) {
      const getJobs = async () => {
        const resst = await fetch(process.env.REACT_APP_API_HOST + '/api/admin/getcandidateappliedjobs/' + candidate_id);
        const getst = await resst.json();
        setjoblist(getst.data);
      }
      getJobs();
    }
  }, [jobAppliedModal]);

  const savejobassigntoggle = (e) => {
    e.preventDefault();
    const myObjStr = JSON.stringify(selectedCheckboxes);
    console.log(myObjStr);
    props.requestCandAssignJob({
      data: { ids: myObjStr, job_id: job, uid: uid },
    });
    jobassigntoggle();
  }

  useEffect(() => {
    // console.log(selectedCand);
  }, [selectedCand]);

  // useEffect(() => {
  //   var loopData = 'search=' + search + '&';
  //   var i;

  //   for (i = 1; i < selectedFilters.length; i++) {
  //     loopData += selectedFilters[i].name + "=" + selectedFilters[i].value + "&";
  //   }
  //   console.log(loopData);
  //   axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candfilter?" + loopData)
  //     .then(res => {
  //       const timeout = setTimeout(() => {
  //         console.log(res.data.data.data);
  //         let candidatesData = res.data.data.data;
  //         setcandidates(candidatesData);
  //         setFilter(candidatesData);
  //         setPending(false);
  //       }, 2000);
  //       return () => clearTimeout(timeout);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, [selectedFilters]);

  useEffect(() => {
    var loopData = 'page=' + pages + 'page_size=' + page_size + '&search=' + search + '&';
    if (typingTimeout) clearTimeout(typingTimeout);
    var bln_load = false;
    setTypingTimeout(setTimeout(() => {
      var i;

      for (i = 1; i < selectedFilters.length; i++) {
        loopData += selectedFilters[i].name + "=" + selectedFilters[i].value + "&";
      }
      console.log(loopData);

      bln_load = true;
      setFilter([]);
      axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candfilter?" + loopData)
        .then(response => {
          const timeout = setTimeout(() => {
            if (bln_load === true) {
              let candidatesData = response.data.data.data;
              setcandidates(candidatesData);
              setTotalRows(response.data.data.total);
              setFilter(candidatesData);
              setPending(false);
              bln_load = false;
              setLoading(false);
            }
          }, 2000);
          return () => clearTimeout(timeout);
        })
        .catch(err => {
          console.log(err);

        });
      setLoading(true);
    }, 3000));


  }, [selectedFilters, search]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candfilterfirst?page=1&page_size=10&job_id=0")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data.data);
          let candidatesData = res.data.data.data;
          setcandidates(candidatesData);
          setFilter(candidatesData);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
    setLoading(true);
    setpage_size(10);
  }, []);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/candidates/1/${e.target.value}/${params.job_id}`);
    }
  }

  const handlePageChange = page => {
    console.log(pages);
    var loopData = 'search=' + search + '&';
    for (var i = 1; i < selectedFilters.length; i++) {
      loopData += selectedFilters[i].name + "=" + selectedFilters[i].value + "&";
    }
    // console.log(process.env.REACT_APP_API_HOST + "/api/admin/candfilter?page=" + pages + "&page_size=" + page_size + "&job_id=0&" + loopData);
    axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candfilter?page=" + pages + "&page_size=" + page_size + "&job_id=0&" + loopData)
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data.data);
          let candidatesData = res.data.data.data;
          setTotalRows(res.data.data.total);
          setcandidates(candidatesData);
          setFilter(candidatesData);
          setPending(false);
          setLoading(false);
        }, 2000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
    setLoading(true);
    setpages(parseInt(pages) + 1);
    // props.requestFilteredCandidates({
    //   page: page,
    //   page_size: page_size,
    //   job_id: 0,
    //   search: ''
    // });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setpage_size(newPerPage);
    axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candfilter?page=" + page + "&page_size=" + newPerPage + "&job_id=0&search=")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data.data);
          let candidatesData = res.data.data.data;
          setcandidates(candidatesData);
          setFilter(candidatesData);
          setPending(false);
        }, 2000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });

    // props.requestFilteredCandidates({
    //   page: page,
    //   page_size: newPerPage,
    //   job_id: 0,
    //   search: ''
    // });
  }

  // useEffect(() => {
  //   console.log(props.data.candFiltered);
  //   if (props.data.candFiltered.data.data !== undefined) {
  //     console.log(props.data.candFiltered.data.data);
  //     let candidatesData = props.data.candFiltered.data.data;
  //     if (candidatesData) {
  //       const timeout = setTimeout(() => {
  //         setcandidates(candidatesData.data);
  //         setFilter(candidatesData.data);
  //         setPending(false);
  //       }, 2000);
  //       return () => clearTimeout(timeout);
  //     }
  //   }
  // }, [props.data.candFiltered.data.data]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Candidates</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  //form field
  useEffect(() => {
    handleCandSearch();
    let loginData = props.data.loginData.data.data;
    setLoginDetails(loginData.admin_detail);
    setProfileUpdates(loginData.profile_updates);
    setProfileNew(loginData.profile_new);
    setUID(loginData.id);
  }, []);

  const handleCandSearch = async () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/admin/candsearchform")
      .then(res => {
        const timeout = setTimeout(() => {
          let formfieldData = res.data.data;
          setlocations(formfieldData.cities);
          setcompanies(formfieldData.companies);
          setfunctional_areas(formfieldData.functional_areas);
          setroles(formfieldData.roles);
          setindustries(formfieldData.industries);
          setkeyskills(formfieldData.keyskills);
          setinstitutes(formfieldData.institutes);
          setcourses(formfieldData.courses);
          setsources(formfieldData.sources);
          setemployers(formfieldData.employers);
          setTotalRows(formfieldData.cand_count);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  //form field
  // useEffect(() => {
  //   if (props.data.candSearch.data.data !== undefined) {
  //     let formfieldData = props.data.candSearch.data.data;
  //     if (formfieldData) {
  //       setlocations(formfieldData.cities);
  //       setcompanies(formfieldData.companies);
  //       setfunctional_areas(formfieldData.functional_areas);
  //       setroles(formfieldData.roles);
  //       setindustries(formfieldData.industries);
  //       setkeyskills(formfieldData.keyskills);
  //       setinstitutes(formfieldData.institutes);
  //       setcourses(formfieldData.courses);
  //       setemployers(formfieldData.employers);
  //     }
  //   }
  // }, [props.data.candSearch.data.data]);

  const prospecttoggle = () => {
    if (prospect_show === true) { setprospect_show(!prospect_show); } else { setprospect_show(!prospect_show); }
  };

  const appliedtoggle = () => {
    if (applied_show === true) { setapplied_show(!applied_show); } else { setapplied_show(!applied_show); }
  };

  const leadtoggle = () => {
    if (lead_show === true) { setlead_show(!lead_show); } else { setlead_show(!lead_show); }
  };

  const shortlisttoggle = () => {
    if (shortlist_show === true) { setshortlist_show(!shortlist_show); } else { setshortlist_show(!shortlist_show); }
  };

  const interviewtoggle = () => {
    if (interview_show === true) { setinterview_show(!interview_show); } else { setinterview_show(!interview_show); }
  };

  const offertoggle = () => {
    if (offer_show === true) { setoffer_show(!offer_show); } else { setoffer_show(!offer_show); }
  };

  const rejectedtoggle = () => {
    if (rejected_show === true) { setrejected_show(!rejected_show); } else { setrejected_show(!rejected_show); }
  };


  const activitytoggle = () => {
    if (activity_show === true) { setactivity_show(!activity_show); } else { setactivity_show(!activity_show); }
  };

  const processmetoggle = () => {
    if (processme_show === true) { setprocessme_show(!processme_show); } else { setprocessme_show(!processme_show); }
  };

  const processanyonetoggle = () => {
    if (processanyone_show === true) { setprocessanyone_show(!processanyone_show); } else { setprocessanyone_show(!processanyone_show); }
  };

  const applicationdatetoggle = () => {
    if (applicationdate_show === true) { setapplicationdate_show(!applicationdate_show); } else { setapplicationdate_show(!applicationdate_show); }
  };

  function handleselect(e) {
    const checked = e.target.checked;
    const value = e.target.value.toString();

    setSelectedCand(
      checked
        ? [...selectedCand, {
          id: value
        },]
        : selectedCand.filter((item) => item.id !== value)
    );
    console.log(checked);
    console.log(selectedCand);
  }

  function checkAllHandler() {
    if (checked_all === false) {
      const postids = filter.map((item) => {
        return item.id
      })
      setSelectedCand(postids);
    }
    else {
      setSelectedCand([]);
    }
    setCheckedAll(!checked_all);
    console.log(selectedCand);
  }

  function handleChange(e) {
    // e.preventDefault();
    let name1 = e.target.name;
    const checked = e.target.checked;
    const value = e.target.value;

    // let newArray = [...selectedFilters, {
    //         name: name1,
    //         value: value
    //       }];
    // if (selectedFilters.includes({
    //         name: name1,
    //         value: value
    //       })) {
    //   newArray = newArray.filter((item) => item.name !== name1 && item.value !== value);
    // }
    // setSelectedFilters(newArray);

    console.log(name1);
    setSelectedFilters(
      checked
        ? [...selectedFilters, {
          name: name1,
          value: value
        },]
        : selectedFilters.filter((item) => item.value !== value)
    );
    console.log(selectedFilters);
  }

  // const Prospects = () => (
  //   <ul>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_all" onChange={handleChange} /> All</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect" onChange={handleChange} /> Prospect</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_tech" onChange={handleChange} /> Prospect Technical</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_loc" onChange={handleChange} /> Prospect Location</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_sal" onChange={handleChange} /> Prospect Salary</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_mob" onChange={handleChange} /> Prospect Mobility</li>
  //     <li><input type="checkbox" name="chkprospect" value="prospect_age" onChange={handleChange} /> Prospect Age</li>
  //   </ul>
  // )

  const interviewdatetoggle = () => {
    if (interviewdate_show === true) { setinterviewdate_show(!interviewdate_show); } else { setinterviewdate_show(!interviewdate_show); }
  };

  const joiningdatetoggle = () => {
    if (joiningdate_show === true) { setjoiningdate_show(!joiningdate_show); } else { setjoiningdate_show(!joiningdate_show); }
  };

  const lastmodifiedtoggle = () => {
    if (lastmodified_show === true) { setlastmodified_show(!lastmodified_show); } else { setlastmodified_show(!lastmodified_show); }
  };

  function locationtoggle(e) {
    e.preventDefault();
    if (location_show === true) { setlocation_show(!location_show); } else { setlocation_show(!location_show); }
  };
  const feedbacktoggle = (e) => {
    e.preventDefault();
    if (feedback_show === true) { setfeedback_show(!feedback_show); } else { setfeedback_show(!feedback_show); }
  };

  const companytoggle = () => {
    if (company_show === true) { setcompany_show(!company_show); } else { setcompany_show(!company_show); }
  };

  const funcareatoggle = () => {
    if (funcarea_show === true) { setfuncarea_show(!funcarea_show); } else { setfuncarea_show(!funcarea_show); }
  };

  const roletoggle = () => {
    if (role_show === true) { setrole_show(!role_show); } else { setrole_show(!role_show); }
  };

  const industrytoggle = () => {
    if (industry_show === true) { setindustry_show(!industry_show); } else { setindustry_show(!industry_show); }
  };

  const industrymore = (e) => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candindustries")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setindustries(formfieldData.industries);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const locationmore = (e) => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candlocations")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setlocations(formfieldData.locations);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const companymore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candcompanies")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setindustries(formfieldData.companies);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const funareamore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candfunarea")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setfunctional_areas(formfieldData.fun_area);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const rolemore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candroles")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setroles(formfieldData.roles);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const institutemore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candinstitutes")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setroles(formfieldData.institutes);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const coursemore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candcourses")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setroles(formfieldData.courses);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const sourcemore = () => {
    axios.get(process.env.REACT_APP_API_HOST + "/api/candsources")
      .then(res => {
        const timeout = setTimeout(() => {
          console.log(res.data.data);
          let formfieldData = res.data.data;
          setsources(formfieldData.sources);
        }, 4000);
        return () => clearTimeout(timeout);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const toggleitem = ($var) => {
    if ($var === 'ctc') { if (ctc_show === true) { setctc_show(!ctc_show); } else { setctc_show(!ctc_show); } }
    if ($var === 'experience') { if (experience_show === true) { setexperience_show(!experience_show); } else { setexperience_show(!experience_show); } }
    if ($var === 'keyskills') { if (keyskills_show === true) { setkeyskills_show(!keyskills_show); } else { setkeyskills_show(!keyskills_show); } }
    if ($var === 'institutes') { if (institutes_show === true) { setinstitutes_show(!institutes_show); } else { setinstitutes_show(!institutes_show); } }
    if ($var === 'courses') { if (courses_show === true) { setcourses_show(!courses_show); } else { setcourses_show(!courses_show); } }
    if ($var === 'req_doc') { if (reqdoc_show === true) { setreqdoc_show(!reqdoc_show); } else { setreqdoc_show(!reqdoc_show); } }
    if ($var === 'employer') { if (employer_show === true) { setemployer_show(!employer_show); } else { setemployer_show(!employer_show); } }
    if ($var === 'employer_contact') { if (empcontact_show === true) { setempcontact_show(!empcontact_show); } else { setempcontact_show(!empcontact_show); } }
    if ($var === 'source') { if (source_show === true) { setsource_show(!source_show); } else { setsource_show(!source_show); } }
    if ($var === 'addedby') { if (addedby_show === true) { setaddedby_show(!addedby_show); } else { setaddedby_show(!addedby_show); } }
    if ($var === 'notice') { if (notice_show === true) { setnotice_show(!notice_show); } else { setnotice_show(!notice_show); } }
    if ($var === 'filters') { if (filter_show === true) { setfilter_show(!filter_show); } else { setfilter_show(!filter_show); } }
  };

  const Filters = () => (
    <ul>
      <li><input type="checkbox" name="chkfilter" value="filter_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkfilter" value="filter_featured" onChange={handleChange} /> Featured</li>
      <li><input type="checkbox" name="chkfilter" value="filter_email" onChange={handleChange} /> Verified Email</li>
      <li><input type="checkbox" name="chkfilter" value="filter_mobile" onChange={handleChange} /> Verified Mobile</li>
      <li><input type="checkbox" name="chkfilter" value="filter_female" onChange={handleChange} /> Female Candidate Only</li>
    </ul>
  )

  const NoticePeriod = () => (
    <ul>
      <li><input type="checkbox" name="chknotice" value="notice_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chknotice" value="notice_serving" onChange={handleChange} /> Serving notice period</li>
      <li><input type="checkbox" name="chknotice" value="notice_15" onChange={handleChange} /> 15 days or less</li>
      <li><input type="checkbox" name="chknotice" value="notice_1" onChange={handleChange} /> 1 month</li>
      <li><input type="checkbox" name="chknotice" value="notice_2" onChange={handleChange} /> 2 months</li>
      <li><input type="checkbox" name="chknotice" value="notice_3" onChange={handleChange} /> 3 months</li>
    </ul>
  )

  const CTC = () => (
    <ul>
      <li><input type="checkbox" name="chkctc" value="ctc_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkctc" value="ctc_1" onChange={handleChange} /> Less than 1 Lakh</li>
      <li><input type="checkbox" name="chkctc" value="ctc_13" onChange={handleChange} /> 1 to 3 Lakhs</li>
      <li><input type="checkbox" name="chkctc" value="ctc_37" onChange={handleChange} /> 3 to 7 Lakhs</li>
      <li><input type="checkbox" name="chkctc" value="ctc_714" onChange={handleChange} /> 7 to 14 Lakhs</li>
      <li><input type="checkbox" name="chkctc" value="ctc_1428" onChange={handleChange} /> 14 to 28 Lakhs</li>
      <li><input type="checkbox" name="chkctc" value="ctc_28" onChange={handleChange} /> 28 Lakhs or more</li>
    </ul>
  )

  const Experience = () => (
    <ul>
      <li><input type="checkbox" name="chkexperience" value="exp_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkexperience" value="exp_fresher" onChange={handleChange} /> Freshers</li>
      <li><input type="checkbox" name="chkexperience" value="exp_1" onChange={handleChange} /> Less than 1 year</li>
      <li><input type="checkbox" name="chkexperience" value="exp_12" onChange={handleChange} /> 1 to 2 years</li>
      <li><input type="checkbox" name="chkexperience" value="exp_25" onChange={handleChange} /> 2 to 5 years</li>
      <li><input type="checkbox" name="chkexperience" value="exp_58" onChange={handleChange} /> 5 to 8 years</li>
      <li><input type="checkbox" name="chkexperience" value="exp_815" onChange={handleChange} /> 8 to 15 years</li>
      <li><input type="checkbox" name="chkexperience" value="exp_1530" onChange={handleChange} /> 15 to 30 years</li>
      <li><input type="checkbox" name="chkexperience" value="exp_30" onChange={handleChange} /> More than 30 years</li>
    </ul>
  )

  const ProcessMe = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="me-Process Viewed" onChange={handleChange} /> Viewed</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Emailed" onChange={handleChange} /> Emailed</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Called" onChange={handleChange} /> Called</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Messaged" onChange={handleChange} /> Messaged</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Call Not Picked" onChange={handleChange} /> Call Not Picked</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Call Not Reachable" onChange={handleChange} /> Call Not Reachable</li>
      <li><input type="checkbox" name="candstatus" value="me-Process WhatsApp" onChange={handleChange} /> WhatsApp</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Downloaded" onChange={handleChange} /> Downloaded</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Starred" onChange={handleChange} /> Starred</li>
      <li><input type="checkbox" name="candstatus" value="me-Process Status Changed" onChange={handleChange} /> Status Changed</li>
    </ul>
  )

  const ProcessAnyone = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="any-Process Viewed" onChange={handleChange} /> Viewed</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Emailed" onChange={handleChange} /> Emailed</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Called" onChange={handleChange} /> Called</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Messaged" onChange={handleChange} /> Messaged</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Call Not Picked" onChange={handleChange} /> Call Not Picked</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Call Not Reachable" onChange={handleChange} /> Call Not Reachable</li>
      <li><input type="checkbox" name="candstatus" value="any-Process WhatsApp" onChange={handleChange} /> WhatsApp</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Downloaded" onChange={handleChange} /> Downloaded</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Starred" onChange={handleChange} /> Starred</li>
      <li><input type="checkbox" name="candstatus" value="any-Process Status Changed" onChange={handleChange} /> Status Changed</li>
    </ul>
  )

  const ApplicationDate = () => (
    <ul>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_today" onChange={handleChange} /> Today</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_23" onChange={handleChange} /> 2 to 3 days ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_47" onChange={handleChange} /> 4 to 7 days ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_815" onChange={handleChange} /> 8 to 15 days ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_1630" onChange={handleChange} /> 16 to 30 days ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_12m" onChange={handleChange} /> 1 to 2 months ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_23m" onChange={handleChange} /> 2 to 3 months ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_36m" onChange={handleChange} /> 3 to 6 months ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_69m" onChange={handleChange} /> 6 to 9 months ago</li>
      <li><input type="checkbox" name="chkapplicationdate" value="ad_912m" onChange={handleChange} /> 9 to 12 months ago</li>
    </ul>
  )

  const InterviewDate = () => (
    <ul>
      <li><input type="checkbox" name="chkinterviewdate" value="id_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_na" onChange={handleChange} /> NA</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_23a" onChange={handleChange} /> 2 to 3 months after</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_12a" onChange={handleChange} /> 1 to 2 months after</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_01a" onChange={handleChange} /> 0 to 1 months after</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_215a" onChange={handleChange} /> 2 to 15 days after</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_today" onChange={handleChange} /> Today</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_215b" onChange={handleChange} /> 2 to 15 days ago</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_01b" onChange={handleChange} /> 0 to 1 months ago</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_12b" onChange={handleChange} /> 1 to 2 months ago</li>
      <li><input type="checkbox" name="chkinterviewdate" value="id_23b" onChange={handleChange} /> 2 to 3 months ago</li>
    </ul>
  )

  const JoiningDate = () => (
    <ul>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_na" onChange={handleChange} /> NA</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_23a" onChange={handleChange} /> 2 to 3 months after</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_12a" onChange={handleChange} /> 1 to 2 months after</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_01a" onChange={handleChange} /> 0 to 1 months after</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_215a" onChange={handleChange} /> 2 to 15 days after</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_today" onChange={handleChange} /> Today</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_215b" onChange={handleChange} /> 2 to 15 days ago</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_01b" onChange={handleChange} /> 0 to 1 months ago</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_12b" onChange={handleChange} /> 1 to 2 months ago</li>
      <li><input type="checkbox" name="chkjoiningdate" value="joining_23b" onChange={handleChange} /> 2 to 3 months ago</li>
    </ul>
  )

  const LastModified = () => (
    <ul>
      <li><input type="checkbox" name="chklastmodified" value="lm_all" onChange={handleChange} /> All</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_na" onChange={handleChange} /> NA</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_23a" onChange={handleChange} /> 2 to 3 months after</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_12a" onChange={handleChange} /> 1 to 2 months after</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_01a" onChange={handleChange} /> 0 to 1 months after</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_215a" onChange={handleChange} /> 2 to 15 days after</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_today" onChange={handleChange} /> Today</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_215b" onChange={handleChange} /> 2 to 15 days ago</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_01b" onChange={handleChange} /> 0 to 1 months ago</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_12b" onChange={handleChange} /> 1 to 2 months ago</li>
      <li><input type="checkbox" name="chklastmodified" value="lm_23b" onChange={handleChange} /> 2 to 3 months ago</li>
    </ul>
  )

  const tableHeaderStyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px"
      },
    },
  }

  // useEffect(() => {
  //   props.requestFilteredCandidates({
  //     page: 1,
  //     page_size: 10,
  //     job_id: 0,
  //     search: search
  //   });
  // }, [search]);

  // word file page no 46
  const Applied = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="Applied" onChange={handleChange} /> Applied</li>
      <li><input type="checkbox" name="candstatus" value="Not Matching" onChange={handleChange} /> Not Matching</li>
      <li><input type="checkbox" name="candstatus" value="Matching" onChange={handleChange} /> Matching</li>
    </ul>
  )

  const Lead = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="Lead CV Sent" onChange={handleChange} /> Lead CV Sent</li>
      <li><input type="checkbox" name="candstatus" value="Lead CV Requested" onChange={handleChange} /> Lead CV Requested</li>
      <li><input type="checkbox" name="candstatus" value="Lead" onChange={handleChange} /> Lead</li>
    </ul>
  )

  const Interview = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="Interview Round 1" onChange={handleChange} /> Round 1</li>
      <li><input type="checkbox" name="candstatus" value="Interview Round 2" onChange={handleChange} /> Round 2</li>
      <li><input type="checkbox" name="candstatus" value="Interview Round 3" onChange={handleChange} /> Round 3</li>
    </ul>
  )

  const Offer = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="Offers and Joined" onChange={handleChange} /> Offers and Joined</li>
      <li><input type="checkbox" name="candstatus" value="Offered" onChange={handleChange} /> Offered</li>
      <li><input type="checkbox" name="candstatus" value="Joined" onChange={handleChange} /> Joined</li>
      <li><input type="checkbox" name="candstatus" value="Declined" onChange={handleChange} /> Declined</li>
    </ul>
  )

  const Feedback = () => (
    <ul>
      <li><input type="checkbox" name="candstatus" value="Approved" onChange={handleChange} /> Approved</li>
      <li><input type="checkbox" name="candstatus" value="Rejected" onChange={handleChange} /> Rejected</li>
    </ul>
  )

  const Activity = () => (
    <div>
      <div>
        <i onClick={() => { processmetoggle(); }} class={'menu-icon fa ' + (processme_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Process by Me
        {processme_show ? <ProcessMe /> : null}
      </div>
      <div>
        <i onClick={() => { processanyonetoggle(); }} class={'menu-icon fa ' + (processanyone_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Process by Anyone
        {processanyone_show ? <ProcessAnyone /> : null}
      </div>
      <input type="checkbox" name="chknotyetprocessed" /> Not Yet Processed
      <div>
        <i onClick={() => { applicationdatetoggle(); }} class={'menu-icon fa ' + (applicationdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Application Date
        {applicationdate_show ? <ApplicationDate /> : null}
      </div>
      <div>
        <i onClick={() => { interviewdatetoggle(); }} class={'menu-icon fa ' + (interviewdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Interview Date
        {interviewdate_show ? <InterviewDate /> : null}
      </div>
      <div>
        <i onClick={() => { joiningdatetoggle(); }} class={'menu-icon fa ' + (joiningdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Joining Date
        {joiningdate_show ? <JoiningDate /> : null}
      </div>
      <div>
        <i onClick={() => { lastmodifiedtoggle(); }} class={'menu-icon fa ' + (lastmodified_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Last Modified
        {lastmodified_show ? <LastModified /> : null}
      </div>
    </div>
  )

  return (
    <>
      <div class="row">
        <div class="col-md-2" style={{ backgroundColor: "#d3d3d3", paddingLeft: "20px" }}>
          <div>
            <i onClick={() => { prospecttoggle(); }} class={'menu-icon fa ' + (prospect_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Prospect
            <div style={prospect_show ? {} : { display: 'none' }}>

            </div>
          </div>
          <div>
            <i onClick={() => { appliedtoggle(); }} class={'menu-icon fa ' + (applied_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Applied
            <div style={applied_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="candstatus" value="Applied" onChange={handleChange} /> Applied</li>
                <li><input type="checkbox" name="candstatus" value="Not Matching" onChange={handleChange} /> Not Matching</li>
                <li><input type="checkbox" name="candstatus" value="Matching" onChange={handleChange} /> Matching</li>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { leadtoggle(); }} class={'menu-icon fa ' + (lead_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Lead
            <div style={lead_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="candstatus" value="Lead CV Sent" onChange={handleChange} /> Lead CV Sent</li>
                <li><input type="checkbox" name="candstatus" value="Lead CV Requested" onChange={handleChange} /> Lead CV Requested</li>
                <li><input type="checkbox" name="candstatus" value="Lead" onChange={handleChange} /> Lead</li>
              </ul>
            </div>
          </div>
          {/* <div>
            <i onClick={() => { shortlisttoggle(); }} class={'menu-icon fa ' + (shortlist_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Shortlisted
            <div style={shortlist_show ? {} : { display: 'none' }}>

            </div>
          </div> */}
          <div>
            <i onClick={() => { interviewtoggle(); }} class={'menu-icon fa ' + (interview_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Interview
            <div style={interview_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="candstatus" value="Interview Round 1" onChange={handleChange} /> Round 1</li>
                <li><input type="checkbox" name="candstatus" value="Interview Round 2" onChange={handleChange} /> Round 2</li>
                <li><input type="checkbox" name="candstatus" value="Interview Round 3" onChange={handleChange} /> Round 3</li>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { offertoggle(); }} class={'menu-icon fa ' + (offer_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Offers & Joined
            <div style={offer_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="candstatus" value="Offers and Joined" onChange={handleChange} /> Offers and Joined</li>
                <li><input type="checkbox" name="candstatus" value="Offered" onChange={handleChange} /> Offered</li>
                <li><input type="checkbox" name="candstatus" value="Joined" onChange={handleChange} /> Joined</li>
                <li><input type="checkbox" name="candstatus" value="Declined" onChange={handleChange} /> Declined</li>
              </ul>
            </div>
          </div>
          {/* <div>
            <i onClick={() => { rejectedtoggle(); }} class={'menu-icon fa ' + (rejected_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Rejected
            {rejected_show ? <Rejected /> : null}
          </div> */}
          <div>
            <i onClick={(e) => { feedbacktoggle(e) }} class={'menu-icon fa ' + (feedback_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Interviewer Feedback
            <div style={feedback_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="candstatus" value="Approved" onChange={handleChange} /> Approved</li>
                <li><input type="checkbox" name="candstatus" value="Rejected" onChange={handleChange} /> Rejected</li>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { activitytoggle(); }} class={'menu-icon fa ' + (activity_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Profile Activity
            <div style={activity_show ? {} : { display: 'none' }}>
              <div>
                <div>
                  <i onClick={() => { processmetoggle(); }} class={'menu-icon fa ' + (processme_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Process by Me
                  <div style={processme_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="candstatus" value="me-Process Viewed" onChange={handleChange} /> Viewed</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Emailed" onChange={handleChange} /> Emailed</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Called" onChange={handleChange} /> Called</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Messaged" onChange={handleChange} /> Messaged</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Call Not Picked" onChange={handleChange} /> Call Not Picked</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Call Not Reachable" onChange={handleChange} /> Call Not Reachable</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process WhatsApp" onChange={handleChange} /> WhatsApp</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Downloaded" onChange={handleChange} /> Downloaded</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Starred" onChange={handleChange} /> Starred</li>
                      <li><input type="checkbox" name="candstatus" value="me-Process Status Changed" onChange={handleChange} /> Status Changed</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <i onClick={() => { processanyonetoggle(); }} class={'menu-icon fa ' + (processanyone_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Process by Anyone
                  <div style={processanyone_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="candstatus" value="any-Process Viewed" onChange={handleChange} /> Viewed</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Emailed" onChange={handleChange} /> Emailed</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Called" onChange={handleChange} /> Called</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Messaged" onChange={handleChange} /> Messaged</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Call Not Picked" onChange={handleChange} /> Call Not Picked</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Call Not Reachable" onChange={handleChange} /> Call Not Reachable</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process WhatsApp" onChange={handleChange} /> WhatsApp</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Downloaded" onChange={handleChange} /> Downloaded</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Starred" onChange={handleChange} /> Starred</li>
                      <li><input type="checkbox" name="candstatus" value="any-Process Status Changed" onChange={handleChange} /> Status Changed</li>
                    </ul>
                  </div>
                </div>
                <input type="checkbox" name="chknotyetprocessed" /> Not Yet Processed
                <div>
                  <i onClick={() => { applicationdatetoggle(); }} class={'menu-icon fa ' + (applicationdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Application Date
                  <div style={applicationdate_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_all" onChange={handleChange} /> All</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_today" onChange={handleChange} /> Today</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_23" onChange={handleChange} /> 2 to 3 days ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_47" onChange={handleChange} /> 4 to 7 days ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_815" onChange={handleChange} /> 8 to 15 days ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_1630" onChange={handleChange} /> 16 to 30 days ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_12m" onChange={handleChange} /> 1 to 2 months ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_23m" onChange={handleChange} /> 2 to 3 months ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_36m" onChange={handleChange} /> 3 to 6 months ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_69m" onChange={handleChange} /> 6 to 9 months ago</li>
                      <li><input type="checkbox" name="chkapplicationdate" value="ad_912m" onChange={handleChange} /> 9 to 12 months ago</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <i onClick={() => { interviewdatetoggle(); }} class={'menu-icon fa ' + (interviewdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Interview Date
                  <div style={interviewdate_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_all" onChange={handleChange} /> All</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_na" onChange={handleChange} /> NA</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_23a" onChange={handleChange} /> 2 to 3 months after</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_12a" onChange={handleChange} /> 1 to 2 months after</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_01a" onChange={handleChange} /> 0 to 1 months after</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_215a" onChange={handleChange} /> 2 to 15 days after</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_today" onChange={handleChange} /> Today</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_215b" onChange={handleChange} /> 2 to 15 days ago</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_01b" onChange={handleChange} /> 0 to 1 months ago</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_12b" onChange={handleChange} /> 1 to 2 months ago</li>
                      <li><input type="checkbox" name="chkinterviewdate" value="id_23b" onChange={handleChange} /> 2 to 3 months ago</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <i onClick={() => { joiningdatetoggle(); }} class={'menu-icon fa ' + (joiningdate_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Joining Date
                  <div style={joiningdate_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_all" onChange={handleChange} /> All</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_na" onChange={handleChange} /> NA</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_23a" onChange={handleChange} /> 2 to 3 months after</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_12a" onChange={handleChange} /> 1 to 2 months after</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_01a" onChange={handleChange} /> 0 to 1 months after</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_215a" onChange={handleChange} /> 2 to 15 days after</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_today" onChange={handleChange} /> Today</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_215b" onChange={handleChange} /> 2 to 15 days ago</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_01b" onChange={handleChange} /> 0 to 1 months ago</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_12b" onChange={handleChange} /> 1 to 2 months ago</li>
                      <li><input type="checkbox" name="chkjoiningdate" value="joining_23b" onChange={handleChange} /> 2 to 3 months ago</li>
                    </ul>
                  </div>
                </div>
                <div>
                  <i onClick={() => { lastmodifiedtoggle(); }} class={'menu-icon fa ' + (lastmodified_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Last Modified
                  <div style={lastmodified_show ? {} : { display: 'none' }}>
                    <ul>
                      <li><input type="checkbox" name="chklastmodified" value="lm_all" onChange={handleChange} /> All</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_na" onChange={handleChange} /> NA</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_23a" onChange={handleChange} /> 2 to 3 months after</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_12a" onChange={handleChange} /> 1 to 2 months after</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_01a" onChange={handleChange} /> 0 to 1 months after</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_215a" onChange={handleChange} /> 2 to 15 days after</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_today" onChange={handleChange} /> Today</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_215b" onChange={handleChange} /> 2 to 15 days ago</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_01b" onChange={handleChange} /> 0 to 1 months ago</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_12b" onChange={handleChange} /> 1 to 2 months ago</li>
                      <li><input type="checkbox" name="chklastmodified" value="lm_23b" onChange={handleChange} /> 2 to 3 months ago</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <i onClick={(e) => { locationtoggle(e) }} class={'menu-icon fa ' + (location_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Location
            <div style={location_show ? {} : { display: 'none' }}>
              <ul>
                {locations.map((option) => (
                  <li key={option.id}><input type="checkbox" name="location" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={(e) => { locationmore(e) }} style={{ cursor: 'pointer' }}>View More...</i>
                {/* <a href={onLocationMore()}>view more...</a> */}
              </ul>
            </div>
            {/* {location_show ? <Location /> : null} */}
          </div>
          <div>
            <i onClick={() => { companytoggle(); }} class={'menu-icon fa ' + (company_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Company
            <div style={company_show ? {} : { display: 'none' }}>
              <ul>
                {companies.map((option) => (
                  <li key={option.id}><input type="checkbox" name="company" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { companymore(); }} style={{ cursor: 'pointer' }}>View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { funcareatoggle(); }} class={'menu-icon fa ' + (funcarea_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Functional Area
            <div style={funcarea_show ? {} : { display: 'none' }}>
              <ul>
                {functional_areas.map((option) => (
                  <li key={option.id}><input type="checkbox" name="fun_area" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { funareamore(); }} style={{ cursor: 'pointer' }}>View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { roletoggle(); }} class={'menu-icon fa ' + (role_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Role
            <div style={role_show ? {} : { display: 'none' }}>
              <ul>
                {roles.map((option) => (
                  <li key={option.id}><input type="checkbox" name="role" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { rolemore(); }} style={{ cursor: 'pointer' }}>View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { industrytoggle(); }} class={'menu-icon fa ' + (industry_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Industry
            <div style={industry_show ? {} : { display: 'none' }}>
              <ul>
                {industries.map((option) => (
                  <li key={option.id}><input type="checkbox" name="industry" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { industrymore(); }} style={{ cursor: 'pointer' }}> View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('ctc'); }} class={'menu-icon fa ' + (ctc_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> CTC
            <div style={ctc_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="ctc" value="ctc_all" onChange={handleChange} /> All</li>
                <li><input type="checkbox" name="ctc" value="ctc_1" onChange={handleChange} /> Less than 1 Lakh</li>
                <li><input type="checkbox" name="ctc" value="ctc_13" onChange={handleChange} /> 1 to 3 Lakhs</li>
                <li><input type="checkbox" name="ctc" value="ctc_37" onChange={handleChange} /> 3 to 7 Lakhs</li>
                <li><input type="checkbox" name="ctc" value="ctc_714" onChange={handleChange} /> 7 to 14 Lakhs</li>
                <li><input type="checkbox" name="ctc" value="ctc_1428" onChange={handleChange} /> 14 to 28 Lakhs</li>
                <li><input type="checkbox" name="ctc" value="ctc_28" onChange={handleChange} /> 28 Lakhs or more</li>
              </ul>
            </div>
          </div>
          {/* <div>
            <i onClick={() => { toggleitem('experience'); }} class={'menu-icon fa ' + (experience_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Experience
            {experience_show ? <Experience /> : null}
          </div> */}
          <div>
            <i onClick={() => { toggleitem('experience'); }} class={'menu-icon fa ' + (experience_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Experience
            <div style={experience_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="experience" value="exp_all" onChange={handleChange} /> All</li>
                <li><input type="checkbox" name="experience" value="exp_fresher" onChange={handleChange} /> Freshers</li>
                <li><input type="checkbox" name="experience" value="exp_1" onChange={handleChange} /> Less than 1 year</li>
                <li><input type="checkbox" name="experience" value="exp_12" onChange={handleChange} /> 1 to 2 years</li>
                <li><input type="checkbox" name="experience" value="exp_25" onChange={handleChange} /> 2 to 5 years</li>
                <li><input type="checkbox" name="experience" value="exp_58" onChange={handleChange} /> 5 to 8 years</li>
                <li><input type="checkbox" name="experience" value="exp_815" onChange={handleChange} /> 8 to 15 years</li>
                <li><input type="checkbox" name="experience" value="exp_1530" onChange={handleChange} /> 15 to 30 years</li>
                <li><input type="checkbox" name="experience" value="exp_30" onChange={handleChange} /> More than 30 years</li>
              </ul>
            </div>
          </div>

          <div>
            <i onClick={() => { toggleitem('keyskills'); }} class={'menu-icon fa ' + (keyskills_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Key Skills
            <div style={keyskills_show ? {} : { display: 'none' }}>
              <ul>
                <li key={0}><input type="checkbox" name="chkkeyskills" /> All </li>
                {keyskills.map((option) => (
                  <li key={option.id}><input type="checkbox" name="skill" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('institutes'); }} class={'menu-icon fa ' + (institutes_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Institutes
            <div style={institutes_show ? {} : { display: 'none' }}>
              <ul>
                <li key={0}><input type="checkbox" name="chkinstitute" /> All </li>
                {institutes.map((option) => (
                  <li key={option}><input type="checkbox" name="university" value={option.id} onChange={handleChange} /> {option} </li>
                ))}
                <i onClick={() => { institutemore(); }} style={{ cursor: 'pointer' }}> View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('courses'); }} class={'menu-icon fa ' + (courses_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Course
            <div style={courses_show ? {} : { display: 'none' }}>
              <ul>
                <li key={0}><input type="checkbox" name="chkcourse" /> All </li>
                {courses.map((option) => (
                  <li key={option.id}><input type="checkbox" name="course" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { coursemore(); }} style={{ cursor: 'pointer' }}> View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('req_doc'); }} class={'menu-icon fa ' + (reqdoc_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Status of Requested Documents
            <div style={reqdoc_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="chkreqdoc" value="reqdoc_all" onChange={handleChange} /> All</li>
                <li><input type="checkbox" name="chkreqdoc" value="reqdoc_pending" onChange={handleChange} /> Document Upload Pending</li>
                <li><input type="checkbox" name="chkreqdoc" value="reqdoc_uploaded" onChange={handleChange} /> Documents Uploaded</li>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('comments'); }} class={'menu-icon fa ' + (comments_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Comments
            {/* {comments_show ? <Comment /> : null} */}
          </div>
          {/* <div>
            <i onClick={() => { toggleitem('employer'); }} class={'menu-icon fa ' + (employer_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Employer
            {employer_show ? <Employer /> : null}
          </div>
          <div>
            <i onClick={() => { toggleitem('employer_contact'); }} class={'menu-icon fa ' + (empcontact_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Employer Contact
            {empcontact_show ? <EmployerContact /> : null}
          </div> */}
          <div>
            <i onClick={() => { toggleitem('source'); }} class={'menu-icon fa ' + (source_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Source
            <div style={source_show ? {} : { display: 'none' }}>
              <ul>
                <li key={0}><input type="checkbox" name="chksource" /> All </li>
                {sources.map((option) => (
                  <li key={option.id}><input type="checkbox" name="source" value={option.id} onChange={handleChange} /> {option.name} </li>
                ))}
                <i onClick={() => { sourcemore(); }} style={{ cursor: 'pointer' }}> View More...</i>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('addedby'); }} class={'menu-icon fa ' + (addedby_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Added By
            {/* {addedby_show ? <AddedBy /> : null} */}
          </div>
          <div>
            <i onClick={() => { toggleitem('notice'); }} class={'menu-icon fa ' + (notice_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> Notice period
            <div style={notice_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="notice_period" value="notice_all" onChange={handleChange} /> All</li>
                <li><input type="checkbox" name="notice_period" value="Immediate" onChange={handleChange} /> Immediate</li>
                <li><input type="checkbox" name="notice_period" value="Serving Notice Period" onChange={handleChange} /> Serving Notice Period</li>
                <li><input type="checkbox" name="notice_period" value="8 Days" onChange={handleChange} /> 8 Days</li>
                <li><input type="checkbox" name="notice_period" value="15 Days" onChange={handleChange} /> 15 Days</li>
                <li><input type="checkbox" name="notice_period" value="30 Days" onChange={handleChange} /> 30 Days</li>
                <li><input type="checkbox" name="notice_period" value="45 Days" onChange={handleChange} /> 45 Days</li>
                <li><input type="checkbox" name="notice_period" value="60 Days" onChange={handleChange} /> 60 Days</li>
                <li><input type="checkbox" name="notice_period" value="90 Days" onChange={handleChange} /> 90 Days</li>
              </ul>
            </div>
          </div>
          <div>
            <i onClick={() => { toggleitem('filters'); }} class={'menu-icon fa ' + (filter_show ? 'fa-arrow-down' : 'fa-arrow-up')}></i> More Filters
            <div style={filter_show ? {} : { display: 'none' }}>
              <ul>
                <li><input type="checkbox" name="chkfilter" value="filter_all" onChange={handleChange} /> All</li>
                <li><input type="checkbox" name="chkfilter" value="filter_featured" onChange={handleChange} /> Featured</li>
                <li><input type="checkbox" name="chkfilter" value="filter_email" onChange={handleChange} /> Verified Email</li>
                <li><input type="checkbox" name="chkfilter" value="filter_mobile" onChange={handleChange} /> Verified Mobile</li>
                <li><input type="checkbox" name="chkfilter" value="filter_female" onChange={handleChange} /> Female Candidate Only</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-10">
          <div class="row">
            {logindetails["cand_assign_job"] != 0 ?
              <div class="col-md-3">
                <button class="btn btn-otline-dark btn-primary" style={{ margin: '8px' }} onClick={() => { jobassigntoggle(); }}>Source to the Job</button>
                {jobassignmodal === true && (
                  <Modal
                    isOpen={jobassignmodal}
                    toggle={jobassigntoggle}
                  >
                    <ModalHeader toggle={jobassigntoggle}>
                      Change Status of the Job
                    </ModalHeader>
                    <ModalBody>
                      <form class="forms-sample">
                        <div class="row">
                          <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                              <label>Assign Candidates to below Job</label>
                              <select class="form-select" name="jobassign" value={employer} onChange={onchangeemployer} >
                                <option value="0">Select Company</option>
                                {employers.map((option) => (
                                  <option value={option.id}>{option.name}</option>
                                ))}
                              </select>
                              <select class="form-select" name="job" value={job} onChange={onchangejob} >
                                <option value="0">Select Job</option>
                                {jobs.map((option) => (
                                  <option value={option.id}>{option.title}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit" class="btn btn-primary me-2" onClick={savejobassigntoggle} >
                          Submit
                        </button>
                        <button
                          class="btn btn-light"
                          onClick={jobassigntoggle}
                        >
                          Cancel
                        </button>
                      </form>
                    </ModalBody>
                  </Modal>
                )}
              </div> : ''}
            {logindetails["cand_add"] != 0 ?
              <div class="col-md-3" style={{ marginTop: '8px' }}>
                <select
                  class="form-select"
                  name="addcandidate"
                  onChange={onchangeaddcandidate}>
                  <option value="0">Add new candidate</option>
                  <option value="1">Add Single Candididate</option>
                  <option value="2">Import Candidates</option>
                  <option value="3">Multiple Candidates - Resume Upload</option>
                </select>
                <div ref={dv_import_multiple}  style={{ display: 'none' }}>
                  <input type="file" multiple onChange={(e) => setFiles(e.target.files)} />
                  {renderFileList()}
                  <button onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}  className="btn btn-success">
                    {getButtonStatusText()}
                  </button>
                </div>
                <a id="import_sample" ref={import_sample} style={{ display: 'none' }} href="https://yourdomain/public/import_sample.csv" className="btn btn-success">Click to download sample file, add candidate in it and upload the file.</a>
                <button ref={upload_file} style={{ display: 'none' }} className="btn btn-success" onClick={onClickUploadFile}>Upload File</button>
              </div>
              // <div class="col-md-3" style={{ marginTop: '8px' }}>
              // 
              // </div>
              : ''
            }
            <div class="col-md-4" style={{ marginTop: '8px' }}>
              <p><b>Today's New Profile Uploaded count: </b>{profile_new}</p>
              <p><b>Today's Profile Update count: </b>{profile_updates}</p>
            </div>
          </div>
          <input type="file" style={{ display: 'none' }} ref={inputFile} onChange={onFileChange} title=" " />

          <div class="table-responsive  mt-1" id="printme">
            <div>
              {/* <input type="checkbox" name="select_all" value={checked_all} onClick={checkAllHandler} /> */}
              <Checkbox id="selectAll" isChecked={selectedCheckboxes.length === page_size} onChange={handleSelectAllChange} />
            </div>
            {loading == false ?
              <DataTable
                columns={columns} data={filter} pagination paginationServer paginationTotalRows={totalRows}
                onChangePage={handlePageChange} onChangeRowsPerPage={handlePerRowsChange} progressPending={pending}
                actions={<button className="btn btn-success">Export to PDF</button>}
                subHeader
                subHeaderComponent={
                  <input id="search" type="text" className="w-25 form-control" placeholder="Search..." value={search} onChange={handleSearch} />
                }
                customStyles={tableHeaderStyle}
              /> : <ReactBootStrap.Spinner animation="border" />}

            {/* <table class="table select-table">
              <thead>
                <tr>
                  <th>Select</th>
                  <th>Name</th>
                  <th>Contact</th>
                  <th>Functional Area</th>
                  <th>Applied Jobs</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {candidates.map((item, index) => {
                  const img = item.profile
                    ? process.env.REACT_APP_API_HOST +
                    item.profile
                    : image;
                  return (
                    <tr key={index}>
                      <td>
                        <input type="checkbox" name="chkselect" value={item.id} onChange={handleselect} />
                      </td>
                      <td>
                        <div class="d-flex ">
                          <a
                            href={`/admin/viewCandidate/${item.id}`}
                          >
                            {" "}
                            <img src={img} alt="" />
                          </a>
                          <div>
                            <a
                              href={`/admin/viewCandidate/${item.id}`}
                            >
                              {" "}
                              <h6>
                                {item.first_name}{" "}
                                {item.last_name}
                              </h6>
                            </a>
                            <p>
                              <i class="fa fa-map-marker"></i>{" "}
                              {item.city_name},{" "}
                              {item.state_name},{" "}
                              {item.country_name}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <h6>
                          <a href={`mailto:${item.email}`}>
                            {item.email}
                          </a>
                        </h6>
                        {item.phone && (
                          <p>
                            <a href={`callto:${item.phone}`}>
                              {item.phone}
                            </a>
                          </p>
                        )}
                      </td>
                      <td>
                        <h6>{item.functional_area1}</h6>
                        <p>{item.industries_name}</p>
                      </td>
                      <td>
                        <button
                          class="badge badge-opacity-warning"
                          style={{ border: "0px" }} onClick={() => { jobappliedtoggle(item.id); }}
                        >
                          <i class="fa fa-chart"></i>
                          {item.job_count}
                        </button>
                        {jobAppliedModal === true && (
                          <Modal
                            isOpen={jobAppliedModal}
                            toggle={jobappliedtoggle}>
                            <ModalHeader toggle={jobappliedtoggle}>
                              Jobs
                            </ModalHeader>
                            <ModalBody>
                              <form class="forms-sample">
                                <div class="row">
                                  <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                      <table>
                                        <thead>
                                          <tr>
                                            <th>Company</th>
                                            <th>Title</th>
                                            <th>Salary</th>
                                            <th>Dates</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {joblist.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <div class="d-flex ">
                                                    <a href={`/admin/viewCompany/${item.company_id}`}></a>
                                                    <div>
                                                      <a href={`/admin/viewCompany/${item.company_id}`}>   <h6>{item.company_name}</h6></a>
                                                      <p>
                                                        <i class="fa fa-map-marker"></i>{" "}
                                                        {item.city_name},{" "}
                                                        {item.state_name},{" "}
                                                        {item.country_name}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <a href={`/admin/viewJob/${item.id}`}>  <h6>{item.title}</h6>
                                                    <p>Category: {item.jobs_name}</p>
                                                    <p>Function Area: {item.functional_area_name}</p></a>
                                                </td>
                                                <td>
                                                  <h6>{item.currency_name} {item.salary_from}-{item.salary_to}</h6>
                                                  <p>{item.experience > 0 ? item.experience + "year experienced" : "Fresher"}</p>
                                                </td>
                                                <td>
                                                  <h6>Created Date: {item.created_at.substring(0, 10)}</h6>
                                                  <p>End Date: {item.expiry_date}</p>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  class="btn btn-light"
                                  onClick={jobappliedtoggle}
                                >
                                  Cancel
                                </button>
                              </form>
                            </ModalBody>
                          </Modal>
                        )}

                      </td>
                      <td>
                        <a href={`/admin/viewCandidate/${item.id}`}>
                          <button
                            class="badge badge-opacity-primary"
                            style={{ border: "0px" }}
                          >
                            <i class="fa fa-eye"></i>
                          </button>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </div>
          {/*
<div class="row">
            <div class="col-10">
              <div class="pagination center">
                <ul class="pagination-list">
                  {pagination.current_page !== 1 && (
                    <li>
                      <a
                        href={`/admin/candidates/${params.page - 1
                          }/${params.page_size}/${params.job_id}`}
                      >
                        <i class="fa fa-long-arrow-left"></i>
                      </a>
                    </li>
                  )}
                  {pages.map((i) => {
                    return pagination.current_page === i ? (
                      <li class="active">
                        <a
                          href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                        >
                          {i}
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          href={`/admin/candidates/${i}/${params.page_size}/${params.job_id}`}
                        >
                          {i}
                        </a>
                      </li>
                    );
                  })}
                  {pagination.current_page !==
                    pagination.last_page && (
                      <li>
                        <a
                          href={`/admin/candidates/${Number(params.page) + 1
                            }/${params.page_size}/${params.job_id}`}
                        >
                          <i class="fa fa-long-arrow-right"></i>
                        </a>
                      </li>
                    )}
                </ul>
              </div>
            </div>
            <div class="col-2">
              <div class="pagination center">
                <form onSubmit={onChangePageSize}>
                  <div class="form-group">
                    <input
                      type="number"
                      class="form-control"
                      placeholder={page_size}
                      name="page_size"
                      value={page_size}
                      onChange={onChangePageSize}
                      style={{ width: "100px" }}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
*/}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestCandSearch, requestFilteredCandidates, requestFilteredCandidatesFirst, 
    requestCandAssignJob, requestPendingJobsByEmployer, requestImportFromResume }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Search);