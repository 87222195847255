import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminCompanies, requestAdminCountry, requestAdminState, requestAdminCity, requestAdminAddCompany, requestAdminGetCompany, requestAdminEditCompany, requestAdminDeleteCompany } from "../Redux/actions";
import image from "../images/extraLogo.png";
import Swal from "sweetalert2";
import axios from 'axios';

function Companies(props) {
  
  const params = useParams();
  const [companies, setcompanies] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [change, setchange] = useState(false);
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const addtoggle = () => setaddModal(!addmodal);
  const [addmodal, setaddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);
  const [addressmodal, setaddressModal] = useState(false);

  const [errorname, seterrorname] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [erroremployer_ceo, seterroremployer_ceo] = useState("");
  const [errorindustry, seterrorindustry] = useState("");
  const [errorownership_type, seterrorownership_type] = useState("");
  const [errorsize, seterrorsize] = useState("");
  const [erroremployer_details, seterroremployer_details] = useState("");
  const [errorestablished_in, seterrorestablished_in] = useState("");
  const [errorno_of_offices, seterrorno_of_offices] = useState("");
  const [errorstatus, seterrorstatus] = useState("");
  const [erroris_featured, seterroris_featured] = useState("");

  const [id, setid] = useState(0);
  const [adddata, setadddata] = useState({
    name: "",
    gst_no: "",
    email: "",
    employer_ceo: "",
    industry: "",
    ownership_type: "",
    size: "",
    employer_details: "",
    established_in: "",
    no_of_offices: "",
    fax: "",
    website: "",
    status: "",
    is_featured: 1,
    facebook_url: "",
    twitter_url: "",
    linkedin_url: "",
    google_plus_url: "",
    pinterest_url: "",
  });
  const [editdata, seteditdata] = useState({
    name: "",
    gst_no: "",
    email: "",
    employer_ceo: "",
    industry: "",
    ownership_type: "",
    size: "",
    employer_details: "",
    established_in: "",
    no_of_offices: "",
    fax: "",
    website: "",
    status: "",
    is_featured: 1,
    facebook_url: "",
    twitter_url: "",
    linkedin_url: "",
    google_plus_url: "",
    pinterest_url: "",
  });
  const [countryId, setcountryId] = useState(0);
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);

  const [cities, setcities] = useState([]);
  const [states, setstates] = useState([]);
  const [countries, setcountries] = useState([]);

  const [size, setsize] = useState([]);
  const [owner, setowner] = useState([]);
  const [industry, setindustry] = useState([]);

  useEffect(() => {
    props.requestAdminCountry();
  }, []);


  useEffect(() => {
    let countryData = props.data.loginData.data.data.countries;
    if (countryData !== undefined) {
        setcountries(countryData);
    }

    let industryData = props.data.loginData.data.data.industries;
    if (industryData !== undefined) {
        setindustry(industryData);
    }

    let ownerData = props.data.loginData.data.data.owners;
    if (ownerData !== undefined) {
        setowner(ownerData);
    }

    let sizeData = props.data.loginData.data.data.sizes;
    if (sizeData !== undefined) {
        setsize(sizeData);
    }

  }, [props.data.loginData.data.data]);

  function onChangeCountry(e) {
    setcountryId(e.target.value);
    axios.get('https://yourdomain/api/state/' + e.target.value)
    .then(res=>{
        setstates(res.data.data.states);
    })
    .catch(err=>{
        console.log(err);
    })
  }    

  function onChangeState(e) {
    setstateId(e.target.value);
    axios.get('https://yourdomain/api/city/' + e.target.value)
    .then(res=>{
        setcities(res.data.data.cities);
    })
    .catch(err=>{
        console.log(err);
    })
  }    

  function onChangeCity(e) {
    setcityId(e.target.value);
  }

  // useEffect(() => {
  //   console.log("statedata");
  //   console.log(props);
  //   let stateData = props.candidate.stateData;
  //   if (stateData !== undefined) {
  //     if (stateData?.data?.status == "success") {
  //       setstates(stateData.data.data.states);
  //     }
  //   }
  //   }, [props.data.loginData.data.data.countries]);

  // function onChangeState(e) {
  //   setstateId(e.target.value);
  //   props.requestCity({
  //     id: e.target.value,
  //   });
  // }

  // useEffect(() => {
  //   let cityData = props.candidate.cityData;
  //   if (cityData !== undefined) {
  //     if (cityData?.data?.status == "success") {
  //       setcities(cityData.data.data.cities);
  //     }
  //   }
  // }, [props.data.loginData.data.data.countries]);

  // function onChangeCity(e) {
  //   setcityId(e.target.value);
  // }

  function validatename() {
    let formIsValid = false;
    if (!adddata["name"]) {
      formIsValid = false;
      seterrorname("*Enter company name.");
    } else if (typeof adddata["name"] === "undefined") {
      formIsValid = false;
      seterrorname("*Enter company name.");
    } else {
      formIsValid = true;
      seterrorname("");
    }
    return formIsValid;
  }
  function validateemail() {
    let formIsValid = false;
    if (!adddata["email"]) {
      formIsValid = false;
      seterroremail("*Enter E-mail ID.");
    } else if (typeof adddata["email"] !== "undefined") {
      if (
        !adddata["email"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        formIsValid = false;
        seterroremail("*Please enter valid E-mail ID.");
      } else {
        formIsValid = true;
        seterroremail("");
      }
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }

  function validateemployer_ceo() {
    let formIsValid = false;
    if (!adddata["employer_ceo"]) {
      formIsValid = false;
      seterroremployer_ceo("*Enter company CEO name.");
    } else if (typeof adddata["employer_ceo"] === "undefined") {
      formIsValid = false;
      seterroremployer_ceo("*Enter company CEO name.");
    } else if (!adddata["employer_ceo"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      formIsValid = false;
      seterroremployer_ceo("*Please enter Alphabet characters only.");
    } else {
      formIsValid = true;
      seterroremployer_ceo("");
    }

    return formIsValid;
  }
  function validateindustry() {
    let formIsValid = false;
    if (adddata["industry"] === "0") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else if (typeof adddata["industry"] === "undefined") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else {
      formIsValid = true;
      seterrorindustry("");
    }
    return formIsValid;
  }
  function validateownership_type() {
    let formIsValid = false;
    if (adddata["ownership_type"] === "0") {
      formIsValid = false;
      seterrorownership_type("*Select your ownership type.");
    } else if (typeof adddata["ownership_type"] === "undefined") {
      formIsValid = false;
      seterrorownership_type("*Select your ownership type.");
    } else {
      formIsValid = true;
      seterrorownership_type("");
    }
    return formIsValid;
  }
  function validatesize() {
    let formIsValid = false;
    if (adddata["size"] === "0") {
      formIsValid = false;
      seterrorsize("*Select your company size.");
    } else if (typeof adddata["size"] === "undefined") {
      formIsValid = false;
      seterrorsize("*Select your company size.");
    } else {
      formIsValid = true;
      seterrorsize("");
    }
    return formIsValid;
  }
  function validateemployer_details() {
    let formIsValid = false;
    if (!adddata["employer_details"]) {
      formIsValid = false;
      seterroremployer_details("*Enter company details.");
    } else if (typeof adddata["employer_details"] === "undefined") {
      formIsValid = false;
      seterroremployer_details("*Enter company details.");
    } else {
      formIsValid = true;
      seterroremployer_details("");
    }
    return formIsValid;
  }
  function validateestablished_in() {
    const currentYear = new Date().getFullYear();
    let formIsValid = false;
    if (!adddata["established_in"]) {
      formIsValid = false;
      seterrorestablished_in("*Enter established year.");
    } else if (typeof adddata["established_in"] === "undefined") {
      formIsValid = false;
      seterrorestablished_in("*Enter established year.");
    } else if (Number(adddata["established_in"]) > Number(currentYear)) {
      formIsValid = false;
      seterrorestablished_in(
        "*Established year should not be greater than current year."
      );
    } else {
      formIsValid = true;
      seterrorestablished_in("");
    }
    return formIsValid;
  }
  function validateno_of_offices() {
    let formIsValid = false;
    if (!adddata["no_of_offices"]) {
      formIsValid = false;
      seterrorno_of_offices("*Enter company no. of offices.");
    } else if (typeof adddata["no_of_offices"] === "undefined") {
      formIsValid = false;
      seterrorno_of_offices("*Enter company no. of offices.");
    } else {
      formIsValid = true;
      seterrorno_of_offices("");
    }
    return formIsValid;
  }

  function validatestatus() {
    let formIsValid = false;
    if (adddata["status"] === "0") {
      formIsValid = false;
      seterrorstatus("*Select your status.");
    } else if (typeof adddata["status"] === "undefined") {
      formIsValid = false;
      seterrorstatus("*Select your status.");
    } else {
      formIsValid = true;
      seterrorstatus("");
    }
    return formIsValid;
  }
  function validateis_featured() {
    let formIsValid = false;
    if (typeof adddata["is_featured"] === "undefined") {
      formIsValid = false;
      seterroris_featured("*Select whether company is featured or not.");
    } else {
      formIsValid = true;
      seterroris_featured("");
    }
    return formIsValid;
  }

  function validateAddForm() {  
    let name = validatename(); let email = validateemail();
    let industry = validateindustry(); let ownership_type = validateownership_type();
//    let employer_details = validateemployer_details();
    let valid = name && email && industry && ownership_type;
    return valid;
  }



  //edit form
  function validateeditname() {
    let formIsValid = false;
    if (!editdata["name"]) {
      formIsValid = false;
      seterrorname("*Enter company name.");
    } else if (typeof editdata["name"] === "undefined") {
      formIsValid = false;
      seterrorname("*Enter company name.");
    } else {
      formIsValid = true;
      seterrorname("");
    }
    return formIsValid;
  }
  function validateeditemail() {
    let formIsValid = false;
    if (!editdata["email"]) {
      formIsValid = false;
      seterroremail("*Enter your E-mail ID.");
    } else if (typeof editdata["email"] !== "undefined") {
      if (
        !editdata["email"].match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      ) {
        formIsValid = false;
        seterroremail("*Please enter valid E-mail ID.");
      } else {
        formIsValid = true;
        seterroremail("");
      }
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }

  function validateeditemployer_ceo() {
    let formIsValid = false;
    if (!editdata["employer_ceo"]) {
      formIsValid = false;
      seterroremployer_ceo("*Enter company CEO name.");
    } else if (typeof editdata["employer_ceo"] === "undefined") {
      formIsValid = false;
      seterroremployer_ceo("*Enter company CEO name.");
    } else if (!editdata["employer_ceo"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
      formIsValid = false;
      seterroremployer_ceo("*Please enter Alphabet characters only.");
    } else {
      formIsValid = true;
      seterroremployer_ceo("");
    }

    return formIsValid;
  }
  function validateeditindustry() {
    let formIsValid = false;
    if (editdata["industry"] === "0") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else if (typeof editdata["industry"] === "undefined") {
      formIsValid = false;
      seterrorindustry("*Select your industry.");
    } else {
      formIsValid = true;
      seterrorindustry("");
    }
    return formIsValid;
  }
  function validateeditownership_type() {
    let formIsValid = false;
    if (editdata["ownership_type"] === "0") {
      formIsValid = false;
      seterrorownership_type("*Select your ownership type.");
    } else if (typeof editdata["ownership_type"] === "undefined") {
      formIsValid = false;
      seterrorownership_type("*Select your ownership type.");
    } else {
      formIsValid = true;
      seterrorownership_type("");
    }
    return formIsValid;
  }
  function validateeditsize() {
    let formIsValid = false;
    if (editdata["size"] === "0") {
      formIsValid = false;
      seterrorsize("*Select your company size.");
    } else if (typeof editdata["size"] === "undefined") {
      formIsValid = false;
      seterrorsize("*Select your company size.");
    } else {
      formIsValid = true;
      seterrorsize("");
    }
    return formIsValid;
  }
  function validateeditemployer_details() {
    let formIsValid = false;
    if (!editdata["employer_details"]) {
      formIsValid = false;
      seterroremployer_details("*Enter company details.");
    } else if (typeof editdata["employer_details"] === "undefined") {
      formIsValid = false;
      seterroremployer_details("*Enter company details.");
    } else {
      formIsValid = true;
      seterroremployer_details("");
    }
    return formIsValid;
  }
  function validateeditestablished_in() {
    const currentYear = new Date().getFullYear();
    let formIsValid = false;
    if (!editdata["established_in"]) {
      formIsValid = false;
      seterrorestablished_in("*Enter established year.");
    } else if (typeof editdata["established_in"] === "undefined") {
      formIsValid = false;
      seterrorestablished_in("*Enter established year.");
    } else if (Number(editdata["established_in"]) > Number(currentYear)) {
      formIsValid = false;
      seterrorestablished_in(
        "*Established year should not be greater than current year."
      );
    } else {
      formIsValid = true;
      seterrorestablished_in("");
    }
    return formIsValid;
  }
  function validateeditno_of_offices() {
    let formIsValid = false;
    if (!editdata["no_of_offices"]) {
      formIsValid = false;
      seterrorno_of_offices("*Enter company no. of offices.");
    } else if (typeof editdata["no_of_offices"] === "undefined") {
      formIsValid = false;
      seterrorno_of_offices("*Enter company no. of offices.");
    } else {
      formIsValid = true;
      seterrorno_of_offices("");
    }
    return formIsValid;
  }

  function validateeditstatus() {
    let formIsValid = false;
    if (editdata["status"] === "0") {
      formIsValid = false;
      seterrorstatus("*Select your status.");
    } else if (typeof editdata["status"] === "undefined") {
      formIsValid = false;
      seterrorstatus("*Select your status.");
    } else {
      formIsValid = true;
      seterrorstatus("");
    }
    return formIsValid;
  }
  function validateeditis_featured() {
    let formIsValid = false;
    if (typeof editdata["is_featured"] === "undefined") {
      formIsValid = false;
      seterroris_featured("*Select whether company is featured or not.");
    } else {
      formIsValid = true;
      seterroris_featured("");
    }
    return formIsValid;
  }

  function validateEditForm() {
    let name = validateeditname(); let email = validateeditemail();
    let industry = validateeditindustry(); let ownership_type = validateeditownership_type();
    let employer_details = validateeditemployer_details();
    let valid = name && email && industry && ownership_type && employer_details;
    return valid;
  }

  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }
  function onchangeeditdata(e) {
    seteditdata((editdata) => ({
      ...editdata,
      [e.target.name]: e.target.value,
    }));
  }

  const saveaddtoggle = (e) => {
    e.preventDefault();
    console.log('save');
    if (validateAddForm()) {
      props.requestAdminAddCompany({
        data: {
          name: adddata.name,
          user_id: 1,
          gst_no: adddata.gst_no,
          email: adddata.email,
          employer_ceo: adddata.employer_ceo,
          industry: adddata.industry,
          ownership_type: adddata.ownership_type,
          size: adddata.size,
          employer_details: adddata.employer_details,
          established_in: adddata.established_in,
          no_of_offices: adddata.no_of_offices,
          fax: adddata.fax,
          website: adddata.website,
          status: adddata.status,
          is_featured: adddata.is_featured,
          facebook_url: adddata.facebook_url,
          twitter_url: adddata.twitter_url,
          linkedin_url: adddata.linkedin_url,
          google_plus_url: adddata.google_plus_url,
          pinterest_url: adddata.pinterest_url
        },
      });
    }
  };

  useEffect(() => {
    let addCompanyData = props.data.addCompanyData;
    if (addCompanyData !== undefined) {
      if (addCompanyData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Company Added Successfully.",
          "success"
        );
        props.data.addCompanyData = undefined;
        setadddata({
          name: "",
          description: "",
        });        
        addtoggle();
        setchange(!change);
      } else {
        Swal.fire("Error!", `${addCompanyData.data.message}`, "error");
        props.data.addCompanyData = undefined;
      }
    }
  }, [props.data.addCompanyData]);

  const edittoggle = (id) => {
    console.log('id');
    console.log(id);
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(id);
      seteditdata({
        name: " ",
        gst_no: " ",
        email: " ",
        employer_ceo: " ",
        industry: 0,
        ownership_type: 0,
        size: 0,
        employer_details: " ",
        established_in: " ",
        no_of_offices: 0,
        fax: " ",
        website: " ",
        status: 0,
        is_featured: 1,
        facebook_url: " ",
        twitter_url: " ",
        linkedin_url: " ",
        google_plus_url: " ",
        pinterest_url: " ",
      });
      props.requestAdminGetCompany({
        id: id,
      });
    }
  };

  useEffect(() => {
    let company = props.data.getCompanyData;    
    if (company !== undefined) {
      if (company.data) {
        console.log('company.data.data.Company[0]');
        console.log(company.data.data.Company[0]);
        seteditdata(company.data.data.Company[0]);
      }
    }
  }, [props.data.getCompanyData]);

  const saveedittoggle = (e) => {
    e.preventDefault();    
    if (validateEditForm()) {
      console.log('here');
      props.requestAdminEditCompany({
        id: id,
        data: {
          name: editdata.name,
          user_id: 1,
          gst_no: editdata.gst_no,
          email: editdata.email,
          employer_ceo: editdata.employer_ceo,
          industry: editdata.industry,
          ownership_type: editdata.ownership_type,
          size: editdata.size,
          employer_details: editdata.employer_details,
          established_in: editdata.established_in,
          no_of_offices: editdata.no_of_offices,
          fax: editdata.fax,
          website: editdata.website,
          status: editdata.status,
          is_featured: editdata.is_featured,
          facebook_url: editdata.facebook_url,
          twitter_url: editdata.twitter_url,
          linkedin_url: editdata.linkedin_url,
          google_plus_url: editdata.google_plus_url,
          pinterest_url: editdata.pinterest_url
        },
      });
    }
  };

  useEffect(() => {
    let editCompanyData = props.data.editCompanyData;
    if (editCompanyData !== undefined) {
      if (editCompanyData?.data?.status == "success") {
        Swal.fire(
          "Good job!",
          "Company Updated Successfully.",
          "success"
        );
        props.data.editCompanyData = undefined;
        seteditdata({
          name: "",
          description: "",
        });
        edittoggle();
        setchange(!change);
      } else {
        Swal.fire("Error!", `${editCompanyData.data.message}`, "error");
        props.data.editCompanyData = undefined;
      }
    }
  }, [props.data.editCompanyData]);

  useEffect(() => {
    props.requestAdminCompanies({
      page: params.page,
      page_size: params.page_size,
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/companies/1/${e.target.value}`);
    }
  }

  useEffect(() => {
    let companiesData = props.data.companiesData;
    if (companiesData !== undefined) {
      if (companiesData.data) {
        setcompanies(companiesData.data.data);
        setpagination(companiesData.data.meta);
        if (companiesData.data.meta.last_page < 5) {
          let array = [];
          Array.from(Array(companiesData.data.meta.last_page), (e, i) => {
            array.push(i + 1);
          });
          setpages(array);
        } else {
          let array = [];
          if (companiesData.data.meta.current_page <= 3) {
            array.push(1, 2, 3, 4, 5);
            setpages(array);
          } else if (
            companiesData.data.meta.last_page -
            companiesData.data.meta.current_page <=
            2
          ) {
            array.push(
              companiesData.data.meta.last_page - 4,
              companiesData.data.meta.last_page - 3,
              companiesData.data.meta.last_page - 2,
              companiesData.data.meta.last_page - 1,
              companiesData.data.meta.last_page
            );
            setpages(array);
          } else {
            array.push(
              Number(companiesData.data.meta.current_page) - 2,
              Number(companiesData.data.meta.current_page) - 1,
              companiesData.data.meta.current_page,
              Number(companiesData.data.meta.current_page) + 1,
              Number(companiesData.data.meta.current_page) + 2
            );
            setpages(array);
          }
        }
      }
    }
  }, [props.data.companiesData]);

  // useEffect(() => {
    
  //   let empData = props.data.companiesData;
  //   if (empData !== undefined) {
  //     if (empData?.data?.status == "success") {
  //       setcountryId(empData.data.data.country);
  //       props.requestAdminState({
  //         id: empData.data.data.country,
  //       });
  //       setstateId(empData.data.data.state);
  //       props.requestCity({
  //         id: empData.data.data.state,
  //       });
  //       setcityId(empData.data.data.city);
  //     }
  //   }
  // }, [props.data.companiesData]);

  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteCompany({
          id: id,
        });
      }
    })
  }

  useEffect(() => {
    let deleteCompanyData = props.data.deleteCompanyData;
    if (deleteCompanyData !== undefined) {
      if (deleteCompanyData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Comapny name deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteCompanyData = undefined;
  }, [props.data.deleteCompanyData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Companies</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <>
      <div class="container-scroller">
        <Header name="Companies" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Companies" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/companies/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                              <div>
                                <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={addtoggle}
                                >
                                  Add new Company
                                </button>
                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                  <ModalHeader toggle={addtoggle}>
                                    Add Company
                                  </ModalHeader>
                                  <ModalBody>
                                    <form class="forms-sample">
                                    <div class="row">
                                      <h3 class="title">Basic Information</h3>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Company Name*</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="name"
                                            value={adddata.name}
                                            onBlur={validatename}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                          {errorname && <p>{errorname}</p>}
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Company Email*</label>
                                          <input
                                            class="form-control"
                                            type="email"
                                            name="email"
                                            value={adddata.email}
                                            onBlur={validateemail}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                          {erroremail && <p>{erroremail}</p>}
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Fax No.</label>
                                          <input
                                            class="form-control"
                                            type="number"
                                            name="fax"
                                            value={adddata.fax}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Company Website</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="website"
                                            value={adddata.website}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Status</label>
                                          <select
                                            class="form-select"
                                            name="status"
                                            value={adddata.status}
                                            onChange={onchangeadddata}
                                            >
                                            <option value="0">Select Status</option>
                                            <option value="1">Active</option>
                                            <option value="2">In Active</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div style={{ color: "black" }}>
                                          <label for="gender" class="label">
                                            Is Featured
                                          </label>
                                          <br />
                                          <br />
                                          <div class="form-check form-check-inline">
                                            <input
                                              class="form-check-input"
                                                type="radio"
                                              style={{margin:"0px"}}
                                              id="yes"
                                              name="is_featured"
                                              value="1"
                                              onChange={onchangeadddata}    
                                              checked
                                            />
                                            <label
                                              class="form-check-label"
                                              for="inlineRadio1"
                                            >
                                              Yes
                                            </label>
                                          </div>
                                          <div class="form-check form-check-inline">
                                            <input
                                              class="form-check-input"
                                                type="radio"
                                              style={{margin:"0px"}}
                                              id="no"
                                              name="is_featured"
                                              value="0"
                                              onChange={onchangeadddata}    
                                              checked={adddata.is_featured === 0}
                                            />
                                            <label
                                              class="form-check-label"
                                              for="inlineRadio2"
                                            >
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <h3 class="title">Company Profile</h3>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Company CEO</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="employer_ceo"
                                            value={adddata.employer_ceo}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Established In</label>
                                          <input
                                            class="form-control"
                                            type="number"
                                            name="established_in"
                                            value={adddata.established_in}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Industry*</label>
                                          <select
                                            class="form-select"
                                            name="industry"
                                            value={adddata.industry}
                                            onBlur={validateindustry}
                                            onChange={onchangeadddata}
                                          >
                                            <option value="0">Select Industry</option>
                                            {industry.map((option) => (
                                              <option value={option.id}>
                                                {option.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errorindustry && <p>{errorindustry}</p>}
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Ownership Type*</label>
                                          <select
                                            class="form-select"
                                            name="ownership_type"
                                            value={adddata.ownership_type}
                                            onBlur={validateownership_type}
                                            onChange={onchangeadddata}
                                          >
                                            <option value="0">
                                              Select Ownership Type
                                            </option>
                                            {owner.map((option) => (
                                              <option value={option.id}>
                                                {option.name}
                                              </option>
                                            ))}
                                          </select>
                                          {errorownership_type && <p>{errorownership_type}</p>}
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Size of Company</label>
                                          <select
                                            class="form-select"
                                            name="size"
                                            value={adddata.size}
                                            onChange={onchangeadddata}
                                          >
                                            <option value="0">Select Company Size</option>
                                            {size.map((option) => (
                                              <option value={option.id}>
                                                {option.size}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>No. of offices</label>
                                          <input
                                            class="form-control"
                                            type="number"
                                            name="no_of_offices"
                                            value={adddata.no_of_offices}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-12">
                                        <div class="form-group">
                                          <label>Company Details</label>
                                          <textarea
                                            class="form-control"
                                            rows="5"
                                            name="employer_details"
                                            value={adddata.employer_details}
                                            onBlur={validateemployer_details}
                                            onChange={onchangeadddata}
                                            style={{ height: "100px" }}
                                            placeholder=""
                                          ></textarea>
                                          {erroremployer_details && <p>{erroremployer_details}</p>}
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>GST No.</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="gst_no"
                                            value={adddata.gst_no}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <h3 class="title">Social Links</h3>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Facebook URL</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="facebook_url"
                                            value={adddata.facebook_url}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Twitter URL</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="twitter_url"
                                            value={adddata.twitter_url}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Linkedin URL</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="linkedin_url"
                                            value={adddata.linkedin_url}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Google Plus URL</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="google_plus_url"
                                            value={adddata.google_plus_url}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                      <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                          <label>Pintrest URL</label>
                                          <input
                                            class="form-control"
                                            type="text"
                                            name="pinterest_url"
                                            value={adddata.pinterest_url}
                                            onChange={onchangeadddata}
                                            placeholder=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                      <button
                                        type="submit"
                                        class="btn btn-primary me-2"
                                        onClick={saveaddtoggle}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        class="btn btn-light"
                                        onClick={addtoggle}
                                      >
                                        Cancel
                                      </button>
                                    </form>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr >
                                    <th>Company</th>
                                    <th>Contact</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {companies.map((item, index) => {
                                    const img = item.logo
                                      ? process.env.REACT_APP_API_HOST + item.logo
                                      : image;
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <a href={`/admin/viewCompany/${item.id}`}>  <img src={img} alt="" /></a>
                                            <div>
                                              <a href={`/admin/viewCompany/${item.id}`}>  <h6>{item.name}</h6></a>
                                              <p>
                                                <i class="fa fa-map-marker"></i>{" "}
                                                {item.city_name},{" "}
                                                {item.state_name},{" "}
                                                {item.country_name}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <h6>
                                            <a href={`mailto:${item.email}`}>
                                              {item.email}
                                            </a>
                                          </h6>
                                          <p>
                                            <a
                                              href={`${item.website}`}
                                              target="_blank"
                                            >
                                              {item.website}
                                            </a>
                                          </p>
                                          {item.fax && (
                                            <p>
                                              <a href={`callto:${item.fax}`}>
                                                {item.fax}
                                              </a>
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          <h6>{item.ownership_type_name}</h6>
                                          <p>{item.industries_name}</p>
                                        </td>
                                        <td>
                                          <a href={`/admin/viewCompany/${item.id}`}>
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-eye"></i>
                                            </button>
                                          </a>
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              edittoggle(item.id);
                                            }}
                                          >
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit Company
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="row">
                                                    <h3 class="title">Basic Information</h3>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Company Name*</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="name"
                                                          value={editdata.name}
                                                          onBlur={validateeditname}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                        {errorname && <p>{errorname}</p>}
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Company Email*</label>
                                                        <input
                                                          class="form-control"
                                                          type="email"
                                                          name="email"
                                                          value={editdata.email}
                                                          onBlur={validateeditemail}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                        {erroremail && <p>{erroremail}</p>}
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Fax No.</label>
                                                        <input
                                                          class="form-control"
                                                          type="number"
                                                          name="fax"
                                                          value={editdata.fax}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Company Website</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="website"
                                                          value={editdata.website}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Status</label>
                                                        <select
                                                          class="form-select"
                                                          name="status"
                                                          value={editdata.status}
                                                          onChange={onchangeeditdata}
                                                          >
                                                          <option value="0">Select Status</option>
                                                          <option value="1">Developed</option>
                                                          <option value="2">Developing</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div style={{ color: "black" }}>
                                                        <label for="gender" class="label">
                                                          Is Featured
                                                        </label>
                                                        <br />
                                                        <br />
                                                        <div class="form-check form-check-inline">
                                                          <input
                                                            class="form-check-input"
                                                              type="radio"
                                                            style={{margin:"0px"}}
                                                            id="yes"
                                                            name="is_featured"
                                                            value="1"
                                                            onChange={onchangeeditdata}    
                                                            checked={editdata.is_featured === 1}
                                                          />
                                                          <label
                                                            class="form-check-label"
                                                            for="inlineRadio1"
                                                          >
                                                            Yes
                                                          </label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                          <input
                                                            class="form-check-input"
                                                              type="radio"
                                                            style={{margin:"0px"}}
                                                            id="no"
                                                            name="is_featured"
                                                            value="0"
                                                            onChange={onchangeeditdata}
                                                            checked={editdata.is_featured === 0}
                                                          />
                                                          <label
                                                            class="form-check-label"
                                                            for="inlineRadio2"
                                                          >
                                                            No
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <h3 class="title">Company Profile</h3>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Company CEO</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="employer_ceo"
                                                          value={editdata.employer_ceo}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Established In</label>
                                                        <input
                                                          class="form-control"
                                                          type="number"
                                                          name="established_in"
                                                          value={editdata.established_in}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Industry</label>
                                                        <select
                                                          class="form-select"
                                                          name="industry"
                                                          value={editdata.industry}
                                                          onBlur={validateeditindustry}
                                                          onChange={onchangeeditdata}
                                                        >
                                                          <option value="0">Select Industry</option>
                                                          {industry.map((option) => (
                                                            <option value={option.id}>
                                                              {option.name}
                                                            </option>
                                                          ))}
                                                        </select>
                                                        {errorindustry && <p>{errorindustry}</p>}
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Ownership Type</label>
                                                        <select
                                                          class="form-select"
                                                          name="ownership_type"
                                                          value={editdata.ownership_type}
                                                          onBlur={validateeditownership_type}
                                                          onChange={onchangeeditdata}
                                                        >
                                                          <option value="0">
                                                            Select Ownership Type
                                                          </option>
                                                          {owner.map((option) => (
                                                            <option value={option.id}>
                                                              {option.name}
                                                            </option>
                                                          ))}
                                                        </select>
                                                        {errorownership_type && <p>{errorownership_type}</p>}
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Size of Company</label>
                                                        <select
                                                          class="form-select"
                                                          name="size"
                                                          value={editdata.size}
                                                          onChange={onchangeeditdata}
                                                        >
                                                          <option value="0">Select Company Size</option>
                                                          {size.map((option) => (
                                                            <option value={option.id}>
                                                              {option.size}
                                                            </option>
                                                          ))}
                                                        </select>
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>No. of offices</label>
                                                        <input
                                                          class="form-control"
                                                          type="number"
                                                          name="no_of_offices"
                                                          value={editdata.no_of_offices}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-12">
                                                      <div class="form-group">
                                                        <label>Company Details</label>
                                                        <textarea
                                                          class="form-control"
                                                          rows="5"
                                                          name="employer_details"
                                                          value={editdata.employer_details}
                                                          onBlur={validateeditemployer_details}
                                                          onChange={onchangeeditdata}
                                                          style={{ height: "100px" }}
                                                          placeholder=""
                                                        ></textarea>
                                                        {erroremployer_details && <p>{erroremployer_details}</p>}
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>GST No.</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="gst_no"
                                                          value={editdata.gst_no}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <h3 class="title">Social Links</h3>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Facebook URL</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="facebook_url"
                                                          value={editdata.facebook_url}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Twitter URL</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="twitter_url"
                                                          value={editdata.twitter_url}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Linkedin URL</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="linkedin_url"
                                                          value={editdata.linkedin_url}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Google Plus URL</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="google_plus_url"
                                                          value={editdata.google_plus_url}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                      <div class="form-group">
                                                        <label>Pintrest URL</label>
                                                        <input
                                                          class="form-control"
                                                          type="text"
                                                          name="pinterest_url"
                                                          value={editdata.pinterest_url}
                                                          onChange={onchangeeditdata}
                                                          placeholder=""
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                                </ModalBody>
                                            </Modal>
                                          )}
                                          <a href={`/admin/addressCompany/${item.id}`} title="Add Address">
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-address-book"></i>
                                            </button>
                                          </a>
                                          <a href={`/admin/contactCompany/${item.id}`} title="Add Contact">
                                            <button
                                              class="badge badge-opacity-primary"
                                              style={{ border: "0px" }}
                                            >
                                              <i class="fa fa-address-card"></i>
                                            </button>
                                          </a>
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              deletedata(item.id);
                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>

                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/companies/${params.page - 1
                                            }/${params.page_size}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/companies/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/companies/${i}/${params.page_size}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/companies/${Number(params.page) + 1
                                              }/${params.page_size}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ 
    requestAdminCompanies, requestAdminAddCompany, requestAdminGetCompany, requestAdminEditCompany, requestAdminDeleteCompany,
    requestAdminCountry, requestAdminState, requestAdminCity}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
