import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { requestCandidateFollowUpAll } from "../Redux/actions";
import Footer from './footer';
import PageHeader from './header';
import Sidebar from './sidebar';
import './style.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import * as ReactBootStrap from "react-bootstrap";

function CandidateFollowUpAll(props) {
  const params = useParams();
  const [jobs, setjobs] = useState([]);
  // const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(10);
  const [change, setchange] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState('');
  const [fromdate, setFromDate] = useState('');
  const [todate, setToDate] = useState('');
  const [status, setStatus] = useState('all');

  const [filter, setFilter] = useState([]);
  const [logindetails, setLoginDetails] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [sourcing, setSourcing] = useState('0');
  const [screening, setScreening] = useState('0');
  const [assessment, setAssessment] = useState('0');
  const [rejected, setRejected] = useState('0');
  const [toClient, setToClient] = useState('0');
  const [sentProfile, setSentProfile] = useState('0');
  const [shortlisted, setShortlisted] = useState('0');
  const [interviewed, setInterviewed] = useState('0');
  const [offered, setOffered] = useState('0');
  const [joined, setJoined] = useState('0');
  const [invoice, setInvoice] = useState('0');

  const handleSearch = (event) => {
    console.log(event.target.value);
    setSearch(event.target.value);
  };

  const columns = [
    {
      name: 'Company',
      cell: item => <div><a href={'/admin/viewCompany/' + item.company_id}></a>
        <div>
          <a href={'/admin/viewCompany/' + item.company_id}><p>{item.company_name}</p></a>
          <p><i class="fa fa-map-marker"></i>{" "}{item.locations}</p>
        </div>
        <div><p>Created Date: {item.job_created.substring(0, 10)}</p>
          <p>End Date: {item.expiry_date}</p></div>
      </div>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Job Title',
      cell: item => <a href={'/admin/viewJob/' + item.id}>  <p>{item.title}
        <p>Industry: {item.industry_name}</p>
        <p>Function Area: {item.functional_area_name}</p></p>
        <p>Salary: {item.currency} {item.salary_from}-{item.salary_to} {item.min_exp > 0 ? 'Exp.: ' + item.min_exp + ' to ' + item.max_exp + " years" : "Fresher"}</p></a>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Cand. Name',
      cell: item => <div><p>{item.first_name} {item.last_name}</p>
        <div><p><b>Created Date: </b></p><p>{item.created_at.substring(0, 10)}</p></div>
      </div>,
      selector: item => item.id,
      width: '18%'
    },
    {
      name: 'Status',
      cell: item => <div><p class='badge' style={{
        color: "white", backgroundColor: (item.status == "Sourcing" ? "#9FE2BF" : item.status == "Offered" ? "#6E2C00" : item.status == "Screening" ? "#78281F" : item.status == "Move to the Assessment" ? "#4A235A" : item.status == "Rejected" ? "#B00020" :
          item.status == "Move to the Client" ? "#1B4F72" : item.status == "Sent Profile" ? "#154360" : item.status == "Shortlisted" ? "#186A3B" : item.status == "Interviewed" ? "#7E5109" : item.status == "Joined" ? "#424949" : item.status == "Invoice" ? "#17202A" : "white")
      }}>{item.status}</p><p>{item.remark}</p></div>,
      selector: item => item.status,
      width: '20%'
    },
    {
      name: 'Follow Up',
      cell: item => <div>
        <p>{item.fu_date} {item.fu_time}</p>
        <p>{item.remark}</p>
      </div>,
      selector: item => item.id,
      width: '14%'
    },
  ];

  //form field
  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    setLoginDetails(formfieldData.admin_detail);
  }, [props.data.loginData.data.data]);

  //request job list
  useEffect(() => {
    props.requestCandidateFollowUpAll({
      page: params.page,
      page_size: params.page_size,
      //      search: ''
    });
    setpage_size(params.page_size);

  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/jobs/1/${e.target.value}`);
    }
  }

  function onChangeFromDate(e) {
    setFromDate(e.target.value);
  }

  function onChangeToDate(e) {
    setToDate(e.target.value);
  }

  function onClickStatus(stats) {
    setStatus(stats);
  }

  //set job list
  useEffect(() => {
    setToDate(to_date);
    setFromDate(from_date);
    let jobsData = props.data.CandidateFollowUpAllData;
    console.log(jobsData.data.data);

    if (jobsData.data.data) {
      if (jobsData.data.data[0] !== undefined) {
        setFilter(jobsData.data.data);
        setTotalRows(jobsData.data.meta.total);
        const job_array = jobsData.data.data[0].job_status.split('|');
        job_array.forEach((key) => {
          const status_array = key.split('^');
          if (status_array[0] === 'Sourcing') {
            setSourcing(status_array[1]);
          }
          else if (status_array[0] === 'Screening') {
            setScreening(status_array[1]);
          }
          else if (status_array[0] === 'Move to the Assessment') {
            setAssessment(status_array[1]);
          }
          else if (status_array[0] === 'Rejected') {
            setRejected(status_array[1]);
          }
          else if (status_array[0] === 'Move to the Client') {
            setToClient(status_array[1]);
          }
          else if (status_array[0] === 'Sent Profile') {
            setSentProfile(status_array[1]);
          }
          else if (status_array[0] === 'Shortlisted') {
            setShortlisted(status_array[1]);
          }
          else if (status_array[0] === 'Interviewed') {
            setInterviewed(status_array[1]);
          }
          else if (status_array[0] === 'Offered') {
            setOffered(status_array[1]);
          }
          else if (status_array[0] === 'Joined') {
            setJoined(status_array[1]);
          }
          else if (status_array[0] === 'Invoice') {
            setInvoice(status_array[1]);
          }
        })
      }
    }
  }, [props.data.jobsData]);

  const handlePageChange = page => {
    console.log(page);
    props.requestCandidateFollowUpAll({
      page: page,
      page_size: page_size,
      search: ''
    });
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setpage_size(newPerPage);
    props.requestCandidateFollowUpAll({
      page: page,
      page_size: newPerPage,
      //      search: ''
    });
  }

  function printPage() {
    var printContents = "<br/><center><h3>Jobs</h3></center>" + document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  useEffect(() => {
    setTypingTimeout(setTimeout(() => {
      props.requestCandidateFollowUpAll({
        page: 1,
        page_size: page_size,
        search: search
      });
    }, 3000));
  }, [search]);

  useEffect(() => {
    var loopData = 'page=' + pages + 'page_size=' + page_size + '&search=' + search + '&fromdate=' + fromdate + '&todate=' + todate + '&status=' + status;
    if (typingTimeout) clearTimeout(typingTimeout);
    var bln_load = false;
    setTypingTimeout(setTimeout(() => {
      bln_load = true;
      setFilter([]);
      axios.get(process.env.REACT_APP_API_HOST + "/api/admin/getfollowupwise?" + loopData)
        .then(response => {
          const timeout = setTimeout(() => {
            if (bln_load === true) {
              let candidatesData = response.data;
              setFilter(candidatesData.data);
              setTotalRows(candidatesData.meta.total);
              console.log(candidatesData.data);
              const job_array = candidatesData.data[0].job_status.split('|');
              job_array.forEach((key) => {
                const status_array = key.split('^');
                if (status_array[0] === 'Sourcing') {
                  setSourcing(status_array[1]);
                }
                else if (status_array[0] === 'Screening') {
                  setScreening(status_array[1]);
                }
                else if (status_array[0] === 'Move to the Assessment') {
                  setAssessment(status_array[1]);
                }
                else if (status_array[0] === 'Rejected') {
                  setRejected(status_array[1]);
                }
                else if (status_array[0] === 'Move to the Client') {
                  setToClient(status_array[1]);
                }
                else if (status_array[0] === 'Sent Profile') {
                  setSentProfile(status_array[1]);
                }
                else if (status_array[0] === 'Shortlisted') {
                  setShortlisted(status_array[1]);
                }
                else if (status_array[0] === 'Interviewed') {
                  setInterviewed(status_array[1]);
                }
                else if (status_array[0] === 'Offered') {
                  setOffered(status_array[1]);
                }
                else if (status_array[0] === 'Joined') {
                  setJoined(status_array[1]);
                }
                else if (status_array[0] === 'Invoice') {
                  setInvoice(status_array[1]);
                }
              })
              setPending(false);
              bln_load = false;
              setLoading(false);
            }
          }, 2000);
          return () => clearTimeout(timeout);
        })
        .catch(err => {
          console.log(err);

        });
      setLoading(true);
    }, 3000));
  }, [search, fromdate, todate, status]);

  const tableHeaderStyle = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px"
      },
    },
  }
  var curr = new Date();
  var to_date = curr.toISOString().substring(0, 10);
  curr.setDate(curr.getDate() - 15);
  var from_date = curr.toISOString().substring(0, 10);


  return (
    <>
      <div class="container-scroller">
        <PageHeader name="Candidate Followups" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="CandidateFollowUpAll" />
          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "HR Ace",
                                    url: `http://localhost:3000/admin/jobs/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center"><i class="icon-share"></i> Share</p>
                                </RWebShare>
                                <button onClick={printPage} class="btn btn-otline-dark"><i class="icon-printer"></i> Print</button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-3"><label class="col-md-4">From Date</label><input class="form-control col-md-2" type="date" defaultValue={from_date} onChange={onChangeFromDate} /></div>
                              <div class="col-md-3"><label class="col-md-4">To Date</label><input class="form-control col-md-2" type="date" defaultValue={to_date} onChange={onChangeToDate} /></div>
                            </div>
                            <br />
                            <div class="col">
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#3700B3" }} href={`/admin/candidates/1/10/${params.id}/all`} onClick={() => { onClickStatus('all'); }}>  All</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#9FE2BF" }} href={`/admin/candidates/1/10/${params.id}/Sourcing`} onClick={() => { onClickStatus('Sourcing'); }}>  Sourcing ({sourcing})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#78281F" }} href={`/admin/candidates/1/10/${params.id}/Screening`} onClick={() => { onClickStatus('Screening'); }}>  Screening ({screening})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#4A235A" }} href={`/admin/candidates/1/10/${params.id}/Move to the Assessment`} onClick={() => { onClickStatus('Move to the Assessment'); }}>  Move to the Assessment ({assessment})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#B00020" }} href={`/admin/candidates/1/10/${params.id}/Rejected`} onClick={() => { onClickStatus('Rejected'); }}>  Rejected ({rejected})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#1B4F72" }} href={`/admin/candidates/1/10/${params.id}/Move to the Client`} onClick={() => { onClickStatus('Move to the Client'); }}>  Move to the Client ({toClient})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#154360" }} href={`/admin/candidates/1/10/${params.id}/Sent Profile`} onClick={() => { onClickStatus('Sent Profile'); }}>  Sent Profile ({sentProfile})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#186A3B" }} href={`/admin/candidates/1/10/${params.id}/Shortlisted`} onClick={() => { onClickStatus('Shortlisted'); }}>  Shortlisted ({shortlisted})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#7E5109" }} href={`/admin/candidates/1/10/${params.id}/Interviewed`} onClick={() => { onClickStatus('Interviewed'); }}>  Interviewed ({interviewed})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#6E2C00" }} href={`/admin/candidates/1/10/${params.id}/Offered`} onClick={() => { onClickStatus('Offered'); }}>Offered ({offered})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#424949" }} href={`/admin/candidates/1/10/${params.id}/Joined`} onClick={() => { onClickStatus('Joined'); }}>Joined ({joined})</button>
                              <button class='badge' style={{ border: "0px", color: "white", backgroundColor: "#17202A" }} href={`/admin/candidates/1/10/${params.id}/Invoice`} onClick={() => { onClickStatus('Invoice'); }}>Invoice ({invoice})</button>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              {loading == false ?
                                <DataTable
                                  columns={columns}
                                  data={filter}
                                  pagination
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangePage={handlePageChange}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  actions={<button className="btn btn-success">Export to PDF</button>}
                                  subHeader
                                  subHeaderComponent={
                                    <input id="search" type="text" className="w-25 form-control" placeholder="Search..." value={search} onChange={handleSearch} />
                                  }
                                  customStyles={tableHeaderStyle}
                                /> : <ReactBootStrap.Spinner animation="border" />}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestCandidateFollowUpAll }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateFollowUpAll);