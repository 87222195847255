import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams, Redirect } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { requestAdminAllUser, requestAdminDeleteUser } from "../Redux/actions";
import image from "../images/profile.png";
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";

function Users(props) {

  const params = useParams();
  const [users, setusers] = useState([]);
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [change, setchange] = useState(false);
  const [admin_id, setadmin_id] = useState(0);

  useEffect(() => {
    let formfieldData = props.data.loginData.data.data;
    console.log(formfieldData.admin_detail.user_list);
    if (formfieldData !== undefined) {
        // settoken(formfieldData.token);
        setadmin_id(formfieldData.id);
        if(formfieldData.admin_detail.user_list === 0){
          navigate(`/admin/home`);    
        }
    }
}, [props.data.loginData.data.data]);

  useEffect(() => {
    props.requestAdminAllUser({
      page: params.page,
      page_size: params.page_size,
      admin_id: admin_id
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  function onChangePageSize(e) {
    setpage_size(e.target.value);
    if (e.target.value > 0) {
      navigate(`/admin/user/1/${e.target.value}`);
    }
  }

  useEffect(() => {
    let usersData = props.data.allUserData;
    console.log(usersData);
    if (usersData !== undefined) {
      if (usersData.data) {
        setusers(usersData.data.data);
      }
    }
  }, [props.data.allUserData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Users</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteUser({
          id: id,
        });
      }
    })
  }

  //delete
  useEffect(() => {
    let deleteJobData = props.data.deleteUserData;
    if (deleteJobData !== undefined) {
      if (deleteJobData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'User deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteUserData = undefined;
  }, [props.data.deleteUserData]);

  const onclickadd = (event) => {
      navigate(`/admin/useradd`);
  };

  return (
    <>
      <div class="container-scroller">
        <Header name="Users" />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Users" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `https://recruitererp.com/admin/user/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                            </div>
                            <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={onclickadd}
                                >
                                  Add new user
                                </button>

                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>User Rights</th>
                                    <th>Candidate Rights</th>
                                    <th>job Rights</th>
                                    <th>Added By</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <h6>
                                            {item.name}
                                          </h6>
                                          <h6>
                                            <a href={`mailto:${item.email}`}>
                                              {item.email}
                                            </a>
                                          </h6>
                                          {item.phone_no && (
                                            <p>
                                              <a href={`callto:${item.phone_no}`}>
                                                {item.phone_no}
                                              </a>
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          <p><b>User Add:</b> {item.user_add == 1 ? "Yes" : "No"}</p>
                                          <p><b>User Edit:</b> {item.user_edit == 1 ? "Yes" : "No"}</p>
                                          <p><b>User Delete:</b> {item.user_delete == 1 ? "Yes" : "No"}</p>
                                          <p><b>User Display:</b> {item.user_list == 1 ? "Yes" : "No"}</p>
                                        </td>
                                        <td>
                                          <p><b>Candidate Add:</b> {item.cand_add == 1 ? "Yes" : "No"}</p>
                                          <p><b>Candidate Edit:</b> {item.cand_edit == 1 ? "Yes" : "No"}</p>
                                          <p><b>Candidate Delete:</b> {item.cand_delete == 1 ? "Yes" : "No"}</p>
                                          <p><b>Candidate Assign Job:</b> {item.cand_assign_job == 1 ? "Yes" : "No"}</p>
                                        </td>
                                        <td>
                                          <p><b>Job Add:</b> {item.job_add == 1 ? "Yes" : "No"}</p>
                                          <p><b>Job Edit:</b> {item.job_edit == 1 ? "Yes" : "No"}</p>
                                          <p><b>Job Delete:</b> {item.job_delete == 1 ? "Yes" : "No"}</p>
                                          <p><b>Job Status:</b> {item.job_status == 1 ? "Yes" : "No"}</p>
                                        </td>
                                        <td>{item.admin_name}</td>
                                        <td>
                                          <p>{item.created_at !== null ? item.created_at.substring(0, 10) : ''}</p>
                                        </td>
                                        <td>
                                          <a href={`/admin/useredit/${item.id}`} title="Edit User">
                                            <button
                                              class="badge badge-opacity-success"
                                              style={{ border: "0px" }}>
                                              <i class="fa fa-pencil-square-o"></i>
                                            </button>
                                          </a>
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              deletedata(item.id);
                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                            <div class="row">
                              <div class="col-10">
                                <div class="pagination center">
                                  <ul class="pagination-list">
                                    {pagination.current_page !== 1 && (
                                      <li>
                                        <a
                                          href={`/admin/user/${params.page - 1
                                            }/${params.page_size}/${params.job_id}`}
                                        >
                                          <i class="fa fa-long-arrow-left"></i>
                                        </a>
                                      </li>
                                    )}
                                    {pages.map((i) => {
                                      return pagination.current_page === i ? (
                                        <li class="active">
                                          <a
                                            href={`/admin/user/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      ) : (
                                        <li>
                                          <a
                                            href={`/admin/user/${i}/${params.page_size}/${params.job_id}`}
                                          >
                                            {i}
                                          </a>
                                        </li>
                                      );
                                    })}
                                    {pagination.current_page !==
                                      pagination.last_page && (
                                        <li>
                                          <a
                                            href={`/admin/user/${Number(params.page) + 1
                                              }/${params.page_size}/${params.job_id}`}
                                          >
                                            <i class="fa fa-long-arrow-right"></i>
                                          </a>
                                        </li>
                                      )}
                                  </ul>
                                </div>
                              </div>
                              <div class="col-2">
                                <div class="pagination center">
                                  <form onSubmit={onChangePageSize}>
                                    <div class="form-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        placeholder={page_size}
                                        name="page_size"
                                        value={page_size}
                                        onChange={onChangePageSize}
                                        style={{ width: "100px" }}
                                      />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ requestAdminAllUser, requestAdminDeleteUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);
