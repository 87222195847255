import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import { RWebShare } from "react-web-share";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  requestAdminAddCompContact, requestAdminAllCompContact, requestAdminGetCompContact, requestAdminDeleteCompContact,
  requestAdminEditCompContact, requestAdminCompanyDetails, requestAdminGetAddresses
} from "../Redux/actions";
import Swal from "sweetalert2";
import axios from 'axios';

function CompanyContact(props) {
  const [pagination, setpagination] = useState({});
  const navigate = useNavigate();
  const [change, setchange] = useState(false)
  const [pages, setpages] = useState([]);
  const [page_size, setpage_size] = useState(0);
  const [error, seterror] = useState("");
  const [erroraddress, seterroraddress] = useState(0);
  const [errordepartment, seterrordepartment] = useState("");
  const [erroremail, seterroremail] = useState("");
  const [errorphone_no, seterrorphone_no] = useState("");
  const [errorposition, seterrorposition] = useState("");
  const [id, setid] = useState(0);  
  const [addressId, setaddressId] = useState(0);
  const [addresses, setaddress] = useState([]);
  const [companyName, setcomapnyname] = useState("");
  const [compContact, setcompContact] = useState([]);
  const [adddata, setadddata] = useState({
    name: "", department: "", remark: "", email: "", phone_no: "", position: "", address: 0
  });
  const [editdata, seteditdata] = useState({
    name: "", department: "", remark: "", email: "", phone_no: "", position: "", address: 0
  });

  const params = useParams();
  const [addmodal, setaddModal] = useState(false);
  const [editmodal, seteditModal] = useState(false);

  useEffect(() => {
    console.log('h');
    props.requestAdminCompanyDetails({
      id: params.id,
    });
    setcomapnyname(props.data.companyDeatilsData.data.data[0].name);
    console.log(props.data.companyDeatilsData.data.data[0].name);
  }, []);

  useEffect(() => {
    props.requestAdminGetAddresses({
      id: params.id,
    });
    setaddress(props.data.addressesData.data.data);
  }, []);

  // useEffect(() => {
  //   let addressData = props.data.loginData.data.data.addresses;
  //   if (addressData !== undefined) {
  //       setaddress(addressData);
  //   }
  // }, [props.data.loginData.data.data]);

  function validateadd() {
    let formIsValid = false;
    if (!adddata["name"]) {
      formIsValid = false;
      seterror("*Enter Contact Name.");
    } else {
      formIsValid = true;
      seterror("");
    }
    return formIsValid;
  }

  function departmentadd() {
    let formIsValid = false;
    if (!adddata["department"]) {
      formIsValid = false;
      seterrordepartment("*Enter Contact department.");
    } else {
      formIsValid = true;
      seterrordepartment("");
    }
    return formIsValid;
  }

  function positionadd() {
    let formIsValid = false;
    if (!adddata["position"]) {
      formIsValid = false;
      seterrorposition("*Enter Contact position.");
    } else {
      formIsValid = true;
      seterrorposition("");
    }
    return formIsValid;
  }

  function emailadd() {
    let formIsValid = false;
    if (!adddata["email"]) {
      formIsValid = false;
      seterroremail("*Enter Contact email.");
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }

  function phone_noadd() {
    let formIsValid = false;
    if (!adddata["phone_no"]) {
      formIsValid = false;
      seterrorphone_no("*Enter Contact phone_no.");
    } else {
      formIsValid = true;
      seterrorphone_no("");
    }
    return formIsValid;
  }

  function validateaddress() {
    let formIsValid = false;
    if (addressId === "0") {
      formIsValid = false;
      seterroraddress("*Select contact address.");
    } else if (typeof addressId === "undefined") {
      formIsValid = false;
      seterroraddress("*Select contact address.");
    } else {
      formIsValid = true;
      seterroraddress("");
    }
    return formIsValid;
  }

  function validateAddForm() {
    let validate = validateadd(); let department = departmentadd(); let address = validateaddress();
    let email = emailadd(); let phone_no = phone_noadd(); let position = positionadd();
    let valid = validate && address && department && email && phone_no && position;
    return valid;
  }

  function validateedit() {
    let formIsValid = false;
    if (!editdata["name"]) {
      formIsValid = false;
      seterror("*Enter Contact name.");
    } else if (typeof editdata["name"] !== "undefined") {
      if (!editdata["name"].match(/^[a-zA-Z][a-zA-Z\s]*$/)) {
        formIsValid = false;
        seterror("*Please enter Alphabet characters only.");
      } else {
        formIsValid = true;
        seterror("");
      }
    }
    return formIsValid;
  }
  function departmentedit() {
    let formIsValid = false;
    if (!editdata["department"]) {
      formIsValid = false;
      seterrordepartment("*Enter department.");
    } else {
      formIsValid = true;
      seterrordepartment("");
    }
    return formIsValid;
  }
  function positionedit() {
    let formIsValid = false;
    if (!editdata["position"]) {
      formIsValid = false;
      seterrorposition("*Enter Contact position.");
    } else {
      formIsValid = true;
      seterrorposition("");
    }
    return formIsValid;
  }

  function emailedit() {
    let formIsValid = false;
    if (!editdata["email"]) {
      formIsValid = false;
      seterroremail("*Enter Contact email.");
    } else {
      formIsValid = true;
      seterroremail("");
    }
    return formIsValid;
  }

  function phone_noedit() {
    let formIsValid = false;
    if (!editdata["phone_no"]) {
      formIsValid = false;
      seterrorphone_no("*Enter Contact phone_no.");
    } else {
      formIsValid = true;
      seterrorphone_no("");
    }
    return formIsValid;
  }

  function validateEditForm() {
    let validate = validateedit(); let department = departmentedit(); let address = validateaddress();
    let email = emailedit(); let phone_no = phone_noedit(); let position = positionedit();
    let valid = validate && address && department && email && phone_no && position;
    return valid;
  }

  function onChangeAddress(e){
    setaddressId(e.target.value);
  }
  function onchangeadddata(e) {
    setadddata((adddata) => ({
      ...adddata,
      [e.target.name]: e.target.value,
    }));
  }
  function onchangeeditdata(e) {
    seteditdata((editdata) => ({
      ...editdata,
      [e.target.name]: e.target.value,
    }));
  }

  const addtoggle = () => setaddModal(!addmodal);
  const saveaddtoggle = (e) => {
    e.preventDefault();
    if (validateAddForm()) {
      console.log(id);
      props.requestAdminAddCompContact({
        data: {
          company_id: params.id,
          name: adddata.name,
          department: adddata.department,
          address_id: addressId,
          user_id: 1,          
          remark: adddata.remark,
          email: adddata.email,
          phone_no: adddata.phone_no,
          position: adddata.position
        },
      });
      setadddata({
        name: "",
        department: "",
        addressId: 0,
        remark: "",
        email: "",
        phone_no: "",
        position: ""
      });
      addtoggle();
    }
  };

  useEffect(() => {
    let addCompContactData = props.data.addCompContactData;
    if (addCompContactData !== undefined) {
      if (addCompContactData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Contact added successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.addCompContactData = undefined;
  }, [props.data.addCompContactData]);

  const edittoggle = (id) => {
    if (editmodal === true) {
      seteditModal(!editmodal);
    } else {
      seteditModal(!editmodal);
      setid(id);
      seteditdata({
        name: " ",
        department: " ",
        name: " ",
        department: " ",
        addressId: 0,
        remark: " ",
        email: " ",
        phone_no: " ",
        position: " "
      });
      props.requestAdminGetCompContact({
        id: id,
      });
    }
  };

  useEffect(() => {
    console.log(props.data);
    let compContact = props.data.getCompContactData;
    if (compContact !== undefined) {
      if (compContact.data) {
        seteditdata(compContact.data.data.CompContact[0]);
      }
    }
  }, [props.data.getCompContactData]);

  const saveedittoggle = (e) => {
    e.preventDefault();
    console.log('here');
    if (validateEditForm()) {
      console.log(id);
      props.requestAdminEditCompContact({
        id: id,
        data: {
          name: editdata.name,
          department: editdata.department,
          address_id: addressId,
          user_id: 1,    
          remark: editdata.remark,
          email: editdata.email,
          phone_no: editdata.phone_no,
          position: editdata.position
        },
      });
      seteditdata({
        name: "",
        department: "",
        addressId: 0,
        remark: "",
        email: "",
        phone_no: "",
        position: ""
      });
      setid(0);
      edittoggle();
    }
  };

  useEffect(() => {
    let editCompContactData = props.data.editCompContactData;
    if (editCompContactData !== undefined) {
      if (editCompContactData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Contact updated successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.editCompContactData = undefined;
  }, [props.data.editCompContactData]);

  useEffect(() => {
    props.requestAdminAllCompContact({
      id: params.id
    });
    setpage_size(params.page_size);
  }, [params.page, params.page_size, change]);

  useEffect(() => {
    let allCompContactData = props.data.allCompContactData.data.data;
    console.log('comapny data');
    console.log(allCompContactData);
    if (allCompContactData !== undefined) {
      if (allCompContactData) {
        setcompContact(allCompContactData);
      }
    }
  }, [props.data.allCompContactData]);
  const CustomUI = ({ onSubmit, onCancel }) => (
    <div
      className="custom-ui"
      style={{
        backgroundColor: "white",
        width: "350px",
        padding: "25px",
        border: "1px solid black",
        margin: "auto",
      }}
    >
      <h3>Are you sure?</h3>
      <p>
        Do you really want to delete this records? This process cannot be
        undone.
      </p>
      <div>
        <button
          onClick={onSubmit}
          style={{ border: "0px", backgroundColor: "green", color: "white", padding: "2px 10px" }}
        >
          Yes
        </button>
        <button
          onClick={onCancel}
          style={{
            border: "0px",
            backgroundColor: "red",
            color: "white",
            marginLeft: "10px",
            padding: "2px 10px"
          }}
        >
          No
        </button>
      </div>
    </div>
  );
  function deletedata(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        props.requestAdminDeleteCompContact({
          id: id,
        });
      }
    })
  }

  useEffect(() => {
    let deleteCompContactData = props.data.deleteCompContactData;
    if (deleteCompContactData !== undefined) {
      if (deleteCompContactData.data) {
        Swal.fire({
          title: 'Good job!',
          text: 'Company Contact deleted successfully..',
          icon: 'success'
        });
        setchange(!change)
      }
    }
    props.data.deleteCompContactData = undefined;
  }, [props.data.deleteCompContactData]);

  function printPage() {
    var printContents =
      "<br/><center><h3>Contacts of " + props.data.companyDeatilsData.data.data[0].name + "</h3></center>" +
      document.getElementById("printme").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <>
      <div class="container-scroller">
        <Header name="Contacts of "{...companyName} />
        <div class="container-fluid page-body-wrapper">
          <Sidebar name="Options" />

          <div class="main-panel">
            <div class="content-wrapper">
              <div class="row">
                <div class="col-sm-12">
                  <div class="home-tab">
                    <div class="row flex-grow">
                      <div class="col-12 grid-margin stretch-card">
                        <div class="card card-rounded">
                          <div class="card-body">
                            <div class="d-sm-flex justify-content-between align-items-start">
                              <div class="btn-wrapper">
                                <RWebShare
                                  data={{
                                    text: "Job Portal",
                                    url: `http://localhost:3000/admin/compdepartment/${params.page}/${params.page_size}`,
                                    title: "Job Portal",
                                  }}
                                >
                                  <p class="btn btn-otline-dark align-items-center">
                                    <i class="icon-share"></i> Share
                                  </p>
                                </RWebShare>
                                <button
                                  onClick={printPage}
                                  class="btn btn-otline-dark"
                                >
                                  <i class="icon-printer"></i> Print
                                </button>
                              </div>
                              <div>
                                <button
                                  class="btn btn-primary btn-md text-white mb-0 me-0"
                                  type="button"
                                  onClick={addtoggle}
                                >
                                  Add new Contact
                                </button>
                                <Modal isOpen={addmodal} toggle={addtoggle}>
                                  <ModalHeader toggle={addtoggle}>
                                    Add Contact
                                  </ModalHeader>
                                  <ModalBody>
                                    <form class="forms-sample">
                                      <div class="form-group">
                                        <label>Name</label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Name"
                                          name="name"
                                          value={adddata.name}
                                          onBlur={validateadd}
                                          onChange={onchangeadddata}
                                        />
                                        {error && <p>{error}</p>}
                                        <label> <br /> Department </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Name"
                                          name="department"
                                          value={adddata.department}
                                          onBlur={departmentadd}
                                          onChange={onchangeadddata}
                                        />
                                        {errordepartment && <p>{errordepartment}</p>}
                                        <div class="row">
                                          <div class="col-lg-12 col-md-12">
                                            <div class="form-group">
                                              <label>Address</label>
                                              <select
                                                class="form-select"
                                                name="address"
                                                value={addressId}
                                                onBlur={validateaddress}
                                                onChange={onChangeAddress}
                                              >
                                                <option value="0">Select Address</option>
                                                {addresses.map((option) => (
                                                  <option value={option.id}>
                                                    {option.name}
                                                  </option>
                                                ))}
                                              </select>
                                              {erroraddress && <p>{erroraddress}</p>}
                                            </div>
                                          </div>
                                        </div>
                                        <label> <br /> Email </label>
                                        <input
                                          type="email"
                                          class="form-control"
                                          placeholder="Email"
                                          name="email"
                                          value={adddata.email}
                                          onBlur={emailadd}
                                          onChange={onchangeadddata}
                                        />
                                        <label> <br /> Phone No </label>
                                        <input
                                          type="tel"
                                          class="form-control"
                                          placeholder="Phone No"
                                          name="phone_no"
                                          value={adddata.phone_no}
                                          onBlur={phone_noadd}
                                          onChange={onchangeadddata}
                                        />
                                        <label> <br /> Position </label>
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Position"
                                          name="position"
                                          value={adddata.position}
                                          onBlur={positionadd}
                                          onChange={onchangeadddata}
                                        />

                                        <label>
                                          <br />
                                          Remark
                                        </label>
                                        <textarea
                                          name="remark"
                                          class="form-control"
                                          placeholder="Remark"
                                          style={{ height: "100px" }}
                                          value={adddata.remark}
                                          onChange={onchangeadddata}
                                        >
                                          Remark
                                        </textarea>
                                      </div>
                                      <button
                                        type="submit"
                                        class="btn btn-primary me-2"
                                        onClick={saveaddtoggle}
                                      >
                                        Submit
                                      </button>
                                      <button
                                        class="btn btn-light"
                                        onClick={addtoggle}
                                      >
                                        Cancel
                                      </button>
                                    </form>
                                  </ModalBody>
                                </Modal>
                              </div>
                            </div>
                            <br />
                            <div class="table-responsive  mt-1" id="printme">
                              <table class="table select-table">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Position</th>
                                    <th>Department</th>
                                    <th>Address</th>
                                    <th>Remark</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {compContact.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          <div class="d-flex ">
                                            <div>
                                              <h6>{item.name}</h6>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <p>Phone No.: {item.phone_no}</p>
                                          <p>Email: {item.email}</p>
                                        </td>
                                        <td>
                                          <p>{item.position}</p>
                                        </td>
                                        <td>
                                          <p>{item.department}</p>
                                        </td>
                                        <td>
                                          <p>{item.address}</p>
                                        </td>
                                        <td>
                                          <p>{item.remark}</p>
                                        </td>
                                        <td>
                                          <p>{item.created_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <p>{item.updated_at.substring(0, 10)}</p>
                                        </td>
                                        <td>
                                          <button
                                            class="badge badge-opacity-success"
                                            style={{
                                              border: "0px",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {
                                              edittoggle(item.id);
                                            }}
                                          >
                                            <i class="fa fa-pencil-square-o"></i>
                                          </button>
                                          {editmodal === true && (
                                            <Modal
                                              isOpen={editmodal}
                                              toggle={edittoggle}
                                            >
                                              <ModalHeader toggle={edittoggle}>
                                                Edit Company's Contact
                                              </ModalHeader>
                                              <ModalBody>
                                                <form class="forms-sample">
                                                  <div class="form-group">
                                                  <label>Name</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Name"
                                                      name="name"
                                                      value={editdata.name}
                                                      onBlur={validateedit}
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    />
                                                    {error && <p>{error}</p>}
                                                    <label>Department</label>
                                                    <input
                                                      type="text"
                                                      class="form-control"
                                                      placeholder="Department"
                                                      name="department"
                                                      value={editdata.department}
                                                      onBlur={departmentedit}
                                                      onChange={
                                                        onchangeeditdata
                                                      }
                                                    />
                                                    {errordepartment && <p>{errordepartment}</p>}

                                                    <div class="row">
                                                      <div class="col-lg-4 col-md-4">
                                                        <div class="form-group">
                                                          <label>Address</label>
                                                          <select
                                                            class="form-select"
                                                            name="address"
                                                            value={editdata.addressId}
                                                            onBlur={validateaddress}
                                                            // onChange={onChangeCountry}
                                                          >
                                                            <option value="0">Select Address</option>
                                                            {addresses.map((option) => (
                                                              <option value={option.id}>
                                                                {option.name}
                                                              </option>
                                                            ))}
                                                          </select>
                                                          {erroraddress && <p>{erroraddress}</p>}
                                                        </div>
                                                      </div>
                                                    </div>                                        
                                                    <label> <br /> Email </label>
                                                    <input type="email" class="form-control" placeholder="Email"
                                                      name="email" value={editdata.email} onBlur={emailedit}
                                                      onChange={onchangeeditdata} />
                                                    <label> <br /> Phone No </label>
                                                    <input type="tel" class="form-control" placeholder="Phone No"
                                                      name="phone_no" value={editdata.phone_no} onBlur={phone_noedit}
                                                      onChange={onchangeeditdata} />
                                                    <label> <br /> Position </label>
                                                    <input type="text" class="form-control" placeholder="Position"
                                                      name="position" value={editdata.position} onBlur={positionedit}
                                                      onChange={onchangeeditdata} />
                                                    <label> <br /> Remark </label>
                                                    <textarea
                                                      name="remark" class="form-control" placeholder="Remark"
                                                      style={{ height: "100px", }} value={ editdata.remark }
                                                      onChange={ onchangeeditdata } > Remark </textarea>
                                                  </div>
                                                  <button
                                                    type="submit"
                                                    class="btn btn-primary me-2"
                                                    onClick={saveedittoggle}
                                                  >
                                                    Submit
                                                  </button>
                                                  <button
                                                    class="btn btn-light"
                                                    onClick={edittoggle}
                                                  >
                                                    Cancel
                                                  </button>
                                                </form>
                                              </ModalBody>
                                            </Modal>
                                          )}
                                          <button
                                            class="badge badge-opacity-danger"
                                            style={{
                                              border: "0px",
                                              background: "#fea8a9",
                                              marginLeft: "5px",
                                            }}
                                            onClick={() => {

                                              deletedata(item.id);

                                            }}
                                          >
                                            <i class="fa fa-trash"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return { data: state.data };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestAdminAddCompContact,
      requestAdminAllCompContact,
      requestAdminGetCompContact,
      requestAdminDeleteCompContact,
      requestAdminEditCompContact, requestAdminCompanyDetails, requestAdminGetAddresses
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyContact);
